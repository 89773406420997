export function PrintIcon() {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22222 21.3125H3C1.89543 21.3125 1 20.4171 1 19.3125V10.25C1 8.59315 2.34315 7.25 4 7.25H7.22222M7.22222 21.3125V16.625H22.7778V21.3125M7.22222 21.3125V24C7.22222 25.1046 8.11765 26 9.22222 26H20.7778C21.8823 26 22.7778 25.1046 22.7778 24V21.3125M22.7778 21.3125H27C28.1046 21.3125 29 20.4171 29 19.3125V10.25C29 8.59315 27.6569 7.25 26 7.25H22.7778M7.22222 7.25V3C7.22222 1.89543 8.11765 1 9.22222 1H20.7778C21.8823 1 22.7778 1.89543 22.7778 3V7.25M7.22222 7.25H22.7778M19.6667 11.9375H22.7778"
        stroke="#2BE2D8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
