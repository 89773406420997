import { Outlet } from "react-router-dom";
import { SidebarMenu } from "./components/Layout/SidebarMenu";

export function Journalist() {
  return (
    <div className="md:-mt-12 flex flex-col lg:flex-row lg:space-x-5 md:container mx-auto font-['Inter'] mb-12">
      <div className="w-fill lg:w-fit">
        <SidebarMenu />
      </div>
      <div className="w-full h-full md:rounded-2xl p-5 md:border-2 bg-[#FAFAFA]">
        <Outlet />
      </div>
    </div>
  );
}
