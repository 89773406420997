import { ButtonColors, ButtonSizes } from "../../constants";
import { LoadingIcon } from "../Icons";

export const FileInput = ({
  name = "",
  label = "Submit",
  children = null,
  theme = "default",
  size = "default",
  setValue = null,
  className = "",
  errors = {},
  multiple = false,
  accept = "*",
  isLoading = false,
}) => {
  let defaultClass = "btn text-center cursor-pointer";
  let buttonClass =
    defaultClass +
    " " +
    (ButtonColors[theme] ? ButtonColors[theme] : ButtonColors.secondary) +
    " " +
    (ButtonSizes[size] ? ButtonSizes[size] : ButtonSizes.default);

  buttonClass += " " + className;

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (setValue) {
      setValue(name, files);
    }
  };

  return (
    <>
      <label htmlFor={name} className={`${buttonClass}`}>
        {isLoading ? <LoadingIcon theme="dark" /> : ""}
        {children}
        {label}
      </label>
      <input
        type="file"
        id={name}
        multiple={multiple}
        accept={accept}
        className={"hidden"}
        onChange={handleFileChange}
      />
      {errors?.[name] && <span>This field is required</span>}
    </>
  );
};
