import { useForm } from "react-hook-form";
import { InfluencerQuestions } from "../../constants";
import { CategoryEditor } from "../../components/Sections/CategoryEditor";
import { Section } from "../../components/Layout/Section";
import { Publish } from "../../components/Sections/Publish";
import { SEO } from "../../components/Sections/SEO";
import { TagEditor } from "../../components/Sections/TagEditor";
import Title from "../../components/Title";
import Image from "../../components/Inputs/Image";
import Audio from "../../components/Inputs/Audio";
import Video from "../../components/Inputs/Video";
import TextInput from "../../components/Inputs/TextInput";
import Wysiwyg from "../../components/Inputs/Wysiwyg";
import { Influencer } from "../../utils/models";
import React, { Suspense, useEffect, useState } from "react";
import Alert from "../../components/Alert";
import { QuestionPickerMulti } from "../../components/Sections/QuestionPickerMulti";
import { Admin } from "../../components/Sections/Admin";
import InfluencerService from "../../services/InfluencerService";
import { sanitizeData } from "../../utils/forms";
import { parseMediaValue, uploadMedia } from "../../utils/media";
import { handleRedirect } from "../../utils/redirects";
import { formatLinks } from "../../utils/links";
import Spinner from "../../components/Spinner";

export function Create() {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<Influencer>();

  // Basics
  const [isLoading, setLoading] = useState("");
  const [response, setResponse] = useState(null);
  const [redirect, setRedirect] = useState(null);

  handleRedirect(redirect);

  // Questions
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    setValue("interviewQuestions", questions);
  }, [questions]);

  // Media
  const [media, setMedia] = useState({});
  const setMediaItem = (name, value, order = 0) => {
    let newMedia = parseMediaValue(name, value, order);
    if (newMedia) {
      for (let i in newMedia) {
        media[i] = newMedia[i];
      }
    }
    setMedia(media);
  };

  // Links
  const [links, setLinks] = useState({});
  const setLink = (name, value) => {
    const newLinks = links;
    newLinks[name] = value;
    setLinks(newLinks);
    setValue("links", formatLinks(newLinks));
  };
  useEffect(() => {
    setValue("links", formatLinks(links));
  }, [links]);

  // SEO Section
  const setSeoValue = (name, value) => {
    if ("object" === typeof value) {
      for (let i in value) {
        if (value[i] instanceof File) {
          setMediaItem(i, { files: [value[i]] });
        }
      }
    }
  };

  // Submit handler
  const onSubmit = async (data) => {
    setLoading(data["status"]);
    try {
      const postResponse = await InfluencerService.create(sanitizeData(data));
      await uploadMedia(media, postResponse.data.response);
      setLoading("");
      setResponse({
        type: "success",
        message:
          data["status"] === "draft"
            ? "Influencer published as draft successfully."
            : "Influencer published successfully.",
      });
      setTimeout(() => {
        setRedirect({ post: postResponse.data.response });
      }, 2000);
    } catch (e: any) {
      setResponse({ type: "danger", message: e.message });
      setLoading("");
    }
  };

  return (
    <Suspense
      fallback={
        <div className="text-center space-y-4">
          <Spinner />
        </div>
      }
    >
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <Title text="Add New Influencer" />

        {response && <Alert type={response.type} text={response.message} />}

        <TextInput
          label=""
          name="title"
          placeholder="Add title"
          register={register}
          errors={errors.title}
          setValue={setValue}
          getValues={getValues}
        />

        <Section label="Introduction">
          <Wysiwyg
            name="influencerIntro"
            setValue={(n, v) => {
              setValue(n, v);
            }}
          />
        </Section>

        <Section label="Publish">
          <Publish
            label={""}
            setValue={setValue}
            getValues={getValues}
            loading={isLoading}
          />
        </Section>

        <div className="space-y-0">
          <div className="grid grid-cols-2">
            <Section label="Name">
              <TextInput
                className="p-3"
                name="influencerName"
                label=""
                setValue={setValue}
                getValues={getValues}
              />
            </Section>
            <Section label="Company">
              <TextInput
                className="p-3"
                name="influencerCompany"
                label=""
                setValue={setValue}
                getValues={getValues}
              />
            </Section>
          </div>
          <div className="grid grid-cols-2">
            <Section label="Position">
              <TextInput
                className="p-3"
                name="influencerJobTitle"
                label=""
                setValue={setValue}
                getValues={getValues}
              />
            </Section>
            <Section label="Email">
              <TextInput
                className="p-3"
                name="email"
                label=""
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
          </div>
          <div className="grid grid-cols-2">
            <Section label="Website">
              <TextInput
                className="p-3"
                name="website"
                label=""
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
            <Section label="Cityverse">
              <TextInput
                className="p-3"
                name="cityverse"
                label=""
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
          </div>
          <div className="grid grid-cols-2">
            <Section label="Twitter">
              <TextInput
                className="p-3"
                name="twitter"
                label=""
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
            <Section label="Facebook">
              <TextInput
                className="p-3"
                name="facebook"
                label=""
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
          </div>
          <div className="grid grid-cols-2">
            <Section label="Instagram">
              <TextInput
                className="p-3"
                name="instagram"
                label=""
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
            <Section label="Linkedin">
              <TextInput
                className="p-3"
                name="linkedin"
                label=""
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
          </div>
        </div>

        <QuestionPickerMulti
          name="interviewQuestions"
          setValue={(name, data) => {
            if (data) {
              setQuestions(data);
            }
          }}
          getValues={getValues}
          questions={InfluencerQuestions}
        />

        <div className="grid grid-cols-2 gap-3">
          <Section label="Thumbnail Photo">
            <Image
              className="p-3"
              name="featured_image_full"
              setValue={setMediaItem}
              getValues={getValues}
              showCaptionInput={false}
            />
          </Section>
          <Section label="Internal Photo">
            <Image
              className="p-3"
              name="influencer_alternative_photo_full"
              setValue={setMediaItem}
              getValues={getValues}
              showCaptionInput={false}
            />
          </Section>
        </div>

        <div className="grid gird-cols-1 xl:grid-cols-2 gap-4">
          <Section label="Tags">
            <TagEditor name="tags" setValue={setValue} getValues={getValues} />
          </Section>
          <Section label="Categories">
            <CategoryEditor
              name="categories"
              setValue={setValue}
              getValues={getValues}
            />
          </Section>
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Section label="Featured Audio">
            <Audio
              className="p-3"
              name="audio"
              setValue={setMediaItem}
              getValues={getValues}
              showCaptionInput={false}
            />
          </Section>
          <Section label="Featured Video">
            <Video
              className="p-3"
              name="interview_recording"
              setValue={setMediaItem}
              getValues={getValues}
              showCaptionInput={false}
            />
          </Section>
        </div>

        <Section label="Admin">
          <Admin
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            register={register}
          />
        </Section>

        <Section label="SEO">
          <SEO
            name="meta"
            register={register}
            setValue={setSeoValue}
            errors={errors.meta}
          />
        </Section>
      </form>
    </Suspense>
  );
}
