import BaseService from "./BaseService";

const getAll = (params) => {
  return BaseService.getAll("medias", params, true);
};

const create = (data) => {
  return BaseService.create("medias", data, true);
};

const update = (id, data) => {
  return BaseService.update(`medias/${id}`, data, true);
};

const uploadToAzure = async (url, file: File) => {
  const body = new Blob([file], { type: "application/octet-stream" });
  return await fetch(url, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/octet-stream",
      "x-ms-blob-type": "BlockBlob",
    }),
    body,
  });
};

const setPostMedia = (post_id, media_id, key, order = 0) => {
  return BaseService.create(`journalist/posts/${post_id}/medias/${media_id}`, {
    key: key,
    order: order,
  });
};

const removePostMedia = (post_id, media_id, key) => {
  return BaseService.remove(`journalist/posts/${post_id}/medias/${media_id}`, {
    key: key,
  });
};

const MediaService = {
  getAll,
  create,
  update,
  setPostMedia,
  removePostMedia,
  uploadToAzure,
};

export default MediaService;
