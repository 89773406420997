import { queryRequest } from "../../utils/request";

const API_BASE = "api_v1";

const getAll = (path, params, authenticated = true) => {
  return queryRequest({
    path: path,
    params: params ? "?" + new URLSearchParams(params).toString() : "",
    method: "GET",
    authenticated: authenticated,
  });
};

const get = (path, authenticated = true) => {
  return queryRequest({
    path: path,
    method: "GET",
    authenticated: authenticated,
  });
};

const create = (path, data, authenticated = true) => {
  return queryRequest({
    path: path,
    method: "POST",
    body: data,
    authenticated: authenticated,
  });
};

const update = (path, data, authenticated = true) => {
  return queryRequest({
    path: path,
    method: "PUT",
    body: data,
    authenticated: authenticated,
  });
};

const remove = (path, data, authenticated = true) => {
  return queryRequest({
    path: path,
    method: "DELETE",
    body: data,
    authenticated: authenticated,
  });
};

const BaseService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default BaseService;
