import { lazy, useEffect, useState } from "react";
const ReactQuill = lazy(() => import("react-quill"));

const Wysiwyg = ({
  name,
  value = "",
  className = "",
  setValue = null,
  getValues = null,
  register = null,
  onBlur = null,
}) => {
  const [numWords, setNumWords] = useState(0);
  const [inputValue, setInputValue] = useState(value);
  const onEditorChange = (newValue: string) => {
    if (setValue) {
      setValue(name, newValue);
    }
    setInputValue(newValue);
    setNumWords(newValue.trim().split(/\s+/).length);
  };

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const wrapperClass = "bg-white";

  return (
    <>
      <div className={wrapperClass}>
        <ReactQuill
          {...(register ? register(name) : {})}
          className={`${className}`}
          value={inputValue}
          theme="snow"
          onChange={onEditorChange}
          onBlur={(r, e) => {
            if (onBlur) {
              onBlur(inputValue);
            }
          }}
          style={{
            height: "20em",
            marginBottom: "2.6em",
          }}
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [
                  "bold",
                  "italic",
                  "underline",
                  "color",
                  { list: "bullet" },
                  "link",
                ],
                ["image", "video"],
              ],
            },
          }}
        />
        <div className="bg-gray-50 ql-toolbar ql-snow !py-0">
          <small>Word count: {numWords}</small>
        </div>
      </div>
    </>
  );
};

export default Wysiwyg;
