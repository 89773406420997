export function Section({ label, children, className = "" }) {
  let fullClassName = "";
  if (className) {
    fullClassName += className;
  }

  return (
    <>
      <div className={fullClassName}>
        <div className="bg-white border border-gray px-2 py-2 !border-b-0 font-bold">
          {label}
        </div>
        <div className="bg-white border border-gray p-4 p-0">{children}</div>
      </div>
    </>
  );
}
