import Skeleton from "@mui/material/Skeleton";

export function KeepExploringLoading() {
  return (
    <div>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="rounded-2xl opacity-70 flex mb-5"
        animation="pulse"
        height={142}
        variant="rectangular"
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="opacity-70 flex mb-4"
        animation="pulse"
        height={32}
        width={85}
        variant="rectangular"
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="rounded-2xl opacity-70 flex"
        animation="pulse"
        height={48}
        variant="rectangular"
      />
    </div>
  );
}
