import Skeleton from "@mui/material/Skeleton";

type CreateLoadingProps = {
  index: number;
  isMobile?: Boolean;
};

export function CreateLoading({ index, isMobile }: CreateLoadingProps) {
  const height = index > 1 ? 279 : 440;
  const colSpan =
    index > 1 ? "col-span-3 md:col-span-6 xl:col-span-3" : "md:col-span-6";

  return (
    <div className={`${colSpan} col-span-12`}>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="rounded-t-2xl opacity-70"
        animation="pulse"
        height={isMobile ? 325 : height}
        variant="rectangular"
      />
    </div>
  );
}
