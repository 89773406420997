import { useMemo } from "react";
import DropdownSimple from "../Inputs/DropdownSimple";
import DropdownCategories from "../Inputs/DropdownCategories";
import DropdownAuthors from "../Inputs/DropdownAuthors";
import Button from "../Inputs/Button";
import useObject from "../Hooks/useObject";
import { FilterIcon } from "../Icons";
import { dateRangeTodayToYear } from "../../utils/dates";

const DataFilters = ({
  type = "post",
  datesEndYear = 2018,
  className = "",
  onFilter = null,
  excludedFilters = [],
}) => {
  const [filters, setFilterValue] = useObject({
    date_range: "",
    category: "",
    format: "",
    author: "",
  });

  const dateRange = useMemo(
    () =>
      [
        {
          label: "All Dates",
          value: "all",
        },
      ].concat(dateRangeTodayToYear(datesEndYear)),
    [datesEndYear]
  );

  const formats = [
    {
      label: "All Formats",
      value: "all",
    },
    {
      label: "Gallery",
      value: "gallery",
    },
    {
      label: "Video",
      value: "video",
    },
  ];

  const setValue = (name, value) => {
    if ("date_range" === name && "all" == value) {
      value = "";
    }
    filters[name] = value;
    setFilterValue(filters);
  };

  const AllExcluded = excludedFilters.length
    ? excludedFilters.every((el) =>
        ["date_range", "category", "author"].includes(el)
      )
    : false;

  return !AllExcluded ? (
    <div
      className={`space-y-2 lg:space-y-0 lg:space-x-2 flex flex-col lg:flex-row w-full ${className}`}
    >
      {!excludedFilters.includes("date_range") && (
        <DropdownSimple
          size=""
          label=""
          name="date_range"
          setValue={setValue}
          options={dateRange}
        />
      )}
      {!excludedFilters.includes("category") && (
        <DropdownCategories
          label=""
          name="category"
          placeholder="All Categories"
          setValue={setValue}
        />
      )}
      {/* HIDE MVP
      <DropdownSimple
        label=""
        name="format"
        setValue={setValue}
        options={formats}
      /> */}
      {!excludedFilters.includes("author") && (
        <DropdownAuthors
          label=""
          name="author"
          placeholder="All Authors"
          setValue={setValue}
        />
      )}
      <div className="flex items-stretch">
        <Button
          className="flex items-center"
          onClick={() => {
            if (onFilter) {
              onFilter(filters);
            }
          }}
        >
          <FilterIcon />
          <span className="ml-1">Filter</span>
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default DataFilters;
