import { range, swap } from "../../utils/arrays";
import Image from "../Inputs/Image";
import SortButton from "../Inputs/SortButton";
import { Fragment, useEffect, useState } from "react";

export function ImagePickerMulti({
  name,
  number,
  setValue,
  value = {},
  className = "",
  getValues,
}) {
  if (number > 3 || !number) {
    number = 3;
  }

  const numberItems = number;
  const numberSortIcons = number - 1;
  const itemsWidth = parseInt((12 - numberSortIcons) / numberItems + "");
  const numberTotal = numberItems + numberSortIcons;
  const wrapperClass = `flex flex-col lg:flex-row items-center justify-center ${className}`;
  const columnClass = `w-${itemsWidth}/12`;

  const initialValues = range(0, numberItems).map((item, index) => {
    let key = name.replace("{index}", index);
    return {
      id: value[index]?.media?.id,
      files: null,
      preview: value[index]?.media?.cdn_source_url,
      order: value[index]?.order,
    };
  });

  const [mediaItems, setMediaItems] = useState(initialValues);
  const getPreview = (name, index) => {
    return mediaItems[index] && mediaItems[index].preview
      ? mediaItems[index].preview
      : "";
  };

  useEffect(() => {
    setValue(
      name,
      mediaItems.map((value, index) => {
        return { key: name.replace("{index}", index), value: value };
      })
    );
  }, [mediaItems]);

  return (
    <div className={wrapperClass}>
      {mediaItems.map((item, index) => {
        let itemName = `${name.replace("{index}", index)}`;
        return (
          <Fragment key={`${name}.${index}.wrap`}>
            <div key={`${name}.${index}.image`} className={`${columnClass}`}>
              <Image
                key={itemName}
                name={itemName}
                showCaptionInput={false}
                showUrlInput={false}
                setValue={(name, value) => {
                  let newValues = [...mediaItems];
                  newValues[index] = value;
                  setMediaItems(newValues);
                }}
                value={{
                  preview: getPreview(itemName, index),
                }}
                onClear={(value) => {
                  let newValues = [...mediaItems];
                  newValues[index] = null;
                  setMediaItems(newValues);
                }}
                full={true}
              />
            </div>

            {index >= 0 && index < numberItems - 1 && (
              <div key={`${name}.${index}.sort`} className={`${"w-1/12"}`}>
                <div className="text-center">
                  <SortButton
                    index={index}
                    onSort={(ind, direction) => {
                      let newItems = [...mediaItems];
                      let index1, index2;
                      if ("f" === direction) {
                        index1 = ind;
                        index2 = ind + 1;
                      } else {
                        index1 = ind + 1;
                        index2 = ind;
                      }
                      const tmp = newItems[index1];
                      newItems[index1] = newItems[index2];
                      newItems[index2] = tmp;
                      setMediaItems(newItems);
                    }}
                  />
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
