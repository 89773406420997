import { useState } from "react";

export function useFormResponse(initialState) {
  const isCloned = localStorage.getItem("cloned");
  if (isCloned) {
    initialState = {
      type: "success",
      message: "Post cloned successfully.",
    };
    localStorage.removeItem("cloned");
  }

  const [response, setResponse] = useState(initialState);
  return [response, setResponse];
}
