import { ReactElement } from "react";
import { relativeTime } from "../../utils/formatDate";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { Badge } from "../Badge";
import { PostLink } from "../PostLink";
import { AuthorTile } from "../AuthorTile";
import placeholderImage from "./placeholder.jpg";
import type { PostType } from "../../types";

const transition = "transition duration-300";

type ArticleTileProps = {
  post?: PostType;
  badge?: ReactElement;
  isTaggedTile?: boolean;
};

export function ArticleTile({ post, badge, isTaggedTile }: ArticleTileProps) {
  const imageUrl =
    post?.featuredImage ??
    post?.originalJsonData?.featured_image?.medium ??
    placeholderImage;

  return (
    <PostLink
      className={`group lg:h-46 grid ${
        isTaggedTile ? "grid-cols-12" : "grid-cols-2"
      } gap-x-5 md:gap-x-0 lg:space-x-5 py-6 md:py-5 overflow-hidden items-center md:items-start`}
      slug={post?.slug}
      preloadData={post}
    >
      <div
        className={`order-2 md:order-none ${
          isTaggedTile ? "col-span-3" : "col-span-1"
        }`}
      >
        <div
          className={`ml-auto rounded-xl brightness-100 group-hover:brightness-75 ${
            isTaggedTile ? "h-[175px]" : "h-[72px]"
          } md:h-32 w-[120px] md:w-full ${transition} bg-cover`}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
      </div>

      <div
        className={`order-1 md:order-none ${
          isTaggedTile ? "col-span-9" : "col-span-1"
        } group-hover:opacity-70 ${transition}`}
      >
        <div className="flex items-center justify-between mb-3 pt-0 md:pt-5 lg:pt-0">
          <div className="flex space-x-2 items-center">
            {badge ?? <Badge title={post?.primaryCategory.name} />}
            <div className="font-normal">
              {relativeTime(post?.effectivePublishDate)}
            </div>
          </div>

          {isTaggedTile && (
            <div className="mr-4">
              <AuthorTile name={post?.author?.name} />
            </div>
          )}
        </div>

        <div
          title={sanitize(post?.title)}
          className={`group-hover:opacity-70 ${transition} line-clamp-2`}
          dangerouslySetInnerHTML={sanitizeForReact(post?.title)}
        />

        {isTaggedTile && (
          <div
            className={`group-hover:opacity-70 ${transition} line-clamp-4 mt-2 font-['PT+Serif'] text-sm`}
            dangerouslySetInnerHTML={sanitizeForReact(post?.excerpt)}
          />
        )}
      </div>
    </PostLink>
  );
}
