import React, { useEffect, useState } from "react";
import {
  PaginationBackward,
  PaginationEnd,
  PaginationForward,
  PaginationStart,
} from "../Icons";

import Button from "../Inputs/Button";
import PageNumber from "./PageNumber";

const DataPager = ({
  postsPerPage,
  totalRecordCount,
  totalPageCount,
  navigate,
  currentPage,
}) => {
  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    if (currentPage) {
      setPage(currentPage);
    }
  }, [currentPage]);

  return (
    <div>
      <nav
        className="relative z-0 inline-flex space-x-1 mt-4 lg:mt-0 flex-wrap lg:flex-nowrap items-center"
        aria-label="Pagination"
      >
        <span className="relative w-full lg:w-auto inline-flex px-2 py-2 font-medium">{`${totalRecordCount} items`}</span>
        <Button
          onClick={() => {
            navigate(0);
          }}
        >
          {PaginationStart()}
        </Button>
        <Button
          onClick={() => {
            if (currentPage > 0) {
              navigate("prev");
            }
          }}
        >
          {PaginationBackward()}
        </Button>
        <PageNumber
          name="pagination_number"
          className="w-16 text-center"
          inputClassName="text-center"
          label=""
          value={page}
        />
        <span className="relative inline-flex px-2 py-2 font-medium">{`of ${totalPageCount}`}</span>
        <Button
          onClick={() => {
            if (currentPage < totalPageCount) {
              navigate("next");
            }
          }}
        >
          {PaginationForward()}
        </Button>
        <Button
          onClick={() => {
            navigate(Infinity);
          }}
        >
          {PaginationEnd()}
        </Button>
      </nav>
    </div>
  );
};

export default DataPager;
