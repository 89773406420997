type InstagramIconProps = {
  addClassName?: string;
  addSvgClassName?: string;
};
export function InstagramIcon({
  addClassName = "text-black",
  addSvgClassName,
}: InstagramIconProps) {
  return (
    <span className={addClassName}>
      <svg
        className={addSvgClassName}
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2147_5385)">
          <path
            d="M14.9644 6.71875H9.32827C7.95083 6.71875 6.83203 7.83755 6.83203 9.21499V14.8511C6.83203 16.2285 7.95083 17.3473 9.32827 17.3473H14.9644C16.3418 17.3473 17.4606 16.2285 17.4606 14.8511V9.21499C17.4606 7.83755 16.3418 6.71875 14.9644 6.71875ZM12.1433 15.4165C10.2786 15.4165 8.76286 13.9007 8.76286 12.036C8.76286 10.1714 10.2786 8.65559 12.1433 8.65559C14.008 8.65559 15.5238 10.1714 15.5238 12.036C15.5238 13.9007 14.008 15.4165 12.1433 15.4165ZM15.638 9.31123C15.205 9.31123 14.8501 8.95634 14.8501 8.52326C14.8501 8.09018 15.205 7.73529 15.638 7.73529C16.0711 7.73529 16.426 8.09018 16.426 8.52326C16.426 8.95634 16.0711 9.31123 15.638 9.31123Z"
            fill="currentColor"
          />
          <path
            d="M12.1426 9.83984C10.9336 9.83984 9.95312 10.8203 9.95312 12.0293C9.95312 13.2383 10.9336 14.2188 12.1426 14.2188C13.3516 14.2188 14.3321 13.2383 14.3321 12.0293C14.3321 10.8203 13.3516 9.83984 12.1426 9.83984Z"
            fill="currentColor"
          />
          <path
            d="M12.1445 0C5.51596 0 0.144531 5.37143 0.144531 12C0.144531 18.6286 5.51596 24 12.1445 24C18.7731 24 24.1445 18.6286 24.1445 12C24.1445 5.37143 18.7731 0 12.1445 0ZM18.6588 14.7549C18.6588 16.8301 16.9746 18.5143 14.8994 18.5143H9.38964C7.31446 18.5143 5.63025 16.8301 5.63025 14.7549V9.24511C5.63025 7.16992 7.31446 5.48571 9.38964 5.48571H14.8994C16.9746 5.48571 18.6588 7.16992 18.6588 9.24511V14.7549Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2147_5385">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.144531)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
