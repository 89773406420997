import dayjs from "dayjs";
import relativeTimePlugin from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTimePlugin);

export function relativeTime(date: Date) {
  return dayjs().to(date);
}

export function prettyDate(date: Date) {
  return dayjs(date).format(`MMM D @ hh:mm A`);
}

export function formatDayMonthYear(date: Date) {
  return dayjs(date).format("DD/MM/YY");
}
