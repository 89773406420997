import { Outlet } from "react-router-dom";
import { News } from "./sections/News";
import { Zaps } from "./sections/Zaps";
import { Media } from "./sections/Media";
import { Create } from "./sections/Create";
// import { Originals } from "./sections/Originals";
// import { Events } from "./sections/Events";
import { Hustle } from "./sections/Hustle";
import { Influencer } from "./sections/Influencer";
import { Shuffle } from "./sections/Shuffle";
import { Partners } from "./sections/Partners";
import { NewsletterSignup } from "./sections/NewsletterSignup";
import { Footer } from "./sections/Footer";
import { CategoryHeader } from "./components/Category";
import { TabModule } from "./sections/TabModule";
import { Advertisement } from "./components/Advertisement";
import { Divider } from "./components/Divider";
import { categories } from "./constants";

type HomeProps = {
  category?: string;
};

const getCategory = (name: string) => {
  if (!name) {
    return null;
  }
  return categories.find((c) => c.name.toLowerCase() === name.toLowerCase());
};

export function Home({ category }: HomeProps) {
  const categoryData = category && getCategory(category);

  return (
    <>
      <div>
        {categoryData && (
          <div className="md:-mt-12 md:mb-4">
            <CategoryHeader
              category={categoryData?.name}
              intro={categoryData?.intro}
            />
          </div>
        )}
        <div className="grid xl:grid-cols-8 gap-5 py-3 px-4 md:p-0">
          <div className="col-span-12 xl:col-span-6">
            <News categoryData={categoryData} key={categoryData?.id} />
          </div>

          <Divider addClassName="md:hidden" />
          <div className="col-span-12 flex flex-col lg:flex-row xl:block xl:col-span-2 md:space-y-4">
            <Zaps isCategoryPage={!!categoryData} />
            <Divider addClassName="md:hidden mb-10" />
            <Advertisement
              path="/21735245768/SPC_HP_MREC_1T"
              size={[300, 250]}
              key="div-gpt-ad-1682306808816-0"
              id="div-gpt-ad-1682306808816-0"
            />
          </div>
        </div>
      </div>

      {!categoryData && (
        <>
          <Media />
          <Create />
          {/* <Originals /> */}
          <TabModule />
          {/* <Events /> */}
          <Influencer />
          <Hustle />
          <Shuffle />
          <Partners />
          <NewsletterSignup />
        </>
      )}

      <Footer />

      <Outlet />
    </>
  );
}
