import { useEffect, useMemo, useRef, useState } from "react";
import { useTable } from "react-table";
import Checkbox from "../Inputs/Checkbox";
import Link from "../Link";
import Spinner from "../Spinner";
import { CommentsIcon, ViewsIcon } from "../Icons";
import { prettyDate } from "../../../utils/formatDate";
import { useDetectMobile } from "../../../utils/isMobile";

const DataTable = ({
  data,
  titleKey = "title",
  onDataSelect = null,
  onRecordClick = null,
  columns = null,
  isLoading = true,
  pageSize = 10,
  excludedColumns = [],
}) => {
  const { isMobile } = useDetectMobile();
  const [records, setRecords] = useState(data);
  const recordsRef = useRef(null);

  useEffect(() => {
    setRecords(data);
    setSelectedRows(initialSelectedRows);
  }, [data]);
  recordsRef.current = records;

  const setAllCheckboxes = (value) => {
    const newData = getSelectedItemsState(value, value);
    setSelectedRows(newData);
    if (onDataSelect) {
      onDataSelect(newData);
    }
  };

  const getSelectedItemsState = (all, value) => {
    let state = { all: all };
    for (let i = 0; i < pageSize; i++) {
      state["row_" + i] = value;
    }
    return state;
  };

  const initialSelectedRows = getSelectedItemsState(false, false);
  const [selectedRows, setSelectedRows] = useState(initialSelectedRows);

  const tableColumns = useMemo(() => {
    let unfiltered = [
      {
        Header: (
          <Checkbox
            name="all"
            checked={selectedRows.all}
            onChange={setAllCheckboxes}
          />
        ),
        accessor: "select",
        width: "30px",
      },
      {
        Header: "Title",
        accessor: titleKey,
        width: "200px",
        Cell: (props) => {
          return (
            <Link
              key={props.value}
              text={props.value}
              onClick={(e) => {
                if (onRecordClick) {
                  onRecordClick(props.row.index);
                }
              }}
            />
          );
        },
      },
      {
        Header: "Author",
        accessor: "author",
        Cell: (props) => {
          return props.value && props.value.name ? (
            <Link key={props.value.id} text={props.value.name} />
          ) : (
            "Unknown"
          );
        },
      },
      {
        Header: "Categories",
        accessor: "primaryCategory",
        hideForMobile: true,
        Cell: (props) => {
          return props.row.original.primaryCategory
            ? props.row.original.primaryCategory.name
            : "Not selected";
        },
      },
      {
        Header: "Tags",
        accessor: "tags",
        width: "120px",
        hideForMobile: true,
        Cell: (props) => {
          return props.row.original.tags
            ? props.row.original.tags.map((item, index) => {
                return (
                  <div key={"tag_" + index} className="inline-block">
                    <Link className="mr-0" text={item.name} />
                    {index !== props.row.original.tags.length - 1 ? `, ` : ""}
                  </div>
                );
              })
            : "";
        },
      },
      {
        Header: (props) => {
          return <ViewsIcon />;
        },
        accessor: "views_count",
        width: "30px",
        hideForMobile: true,
      },
      {
        Header: <CommentsIcon />,
        accessor: "comments_count",
        width: "30px",
        hideForMobile: true,
      },
      {
        Header: "Date",
        accessor: "createdAt",
        width: "130px",
        Cell: (props) => {
          return (
            <>
              <span className="text-xs lg:text-base">
                <span>Published:</span>
                <br />
                <span>
                  {prettyDate(props.row.original?.effectivePublishDate)}
                </span>
              </span>
            </>
          );
        },
      },
    ];
    for (let i in excludedColumns) {
      unfiltered = unfiltered.filter((e) => e.accessor !== excludedColumns[i]);
    }
    return unfiltered;
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: tableColumns,
      data: records,
    });

  return (
    <>
      <table
        className="table bg-white w-full border border-[#969696] text-left table-auto lg:table-fixed"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps({
                    style: {
                      width: isMobile ? "" : column.width ? column.width : "",
                    },
                  })}
                  className={`border-b border-b-[#969696] border-r border-r-[#969696] px-2.5 py-2.5 ${
                    column.hideForMobile ? "hidden lg:table-cell" : ""
                  }`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isLoading || rows.length == 0 ? (
          <tbody>
            <tr>
              <td
                className="text-center py-6 px-5 justify-center items-center"
                colSpan={tableColumns.length}
              >
                {isLoading && <Spinner />}
                {!isLoading && rows.length === 0 && <p>No records found</p>}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`px-2.5 py-2.5 leading-none lg:leading-normal ${
                          cell.column.hideForMobile
                            ? "hidden lg:table-cell "
                            : ""
                        }${
                          rows.length - 1 > rowIndex
                            ? `border-b-2 border-b-[#E3E3E3]`
                            : ""
                        }`}
                      >
                        {cellIndex === 0 &&
                          !excludedColumns.includes("select") && (
                            <Checkbox
                              key={"select_" + rowIndex}
                              name={"select_" + rowIndex}
                              checked={selectedRows["row_" + rowIndex]}
                              onChange={(value) => {
                                selectedRows["row_" + rowIndex] = value;
                                setSelectedRows(selectedRows);
                                if (onDataSelect) {
                                  onDataSelect(selectedRows);
                                }
                              }}
                            />
                          )}
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </>
  );
};

export default DataTable;
