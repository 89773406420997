import { SectionHeader } from "../../components/SectionHeader";
import { DailyEditionTile } from "./DailyEditionTile";
import { Advertisement } from "../../components/Advertisement";
import { DailyEditionLoading } from "./DailyEditionLoading";
import { useDailyEditionSlide } from "./useDailyEditionSlide";
import { SlideButton } from "../../components/SlideButton";
import { DailyEditionType } from "../../types";
import { Footer } from "../Footer";

type DailyEdition = {
  post: DailyEditionType;
};

export function DailyEdition() {
  const {
    posts,
    isLoading,
    isFetching,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
  } = useDailyEditionSlide<DailyEdition>({
    count: 8,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <>
      <div className="container mx-auto grid grid-cols-12 gap-x-5 md:-mt-12">
        <div className="col-span-12 xl:col-span-9">
          <div className="hidden md:block">
            <SectionHeader title="Daily Edition Archive" />
          </div>
          <div className="block md:hidden">
            <SectionHeader
              title="Daily Edition Archive"
              gradient={false}
              border={false}
              addClassName="p-0 mb-3 mt-2 mx-4"
            />
          </div>

          {(loading ? Array.from(new Array(8)) : posts).map((post, i) =>
            post ? (
              <DailyEditionTile key={post.id} index={i} post={post} />
            ) : (
              <DailyEditionLoading key={i} />
            )
          )}

          <div className="flex w-full flex-col mt-6">
            <div className="flex justify-between">
              <SlideButton
                appearance="light"
                direction="back"
                onClick={slideBack}
                disabled={slideBackDisabled}
              />
              <SlideButton
                appearance="light"
                direction="forward"
                onClick={slideForward}
                disabled={slideForwardDisabled}
              />
            </div>
          </div>
        </div>
        <div className="col-span-12 xl:col-span-3 !ml-0 xl:!ml-6">
          <div className="sticky top-6 flex items-center justify-center flex-col md:flex-row xl:flex-col space-y-6 md:space-y-0 xl:space-y-14 md:space-x-6 xl:space-x-0 mt-10 xl:mt-0">
            <Advertisement
              path="/21735245768/SPC_NEWS_MREC_T"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-p1"
            />
            <Advertisement
              path="/21735245768/SPC_PL_HP_MREC_1"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-p2"
            />
            <Advertisement
              path="/21735245768/SPC_PL_HP_MREC_2"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-p3"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
