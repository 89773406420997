import { MinusSignIcon, PlusSignIcon } from "../Icons";

export function RepeaterSection({
  label,
  children,
  index,
  max,
  total,
  onAdd = null,
  onRemove = null,
  className = "",
}) {
  const showMinus = index !== 0;
  const showPlus = index < max - 1 && total < max;
  const showIcons = showPlus || showMinus;

  const handleAdd = () => {
    if (onAdd) {
      onAdd(index);
    }
  };

  const handleRemove = () => {
    if (onRemove) {
      onRemove(index);
    }
  };

  const Icons = [];
  if (showIcons) {
    if (showMinus) {
      Icons.push(
        <li key={`item.${index}.remove`} className="inline-block">
          <button aria-label="minus_sign" type="button" onClick={handleRemove}>
            <MinusSignIcon />
          </button>
        </li>
      );
    }
    if (showPlus) {
      Icons.push(
        <li key={`item.${index}.add`} className="inline-block mr-1">
          <button aria-label="plus_sign" type="button" onClick={handleAdd}>
            <PlusSignIcon />
          </button>
        </li>
      );
    }
  }

  return (
    <>
      <div className={`box ${className}`}>
        <div className="bg-white border border-gray px-2 py-2 !border-b-0 font-bold">
          {showIcons && <ul className="inline-block mb-0 mt-0">{Icons}</ul>}
          <span className="inline-block">{label}</span>
        </div>
        <div className="border border-gray p-4 p-0 bg-white">{children}</div>
      </div>
    </>
  );
}
