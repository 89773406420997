import { useEffect, useState } from "react";
import { InputFieldSizes, InputFieldStyles } from "../../constants";
import Label from "../Label";

const PageNumber = ({
  label,
  showLabel = true,
  name,
  theme = "default",
  size = "default",
  value = "",
  className = "",
  inputClassName = "",
  placeholder = "",
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  let fieldClasses =
    (InputFieldStyles[theme]
      ? InputFieldStyles[theme]
      : InputFieldStyles.default) + " ";
  fieldClasses += InputFieldSizes[size]
    ? InputFieldSizes[size]
    : InputFieldSizes.default;

  if (inputClassName) {
    fieldClasses += ` ${inputClassName}`;
  }

  let wrapperClassName = "flex w-full flex-wrap items-stretch text-left";
  if (className) {
    if (className.includes("w-")) {
      wrapperClassName = wrapperClassName.replace("w-full", "");
    }
    wrapperClassName += " " + className;
  }

  return (
    <div className={`${wrapperClassName}`}>
      {label ? (
        <Label className={`${showLabel ? "" : "sr-only"}`} text={label} />
      ) : (
        ""
      )}

      <input
        type="number"
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        className={`${fieldClasses}`}
      />
    </div>
  );
};

export default PageNumber;
