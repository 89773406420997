import Skeleton from "@mui/material/Skeleton";

export function ShuffleExploringLoading() {
  return (
    <div className="grid grid-cols-1 gap-x-5">
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="rounded-2xl opacity-70 flex"
        animation="pulse"
        height={294}
        variant="rectangular"
      />
    </div>
  );
}
