import { useFeaturedSlideContent } from "../../utils/useSlideContent";
import { ArticleTile, ArticleTileLoading } from "../../components/ArticleTile";
import {
  FeaturedPost,
  FeaturedPostLoading,
} from "../../components/FeaturedPost";
import { SectionHeader } from "../../components/SectionHeader";
import { SlideButton } from "../../components/SlideButton";
import { Divider } from "../../components/Divider";
import type { PostType } from "../../types";

// see constants.ts for category definitions
// 14 = innovate
// 16 = know
// 27 = thrive
const defaultCategoryIds = [14, 16, 27];

export function News({ categoryData }) {
  const categoryQuery = categoryData?.id
    ? `primaryCategoryIds=${categoryData.id}`
    : `categoryIds=${defaultCategoryIds.join("&categoryIds=")}`;
  const {
    posts,
    isLoading,
    featuredPost,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
    slideFeaturedPostForward,
    slideFeaturedPostBack,
    slideFeaturedPostBackDisabled,
  } = useFeaturedSlideContent<PostType>({
    requestUrl: `posts?${categoryQuery}`,
    aboveTheFold: true,
  });

  return (
    <>
      <div className="hidden md:block">
        <SectionHeader title="news" gradient={!categoryData} />
      </div>
      <div className="block md:hidden">
        <SectionHeader
          title="news"
          gradient={false}
          border={false}
          addClassName="p-0 mb-3"
        />
      </div>

      <div className="grid md:grid-cols-6 gap-5">
        <div className="flex flex-col md:col-span-3 rounded-tr-xl rounded-br-xl md:border md:border-gray md:border-l-transparent md:p-5">
          {isLoading ? (
            <FeaturedPostLoading />
          ) : (
            <FeaturedPost post={featuredPost} />
          )}

          <div className="flex flex-col grow justify-end">
            <div className="flex justify-between">
              <SlideButton
                direction="back"
                disabled={slideFeaturedPostBackDisabled}
                onClick={slideFeaturedPostBack}
              />
              <SlideButton
                direction="forward"
                onClick={slideFeaturedPostForward}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>

        <Divider addClassName="md:hidden" />

        <div className="flex flex-col md:col-span-3 md:rounded-xl md:border md:border-gray md:px-5">
          <div className="divide-y">
            {(isLoading ? Array.from(new Array(4)) : posts).map((post, i) =>
              post ? (
                <ArticleTile key={post.id} post={post} />
              ) : (
                <ArticleTileLoading key={i} />
              )
            )}
          </div>

          <div className="flex flex-col grow justify-end">
            <div className="flex justify-between my-5">
              <SlideButton
                direction="back"
                disabled={slideBackDisabled}
                onClick={slideBack}
              />
              <SlideButton
                direction="forward"
                disabled={slideForwardDisabled}
                onClick={slideForward}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
