import { Section } from "../Layout/Section";
import Wysiwyg from "../Inputs/Wysiwyg";
import { RepeaterSection } from "../Layout/RepeaterSection";
import TextInput from "../Inputs/TextInput";
import { useEffect, useState } from "react";
import { insert } from "../../utils/arrays";

export function QuestionPickerMulti({
  name = "",
  questions,
  ref = null,
  selected = [],
  setValue,
  getValues = null,
}) {
  // Questions
  const [standardQuestions, setStandardQuestions] = useState(questions);

  const setQuestionValue = (index, question, answer) => {
    let object = standardQuestions[index];
    if (!object) {
      return;
    }
    object["question"] = question;
    object["answer"] = answer;
    standardQuestions[index] = object;
    setStandardQuestions([]);
    setStandardQuestions(standardQuestions);
  };

  const maxAdditionalQuestions = 3;
  const [additionalQuestions, setAdditionalQuestions] = useState([
    {
      key: "additional_question_0",
      question: "",
      answer: "",
    },
  ]);

  const getAllQuestions = (questions = []) => {
    let list = [];
    if (standardQuestions.length) {
      for (const i in standardQuestions) {
        list.push(standardQuestions[i]);
      }
    }
    if (!questions.length) {
      questions = additionalQuestions;
    }
    if (questions.length) {
      for (const i in questions) {
        list.push(questions[i]);
      }
    }
    return list;
  };

  const addAdditionalQuestion = (index) => {
    let newArray = insert(additionalQuestions, index, {
      key: "additional_question_" + index,
      question: "",
      answer: "",
    });
    for (const i in newArray) {
      newArray[i]["key"] = "additional_question_" + i;
    }
    setAdditionalQuestions([]);
    setAdditionalQuestions(newArray);
  };

  const removeAdditionalQuestion = (index) => {
    let newArray = [];
    for (const i in additionalQuestions) {
      if (i != index) {
        newArray.push(additionalQuestions[i]);
      }
    }
    for (const i in newArray) {
      newArray[i]["key"] = "additional_question_" + i;
    }
    setAdditionalQuestions([]);
    setAdditionalQuestions(newArray);
  };

  const setAdditionalQuestionValue = (index, key, value) => {
    let questions = additionalQuestions;
    let question = questions[index] ? questions[index] : null;
    if (!question) {
      console.warn("Question not found.");
      return;
    }
    question[key] = value;
    questions[index] = question;
    setAdditionalQuestions([]);
    setAdditionalQuestions(questions);
  };

  useEffect(() => {
    if (setValue) {
      setValue(name, getAllQuestions());
    }
  }, [JSON.stringify(standardQuestions), JSON.stringify(additionalQuestions)]);

  useEffect(() => {
    if (selected.length > 0) {
      const total = questions.length;
      const newStandardQuestions = [];
      const newAdditionalQuestions = [];
      if (selected.length > 0) {
        for (let i = 0; i < total; i++) {
          if (selected[i]) {
            newStandardQuestions.push(selected[i]);
          }
        }
        for (let i in selected) {
          for (let j = 0; j < maxAdditionalQuestions; j++) {
            if (selected[i]["key"] === "additional_question_" + j) {
              newAdditionalQuestions.push(selected[i]);
            }
          }
        }
      }
      if (newStandardQuestions.length) {
        setStandardQuestions(newStandardQuestions);
      }
      if (newAdditionalQuestions.length) {
        setAdditionalQuestions(newAdditionalQuestions);
      }
    }
  }, [selected]);

  return (
    <>
      {standardQuestions.map((question, i) => (
        <Section
          key={question.key}
          label={[
            <strong key={`question_${i}_question_label`}>Q:</strong>,
            <span key={`question_${i}_question`} className="font-normal">
              {" "}
              {question.question}
            </span>,
          ]}
        >
          <Wysiwyg
            key={`question_${i}_answer`}
            name=""
            value={question.answer}
            onBlur={(val) => {
              setQuestionValue(i, question.question, val);
            }}
            getValues={getValues}
          />
        </Section>
      ))}

      {additionalQuestions.map((item, index) => (
        <RepeaterSection
          key={`additional_question_${index}_${Math.random().toString(36)}`}
          label={`Added Question (${index + 1})`}
          index={index}
          total={additionalQuestions.length}
          max={maxAdditionalQuestions}
          onAdd={() => {
            addAdditionalQuestion(index + 1);
          }}
          onRemove={() => {
            removeAdditionalQuestion(index);
          }}
        >
          <TextInput
            name=""
            className="p-3"
            label=""
            value={item["question"]}
            onBlur={(val) => {
              setAdditionalQuestionValue(index, "question", val);
            }}
            getValues={getValues}
          />

          <Wysiwyg
            className="p-3"
            name=""
            value={item["answer"]}
            onBlur={(val) => {
              setAdditionalQuestionValue(index, "answer", val);
            }}
            getValues={getValues}
          />
        </RepeaterSection>
      ))}
    </>
  );
}
