import { FluentsIcon } from "./FluentsIcon";

export function RewardFluents({ onClick }) {
  return (
    <button
      aria-label="reward"
      className="flex items-center space-x-2 cursor-pointer hover:text-primary-darker"
      onClick={onClick}
    >
      <FluentsIcon />
      <div className="font-normal">Reward Fluents</div>
    </button>
  );
}
