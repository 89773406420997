import Title from "../../components/Title";
import DefaultLayout from "../../components/PostType/DefaultLayout";

export function Index() {
  return (
    <div className="space-y-4">
      <Title text="All Zaps" />
      <DefaultLayout type="zap" excludedColumns={["tags", "comments_count"]} />
    </div>
  );
}
