export const PostTypes = [
  { value: "custom", label: "Custom" },
  { value: "post", label: "Post" },
  { value: "shuffle", label: "Shuffle" },
  { value: "hustle", label: "Hustle" },
  { value: "pressRelease", label: "Press Release" },
  { value: "venture", label: "Venture" },
  { value: "influencer", label: "Influencer" },
  { value: "event", label: "Event" },
  { value: "impact", label: "Impact" },
  { value: "podcast", label: "Podcast" },
  { value: "zap", label: "Zap" },
  { value: "placeshow", label: "Place Show" },
  { value: "startupReport", label: "Startup Report" },
  { value: "marketProperty", label: "Market Property" },
];

export const ButtonColors = {
  primary: "bg-[#005CB2] text-white ",
  secondary: "bg-[#F0F0F0] border border-[#005CB2] text-[#005CB2]",
  link: "text-[#005CB2]",
};

export const StateColors = {
  success: "#28a745",
  danger: "#dc3545",
  warning: "#ffc107",
  secondary: "#6c757d",
  info: "#17a2b8",
};

export const ButtonSizes = {
  small: "px-2 py-1 rounded",
  medium: "px-3 py-1 rounded",
  large: "px-4 py-2 rounded",
  default: "px-3 py-1 rounded",
  smallRounded: "px-3 py-0 rounded-full",
  mediumRounded: "px-3 py-1 !pl-3 rounded-full",
};

export const InputFieldStyles = {
  default:
    "w-full bg-white form-control rounded border border-[#969696] focus:outline-none focus:border-2 focus:border-blue",
};

export const InputFieldSizes = {
  default: "py-1 px-3",
};

export const Tenants = [
  {
    id: "stpete",
    name: "St Pete",
  },
  {
    id: "tampa",
    name: "Tampa",
  },
];

export const InfluencerQuestions = [
  {
    key: "0",
    question: "Years in St Pete?",
    answer: "",
  },
  {
    key: "1",
    question: "Organizations involved in?",
    answer: "",
  },
  {
    key: "2",
    question: "What gets you out of bed everyday?",
    answer: "",
  },
  {
    key: "3",
    question: "Why St Pete?",
    answer: "",
  },
  {
    key: "4",
    question: "What is one habit that you keep?",
    answer: "",
  },
  {
    key: "5",
    question: "Who are some people that influence you?",
    answer: "",
  },
  {
    key: "6",
    question:
      "What is one piece of insight - a book, methodology or practice that you would share with our readers?",
    answer: "",
  },
  {
    key: "7",
    question:
      "What is one thing you wish you knew about your work 3 years ago?",
    answer: "",
  },
  {
    key: "8",
    question: "What’s next?",
    answer: "",
  },
];

export const HustleQuestions = [
  {
    key: "0",
    question: "Years in St Pete?",
    answer: "",
  },
  {
    key: "1",
    question: "Organizations involved in?",
  },
  {
    key: "2",
    question: "Why do you do it?",
    answer: "",
  },
  {
    key: "3",
    question: "What do you do?",
    answer: "",
  },
  {
    key: "4",
    question: "What was your Catalyst (how did you get started)?",
    answer: "",
  },
  {
    key: "5",
    question: "What’s a common misconception or unknown aspect of what you do?",
    answer: "",
  },
  {
    key: "6",
    question: "What’s the most challenging part of your hustle?",
    answer: "",
  },
  {
    key: "7",
    question:
      "What’s the most valuable piece of business advice/insight that’s helped you?",
    answer: "",
  },
  {
    key: "8",
    question: "What’s next?",
    answer: "",
  },
];
