import { useForm } from "react-hook-form";
import { CategoryEditor } from "../../components/Sections/CategoryEditor";
import { Section } from "../../components/Layout/Section";
import { Publish } from "../../components/Sections/Publish";
import { SEO } from "../../components/Sections/SEO";
import { TagEditor } from "../../components/Sections/TagEditor";
import Title from "../../components/Title";
import Image from "../../components/Inputs/Image";
import TextInput from "../../components/Inputs/TextInput";
import { Shuffle } from "../../utils/models";
import { Suspense, useEffect, useState } from "react";
import { sanitizeData } from "../../utils/forms";
import Alert from "../../components/Alert";
import { Admin } from "../../components/Sections/Admin";
import ShuffleService from "../../services/ShuffleService";
import { parseMediaValue, uploadMedia } from "../../utils/media";
import { handleRedirect } from "../../utils/redirects";
import { formatLinks } from "../../utils/links";
import Spinner from "../../components/Spinner";

export function Create() {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Shuffle>();

  const [isLoading, setLoading] = useState("");
  const [response, setResponse] = useState(null);
  const [redirect, setRedirect] = useState(null);

  handleRedirect(redirect);

  // Media
  const [media, setMedia] = useState({});
  const setMediaItem = (name, value, order = 0) => {
    let newMedia = parseMediaValue(name, value, order);
    if (newMedia) {
      for (let i in newMedia) {
        media[i] = newMedia[i];
      }
    }
    setMedia(media);
  };

  // Links
  const [links, setLinks] = useState({});
  const setLink = (name, value) => {
    const newLinks = links;
    newLinks[name] = value;
    setLinks(newLinks);
    setValue("links", formatLinks(newLinks));
  };
  useEffect(() => {
    let formattedLinks = [];
    for (const i in links) {
      formattedLinks.push({ key: i, href: links[i] });
    }
    setValue("links", formattedLinks);
  }, [links]);

  // SEO Section
  const setSeoValue = (name, value) => {
    if ("object" === typeof value) {
      for (let i in value) {
        if (value[i] instanceof File) {
          setMediaItem(i, { files: [value[i]] });
        }
      }
    }
  };

  const onSubmit = async (data) => {
    setLoading(data["status"]);
    try {
      const postResponse = await ShuffleService.create(sanitizeData(data));
      await uploadMedia(media, postResponse.data.response);
      setLoading("");
      setResponse({
        type: "success",
        message:
          data["status"] === "draft"
            ? "Shuffle published as draft successfully."
            : "Shuffle published successfully.",
      });
      setTimeout(() => {
        setRedirect({ post: postResponse.data.response });
      }, 2000);
      reset();
    } catch (e: any) {
      setResponse({ type: "danger", message: e.message });
      setLoading("");
    }
  };

  return (
    <Suspense
      fallback={
        <div className="text-center space-y-4">
          <Spinner />
        </div>
      }
    >
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <Title text="Add New Shuffle" />

        {response && <Alert type={response.type} text={response.message} />}

        <TextInput
          key="title"
          label=""
          name="title"
          placeholder="Add title"
          register={register}
          errors={errors}
        />

        <Section label="Publish">
          <Publish
            label={""}
            setValue={setValue}
            getValues={getValues}
            loading={isLoading}
          />
        </Section>

        <div className="space-y-0">
          <div className="grid grid-cols-2">
            <Section label="First Name">
              <TextInput
                className="p-3"
                name="firstName"
                label=""
                setValue={setValue}
                getValues={getValues}
              />
            </Section>
            <Section label="Last Name">
              <TextInput
                className="p-3"
                name="lastName"
                label=""
                setValue={setValue}
                getValues={getValues}
              />
            </Section>
          </div>
          <div className="grid grid-cols-2">
            <Section label="Former Company">
              <TextInput
                className="p-3"
                name="previousCompany"
                label=""
                setValue={setValue}
                getValues={getValues}
              ></TextInput>
            </Section>
            <Section label="Former Position">
              <TextInput
                className="p-3"
                name="previousPosition"
                label=""
                setValue={setValue}
                getValues={getValues}
              ></TextInput>
            </Section>
          </div>
          <div className="grid grid-cols-2">
            <Section label="New Company">
              <TextInput
                className="p-3"
                name="newCompany"
                label=""
                setValue={setValue}
                getValues={getValues}
              />
            </Section>
            <Section label="New Position">
              <TextInput
                className="p-3"
                name="newPosition"
                label=""
                setValue={setValue}
                getValues={getValues}
              />
            </Section>
          </div>
          <div className="grid grid-cols-2">
            <Section label="Previous Company Email">
              <TextInput
                className="p-3"
                name="previousCompanyEmail"
                label=""
                setValue={setValue}
                getValues={getValues}
              ></TextInput>
            </Section>
            <Section label="New Company Email">
              <TextInput
                className="p-3"
                name="newCompanyEmail"
                label=""
                setValue={setValue}
                getValues={getValues}
              ></TextInput>
            </Section>
          </div>
        </div>

        <Section label="Additional content">
          <TextInput
            className="p-3"
            name="htmlContent"
            setValue={setValue}
            getValues={getValues}
            label=""
          ></TextInput>
        </Section>

        <Section label="Featured Image">
          <Image
            className="p-3"
            name="featured_image_full"
            setValue={setMediaItem}
            getValues={getValues}
          />
        </Section>

        <div className="grid grid-cols-2">
          <Section label="Linkedin">
            <TextInput
              className="p-3"
              name="linkedin"
              label=""
              setValue={setLink}
              getValues={getValues}
            />
          </Section>
          <Section label="Email">
            <TextInput
              className="p-3"
              name="email"
              label=""
              setValue={setLink}
              getValues={getValues}
            />
          </Section>
        </div>

        <div className="grid gird-cols-1 xl:grid-cols-2 gap-4">
          <Section label="Tags">
            <TagEditor name="tags" setValue={setValue} getValues={getValues} />
          </Section>
          <Section label="Categories">
            <CategoryEditor
              name="categories"
              setValue={setValue}
              getValues={getValues}
            />
          </Section>
        </div>

        <Section label="Admin">
          <Admin
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            register={register}
          />
        </Section>

        <Section label="SEO">
          <SEO
            name="meta"
            register={register}
            setValue={setSeoValue}
            errors={errors.meta}
          />
        </Section>
      </form>
    </Suspense>
  );
}
