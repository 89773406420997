export function NewsletterCloseIcon() {
  return (
    <svg
      width="53"
      height="50"
      viewBox="0 0 53 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="22.3047"
        y="29.3047"
        width="13.482"
        height="1.30471"
        rx="0.652354"
        transform="rotate(-45 22.3047 29.3047)"
        fill="#CACACA"
        stroke="#CACACA"
      />
      <rect
        x="23.2285"
        y="19.7695"
        width="13.482"
        height="1.30471"
        rx="0.652354"
        transform="rotate(45 23.2285 19.7695)"
        fill="#CACACA"
        stroke="#CACACA"
      />
      <path
        d="M53 49L6 49C3.23857 49 0.999997 46.7614 0.999997 44L1 6C1 3.23857 3.23858 0.999996 6 0.999996L53 1"
        stroke="#CACACA"
      />
    </svg>
  );
}
