import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

type AdvertisementProps = {
  path: string;
  size: [number, number];
  id: string;
};

export function Advertisement({ path, size, id }: AdvertisementProps) {
  const adSlot = useRef<googletag.Slot>();
  const location = useLocation();

  useEffect(() => {
    return () => {
      googletag.destroySlots([adSlot.current]);
    };
  }, []);

  useEffect(() => {
    if (adSlot.current) googletag.pubads().refresh([adSlot.current]);
  }, [location]);

  useEffect(() => {
    window.googletag = window.googletag || ({ cmd: [] } as typeof googletag);
    googletag.cmd.push(() => {
      adSlot.current = googletag
        .defineSlot(path, size, id)
        .addService(googletag.pubads());

      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.pubads().setCentering(true);
      googletag.enableServices();

      googletag.cmd.push(() => {
        googletag.display(id);
      });
    });

    // we don't want to re-run this function on prop changes,
    // it will lead to errors with trying to set multiple ads for one id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center items-center p-2 bg-white border overflow-hidden w-[310px] h-[260px] m-auto">
      <div
        id={id}
        style={{ minWidth: `${size[0]}px`, minHeight: `${size[1]}px` }}
      />
    </div>
  );
}
