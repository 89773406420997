export const categories = [
  {
    id: 7,
    parentId: null,
    name: "Comm Voice",
    externalId: 4501,
    externalProvider: "catalyst",
  },
  {
    id: 8,
    parentId: null,
    name: "Comm Voice Feat",
    externalId: 4502,
    externalProvider: "catalyst",
  },
  {
    id: 9,
    parentId: null,
    name: "Create",
    externalId: 580,
    externalProvider: "catalyst",
  },
  {
    id: 10,
    parentId: null,
    name: "Further",
    externalId: 176,
    externalProvider: "catalyst",
  },
  {
    id: 11,
    parentId: null,
    name: "History",
    externalId: 4361,
    externalProvider: "catalyst",
  },
  {
    id: 12,
    parentId: null,
    name: "Impact",
    externalId: 3965,
    externalProvider: "catalyst",
  },
  {
    id: 13,
    parentId: null,
    name: "Influence",
    externalId: 189,
    externalProvider: "catalyst",
  },
  {
    id: 14,
    parentId: null,
    name: "Innovate",
    intro:
      "St Pete is fast becoming a regional technology and innovation hub. For <i>local St Petersburg technology news, </i>look no further than the Innovate section of the Catalyst. We’ll bring you sharp local news on the innovators, scientists, technologists and companies that are leading the growth of the St Pete technology sector. Our economic development follows our innovation and we’ll keep your finger on the pulse.",
    externalId: 184,
    externalProvider: "catalyst",
  },
  {
    id: 15,
    parentId: null,
    name: "Insight",
    externalId: 173,
    externalProvider: "catalyst",
  },
  {
    id: 16,
    parentId: null,
    name: "Know",
    intro:
      "<i>Local St Petersburg business news</i> lives here. Our experienced business journalists cover business & finance news with a strong understanding of the St Petersburg economic landscape. We feature the companies and executives whose business thought leadership will shape the St Petersburg business ecosystem for years to come.",
    externalId: 183,
    externalProvider: "catalyst",
  },
  {
    id: 17,
    parentId: null,
    name: "Opinion",
    externalId: 300,
    externalProvider: "catalyst",
  },
  {
    id: 18,
    parentId: null,
    name: "Partner Stories",
    externalId: 187,
    externalProvider: "catalyst",
  },
  {
    id: 19,
    parentId: null,
    name: "Partner Story Bottom",
    externalId: 182,
    externalProvider: "catalyst",
  },
  {
    id: 20,
    parentId: null,
    name: "Partner Story RE-C",
    externalId: 6323,
    externalProvider: "catalyst",
  },
  {
    id: 21,
    parentId: null,
    name: "Partner Story RE-R",
    externalId: 6324,
    externalProvider: "catalyst",
  },
  {
    id: 22,
    parentId: null,
    name: "Partner Story Top",
    externalId: 181,
    externalProvider: "catalyst",
  },
  {
    id: 23,
    parentId: null,
    name: "Place",
    externalId: 5552,
    externalProvider: "catalyst",
  },
  {
    id: 24,
    parentId: null,
    name: "Presence",
    externalId: 188,
    externalProvider: "catalyst",
  },
  {
    id: 25,
    parentId: null,
    name: "The Hustle",
    externalId: 177,
    externalProvider: "catalyst",
  },
  {
    id: 26,
    parentId: null,
    name: "The Shuffle",
    externalId: 186,
    externalProvider: "catalyst",
  },
  {
    id: 27,
    parentId: null,
    name: "Thrive",
    intro:
      "St Petersburg is thriving and the Catalyst is dedicated to <i>documenting the neighborhoods, districts, politics and social issues that shape our community.</i> This is vital local news. From equity to climate to affordable housing to education, we’re sharing the local St Petersburg news stories that matter to you.",
    externalId: 185,
    externalProvider: "catalyst",
  },
  {
    id: 28,
    parentId: null,
    name: "Uncategorized",
    externalId: 1,
    externalProvider: "catalyst",
  },
  {
    id: 29,
    parentId: null,
    name: "Venture Names",
    externalId: 1890,
    externalProvider: "catalyst",
  },
  {
    id: 30,
    parentId: null,
    name: "Venture News",
    externalId: 1981,
    externalProvider: "catalyst",
  },
  {
    id: 31,
    parentId: null,
    name: "Wire",
    externalId: 412,
    externalProvider: "catalyst",
  },
  {
    id: 32,
    parentId: null,
    name: "Zaps:Catalyst",
    externalId: 3962,
    externalProvider: "catalyst",
  },
  {
    id: 33,
    parentId: null,
    name: "Zaps:Impact",
    externalId: 3963,
    externalProvider: "catalyst",
  },
  {
    id: 34,
    parentId: null,
    name: "Zaps:Place",
    externalId: 5533,
    externalProvider: "catalyst",
  },
];
