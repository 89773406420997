import Skeleton from "@mui/material/Skeleton";

export function HustleLoading() {
  return (
    <Skeleton
      sx={{ bgcolor: "grey.900" }}
      className="rounded-2xl opacity-70 flex"
      animation="pulse"
      height={324}
      variant="rectangular"
    />
  );
}
