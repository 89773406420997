export function ArrowRightIcon() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4571 8.70705C14.8476 8.31653 14.8476 7.68336 14.4571 7.29284L8.09312 0.928903C7.70259 0.53838 7.06943 0.538382 6.67891 0.928908C6.28838 1.31943 6.28839 1.9526 6.67891 2.34312L12.3358 7.99995L6.67895 13.6568C6.28843 14.0474 6.28843 14.6805 6.67896 15.071C7.06948 15.4616 7.70265 15.4616 8.09317 15.071L14.4571 8.70705ZM13.75 6.99995L0.249996 7L0.250004 9L13.75 8.99995L13.75 6.99995Z"
        fill="white"
      />
    </svg>
  );
}
