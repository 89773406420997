import { SidebarSubmenu } from "./SidebarSubmenu";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

export function SidebarButton({
  label,
  icon,
  submenuLabel = null,
  submenuItems = null,
  collapsed = false,
  onSubmenuItemClick = null,
  currentRoute = "",
  isHighlighted,
  isOpen,
  onClick,
  addClassName = "",
}) {
  const [hovering, setHovering] = useState(false);
  const [submenuState, setSubmenuState] = useState(isOpen);
  const [itemHighlighted, setHighlighted] = useState(isHighlighted);
  const [activeRoute, setActiveRoute] = useState(currentRoute);

  useEffect(() => {
    setSubmenuState(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setHighlighted(isHighlighted);
  }, [isHighlighted]);

  useEffect(() => {
    setActiveRoute(currentRoute);
  }, [currentRoute]);

  const ariaLabel = label
    ? label.toLocaleLowerCase().replace(" ", "_").replace("-", "_")
    : `button_${v4()}`;

  let className = `w-max font-['Inter'] text-white px-4 text-left flex items-left place-items-center h-4 py-4 rounded-lg cursor-pointer hover:bg-[#262626] relative border-2 border-solid ${addClassName} `;
  className +=
    itemHighlighted || hovering ? "border-[#2BE2D8]" : "border-transparent";

  return (
    <div className="relative">
      <button
        aria-label={ariaLabel}
        className={`${className}`}
        onClick={onClick}
        onMouseOver={() => setHovering(true)}
        onFocus={() => setHovering(true)}
        onMouseOut={() => setHovering(false)}
        onBlur={() => setHovering(false)}
      >
        <span className={collapsed ? "mx-auto" : ""}>{icon}</span>
        {!collapsed && <span className="ml-2">{label}</span>}
        {collapsed && hovering && (
          <div className="absolute left-20 bg-black px-4 py-1 whitespace-nowrap z-40 rounded-lg">
            {label}
          </div>
        )}
      </button>
      {submenuItems && submenuState && (
        <SidebarSubmenu
          label={submenuLabel}
          items={submenuItems}
          currentRoute={activeRoute}
          onClick={(item) => {
            onSubmenuItemClick(item);
          }}
        />
      )}
    </div>
  );
}
