import { sanitize } from "../../utils/sanitizeHtml";

const Link = ({ text = "", className = "", onClick = null }) => {
  let wrapperClasses = `mr-1 text-xs lg:text-base text-[#005CB2] cursor-pointer " + ${className}`;
  return (
    <span
      role="presentation"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      className={wrapperClasses}
    >
      {sanitize(text)}
    </span>
  );
};

export default Link;
