import { useParams } from "react-router-dom";
import { Suspense, useState } from "react";
import Title from "../../components/Title";
import Alert from "../../components/Alert";
import TextInput from "../../components/Inputs/TextInput";
import Wysiwyg from "../../components/Inputs/Wysiwyg";
import { Section } from "../../components/Layout/Section";
import { Publish } from "../../components/Sections/Publish";
import Image from "../../components/Inputs/Image";
import { TagEditor } from "../../components/Sections/TagEditor";
import { CategoryEditor } from "../../components/Sections/CategoryEditor";
import { Admin } from "../../components/Sections/Admin";
import { SEO } from "../../components/Sections/SEO";
import { useForm } from "react-hook-form";
import ArticleService from "../../services/ArticleService";
import Spinner from "../../components/Spinner";
import { only } from "../../utils/arrays";
import dayjs from "dayjs";
import {
  getPreviewItem,
  getPreviewItems,
  parseMediaValue,
  uploadMedia,
} from "../../utils/media";
import { useFormResponse } from "../../utils/hooks";
import { handleRedirect } from "../../utils/redirects";
import { sanitizeData } from "../../utils/forms";

const prepareFormData = (model) => {
  const data = only(model, [
    "title",
    "htmlContent",
    "primaryCategoryId",
    "categories",
    "tags",
    "author",
    "effectivePublishDate",
  ]);
  const jsonData = only(model.jsonData, ["seoTitle", "seoDescription"]);
  for (const i in jsonData) {
    data[i] = jsonData[i];
  }
  data["effectivePublishDate"] = dayjs(
    data["effectivePublishDate"],
    "YYYY-MM-DDTHH:mm:ss.sssZ"
  ).toDate();
  return data;
};

export function Edit() {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const [currentPost, setCurrentPost] = useState(null);
  const [isLoading, setLoading] = useState("");
  const [isPageLoading, setPageLoading] = useState(true);
  const [response, setResponse] = useFormResponse(null);
  const [redirect, setRedirect] = useState(null);

  handleRedirect(redirect);

  // Media
  const [media, setMedia] = useState({});
  const setMediaItem = (name, value, order = 0) => {
    let newMedia = parseMediaValue(name, value, order);
    if (newMedia) {
      for (let i in newMedia) {
        media[i] = newMedia[i];
      }
    }
    setMedia(media);
  };

  // SEO Section
  const setSeoValue = (name, value) => {
    if ("object" === typeof value) {
      for (let i in value) {
        if (value[i] instanceof File) {
          setMediaItem(i, { files: [value[i]] });
        }
      }
    }
  };

  const retrievePost = (postId) => {
    return ArticleService.get(postId)
      .then((response) => {
        if (response.data) {
          setCurrentPost(response.data);
          const prepared = prepareFormData(response.data);
          for (let i in prepared) {
            setValue(i, prepared[i]);
          }
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const onSubmit = async (data) => {
    setLoading(data["status"]);
    try {
      await ArticleService.update(id, sanitizeData(data));
      await uploadMedia(media, currentPost);
      setLoading("");
      setResponse({
        type: "success",
        message:
          data["status"] === "draft"
            ? "Post published as draft successfully."
            : "Post updated successfully.",
      });
      setMedia({});
      await retrievePost(id);
    } catch (e: any) {
      setResponse({ type: "danger", message: e.message });
      setLoading("");
    }
  };

  const onDelete = (post) => {
    if (post) {
      setPageLoading(true);
      ArticleService.remove(post.id)
        .then((r) => {
          setResponse({
            type: "success",
            message: "Post deleted successfully.",
          });
          setTimeout(() => {
            setRedirect({ index: post });
          });
        })
        .catch((e) => {
          setResponse({
            type: "danger",
            message: "Unable to delete post, error happened.",
          });
          console.warn(e);
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
  };

  const onClone = (result) => {
    setRedirect({ post: result.data.response, params: { cloned: true } });
  };

  if (!currentPost) {
    retrievePost(id);
  }

  return (
    <Suspense
      fallback={
        <div className="text-center space-y-4">
          <Spinner />
        </div>
      }
    >
      {currentPost ? (
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Title text="Edit Article" />

          {response && <Alert type={response.type} text={response.message} />}

          <TextInput
            key="title"
            label=""
            name="title"
            placeholder="Add title"
            register={register}
            errors={errors}
          />

          <Wysiwyg
            name="htmlContent"
            setValue={(n, v) => {
              setValue(n, v);
            }}
            value={currentPost.htmlContent}
          />

          <Section label="Publish">
            <Publish
              label={""}
              post={currentPost}
              setValue={setValue}
              getValues={getValues}
              loading={isLoading}
              onClone={onClone}
            />
          </Section>
          <Section label="Featured Image">
            <div className="p-3">
              <Image
                name="featured_image_full"
                register={register}
                setValue={setMediaItem}
                value={getPreviewItem(
                  currentPost.medias,
                  "featured_image_full"
                )}
              />
            </div>
          </Section>

          <div className="grid gird-cols-1 xl:grid-cols-2 gap-4">
            <Section label="Tags">
              <TagEditor
                name="tags"
                setValue={setValue}
                selected={currentPost.tags}
              />
            </Section>
            <Section label="Categories">
              <CategoryEditor
                name="categories"
                setValue={setValue}
                primaryCategory={
                  currentPost ? { id: currentPost.primaryCategoryId } : null
                }
                selected={currentPost.categories}
              />
            </Section>
          </div>

          <Section label="Admin">
            <Admin
              setValue={setValue}
              getValues={getValues}
              post={currentPost}
              watch={watch}
              register={register}
              onDelete={onDelete}
            />
          </Section>
          <Section label="SEO">
            <SEO
              name="meta"
              register={register}
              setValue={setSeoValue}
              errors={errors.meta}
              value={{
                ...{
                  seoTitle: currentPost?.jsonData?.seoTitle,
                  seoDescription: currentPost?.jsonData?.seoDescription,
                },
                ...getPreviewItems(currentPost.medias, [
                  "facebook_image_full",
                  "twitter_image_full",
                  "linkedin_image_full",
                  "instagram_image_full",
                ]),
              }}
            />
          </Section>
        </form>
      ) : isPageLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <p>Not found</p>
      )}
    </Suspense>
  );
}
