import { ReactElement } from "react";

type SectionHeaderProps = {
  title: string | ReactElement;
  gradient?: boolean;
  border?: boolean;
  textColor?: string;
  addClassName?: string;
};

export function SectionHeader({
  title,
  gradient = true,
  border = true,
  addClassName,
}: SectionHeaderProps) {
  return (
    <div
      className={`font-montserrat italic font-semibold text-2xl p-3 uppercase ${addClassName} ${
        border ? "primary-gradient-border" : ""
      } ${gradient ? "primary-gradient-border-gradient-bg" : ""}`}
    >
      {title}
    </div>
  );
}
