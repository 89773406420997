import { Footer } from "../Footer";

const Support = () => {
  return (
    <>
      <div className="grid lg:grid-cols-1 gap-5 p-5 md:p-0 items-center font-['PT+Serif']">
        <header>
          <h1 className="text-4xl text-center font-bold">Start Supporting</h1>
        </header>

        <div className="text-base font-normal px-40">
          <p>
            If you’ve made it this far, we trust that you’re finding value in St
            Pete Catalyst content. As a community elevation platform, we’re
            committed to never using a paywall to keep people from the ideas
            that connect and inspire us. Subscription revenue is an important
            part of operating a healthy news organization. We augment our
            community sourced content with directed coverage from our fine
            journalists. All content flows through our editor, and we employ
            social media gurus, web developers, designers and other support
            staff to keep the Catalyst humming. In consideration of this
            important revenue, we felt voluntary subscriptions were the way
            forward. If you’ve got the capacity and find value in our work, we
            appreciate your subscribing at a level you feel comfortable with.
            Either way thank you for joining us in this grand news experiment.
          </p>
          <br />
          <p className="font-bold">Support with crypto:</p>
          <div>Bitcoin: bc1qw2evaj2hmuy0t3adtyrhzf88jurludyah4xvw2</div>
          <div>Eth: 0xEC2b9080c387Ab6b4d38424F6144fb3Fc4eC9732</div>
          <div>
            ADA:
            addr1q9xge34pl58s3wdz7gzzsk2u5u9pgs2cyj20px8xw6swwyjv3nr2rlg0pzu69usy9pv4efc2z3q4sfy57zvwva4quufq9s8ezg
          </div>
          <div>Doge: D5UiRQjQwnXfCHyjJaM7HSCFH4K9WNZjBA</div>
          <div>USDC: 0xEC2b9080c387Ab6b4d38424F6144fb3Fc4eC9732</div>
          <div>Litecoin: LiSyqYFcrkxNJcZRirWFpgyUW7tBkobijN</div>
          <br />
          <p>
            We happily accept all crypto – please contact us:
            Spark@StPeteCatalyst.com for other tokens.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Support;
