type TwitterIconProps = {
  addClassName?: string;
  addSvgClassName?: string;
};
export function TwitterIcon({
  addClassName = "text-black",
  addSvgClassName,
}: TwitterIconProps) {
  return (
    <span className={addClassName}>
      <svg
        className={addSvgClassName}
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1370_579)">
          <path
            d="M12.1455 0C5.51694 0 0.145508 5.37143 0.145508 12C0.145508 18.6286 5.51694 24 12.1455 24C18.7741 24 24.1455 18.6286 24.1455 12C24.1455 5.37143 18.7741 0 12.1455 0ZM16.9636 10.0632C16.9636 10.1654 16.9636 10.2617 16.9636 10.3639C16.9636 13.4496 14.6117 17.0165 10.3109 17.0165C8.99363 17.0165 7.76054 16.6316 6.73197 15.9699C6.91242 15.994 7.09889 16 7.28536 16C8.38009 16 9.38461 15.6271 10.1906 14.9955C9.16806 14.9774 8.3019 14.3038 8.00716 13.3714C8.15152 13.4015 8.29588 13.4135 8.44626 13.4135C8.6628 13.4135 8.86731 13.3835 9.05979 13.3293C7.99513 13.1128 7.1831 12.1684 7.1831 11.0376V11.0075C7.49588 11.182 7.85679 11.2842 8.24175 11.3023C7.61618 10.8812 7.20115 10.1654 7.20115 9.35338C7.20115 8.92632 7.31543 8.52331 7.51393 8.17444C8.66882 9.58797 10.3891 10.5203 12.332 10.6165C12.2959 10.4421 12.2718 10.2677 12.2718 10.0812C12.2718 8.78797 13.3184 7.74135 14.6117 7.74135C15.2793 7.74135 15.8869 8.02406 16.3199 8.4812C16.8553 8.37895 17.3545 8.18647 17.8057 7.91579C17.6252 8.46316 17.2583 8.92632 16.7771 9.20902C17.2523 9.14887 17.7034 9.02857 18.1245 8.8421C17.8117 9.31128 17.4147 9.72631 16.9575 10.0571L16.9636 10.0632Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1370_579">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.145508)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
