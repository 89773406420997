import { useState } from "react";
import slideBackButtonDisabledBackground from "./slide-back-button-bg-disabled.svg";
import slideBackButtonBackground from "./slide-back-button-bg.svg";
import slideForwardButtonBackground from "./slide-forward-button-bg.svg";

type SlideButtonProps = {
  direction: "back" | "forward";
  appearance?: "light" | "dark";
  disabled?: boolean;
  onClick?: () => void;
};

export function SlideButton({
  direction,
  appearance = "light",
  disabled,
  onClick,
}: SlideButtonProps) {
  const [effect, setEffect] = useState(false);
  const dark = appearance === "dark";

  return (
    <div
      className={`py-1 px-1 pb-0.5 rounded-lg ${
        dark ? "bg-black" : "bg-white"
      }`}
    >
      <button
        aria-label="slide"
        style={{
          backgroundImage: `url(${
            disabled
              ? slideBackButtonDisabledBackground
              : direction === "back"
              ? slideBackButtonBackground
              : slideForwardButtonBackground
          })`,
        }}
        className={`bg-no-repeat py-2 px-3 cursor-pointer bg-contain select-none rounded ${
          dark ? "bg-black text-white" : "bg-white"
        } ${disabled && "cursor-default pointer-events-none text-gray-200"} ${
          effect && "animate-slideClick"
        }`}
        onClick={onClick}
        onMouseDown={() => setEffect(true)}
        onAnimationEnd={() => setEffect(false)}
      >
        Slide
      </button>
    </div>
  );
}
