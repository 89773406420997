import { useEffect, useState, useMemo } from "react";

export function useDetectMobile(breakpoint = "768px") {
  const [isMobileDevice, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaWatcher = window.matchMedia(`(max-width: ${breakpoint})`);
    setIsMobile(mediaWatcher.matches);

    function updateIsMobile(event) {
      setIsMobile(event.matches);
    }

    if (mediaWatcher.addEventListener) {
      mediaWatcher.addEventListener("change", updateIsMobile);
    }
  }, []);

  const isMobile = useMemo(() => {
    return isMobileDevice;
  }, [isMobileDevice]);

  return {
    isMobile,
  };
}
