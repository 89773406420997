import BaseService from "./BaseService";

const getAll = (params) => {
  return BaseService.getAll("news/categories", params, true);
};

const get = (id) => {};

const create = (data) => {};

const update = (id, data) => {};

const remove = (id) => {};

const CategoryService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default CategoryService;
