import { ArrowLeftDirectionIcon, ArrowRightDirectionIcon } from "../Icons";

const SortButton = ({ index, onSort = null }) => {
  return (
    <ul className="inline-block">
      <li
        role="presentation"
        className="inline mr-0.5  hover:opacity-70 cursor-pointer"
        onClick={(e) => {
          onSort(index, "f");
        }}
      >
        <ArrowRightDirectionIcon />
      </li>
      <li
        role="presentation"
        className="inline hover:opacity-70 cursor-pointer"
        onClick={(e) => {
          onSort(index, "b");
        }}
      >
        <ArrowLeftDirectionIcon />
      </li>
    </ul>
  );
};

export default SortButton;
