import { SlideButton } from "../../components/SlideButton";
import { SectionHeader } from "../../components/SectionHeader";
import { useSlideContent } from "../../utils/useSlideContent";
import { PartnerTile } from "./PartnerTile";
import { PartnerLoading } from "./PartnerLoading";
import { useDetectMobile } from "../../utils/isMobile";
import type { PostType } from "../../types";

export function Partners() {
  const { isMobile } = useDetectMobile();
  const postCount = isMobile ? 1 : 6;
  const {
    posts,
    isLoading,
    isFetching,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
  } = useSlideContent<PostType>({
    requestUrl: "posts?categoryIds=18&postType=post",
    count: postCount,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <div className="md:rounded-2xl mt-10 p-5 bg-black">
      <div className="text-white">
        <SectionHeader
          title="Partner Stories"
          gradient={false}
          border={false}
        />
      </div>

      <div className="grid grid-cols-12 gap-5">
        {(loading ? Array.from(new Array(postCount)) : posts).map((post, i) =>
          post ? (
            <PartnerTile key={post.id} post={post} index={i} />
          ) : (
            <PartnerLoading key={i} index={i} isMobile={isMobile} />
          )
        )}
      </div>

      <div className="flex justify-between my-5">
        <SlideButton
          direction="back"
          onClick={slideBack}
          disabled={slideBackDisabled}
        />
        <SlideButton
          direction="forward"
          onClick={slideForward}
          disabled={slideForwardDisabled}
        />
      </div>
    </div>
  );
}
