import { useState } from "react";
import { FileInput } from "./FileInput";
import Button from "./Button";
import { readAsDataURL } from "../../utils/files";

export const SocialMediaImage = ({
  title,
  label,
  name,
  value = null,
  setValue,
  errors,
  className = "",
  onClear = null,
}) => {
  const [inputValue, setInputValue] = useState(
    value && value.id ? "File #" + value.id : ""
  );
  const [inputPreview, setInputPreview] = useState(
    value && value.preview ? value.preview : ""
  );
  const [isLoading, setLoading] = useState(false);

  return (
    <>
      <div>
        <h2>{title}</h2>
        <div className="inline-flex mb-4 w-full">
          <div className="w-full bg-white form-control rounded border border-[#969696] focus:outline-none focus:border-2 focus:border-blue py-1 px-3">
            {inputValue}
          </div>
          {inputPreview ? (
            <Button
              className="-ml-1 mr-2"
              onClick={() => {
                const image = new Image();
                image.src = inputPreview;
                const w = window.open("");
                w.document.write(image.outerHTML);
              }}
              label="Preview"
            />
          ) : (
            <FileInput
              label="Upload"
              name={name}
              accept="image/*"
              className="-ml-1 mr-2"
              setValue={async (name, value) => {
                setLoading(true);
                const file =
                  value instanceof FileList && Object.keys(value).length > 0
                    ? value[0]
                    : null;
                const preview = await readAsDataURL(file);
                if (setValue) {
                  setValue(name, file);
                }
                setInputValue(file.name);
                setInputPreview(preview);
                setLoading(false);
              }}
              isLoading={isLoading}
              errors={errors}
            />
          )}
          <Button
            type="button"
            label="Clear"
            theme="secondary"
            size="medium"
            onClick={() => {
              setInputPreview("");
              setInputValue("");
              setLoading(false);
              if (setValue) {
                setValue(name, null);
              }
              if (onClear) {
                onClear(name);
              }
            }}
          ></Button>
        </div>
      </div>
    </>
  );
};
