import TextArea from "../../components/Inputs/TextArea";
import TextInput from "../../components/Inputs/TextInput";
import { useEffect, useRef } from "react";
import { getBase64FromUrl, readAsDataURL } from "../../utils/files";
import { ImageClear, ImageIcon, ImageSwap } from "../Icons";
import useObject from "../Hooks/useObject";

export type ImageType = {
  id: string;
  files: FileList;
  caption: string;
  link: string;
  preview: string;
};

const Image = ({
  name,
  setValue = null,
  getValues = null,
  errors = null,
  className = "",
  value = null,
  preview = null,
  allowedFiles = "image/*",
  showCaptionInput = true,
  showUrlInput = true,
  full = false,
  register = null,
  onClear = null,
  onSwap = null,
}) => {
  const initialValues: ImageType = {
    id: "",
    files: null,
    caption: "",
    link: "",
    preview: "",
  };

  const [values, setValues] = useObject(initialValues);

  const fileInput = useRef<HTMLInputElement | null>(null);
  const fieldName = name;

  useEffect(() => {
    if (value) {
      setValues({
        id: value.id ? value.id : "",
        link: value.link ? value.link : "",
        preview: value.preview ? value.preview : "",
        caption: value.caption ? value.caption : "",
      });
    }
  }, [value]);

  const handleImagePreviewClick = (event) => {
    if (event.target.nodeName !== "BUTTON") {
      fileInput.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const result = await readAsDataURL(files[0]);
    values.preview = result;
    values.files = files;
    setValues(values);
    if (setValue) {
      setValue(fieldName, {
        files: files,
        preview: result,
      });
    }
  };

  const handleSubFieldChange = async (name, val) => {
    if (value && name === "link") {
      let snapshot = await getBase64FromUrl(val);
      if (snapshot.includes("data:image/")) {
        setValues({ preview: value.preview });
      } else {
        alert("Invalid image URL.");
        return;
      }
    }

    values[name] = val;
    setValues(values);

    if (setValue) {
      setValue(fieldName, values);
    }
  };

  const handleClear = (event) => {
    setValues(initialValues);
    if (setValue) {
      setValue(fieldName, initialValues);
    }
    if (onClear) {
      onClear(values);
    }
  };

  const handleSwap = (event) => {
    values.files = null;
    values.preview = "";
    setValues(values);
    if (setValue) {
      setValue(fieldName, values);
    }
    if (onClear) {
      onSwap(values);
    }
  };

  let wrapperClasses =
    "flex flex-col lg:flex-row w-full flex-wrap items-stretch text-left " +
    className;
  let rowClasses = "flex flex-col lg:flex-row lg:items-center w-full gap-4";
  let columnClasses =
    "bg-gray-100 border-[#005CB2] border-2 border-solid relative flex justify-center" +
    (errors?.[name] ? "error" : "");
  columnClasses = full ? `${columnClasses + " w-full"}` : columnClasses;

  let captionColumnClasses = "w-2/3 pl-5";

  return (
    <div className={`${wrapperClasses}`}>
      <div className={`${rowClasses}`}>
        <div role="presentation" className={`${columnClasses}`}>
          <input
            name="files"
            type="file"
            ref={fileInput}
            style={{ display: "none" }}
            accept={allowedFiles}
            onChange={handleFileChange}
          />
          <div
            className="w-full text-center w-[180px] h-[130px] overflow-hidden"
            role="presentation"
            onClick={handleImagePreviewClick}
          >
            {values.preview ? (
              <div className="w-full">
                <img
                  alt={""}
                  src={values.preview}
                  width="50"
                  className="inline-block w-full object-cover"
                />
              </div>
            ) : (
              <div className="w-full pt-4 pr-4 pl-4">
                <ImageIcon />
              </div>
            )}

            {!values.preview ? (
              <div className="pb-4 pl-4 pr-4">
                <p className="text-sm mb-1 mt-2 font-bold text-[#005CB2]">
                  {preview ? "Click to change image" : "Click to add image"}
                </p>
                <p className="text-xs mb-0">or drag and drop here</p>
              </div>
            ) : (
              ""
            )}
          </div>

          {values.preview ? (
            <div className="w-full text-center absolute top-0 -right-6 !w-[15px] text-center">
              <span
                role="presentation"
                className="block w-full bg-red rounded-full w-full !h-[15px] flex justify-center items-center hover:opacity-70 cursor-pointer mb-1"
                onClick={handleClear}
              >
                <ImageClear />
              </span>
              <span
                role="presentation"
                className="block w-full bg-[#005CB2] rounded-full w-full !h-[15px] flex justify-center items-center hover:opacity-70 cursor-pointer"
                onClick={handleSwap}
              >
                <ImageSwap />
              </span>
            </div>
          ) : (
            ""
          )}
        </div>

        {showCaptionInput && (
          <div className={`${captionColumnClasses}`}>
            <TextArea
              label="Add caption or credit/s"
              className="text-[#969696]"
              name="caption"
              errors={{}}
              value={values.caption}
              setValue={handleSubFieldChange}
            />
          </div>
        )}
      </div>

      {showUrlInput && (
        <TextInput
          label=""
          placeholder="Or insert link to the source file here"
          className="w-full placeholder-[#969696] mt-1"
          name="link"
          errors={{}}
          value={values.link}
          setValue={handleSubFieldChange}
        />
      )}

      {errors?.[name] && <p className="text-[red]">This field is required</p>}
    </div>
  );
};

export default Image;
