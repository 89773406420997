export function ToTopIcon() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17"
        cy="17"
        r="17"
        transform="rotate(-90 17 17)"
        fill="black"
      />
      <path
        d="M17.7071 10.2929C17.3165 9.90237 16.6834 9.90237 16.2928 10.2929L9.9289 16.6569C9.53838 17.0474 9.53838 17.6806 9.92891 18.0711C10.3194 18.4616 10.9526 18.4616 11.3431 18.0711L17 12.4142L22.6568 18.071C23.0474 18.4616 23.6805 18.4616 24.071 18.071C24.4616 17.6805 24.4616 17.0474 24.071 16.6568L17.7071 10.2929ZM15.9999 11L16 24.5L18 24.5L17.9999 11L15.9999 11Z"
        fill="white"
      />
    </svg>
  );
}
