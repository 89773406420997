import { SectionHeader } from "../../components/SectionHeader";
import { SlideButton } from "../../components/SlideButton";
import { HustleTile } from "./HustleTile";
import { useSlideContent } from "../../utils/useSlideContent";
import { HustleLoading } from "./HustleLoading";
import { useDetectMobile } from "../../utils/isMobile";
import { Advertisement } from "../../components/Advertisement";
import patternBackground from "./pattern-background.svg";
import { HustleType } from "../../types";

type Hustle = {
  post: HustleType;
};

export function Hustle() {
  const { isMobile } = useDetectMobile();
  const postCount = isMobile ? 1 : 3;
  const {
    posts,
    isLoading,
    isFetching,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
  } = useSlideContent<Hustle>({
    requestUrl: "posts?postType=hustle",
    count: postCount,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <>
      <div
        className="mt-10 bg-cover bg-no-repeat md:rounded-2xl border-t-4 border-b-4 border-solid border-[#F0F0F0] md:border md:border-gray bg-black"
        style={{
          backgroundImage: `url(${patternBackground})`,
        }}
      >
        <div className="rounded-2xl p-5 backdrop-brightness-50">
          <div className="text-primary">
            <SectionHeader title="hustle" gradient={false} border={false} />
          </div>
          <div className="flex flex-col w-full mb-4 lg:mb-0">
            <div className="w-full flex-row md:space-x-4 grid grid-cols-1 md:grid-cols-3 mb-4">
              {(loading ? Array.from(new Array(postCount)) : posts).map(
                (post, i) =>
                  post ? (
                    <HustleTile key={post.id} post={post} />
                  ) : (
                    <HustleLoading key={i} />
                  )
              )}
            </div>

            <div className="flex w-full flex-col mt-auto">
              <div className="flex justify-between">
                <SlideButton
                  appearance="light"
                  direction="back"
                  onClick={slideBack}
                  disabled={slideBackDisabled}
                />
                <SlideButton
                  appearance="light"
                  direction="forward"
                  onClick={slideForward}
                  disabled={slideForwardDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden pt-10">
        <Advertisement
          path="/21735245768/SPC_News_MREC_B"
          size={[300, 250]}
          id="div-gpt-ad-1682306808816-hustle"
        />
      </div>
    </>
  );
}
