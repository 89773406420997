import { useEffect, useState } from "react";

const ProgressBar = ({ current, total: full = 0 }) => {
  const [total, setTotal] = useState(current);

  useEffect(() => {
    setTotal(current);
  }, [current]);

  const getProgressColor = (progress) => {
    if (progress < 50) {
      return "red";
    } else if (progress < 100) {
      return "orange";
    } else {
      return "green";
    }
  };

  const progress = Math.round((total / full) * 100);

  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-1">
      <div
        className="bg-blue h-2.5 rounded-full"
        style={{
          width: `${Math.min(progress, 100)}%`,
          backgroundColor: `${getProgressColor(Math.min(progress, 100))}`,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
