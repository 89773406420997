import { Footer } from "../Footer";
import { Badge } from "../../components/Badge";

const MeetTheTeam = () => {
  const catalystTeam = [
    {
      id: "bill-deyoung",
      name: "Bill DeYoung",
      nameUrl: "#bill-deyoung",
      imagePath: "bdeyoung-150x150.jpg",
      descriptionUrl:
        "https://stpetecatalyst.com/july-in-the-arts-hot-fun-in-the-summertime/",
      description: "July in the arts: Hot fun in the summertime",
    },
    {
      id: "joe-hamilton",
      name: "Joe Hamilton",
      nameUrl: "#joe-hamilton",
      imagePath: "jhamilton-150x150.jpg",
      descriptionUrl:
        "https://stpetecatalyst.com/origin-story-pods-and-red-rover/",
      description: "Origin Story: PODS and Red Rover",
    },
    {
      id: "mark-parker",
      name: "Mark Parker",
      nameUrl: "#mark-parker",
      imagePath: "mparker-150x150.jpg",
      descriptionUrl:
        "https://stpetecatalyst.com/visually-impaired-students-test-navigational-app/",
      description: "Visually impaired students test navigational app",
    },
    {
      id: "veronica-brezina",
      name: "Veronica Brezina",
      nameUrl: "#veronica-brezina",
      imagePath: "vbrezina-150x150.jpg",
      descriptionUrl:
        "https://stpetecatalyst.com/its-tee-time-st-petes-first-topgolf-opens/",
      description: "It’s tee time: St. Pete’s first Topgolf opens",
    },
    {
      id: "waveney-ann-moore",
      name: "Waveney Ann Moore",
      nameUrl: "#waveney-ann-moore",
      imagePath: "wmoore-150x150.jpg",
      descriptionUrl:
        "https://stpetecatalyst.com/waveney-ann-moore-lessons-for-parents-teachers-and-children/",
      description:
        "Waveney Ann Moore: Lessons for parents, teachers and children ",
    },
  ];

  return (
    <>
      <div className="grid lg:grid-cols-1 gap-5 p-5 md:p-0 items-center">
        <header>
          <span className="w-full border-t-8 border-t-black">
            <h1 className="text-4xl text-center font-bold">
              <Badge title={"Meet"} />
            </h1>
          </span>
        </header>

        <div className="grid lg:grid-cols-5 gap-5 p-5 md:p-0">
          {catalystTeam.map((teamMember) => (
            <div key={`team-member-${teamMember.id}`} className="md:col-span-1">
              <section className="content-center">
                <div>
                  <img
                    src={require(`../../assets/Images/${teamMember.imagePath}`)}
                    width="150"
                    height="150"
                    alt={teamMember.name}
                    className="rounded-full border-4 border-gray-400 mx-auto block"
                  />
                </div>
                <h2 className="text-center border-b-2 border-brand py-2">
                  <a href={teamMember.nameUrl}>{teamMember.name}</a>
                </h2>
              </section>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MeetTheTeam;
