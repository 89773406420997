import Label from "../Label";

export function SubSection({ label = "", children }) {
  return (
    <>
      <div>
        {label ? (
          <Label text={label} className="border-b px-4 py-2 w-full block" />
        ) : (
          ""
        )}
        <div className="border-b p-4">{children}</div>
      </div>
    </>
  );
}
