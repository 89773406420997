import { useQuery } from "react-query";
import { queryRequest } from "./utils/request";
import { CircularProgress } from "@mui/material";

export function AuthenticatedRoute({ children }) {
  const token = window.localStorage.getItem("token");
  const { data: userResponse, isLoading } = useQuery(
    "auth/me",
    () =>
      queryRequest({
        service: "identity",
        path: "auth/me",
        authenticated: true,
      }),
    { enabled: !!token }
  );

  if (!token || (!isLoading && !userResponse.data.user_id)) {
    window.location.href = "/auth/login";
    return null;
  }

  return isLoading ? (
    <div className="h-full w-full flex flex-col items-center text-primary">
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <>{children}</>
  );
}
