import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RecordDataService from "../../services/PostTypeService";
import DataTable from "./DataTable";
import DataSearch from "./DataSearch";
import DataPager from "./DataPager";
import DataFilters from "./DataFilters";
import StatusFilters from "./StatusFilters";
import { useQuery } from "react-query";
import { queryRequest } from "../../../utils/request";

const DefaultLayout = ({
  type = "post",
  perPage = 10,
  titleKey = "title",
  titleRoute = "edit/:id",
  showSearchBar = true,
  excludedColumns = [],
  excludedDataFilters = [],
  excludedStatusFilters = [],
}) => {
  const [records, setRecords] = useState([]);
  const [searchTitle, setSearchTitle] = useState(" ");
  const recordsRef = useRef(null);

  const [isLoading, setLoading] = useState(true);

  const [statusFilter, setStatusFilter] = useState("all");
  const [statusFilterCounters, setStatusFilterCounters] = useState([]);
  const [dataFilter, setDataFilter] = useState(null);

  const [page, setPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [pageSize, setPageSize] = useState(perPage);
  const [selectedRows, setSelectedRows] = useState(null);

  const navigate = useNavigate();
  recordsRef.current = records;

  const token = window.localStorage.getItem("token");
  const { data: userResponse } = useQuery(
    "auth/me",
    () =>
      queryRequest({
        service: "identity",
        path: "auth/me",
        authenticated: true,
      }),
    { enabled: !!token }
  );

  const prepareRequestParams = (
    postType,
    title,
    dataFilter,
    statusFilter,
    page,
    maxRows
  ) => {
    let params = {
      sortBy: "date",
      sortType: "desc",
    };
    if (postType) {
      params["postType"] = postType;
    }
    if (title) {
      params["title"] = title;
    }
    if (statusFilter) {
      if (!["all", "mine"].includes(statusFilter)) {
        params["status"] = statusFilter;
      } else {
        if ("mine" === statusFilter) {
          params["authorName"] = userResponse.data.first_name;
        }
      }
    }

    if (page) {
      params["page"] = page;
    }

    if (maxRows) {
      params["take"] = maxRows;
    }

    if (dataFilter) {
      if (dataFilter.date_range) {
        const parts = dataFilter.date_range.split("/");
        params["dateFrom"] = parts[0];
        params["dateTo"] = parts[1];
      }
      if (dataFilter.category && dataFilter.category.id) {
        params["categoryIds"] = [dataFilter.category.id];
      }
      if (dataFilter.author && dataFilter.author.id) {
        params["authorName"] = dataFilter.author.name;
      }
    }
    return params;
  };

  const retrieveRecords = () => {
    const params = prepareRequestParams(
      type,
      searchTitle,
      dataFilter,
      statusFilter,
      page,
      pageSize
    );
    setLoading(true);
    RecordDataService.getAll(params)
      .then((response) => {
        if (type === "newsletter") {
          setRecords(response.data.items);
          setTotalPageCount(response.data.totalPages);
          setTotalRecordCount(response.data.totalItems);
        } else {
          setRecords(response.data.posts);
          setTotalPageCount(response.data.totalPages);
          setTotalRecordCount(response.data.totalPosts);
        }

        setLoading(false);
        RecordDataService.getCounters(params)
          .then((response) => {
            setStatusFilterCounters([
              {
                id: "all",
                name: "All",
                count: response.data.totalArticleCount,
              },
              // {
              //   id: "mine",
              //   name: "Mine",
              //   count: response.data.myArticleCount,
              // },
              {
                id: "published",
                name: "Published",
                count: response.data.publishedArticleCount,
              },
              {
                id: "draft",
                name: "Drafts",
                count: response.data.draftArticleCount,
              },
            ]);
          })
          .catch((e) => {
            console.warn(e);
          });
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  const retrieveSearchedResults = () => {
    if (searchTitle === " " || (searchTitle.length < 3 && searchTitle !== "")) {
      return;
    }

    retrieveRecords();
  };

  useEffect(retrieveRecords, [page, pageSize, statusFilter, dataFilter]);
  useEffect(retrieveSearchedResults, [searchTitle]);

  const handleSearch = (input) => {
    setLoading(true);
    setSearchTitle(input);
    setPage(1);
  };

  const handleStatusFilter = (filter) => {
    setStatusFilter(filter.id);
  };

  const handleOpenRecord = (rowIndex) => {
    const id = recordsRef.current[rowIndex]?.id;
    const route = titleRoute.replace(":id", id);
    navigate(route);
  };

  const handleDeleteRecord = (rowIndex) => {
    const id = recordsRef.current[rowIndex].id;
    RecordDataService.remove(id)
      .then((response) => {
        navigate(`journalist/${type}`);
        let newRecords = [...recordsRef.current];
        newRecords.splice(rowIndex, 1);
        setRecords(newRecords);
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  const handlePageChange = (value) => {
    setLoading(true);
    if (value === "prev") {
      setPage(page - 1);
    } else if (value === "next") {
      setPage(page + 1);
    } else if (value === Infinity) {
      setPage(totalPageCount);
    } else {
      setPage(value);
    }
  };

  const handleDataSelect = (value) => {
    setSelectedRows(value);
  };

  return (
    <>
      {(showSearchBar || totalPageCount > 1) && (
        <div className="flex w-full lg:justify-end">
          <div className="flex lg:inline-flex flex-col lg:flex-row">
            {showSearchBar ? (
              <DataSearch
                onInput={(input) => {
                  handleSearch(input);
                }}
              />
            ) : (
              ""
            )}
            {totalPageCount > 1 ? (
              <DataPager
                navigate={handlePageChange}
                postsPerPage={pageSize}
                totalRecordCount={totalRecordCount}
                totalPageCount={totalPageCount}
                currentPage={page}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      <div className="w-full border-b-2 border-b-[#E3E3E3] !mt-8 pb-2">
        <StatusFilters
          filters={statusFilterCounters}
          onClick={handleStatusFilter}
          current={statusFilter}
          excludedFilters={excludedStatusFilters}
        />
      </div>

      <div className="flex w-full">
        {/* MVP HIDE
        <div className="w-2/6">
          <BulkActions
            onAction={() => {
              // TODO: Implement
            }}
          />
        </div> */}
        <div className="w-full lg:w-4/6 ml-auto">
          <DataFilters
            excludedFilters={excludedDataFilters}
            onFilter={(values) => {
              setDataFilter(values);
            }}
          />
        </div>
      </div>

      <div className="w-full">
        <DataTable
          data={records}
          titleKey={titleKey}
          onDataSelect={handleDataSelect}
          onRecordClick={handleOpenRecord}
          isLoading={isLoading}
          pageSize={pageSize}
          excludedColumns={excludedColumns}
        />
      </div>
      <div className="flex w-full lg:justify-end">
        <div className="inline-flex items-center">
          {totalPageCount > 1 ? (
            <DataPager
              navigate={handlePageChange}
              postsPerPage={pageSize}
              totalRecordCount={totalRecordCount}
              totalPageCount={totalPageCount}
              currentPage={page}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
