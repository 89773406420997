import "../public/styles.css";
import { Outlet } from "react-router-dom";

export default function Root() {
  return (
    <div className="container mx-auto md:-mt-12 md:mb-12 relative">
      <Outlet />
    </div>
  );
}
