import { useState, useEffect } from "react";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { ZapModalIcon } from "./ZapModalIcon";
import { ZapModalCloseIcon } from "./ZapModalCloseIcon";
import { queryRequest } from "../../utils/request";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { SlideButton } from "../../components/SlideButton";
import { useQuery } from "react-query";
import Skeleton from "@mui/material/Skeleton";
import { Modal } from "../../components/Modal/Modal";

const getPost = async ({ queryKey }) => {
  const [_, { id }] = queryKey;
  return queryRequest({
    path: "news/posts/",
    params: id,
  });
};

export function ZapModal() {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);

  const { data: postData, isLoading } = useQuery(
    ["post", { id: state?.id ? state.id : id }],
    getPost
  );

  useEffect(() => {
    setPost(postData?.data);
  }, [postData, state]);

  const toPrevPost = (id) => {
    navigate(`/zap/${id}`, {
      state: { id: id, type: "Zap" },
    });
  };

  const toNextPost = (id) => {
    navigate(`/zap/${id}`, {
      state: { id: id, type: "Zap" },
    });
  };

  return (
    <Modal hideClose={true} appearance="zap">
      <div className="pt-14 pb-10 text-center">
        <div className="flex justify-center mb-8">
          <ZapModalIcon />
        </div>
        <div className="px-6 md:px-20">
          {isLoading ? (
            <div>
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="rounded-2xl opacity-50 mb-6"
                animation="wave"
                height={40}
                variant="rectangular"
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="rounded-2xl opacity-50"
                animation="wave"
                height={150}
                variant="rectangular"
              />
            </div>
          ) : (
            <div>
              <h2
                title={sanitize(post?.title)}
                className="text-2xl leading-6 font-semibold mb-6"
                dangerouslySetInnerHTML={sanitizeForReact(post?.title)}
              />
              <div
                className="font-['PT+Serif'] text-base leading-6 font-normal"
                dangerouslySetInnerHTML={sanitizeForReact(post?.htmlContent)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="border-t border-[#4D4D4D] p-6 flex justify-between mt-auto">
        <SlideButton
          direction="back"
          onClick={() => toNextPost(post?.nextId)}
          appearance={"dark"}
          disabled={!post || !post?.nextId}
        />
        <Link to={`/`} className="text-center" title="Close Modal">
          <ZapModalCloseIcon />
        </Link>
        <SlideButton
          direction="forward"
          onClick={() => toPrevPost(post?.prevId)}
          appearance={"dark"}
          disabled={!post || !post?.prevId}
        />
      </div>
    </Modal>
  );
}
