import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";

type ShuffleUserInfoProps = {
  infoTitle: string;
  infoData: string;
};

export function ShuffleUserInfo({ infoTitle, infoData }: ShuffleUserInfoProps) {
  return (
    <div>
      <span className="text-white text-sm leading-4 mb-2">{infoTitle}</span>
      <h3
        className="text-primary text-md md:text-lg leading-6 font-medium line-clamp-2"
        dangerouslySetInnerHTML={sanitizeForReact(infoData)}
        title={sanitize(infoData)}
      />
    </div>
  );
}
