import { useForm } from "react-hook-form";
import { Section } from "../../components/Layout/Section";
import { Publish } from "../../components/Sections/Publish";
import { ArticlePickerComplex } from "../../components/Sections/ArticlePickerComplex";
import { useState } from "react";
import Advertisement from "../../components/Inputs/Advertisement";
import { RepeaterSection } from "../../components/Layout/RepeaterSection";
import Title from "../../components/Title";
import DropdownAsync from "../../components/Inputs/DropdownAsync";
import { searchQueryRequest } from "../../../utils/request";
import { Newsletter } from "../../utils/models";
import Alert from "../../components/Alert";

import * as _ from "lodash";
import DailyEditionService from "../../services/DailyEditionService";

import TextInput from "../../components/Inputs/TextInput";
import { handleRedirect } from "../../utils/redirects";
import { useNavigate, useParams } from "react-router-dom";
import { generateHtml } from "./generator";
import {
  formatFormData,
  generateJson,
  handleImageUpload,
} from "../../utils/newsletter";
import Spinner from "../../components/Spinner";
import Button from "../../components/Inputs/Button";

export function Edit() {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Newsletter>();

  // Basics
  const { id } = useParams();
  const [currentPost, setCurrentPost] = useState(null);
  const [isLoading, setLoading] = useState("");
  const [response, setResponse] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  // Form
  const initialValues = {
    top_main_article: null,
    grid_post1: null,
    grid_post2: null,
    grid_post3: null,
    grid_post4: null,
    advertisement1: null,
    advertisement2: null,
    advertisement3: null,
    advertisement4: null,
    advertisement5: null,
    advertisement6: null,
    second_main_article: null,
    zap1: null,
    zap2: null,
    zap3: null,
    zap4: null,
    zap5: null,
    zap6: null,
    comment1: null,
    comment2: null,
    comment3: null,
    cityverse_post1: null,
    cityverse_post2: null,
    cityverse_post3: null,
    media1: {},
    media2: null,
    media3: null,
    graphic_article: null,
    event1: {},
    event2: null,
    event3: null,
    shuffle: null,
    hustle: null,
    influencer: null,
    spark_plug: null,
  };

  // Redirects
  handleRedirect(redirect);

  // The form
  const [form, setForm] = useState(initialValues);

  // Media Articles
  const maxMediaItems = 3;
  const [totalMediaItems, setTotalMediaItems] = useState(1);

  // Event Articles
  const maxEvents = 3;
  const [totalEventItems, setTotalEventItems] = useState(1);

  // Repeater fields
  const addRepeatableItem = (index, name) => {
    let newIndex = index + 1;
    let isOk = false;
    if (form[name + (newIndex + 1)] == null) {
      isOk = true;
    }
    form[name + (newIndex + 1)] = {};
    setForm(form);
    return isOk;
  };
  const removeRepeatableItem = (index, name) => {
    let newIndex = index + 1;
    let isOk = false;
    if (form[name + newIndex] !== null) {
      isOk = true;
    }
    form[name + newIndex] = null;
    setForm(form);
    return isOk;
  };

  const onSubmit = async (data) => {
    setLoading(data["status"]);

    const formData = _.cloneDeep(data);

    try {
      for (let i in form) {
        let image = await handleImageUpload(form[i]);
        if (null !== image) {
          form[i].image = image;
        }
      }

      let postData = {
        specId: formData.specId,
        subject: formData.subject,
        effectivePublishDate: formData.effectivePublishDate,
        htmlContent: generateHtml(form),
        contentJson: generateJson(form),
      };

      const postResponse = await DailyEditionService.update(
        currentPost.id,
        postData
      );
      setLoading("");
      setResponse({
        type: "success",
        message: "DailyEdition updated successfully.",
      });

      setTimeout(() => {
        setResponse({ type: "", message: "" });
      }, 5000);
    } catch (e: any) {
      console.warn("Error:");
      console.warn(e);
      setResponse({ type: "danger", message: e.message });
      setLoading("");
    }
  };

  const setInputValue = (name, value) => {
    if ("specId" === name) {
      if (value.id) {
        setValue(name, value.id);
      }
    } else {
      form[name] = value;
      setForm(form);
    }
  };
  const setAdvertisement = setInputValue;
  const setArticleFromPicker = setInputValue;

  const retrieveFormData = (postId) => {
    return DailyEditionService.get(postId)
      .then((response) => {
        if (response.data) {
          const newData = formatFormData(response.data);

          // Map data
          const items = {};
          if (newData.contentJson) {
            for (let i in newData.contentJson) {
              items[newData.contentJson[i].type] =
                newData.contentJson[i].component;
            }
          }

          // Set Basic details
          setValue("subject", newData.subject);
          setValue("specId", newData?.spec?.id);
          setValue(
            "effectivePublishDate",
            new Date(Date.parse(newData?.effectivePublishDate))
          );

          // Set media items
          let totalMediaItems = 0;
          for (let i = 0; i < maxMediaItems; i++) {
            if (items["media" + i]) {
              totalMediaItems++;
            }
          }
          setTotalMediaItems(totalMediaItems);

          // Load components
          for (let i in items) {
            if (i in form) {
              form[i] = items[i];
            }
          }
          setForm(form);

          // Set data
          setCurrentPost(newData);
        }
      })
      .finally(() => {
        setLoading("");
      });
  };

  if (!currentPost) {
    retrieveFormData(id);
  }

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <Title text="Daily Edition Generator" />

      {response && <Alert type={response.type} text={response.message} />}

      {currentPost ? (
        <>
          <Section label="Publish">
            <Publish
              label={""}
              setValue={setValue}
              getValues={getValues}
              loading={isLoading}
              disableTenants={true}
              disableStatus={true}
            />
          </Section>

          <Section label="General Details">
            <div className="p-3">
              <TextInput
                key="subject"
                label="Subject"
                name="subject"
                placeholder="Enter newsletter subject"
                register={register}
                errors={errors}
              />
            </div>
            <div key={`newsletter_spec`} className="p-3">
              <DropdownAsync
                key={`specId`}
                className="w-full mt-2"
                name={`specId`}
                showLabel={true}
                label="Specification"
                required={false}
                value={currentPost?.spec}
                setValue={setInputValue}
                placeholder="Search for specification..."
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={(inputValue) => {
                  return searchQueryRequest("newsletterSpecs", {
                    text: inputValue,
                  });
                }}
              />
            </div>
          </Section>

          {/* Top Main Article */}
          <Section label="Top Main Article">
            <ArticlePickerComplex
              label=""
              name="top_main_article"
              value={form.top_main_article}
              setValue={setArticleFromPicker}
            />
          </Section>

          {/* Grid Posts */}
          <div className="grid grid-cols-2 space-x-3">
            <Section label="Grid Post (1)">
              <ArticlePickerComplex
                label=""
                name="grid_post1"
                value={form.grid_post1}
                setValue={setArticleFromPicker}
              />
            </Section>
            <Section label="Grid Post (2)">
              <ArticlePickerComplex
                label=""
                name="grid_post2"
                value={form.grid_post1}
                setValue={setArticleFromPicker}
              />
            </Section>
          </div>
          <div className="grid grid-cols-2 space-x-3">
            <Section label="Grid Post (3)">
              <ArticlePickerComplex
                label=""
                name="grid_post3"
                value={form.grid_post3}
                setValue={setArticleFromPicker}
              />
            </Section>
            <Section label="Grid Post (4)">
              <ArticlePickerComplex
                label=""
                name="grid_post4"
                value={form.grid_post4}
                setValue={setArticleFromPicker}
              />
            </Section>
          </div>

          {/* Advertisements 1 */}
          <div className="grid grid-cols-2 space-x-2">
            <Section label="Advertisement (1)">
              <Advertisement
                className="p-3"
                name="advertisement1"
                value={form.advertisement1}
                setValue={setAdvertisement}
              />
            </Section>
            <Section label="Advertisement (2)">
              <Advertisement
                className="p-3"
                name="advertisement2"
                value={form.advertisement2}
                setValue={setAdvertisement}
              />
            </Section>
          </div>

          {/* 2nd Main Article */}
          <Section label="2nd Main Article">
            <ArticlePickerComplex
              label=""
              name="second_main_article"
              value={form.second_main_article}
              setValue={setArticleFromPicker}
            />
          </Section>

          {/* Zaps */}
          <Section label="Zaps">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              {Array.apply(0, Array(6)).map(function (x, i) {
                return (
                  <div key={`zap${i}_wrap`} className="p-3">
                    <DropdownAsync
                      key={`zap${i + 1}`}
                      className="w-full mt-2"
                      name={`zap${i + 1}`}
                      showLabel={true}
                      label={"Zap " + (i + 1) + ":"}
                      value={form[`zap${i + 1}`]}
                      setValue={setInputValue}
                      placeholder="Search for existing zap..."
                      getOptionLabel={(e) => e.title}
                      getOptionValue={(e) => e.id}
                      loadOptions={(inputValue) => {
                        return searchQueryRequest("zap", { text: inputValue });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Section>

          {/* Media Articles */}
          <div key={`media_${totalMediaItems}`}>
            {Array.apply(0, Array(maxMediaItems)).map((x, i) => {
              return form["media" + (i + 1)] ? (
                <RepeaterSection
                  key={`media${i + 1}_wrap`}
                  label={`Media Article (${i + 1})`}
                  index={i}
                  total={totalMediaItems}
                  max={maxMediaItems}
                  className="mb-2"
                  onAdd={(index) => {
                    if (addRepeatableItem(index, "media")) {
                      setTotalMediaItems(totalMediaItems + 1);
                    }
                  }}
                  onRemove={(index) => {
                    if (removeRepeatableItem(index, "media")) {
                      setTotalMediaItems(totalMediaItems - 1);
                    }
                  }}
                >
                  <ArticlePickerComplex
                    label=""
                    name={`media${i + 1}`}
                    value={form?.[`media${i + 1}`]}
                    setValue={setArticleFromPicker}
                  />
                </RepeaterSection>
              ) : (
                ""
              );
            })}
          </div>

          {/* Advertisements 2 */}
          <div className="grid grid-cols-2 space-x-2">
            <Section label="Advertisement (3)">
              <Advertisement
                className="p-3"
                name="advertisement3"
                value={form.advertisement3}
                setValue={setAdvertisement}
              />
            </Section>
            <Section label="Advertisement (4)">
              <Advertisement
                className="p-3"
                name="advertisement4"
                value={form.advertisement4}
                setValue={setAdvertisement}
              />
            </Section>
          </div>

          {/* Graphic Article */}
          <Section label="Graphic Article">
            <ArticlePickerComplex
              label=""
              name="graphic_article"
              value={form.graphic_article}
              setValue={setArticleFromPicker}
            />
          </Section>

          {/* Event Articles */}
          {/*
          <div key={`events_${totalEventItems}`}>
            {Array.apply(0, Array(maxEvents)).map((x, i) => {
              return form["event" + (i + 1)] ? (
                <RepeaterSection
                  key={`event${i + 1}_wrap`}
                  label={`Event (${i + 1})`}
                  index={i}
                  total={totalEventItems}
                  max={maxEvents}
                  className="mb-2"
                  onAdd={(index) => {
                    if (addRepeatableItem(index, "event")) {
                      setTotalEventItems(totalEventItems + 1);
                    }
                  }}
                  onRemove={(index) => {
                    if (removeRepeatableItem(index, "event")) {
                      setTotalEventItems(totalEventItems - 1);
                    }
                  }}
                >
                  <ArticlePickerComplex
                    label=""
                    name={`event${i + 1}`}
                    value={form?.[`media${i + 1}`]}
                    setValue={setArticleFromPicker}
                  />
                </RepeaterSection>
              ) : (
                ""
              );
            })}
          </div> */}

          {/* Shuffle */}
          <Section label="Shuffle">
            <ArticlePickerComplex
              label=""
              name="shuffle"
              value={form.shuffle}
              setValue={setArticleFromPicker}
              allowedPostTypes={["custom", "shuffle"]}
            />
          </Section>

          {/* Hustle */}
          <Section label="Hustle">
            <ArticlePickerComplex
              label=""
              name="hustle"
              value={form.hustle}
              setValue={setArticleFromPicker}
              allowedPostTypes={["custom", "hustle"]}
            />
          </Section>

          {/* Influencer */}
          <Section label="Influencer">
            <ArticlePickerComplex
              label=""
              name="influencer"
              value={form.influencer}
              setValue={setArticleFromPicker}
              allowedPostTypes={["custom", "influencer"]}
            />
          </Section>

          {/* Cityverse Posts */}
          <Section label="Cityverse Posts">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              {Array.apply(0, Array(3)).map(function (x, i) {
                return (
                  <div key={`cityverse_post${i + 1}_wrap`} className="p-3">
                    <DropdownAsync
                      key={`cityverse_post${i + 1}`}
                      className="w-full mt-2"
                      name={`cityverse_post${i + 1}`}
                      showLabel={true}
                      label={"Post " + (i + 1) + ":"}
                      value={form[`cityverse_post${i + 1}`]}
                      setValue={setInputValue}
                      placeholder="Search for existing post..."
                      getOptionLabel={(e) => e.title}
                      getOptionValue={(e) => e.id}
                      loadOptions={(inputValue) => {
                        return searchQueryRequest("post", { text: inputValue });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </Section>

          {/* Advertisements 3 */}
          <div className="grid grid-cols-2 space-x-2">
            <Section label="Advertisement (5)">
              <Advertisement
                className="p-3"
                name="advertisement5"
                value={form.advertisement5}
                setValue={setAdvertisement}
              />
            </Section>
            <Section label="Advertisement (6)">
              <Advertisement
                className="p-3"
                name="advertisement6"
                value={form.advertisement6}
                setValue={setAdvertisement}
              />
            </Section>
          </div>

          {/* Featured Comments 
          NOTE: NOT IN MVP
      
      <Section label="Featured Comments">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {Array.apply(0, Array(3)).map(function (x, i) {
            return (
              <div key={`comment${i + 1}_wrap`} className="p-3">
                <DropdownAsync
                  key={`comment${i + 1}`}
                  className="w-full mt-2"
                  name={`comment${i + 1}`}
                  showLabel={true}
                  label={"Comment " + (i + 1) + ":"}
                  value={form[`comment${i + 1}`]}
                  setValue={setInputValue}
                  placeholder="Search for existing comment..."
                  getOptionLabel={(e) => e.title}
                  getOptionValue={(e) => e.id}
                  loadOptions={(inputValue) => {
                    return searchQueryRequest("comment", { text: inputValue });
                  }}
                />
              </div>
            );
          })}
        </div>
      </Section>
      */}

          <Section label="Spark Plug">
            <Advertisement
              className="p-3"
              name="spark_plug"
              value={form.spark_plug}
              setValue={setAdvertisement}
            />
          </Section>
        </>
      ) : (
        <div className="w-full text-center mt-4 mb-4">
          <Spinner />
        </div>
      )}
    </form>
  );
}
