import BaseService from "./BaseService";

const getAll = (params) => {
  return BaseService.getAll("tags", params, true);
};

const get = (id) => {};

const create = (data) => {
  return BaseService.create("tags", data, true);
};

const update = (id, data) => {};

const remove = (id) => {};

const CategoryService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default CategoryService;
