import { useRef } from "react";
import { PrintIcon } from "../../components/PrintIcon";
import { useReactToPrint } from "react-to-print";
import { sanitizeForReact } from "../../utils/sanitizeHtml";
import { relativeTime } from "../../utils/formatDate";

type PostPrintProps = {
  featuredImage: string;
  title: string;
  author: string;
  content: string;
  time: Date;
};

export function PostPrint({
  featuredImage,
  title,
  author,
  content,
  time,
}: PostPrintProps) {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: title,
    pageStyle: "@page { margin: 4rem auto; }",
  });

  return (
    <div>
      <button aria-label="print" onClick={handlePrint}>
        <PrintIcon />
      </button>
      <div style={{ display: "none" }}>
        <div ref={printRef} className="py-4 container m-auto px-8">
          <h1
            className="text-6xl mb-5"
            dangerouslySetInnerHTML={sanitizeForReact(title)}
          />
          <div>
            {author} - posted {relativeTime(time)}
          </div>
          <img
            src={featuredImage}
            alt={title}
            className="w-full mt-4 max-h-[450px] object-cover"
          />
          <div
            className="font-['PT+Serif']"
            dangerouslySetInnerHTML={sanitizeForReact(content)}
          />
        </div>
      </div>
    </div>
  );
}
