import React from "react";

const EmailInput = ({
  label,
  showLabel = true,
  name,
  register,
  errors,
  className = "",
  inputClassName = "",
  placeholder = "",
}) => {
  return (
    <div
      className={
        "mb-4 flex w-full flex-wrap items-stretch text-left " + className
      }
    >
      {label && (
        <label className={`${showLabel ? "" : "sr-only"}`}>{label}</label>
      )}
      <input
        type="email"
        placeholder={placeholder}
        className={
          (errors?.[name] ? "error" : "") +
          " w-full form-control " +
          inputClassName
        }
        {...register(name, { required: true })}
      />
      {errors?.[name] && <span>This field is required</span>}
    </div>
  );
};

export default EmailInput;
