import { PostLink } from "../../components/PostLink";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { HustleType } from "../../types";

type CreateTitleProps = {
  post: HustleType;
};

export function HustleTile({ post }: CreateTitleProps) {
  return (
    <PostLink
      slug={`hustle/${post?.slug}`}
      preloadData={post}
      title={sanitize(post?.title)}
      className="flex flex-col rounded-2xl grow shadow-sm mb-4 md:mb-0 group"
    >
      {post?.featuredImage ? (
        <img
          alt={sanitize(post?.title)}
          src={post?.featuredImage}
          title={sanitize(post?.title)}
          className="w-full h-[252px] shrink-0 bg-[#d9d9d9] rounded-tl-2xl rounded-tr-2xl aspect-video object-cover brightness-100 group-hover:brightness-75 transition duration-300"
        />
      ) : (
        <div className="w-full h-[252px] shrink-0 bg-[#d9d9d9] rounded-tl-2xl rounded-tr-2xl aspect-video brightness-100 group-hover:brightness-75 transition duration-300"></div>
      )}
      <div className="w-full bg-white rounded-b-2xl px-5 py-6 mt-0 h-full">
        <h2
          className="font-semibold text-2xl leading-6 text-black mb-0 group-hover:opacity-70 transition duration-300"
          dangerouslySetInnerHTML={sanitizeForReact(post?.title)}
          title={sanitize(post?.title)}
        />
      </div>
    </PostLink>
  );
}
