import BaseService from "./BaseService";

const getAll = (params) => {
  return BaseService.getAll("news/wp/posts", params, true);
};

const get = (id) => {
  return BaseService.get(`news/posts/${id}`, true);
};

const create = (data) => {
  return BaseService.create("journalist/influencers", data, true);
};

const update = (id, data) => {
  return BaseService.update(`journalist/influencers/${id}`, data, true);
};

const remove = (id) => {
  return BaseService.remove(`journalist/influencers/${id}`, "", true);
};

const InfluencerService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default InfluencerService;
