export function ZapModalIcon() {
  return (
    <svg
      width="520"
      height="57"
      viewBox="0 0 520 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M236.683 52.7223C225.677 50.0483 217.489 40.1799 217.489 28.4464C217.489 20.0848 221.651 12.6742 228.022 8.13521L227.263 5.64648C219.872 10.5807 215 18.9547 215 28.4403C215 42.9033 226.324 54.785 240.628 55.8224C239.315 54.7911 237.996 53.7536 236.683 52.7162V52.7223Z"
        fill="white"
      />
      <path
        d="M231.664 1.54426C231.579 2.22765 231.754 3.01895 231.96 3.69035C233.591 9.0136 235.215 14.3309 236.846 19.6541C237.287 21.0928 237.728 22.6275 237.341 24.0782C237.051 25.1512 236.345 26.0504 235.662 26.9256C233.126 30.1807 230.728 33.5317 228.469 36.9787C228.239 37.3263 228.004 37.71 228.01 38.1236C228.022 38.7411 228.547 39.2087 229.025 39.5983C235.765 45.0295 242.553 50.4007 249.39 55.712C250.405 56.5032 251.806 57.3185 252.887 56.6171C253.987 55.9038 253.763 54.2612 253.407 53.0084C252.03 48.1467 250.653 43.291 249.276 38.4294C248.853 36.9367 248.424 35.4021 248.581 33.8614C248.817 31.5295 250.321 29.5453 251.776 27.6989C253.111 26.0024 254.446 24.306 255.774 22.6095C256.439 21.7702 257.121 20.883 257.308 19.828C257.671 17.7958 255.551 16.8606 254.216 15.7936C252.163 14.157 250.103 12.5145 248.05 10.8779C244.003 7.65281 239.956 4.42769 235.91 1.19657C234.871 0.363314 233.373 -0.481932 232.316 0.327347C231.918 0.633074 231.73 1.05869 231.67 1.53827L231.664 1.54426Z"
        fill="#2BE2D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M248.294 4.07546L244.48 1.03711H244.487C255.024 1.71421 263.976 8.27098 268.03 17.4299H265.284C261.975 10.7678 255.759 5.7732 248.294 4.07546ZM266.885 35.3388C265.314 40.7552 261.942 45.4209 257.459 48.6564L258.175 51.1822C262.388 48.3436 265.775 44.3846 267.897 39.7403L266.885 35.3388Z"
        fill="white"
      />
      <path
        d="M255.646 36.5037L266.129 20.3769H269.101L273.11 36.5037H270.138L266.774 21.9204H267.972L258.849 36.5037H255.646ZM259.747 32.7715L261.014 30.4216H269.7L270.092 32.7715H259.747Z"
        fill="white"
      />
      <path
        d="M275.184 36.5037L278.409 20.3769H284.629C286.58 20.3769 288.085 20.8069 289.145 21.667C290.22 22.5271 290.757 23.7174 290.757 25.238C290.757 26.6049 290.443 27.7875 289.813 28.7859C289.198 29.7688 288.315 30.5291 287.163 31.0667C286.012 31.5889 284.645 31.85 283.063 31.85H277.81L279.4 30.4216L278.178 36.5037H275.184ZM279.33 30.7672L278.271 29.3157H283.178C284.622 29.3157 285.743 28.9932 286.541 28.3481C287.34 27.7031 287.739 26.7585 287.739 25.5144C287.739 24.639 287.44 23.9862 286.841 23.5562C286.257 23.1261 285.413 22.9111 284.307 22.9111H279.63L281.197 21.4136L279.33 30.7672Z"
        fill="white"
      />
      <path
        d="M297.176 36.7341C296.331 36.7341 295.509 36.6496 294.71 36.4807C293.927 36.3117 293.205 36.089 292.545 35.8125C291.9 35.5207 291.362 35.1982 290.932 34.8449L292.199 32.495C292.645 32.8636 293.152 33.1785 293.72 33.4396C294.288 33.7007 294.887 33.9004 295.517 34.0386C296.162 34.1768 296.807 34.2459 297.452 34.2459C298.235 34.2459 298.919 34.1538 299.502 33.9695C300.086 33.7852 300.531 33.5241 300.839 33.1862C301.161 32.8483 301.322 32.4413 301.322 31.9651C301.322 31.5351 301.177 31.1895 300.885 30.9284C300.593 30.652 300.201 30.4293 299.71 30.2603C299.234 30.076 298.704 29.9071 298.12 29.7535C297.552 29.5845 296.976 29.4002 296.392 29.2005C295.809 28.9855 295.279 28.7244 294.803 28.4172C294.327 28.0947 293.935 27.6877 293.628 27.1962C293.336 26.7047 293.19 26.0904 293.19 25.3531C293.19 24.2934 293.482 23.3795 294.065 22.6116C294.664 21.8283 295.486 21.2216 296.531 20.7916C297.59 20.3615 298.819 20.1465 300.217 20.1465C301.261 20.1465 302.252 20.2617 303.189 20.4921C304.125 20.7224 304.932 21.0603 305.608 21.5057L304.433 23.8787C303.834 23.4794 303.158 23.1722 302.405 22.9572C301.653 22.7421 300.869 22.6346 300.055 22.6346C299.257 22.6346 298.566 22.7345 297.982 22.9341C297.398 23.1338 296.953 23.4102 296.646 23.7635C296.339 24.1168 296.177 24.5314 296.162 25.0076C296.162 25.453 296.308 25.8139 296.6 26.0904C296.891 26.3515 297.275 26.5742 297.752 26.7585C298.228 26.9428 298.758 27.1194 299.341 27.2884C299.925 27.442 300.501 27.6186 301.069 27.8182C301.653 28.0179 302.19 28.2713 302.682 28.5785C303.173 28.8857 303.565 29.2773 303.857 29.7535C304.149 30.2142 304.294 30.7979 304.294 31.5044C304.294 32.5641 303.995 33.4857 303.396 34.269C302.812 35.0523 301.983 35.659 300.908 36.089C299.848 36.5191 298.604 36.7341 297.176 36.7341Z"
        fill="white"
      />
      <path d="M200 27H0" stroke="url(#paint0_linear_0_1)" strokeWidth="2" />
      <path d="M320 27H520" stroke="url(#paint1_linear_0_1)" strokeWidth="2" />
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="160"
          y1="27.9998"
          x2="-7.99997"
          y2="27.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2BE2D8" />
          <stop offset="1" stopColor="#2BE2D8" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="360"
          y1="27.9998"
          x2="528"
          y2="27.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2BE2D8" />
          <stop offset="1" stopColor="#2BE2D8" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
