export function ZapIcon() {
  return (
    <svg
      width="34"
      height="36"
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_73_81)">
        <path
          d="M13.321 32.9158C6.55919 31.273 1.52895 25.2104 1.52895 18.0021C1.52895 12.8652 4.08612 8.31254 8.00023 5.52405L7.5339 3.99512C2.99292 7.02642 0 12.1709 0 17.9983C0 26.8835 6.95672 34.1829 15.7444 34.8203C14.9378 34.1867 14.1275 33.5493 13.321 32.912V32.9158Z"
          fill="black"
        />
        <path
          d="M18.1113 1.16504L20.4544 3.03162C27.3271 4.59469 32.4758 10.7066 32.4758 17.9984C32.4758 23.1012 29.953 27.6273 26.0848 30.4195L26.5244 31.9712C31.0348 28.9323 34.0048 23.803 34.0048 17.9984C34.0048 9.06387 26.9716 1.73412 18.1151 1.16504H18.1113Z"
          fill="black"
        />
        <path
          d="M10.0333 0.975371C9.9798 1.40787 10.0906 1.90866 10.2206 2.33358C11.2526 5.70253 12.2809 9.06769 13.3129 12.4366C13.5919 13.3472 13.871 14.3184 13.6263 15.2365C13.4429 15.9156 12.9956 16.4847 12.5637 17.0386C10.9583 19.0987 9.44084 21.2194 8.01127 23.4009C7.86602 23.621 7.71695 23.8638 7.72078 24.1255C7.72842 24.5163 8.06097 24.8122 8.36293 25.0588C12.6287 28.4961 16.925 31.8954 21.252 35.2568C21.8941 35.7575 22.7809 36.2735 23.4651 35.8296C24.1608 35.3782 24.0194 34.3386 23.7938 33.5457C22.9223 30.4689 22.0508 27.3959 21.1793 24.319C20.9118 23.3744 20.6404 22.4031 20.7398 21.4281C20.8888 19.9523 21.8406 18.6965 22.7618 17.528C23.6066 16.4543 24.4513 15.3807 25.2922 14.307C25.7127 13.7759 26.1446 13.2144 26.2631 12.5467C26.4924 11.2605 25.1508 10.6687 24.306 9.99339C23.0064 8.95767 21.703 7.91815 20.4034 6.88242C17.8424 4.84132 15.2814 2.80022 12.7204 0.755327C12.063 0.22798 11.115 -0.306956 10.4461 0.205216C10.1938 0.398704 10.0754 0.668068 10.0371 0.971577L10.0333 0.975371Z"
          fill="#2BE2D8"
        />
      </g>
      <defs>
        <clipPath id="clip0_73_81">
          <rect width="34" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
