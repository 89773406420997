import { useMemo } from "react";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { queryRequest } from "../../utils/request";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { AuthorTile } from "../../components/AuthorTile";
import { SocialIcons } from "../../components/SocialIcons";
import { HustleKeepExploring } from "./HustleKeepExploring";
import { Modal } from "../../components/Modal";
import Skeleton from "@mui/material/Skeleton";

const getPost = async ({ queryKey }) => {
  const [_, { slug }] = queryKey;
  return queryRequest({
    path: "news/posts",
    params: `?slug=${slug}`,
  });
};

const headerImageKeys = [
  "header_images_0_full",
  "header_images_1_full",
  "header_images_2_full",
];

export function HustleModal() {
  const { state } = useLocation();
  const { slug } = useParams();

  const { data: postData, isLoading } = useQuery(
    ["post", { slug: state?.slug ? state.slug : slug }],
    getPost
  );

  const post = useMemo(
    () => (isLoading ? {} : postData?.data.posts[0]),
    [isLoading, postData]
  );

  const headerImages = useMemo(() => {
    return isLoading
      ? []
      : post?.medias?.filter((image) => headerImageKeys.includes(image.key));
  }, [isLoading, post]);

  return (
    <Modal>
      <div>
        {isLoading ? (
          <div className="flex gap-x-5 items-center text-white mb-5">
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              className="opacity-70 rounded-full rounded-br-none shrink-0"
              animation="pulse"
              height={200}
              width={200}
              variant="rectangular"
            />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              className="opacity-70 w-full rounded-full rounded-tl-none"
              animation="pulse"
              height={200}
              variant="rectangular"
            />
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              className="rounded-xl opacity-70 shrink-0"
              animation="pulse"
              height={200}
              width={200}
              variant="circular"
            />
          </div>
        ) : (
          <div className="flex flex-wrap md:flex-nowrap gap-x-5 items-center text-white mb-5">
            {headerImages?.map((image, index) => (
              <div
                key={index}
                className={`md:order-none ${
                  index !== 1
                    ? "w-[120px] md:w-[200px] shrink-0"
                    : "w-full order-3 mt-5 md:mt-0"
                }`}
              >
                <img
                  src={image.media.cdn_source_url}
                  alt=""
                  className={`w-full object-cover h-[120px] md:h-[200px] rounded-full 
                ${index === 0 ? "rounded-br-none" : ""}
                ${index === 1 ? "rounded-tl-none" : ""}
                `}
                />
              </div>
            ))}
          </div>
        )}
        <div className="bg-black py-7 px-5 rounded-xl z-90">
          <div className="flex flex-col md:flex-row md:items-center">
            <span className="font-montserrat text-2xl leading-8 font-extrabold text-primary mr-5">
              The Hustle
            </span>
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="rounded-xl opacity-70 my-2 md:my-0"
                animation="pulse"
                height={36}
                width={150}
                variant="rectangular"
              />
            ) : (
              <h3 className="text-xl md:text-2xl font-semibold leading-8 text-white my-2 md:my-0">
                {sanitize(post?.title)}
              </h3>
            )}
            <div className="flex md:ml-auto items-center text-sm leading-4">
              <span className="mr-1 text-white">Posted By</span>
              {isLoading ? (
                <div className="flex items-center">
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    className="rounded-xl opacity-70 mx-3"
                    animation="pulse"
                    height={16}
                    width={105}
                    variant="rectangular"
                  />
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    className="rounded-xl opacity-70"
                    animation="pulse"
                    height={38}
                    width={38}
                    variant="circular"
                  />
                </div>
              ) : (
                <AuthorTile
                  name={post?.author?.name}
                  addClassName="flex-row-reverse gap-x-3 text-primary"
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-5 mt-3 items-start">
            <div className="col-span-12 md:col-span-3">
              {post?.jsonData?.introduction && !isLoading ? (
                <div className="bg-white py-3 px-5">
                  <h3 className="uppercase text-primary font-semibold mb-2">
                    Introduction
                  </h3>
                  <p
                    className="text-black font-['PT+Serif'] text-base leading-6"
                    dangerouslySetInnerHTML={sanitizeForReact(
                      post?.jsonData?.introduction
                    )}
                  />
                </div>
              ) : (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  className="opacity-70 flex"
                  animation="pulse"
                  height={246}
                  variant="rectangular"
                />
              )}
              {isLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  className="rounded-l-xl mt-5 mb-5 opacity-70"
                  animation="pulse"
                  height={64}
                  variant="rectangular"
                />
              ) : (
                <>
                  {post?.jsonData?.links && (
                    <div className="flex items-center mb-5 md:mb-0 bg-gradient-to-r from-primary from-0% via-primary via-80% to-transparent to-100% p-6 rounded-l-xl mt-5 space-x-3">
                      <SocialIcons data={post?.jsonData?.links} />
                    </div>
                  )}
                </>
              )}
            </div>
            {post?.jsonData?.questions && !isLoading ? (
              <div className="bg-white col-span-12 md:col-span-9 py-3 px-5 md:max-h-[580px] overflow-y-scroll w-full">
                <div>
                  {post?.jsonData?.questions.map((question, index) => (
                    <div key={index}>
                      <p className="uppercase text-primary font-semibold mb-2">
                        {question.question}
                      </p>
                      <p
                        className="mb-2 font-['PT+Serif'] text-base leading-6"
                        dangerouslySetInnerHTML={sanitizeForReact(
                          question.answer
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="opacity-70 flex col-span-12 md:col-span-9"
                animation="pulse"
                height={588}
                variant="rectangular"
              />
            )}
          </div>
        </div>
        <div className="bg-black py-7 px-5 rounded-xl mt-5 z-90 relative mb-16 md:mb-0">
          <HustleKeepExploring currentId={post?.id} />
        </div>
      </div>
    </Modal>
  );
}
