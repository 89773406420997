import React, { useEffect, useState } from "react";
import { EyeOpen, LoadingIcon } from "../Icons";
import Button from "../Inputs/Button";
import Checkbox from "../Inputs/Checkbox";
import { remove } from "../../utils/arrays";
import { Tenants } from "../../constants";
import PostTypeService from "../../services/PostTypeService";
import Spinner from "../Spinner";

export function Publish({
  label,
  post = null,
  setValue,
  getValues,
  loading = "",
  onClone = null,
  disableTenants = false,
  disableStatus = false,
}) {
  const [isLoading, setIsLoading] = useState("");
  const [isCloneLoading, setCloneLoading] = useState(false);
  const [publishNow, setPublishNow] = useState(false);
  const [showEditPanel, setEditPanel] = useState(false);
  const [publishTenants, setPublishTenants] = useState(
    post?.tenants?.length ? post?.tenants?.map((item) => item.name) : []
  );
  const [status, setStatus] = useState(post ? post.status : "draft");

  const effectivePDGV = getValues("effectivePublishDate");
  const dateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = (getValues("effectivePublishDate") as Date) ?? new Date();
    const newDate = new Date(event.target.value + "Z"); // add 'Z' to the end of the string to indicate UTC
    newDate.setUTCHours(date.getUTCHours(), date.getUTCMinutes());
    setPublishNow(new Date() > newDate);
    setValue("effectivePublishDate", newDate);
  };
  const timeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = (getValues("effectivePublishDate") as Date) ?? new Date();
    const userOffset = date.getTimezoneOffset() / 60; // in hours
    date.setUTCHours(
      event.target.valueAsDate.getUTCHours() + userOffset,
      event.target.valueAsDate.getUTCMinutes()
    );
    setPublishNow(new Date() > date);

    setValue("effectivePublishDate", date);
  };

  const handleClone = async () => {
    setCloneLoading(true);
    try {
      const cloneResponse = await PostTypeService.clone(post?.id);
      if (onClone) {
        onClone(cloneResponse);
      }
    } catch (e) {
      console.warn("Error cloning post");
      console.warn(e.message);
    }
    setCloneLoading(false);
  };

  const effectivePublishDate = getValues("effectivePublishDate")
    ? `${getValues("effectivePublishDate").toLocaleDateString()} at ${getValues(
        "effectivePublishDate"
      ).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`
    : "00/00/0000 at 00:00";

  useEffect(() => {
    if (publishNow) {
      setValue("effectivePublishDate", new Date());
    }
  }, [publishNow]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (post) {
      setValue("status", post.status);
      setStatus(post.status);
    }
  }, [post]);

  return (
    <>
      <div className="relative">
        <div className="my-2 flex justify-between items-center flex-col 2xl:flex-row">
          {!disableStatus && (
            <>
              <div className="flex items-center px-2 mb-4 2xl:mb-0">
                <Button
                  type="submit"
                  label="Save Draft"
                  theme="secondary"
                  size="default"
                  onClick={() => {
                    if (setValue) {
                      setValue("status", "draft");
                      setStatus("draft");
                    }
                  }}
                >
                  {isLoading === "draft" && <LoadingIcon />}
                </Button>
                {post ? (
                  <Button
                    type="button"
                    label="Preview"
                    theme="secondary"
                    size="default"
                    className="ml-2"
                    onClick={() => {
                      window.open(`/news/${post.slug}`);
                    }}
                  >
                    {EyeOpen()}
                  </Button>
                ) : (
                  ""
                )}
              </div>

              <div className="flex space-x-1 items-center mb-4 2xl:mb-0">
                <span className="2xl:block 2xl:w-full">Status:</span>
                <Button
                  type="button"
                  label="Draft"
                  theme={status === "draft" ? "primary" : "secondary"}
                  size="smallRounded"
                  className="ml-2 text-sm"
                  onClick={(e) => {
                    setValue("status", "draft");
                    setStatus("draft");
                  }}
                />
                <Button
                  type="button"
                  label="Publish"
                  theme={status === "published" ? "primary" : "secondary"}
                  size="smallRounded"
                  className="ml-2 text-sm"
                  onClick={(e) => {
                    setValue("status", "published");
                    setStatus("published");
                  }}
                />
              </div>

              <span className="hidden 2xl:inline-block">|</span>
            </>
          )}

          <div className="flex space-x-2 items-center mb-4 2xl:mb-0">
            <span>Publish:</span>
            <Button
              type="button"
              theme="primary"
              size="smallRounded"
              className="text-sm"
              onClick={(e) => {
                e.preventDefault();
              }}
              label={effectivePublishDate}
            />
            <Button
              type="button"
              theme="link"
              className="text-sm"
              onClick={() => setEditPanel(true)}
              label="Edit"
            />
          </div>

          {post && (
            <>
              <span className="hidden 2xl:inline-block">|</span>
              <div className="flex space-x-4 items-center mb-4 2xl:mb-0">
                {isCloneLoading ? (
                  <Spinner width="10" height="10" className="!ml-2 !mr-2" />
                ) : (
                  ""
                )}
                <Button
                  type="button"
                  theme="link"
                  className={`text-sm ${isCloneLoading ? "!ml-0 !pl-0" : ""}`}
                  onClick={handleClone}
                  label="Copy to a new draft"
                />
              </div>
            </>
          )}

          {!disableTenants && (
            <>
              <span className="hidden 2xl:inline-block">|</span>

              <div className="flex space-x-4 items-center mb-4 2xl:mb-0">
                <div className="inline-block text-sm">Publish to:</div>
                {Tenants.map((item, index) => (
                  <div className="inline-block" key={`tenant_${item.id}`}>
                    <Checkbox
                      name="tenants"
                      className="inline-block mr-1 text-sm"
                      checked={
                        publishTenants ? publishTenants.includes(item.id) : []
                      }
                      onChange={(prop) => {
                        if (prop) {
                          if (!publishTenants.includes(item.id)) {
                            publishTenants.push(item.id);
                            setPublishTenants(publishTenants);
                            setValue("tenants", publishTenants);
                          }
                        } else {
                          if (publishTenants.includes(item.id)) {
                            let newPublishTenants = remove(
                              publishTenants,
                              item.id
                            );
                            setPublishTenants(newPublishTenants);
                            setValue("tenants", newPublishTenants);
                          }
                        }
                      }}
                    />
                    <span className="text-sm inline-block text-sm">
                      {item.name}
                    </span>
                  </div>
                ))}
              </div>

              <span className="hidden 2xl:inline-block">|</span>
            </>
          )}

          <Button
            theme="primary"
            size="default"
            label={post ? "Update" : "Publish"}
            className="mr-3"
            type="submit"
            onClick={(e) => {
              if (setValue) {
                setValue("status", "published");
              }
            }}
          >
            {isLoading === "published" && <LoadingIcon />}
          </Button>
        </div>

        {showEditPanel && (
          <div
            className={`mx-auto bg-white flex justify-between space-x-2 items-center w-fit absolute left-0 top-50 right-0 p-2 border border-[#939393] shadow-sm ${
              post ? "w-full" : "max-w-[70%]"
            }`}
          >
            <div className="flex justify-between items-center">
              <h2 className="font-bold">Edit Publish Date</h2>
            </div>

            {!post ? (
              <>
                <div className="flex items-center">
                  <Checkbox
                    name="publish_immediately"
                    checked={publishNow}
                    onChange={(value) => {
                      setPublishNow(value);
                    }}
                  ></Checkbox>
                  <span className="pl-1">Publish immediately</span>
                </div>
                <div className="flex items-center">
                  <span>or</span>
                </div>
              </>
            ) : (
              ""
            )}

            <div className="flex items-center">
              <input
                type="date"
                className="w-fit"
                value={effectivePDGV?.toISOString().substr(0, 10)}
                onChange={dateChange}
              />
              <span className="mx-2">at</span>
              <input type="time" className="w-fit" onChange={timeChange} />
            </div>
            <div className="flex justify-end">
              <button
                aria-label="cancel"
                className="text-blue px-3 py-1 ml-2"
                onClick={() => setEditPanel(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
