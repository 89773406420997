import { useState } from "react";
import ProgressBar from "../Inputs/ProgressBar";
import TextArea from "../Inputs/TextArea";
import { SocialMediaImage } from "../Inputs/SocialMediaImage";
import { SubSection } from "../Layout/SubSection";

export function SEO({ name, register, setValue, errors, value = null }) {
  const [values, setValues] = useState({
    seoTitle: value && value.seoTitle ? value.seoTitle : "",
    seoDescription: value && value.seoDescription ? value.seoDescription : "",
    facebook_image_full:
      value && value.facebook_image_full ? value.facebook_image_full : null,
    twitter_image_full:
      value && value.twitter_image_full ? value.twitter_image_full : null,
    linkedin_image_full:
      value && value.linkedin_image_full ? value.linkedin_image_full : null,
    instagram_image_full:
      value && value.instagram_image_full ? value.instagram_image_full : null,
  });

  const [titleLen, setTitleLen] = useState(
    values.seoTitle ? values.seoTitle.length : 0
  );
  const [descLen, setDescLen] = useState(
    values.seoDescription ? values.seoDescription.length : 0
  );

  const setValuesField = (key, value) => {
    values[key] = value;
    if (setValue) {
      setValue(name, values);
    }
    setValues(values);
    if ("seoTitle" === key) {
      setTitleLen(value ? value.length : 0);
    }
    if ("seoDescription" === key) {
      setDescLen(value ? value.length : 0);
    }
  };

  return (
    <>
      <div className="border-b p-4">
        <div className="flex w-full flex-wrap items-stretch text-left">
          <input
            {...(register ? register("seoTitle", { required: false }) : {})}
            name="seoTitle"
            className="w-full bg-white form-control rounded border border-[#969696] focus:outline-none focus:border-2 focus:border-blue py-1 px-3"
            placeholder="Add Title"
            onChange={(e) => {
              setValuesField("seoTitle", e.target.value);
            }}
          />
          <ProgressBar current={titleLen} total={50} />
        </div>
      </div>
      <div className="border-b p-4">
        <TextArea
          label="Description"
          name="seoDescription"
          setValue={setValuesField}
          errors={errors?.seoDescription.value}
          register={register}
          required={false}
        />
        <ProgressBar current={descLen} total={155} />
      </div>

      <SubSection label="Social Media Images">
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:space-x-8">
          <SocialMediaImage
            title={"Facebook:"}
            label="Upload"
            value={
              values.facebook_image_full ? values.facebook_image_full : null
            }
            name="facebook_image_full"
            setValue={setValuesField}
            errors={errors?.facebook_image_full.value}
          />
          <SocialMediaImage
            title={"LinkedIn:"}
            label="Upload"
            name="linkedin_image_full"
            value={
              values.linkedin_image_full ? values.linkedin_image_full : null
            }
            setValue={setValuesField}
            errors={errors?.linkedin_image_full.value}
          />
        </div>
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:space-x-8">
          <SocialMediaImage
            title={"Twitter:"}
            label="Upload"
            name={"twitter_image_full"}
            value={values.twitter_image_full ? values.twitter_image_full : null}
            setValue={setValuesField}
            errors={errors?.twitter_image_full.value}
          />
          <SocialMediaImage
            title={"Instagram:"}
            label="Upload"
            name={"instagram_image_full"}
            value={
              values.instagram_image_full ? values.instagram_image_full : null
            }
            setValue={setValuesField}
            errors={errors?.instagram_image_full.value}
          />
        </div>
      </SubSection>
    </>
  );
}
