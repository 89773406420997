export function SidebarSubmenu({ label, items, currentRoute, onClick }) {
  const menuItemClass = (item) => {
    return currentRoute === item.path
      ? "p-1 rounded-sm border-l-2 bg-[#F0F0F0] border-l-[#2BE2D8]"
      : "p-1";
  };
  return (
    <>
      <div className="absolute bg-white lg:-right-60 top-[36px] lg:top-0 border-2 border-[#969696] rounded-tr-xl  rounded-br-xl p-4 z-50">
        {label && (
          <div className="w-full mb-4">
            <h2 className="uppercase font-['Source Code Pro'] text-[#636363] text-sm">
              {label}
            </h2>
          </div>
        )}
        <div className="w-full">
          {items.map((item, i) => (
            <div className={menuItemClass(item)} key={"submenu/" + item.path}>
              <div
                className="cursor-pointer"
                role="presentation"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(item);
                }}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
