import { useState } from "react";

const useObject = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (newValue) => {
      setValues({
        ...values,
        ...newValue,
      });
    },
  ];
};

export default useObject;
