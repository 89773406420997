import Title from "../../components/Title";
import { Section } from "../../components/Layout/Section";
import Spinner from "../../components/Spinner";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DailyEditionService from "../../services/DailyEditionService";
import Button from "../../components/Inputs/Button";
import {
  ButtonArrowLeft,
  ButtonCopy,
  ButtonEdit,
} from "../../components/Icons";
import { copyTextToClipboard } from "../../utils/io";

export function View() {
  const { id } = useParams();
  const [currentPost, setCurrentPost] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  const retrievePost = (postId) => {
    return DailyEditionService.get(postId)
      .then((response) => {
        if (response.data) {
          setCurrentPost(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!currentPost) {
    retrievePost(id);
  }

  const resizeIframe = (e) => {
    const obj = e.target;
    obj.style.height =
      obj.contentWindow.document.documentElement.scrollHeight + "px";
  };

  const redirectToSingle = () => {
    window.location.href =
      "/news/journalist/daily-edition/edit/" + currentPost.id;
  };

  return (
    <>
      <Title text="Daily Edition Generator" className="mb-3" />
      {isLoading ? (
        <div className="w-full text-center mt-4 mb-4">
          <Spinner />
        </div>
      ) : (
        <div>
          <Section label="HTML Markup">
            <p>
              <Button
                onClick={() => {
                  window.location.href =
                    "/news/journalist/daily-edition/edit/" + currentPost.id;
                }}
              >
                <ButtonArrowLeft />
                <span className="ml-2">Back to editor</span>
              </Button>
            </p>
            <div className=" p-4">
              <div className="w-full">
                <pre className="bg-[#f0f0f0] max-w-[800px] border-2 border-[#969696] overflow-y-scroll ml-auto mr-auto overflow-x-scroll max-h-[650px]">
                  {currentPost.htmlContent}
                </pre>
              </div>
              <div className="w-full">
                <div className="max-w-[800px] ml-auto mr-auto mt-4">
                  <Button
                    className="mr-2"
                    onClick={() => {
                      copyTextToClipboard(currentPost.htmlContent);
                      alert("HTML Markup copied to clipboard.");
                    }}
                  >
                    <ButtonCopy /> <span className="ml-2">Copy</span>
                  </Button>
                  <Button onClick={redirectToSingle}>
                    <ButtonEdit /> <span className="ml-2">Edit</span>
                  </Button>
                </div>
              </div>
            </div>
          </Section>
          <Section label="Preview" className="mb-4">
            <p>
              <Button onClick={redirectToSingle}>
                <ButtonArrowLeft />
                <span className="ml-2">Back to editor</span>
              </Button>
            </p>
            <div className="max-w-[760px] h-full ml-auto mr-auto !shadow-xl pr-2 pl-2 pt-4 pb-4">
              <iframe
                title="DailyEdition Preview"
                width="100%"
                className="h-[100vh]"
                srcDoc={
                  '<?xml version="1.0" encoding="utf-8"?> ' +
                  currentPost.htmlContent
                }
                onLoad={resizeIframe}
              ></iframe>
            </div>
          </Section>
        </div>
      )}
    </>
  );
}
