import Skeleton from "@mui/material/Skeleton";

export function ShuffleLoading() {
  return (
    <div className="grid grid-cols-12 gap-x-5">
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="bg-red-50 col-span-12 md:col-span-4 rounded-t-2xl md:rounded-l-2xl md:rounded-tr-none mb-4 md:mb-0"
        animation="pulse"
        height={224}
        variant="rectangular"
      />
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="bg-red-50 col-span-12 md:col-span-8 rounded-b-2xl md:rounded-r-2xl md:rounded-bl-none"
        animation="pulse"
        height={224}
        variant="rectangular"
      />
    </div>
  );
}
