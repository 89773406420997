type NewsletterCloseIconProps = {
  addClassName?: string;
};

export function NewsletterSubmitIcon({
  addClassName = "",
}: NewsletterCloseIconProps) {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={addClassName}
    >
      <path
        opacity="0.05"
        d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z"
        fill="#2BE2D8"
      />
      <path
        d="M28.0876 62C28.0876 43.2697 43.2697 28.0876 62 28.0876V0C27.7579 0 0 27.7579 0 62C0 96.2421 27.7579 124 62 124V95.9124C43.2697 95.9124 28.0876 80.7303 28.0876 62Z"
        fill="#2BE2D8"
      />
      <path
        d="M62 28.0859V95.9107C80.7303 95.9107 95.9124 80.7286 95.9124 61.9983C95.9124 43.268 80.7303 28.0859 62 28.0859Z"
        fill="#2BE2D8"
      />
      <circle cx="62" cy="62" r="17" fill="black" />
      <path
        d="M68.7071 62.7071C69.0976 62.3165 69.0976 61.6834 68.7071 61.2928L62.3431 54.9289C61.9526 54.5384 61.3194 54.5384 60.9289 54.9289C60.5384 55.3194 60.5384 55.9526 60.9289 56.3431L66.5858 62L60.929 67.6568C60.5384 68.0474 60.5384 68.6805 60.929 69.071C61.3195 69.4616 61.9526 69.4616 62.3432 69.071L68.7071 62.7071ZM68 60.9999L54.5 61L54.5 63L68 62.9999L68 60.9999Z"
        fill="white"
      />
    </svg>
  );
}
