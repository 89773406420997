type FacebookIconProps = {
  addClassName?: string;
  addSvgClassName?: string;
};
export function FacebookIcon({
  addClassName = "text-black",
  addSvgClassName,
}: FacebookIconProps) {
  return (
    <span className={addClassName}>
      <svg
        className={addSvgClassName}
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1962_3502)">
          <path
            d="M24.1452 12.0726C24.1452 5.40393 18.7413 0 12.0726 0C5.40393 0 0 5.40393 0 12.0726C0 18.0998 4.41755 23.0923 10.1846 24V15.5643H7.11649V12.0726H10.1846V9.40998C10.1846 6.38427 11.9879 4.71407 14.7474 4.71407C16.0666 4.71407 17.4523 4.95008 17.4523 4.95008V7.92133H15.9274C14.4266 7.92133 13.9607 8.85325 13.9607 9.80938V12.0726H17.3071L16.7746 15.5643H13.9607V24C19.7337 23.0923 24.1452 18.0998 24.1452 12.0726Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1962_3502">
            <rect width="24.1452" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
