import { MenuHeader } from "../../../components/MenuHeader";
import { MenuLink } from "../../../components/MenuLink";

const MENU_LINKS = [
  {
    id: 1,
    title: "CONTRIBUTE CONTENT",
    to: "",
    active: false,
  },
  {
    id: 2,
    title: "EVENTS",
    to: "",
    active: false,
  },
  {
    id: 3,
    title: "THE WIRE",
    to: "",
    active: false,
  },
  {
    id: 4,
    title: "ST. PETE X",
    to: "https://stpetersburggroup.com/stpetex",
    active: true,
  },
];

export function LinksSection() {
  return (
    <>
      <MenuHeader title="LINKS" addClassName="pb-2 mb-4 mt-11 font-semibold" />
      <div className="space-y-2">
        {MENU_LINKS.filter((link) => link.active).map((link) => {
          return (
            <MenuLink
              key={`menu_link_${link.id}`}
              title={link.title}
              to={link.to}
              size="md:text-sm md:leading-6"
              addClassName="font-normal"
            />
          );
        })}
      </div>
    </>
  );
}
