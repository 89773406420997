export function FluentsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_320_2300)">
        <path
          d="M15.9922 0H11.9974H8.00259V3.99483V8.00259H3.99483H0V11.9974V15.9922V20H3.99483V15.9922V11.9974H8.00259H11.9974V8.00259V3.99483H15.9922H20V0H15.9922Z"
          fill="#2BE2D8"
        />
        <path d="M3.99483 0H0V3.99483H3.99483V0Z" fill="#2BE2D8" />
      </g>
      <defs>
        <clipPath id="clip0_320_2300">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
