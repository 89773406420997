import { SlideButton } from "../../components/SlideButton";
import { SectionHeader } from "../../components/SectionHeader";
import { useSlideContent } from "../../utils/useSlideContent";
import { Advertisement } from "../../components/Advertisement";
import { InfluencerTile } from "./InfluencerTile";
import { InfluencerLoading } from "./InfluencerLoading";
import influencerBackground from "./influencer-background.svg";
import type { InfluencerType } from "../../types";

type Influencer = {
  post: InfluencerType;
};

export function Influencer() {
  const influencerCount = 1;
  const { posts, isLoading, slideForward, slideBack, slideBackDisabled } =
    useSlideContent<Influencer[]>({
      requestUrl: "posts?postType=influencer",
      count: influencerCount,
    });

  return (
    <div className="grid grid-cols-1 gap-x-5 md:gap-y-5 xl:gap-y-0 xl:grid-cols-2 md:mt-10">
      <div
        className="md:rounded-2xl p-5 bg-black bg-cover bg-no-repeat min-h-[550px] md:min-h-[725px] xl:min-h-[384px] overflow-hidden flex flex-col relative"
        style={{
          backgroundImage: `url(${influencerBackground})`,
        }}
      >
        <div className="text-white">
          <SectionHeader title="Influencers" gradient={false} border={false} />
        </div>
        <div>
          {(isLoading ? Array.from(new Array(influencerCount)) : posts).map(
            (post, i) =>
              post ? (
                <InfluencerTile
                  key={post.id}
                  post={post}
                  modalSection={false}
                />
              ) : (
                <InfluencerLoading key={i} modalSection={false} />
              )
          )}
        </div>

        <div className="flex justify-between my-5 z-20 mt-auto">
          <SlideButton
            direction="back"
            onClick={slideBack}
            disabled={slideBackDisabled}
          />
          <SlideButton direction="forward" onClick={slideForward} />
        </div>
      </div>
      <div className="pt-10 md:py-3 border-t-4 md:border border-solid border-[#F0F0F0] md:border-[#CACACA] md:rounded-2xl grid md:grid-cols-2 items-center justify-center">
        <Advertisement
          path="/21735245768/SPC_HP_MREC_3L"
          size={[300, 250]}
          id="div-gpt-ad-1682306808816-3"
        />
        <div className="hidden md:block">
          <Advertisement
            path="/21735245768/SPC_News_MREC_B"
            size={[300, 250]}
            id="div-gpt-ad-1682306808816-4"
          />
        </div>
      </div>
    </div>
  );
}
