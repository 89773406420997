import { getPostEditUrl, getPostIndexUrl } from "./urls";

export const handleRedirect = (redirect) => {
  let path = null;
  if (redirect) {
    if (redirect.index) {
      path = getPostIndexUrl(redirect.index);
    } else if (redirect.post) {
      path = getPostEditUrl(redirect.post);
    }
    if (path) {
      if (redirect.params) {
        for (const i in redirect.params) {
          localStorage.setItem(i, redirect.params[i]);
        }
      }
      window.location.href = "/news" + path;
    }
  }
};
