import { useState, useEffect } from "react";
import { queryRequest } from "../../utils/request";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { SocialIcons } from "../../components/SocialIcons";
import { ShuffleKeepExploring } from "./ShuffleKeepExploring";
import { Modal } from "../../components/Modal";
import Skeleton from "@mui/material/Skeleton";
import { useDetectMobile } from "../../utils/isMobile";

const getPost = async ({ queryKey }) => {
  const [_, { slug }] = queryKey;
  return queryRequest({
    path: "news/posts",
    params: `?slug=${slug}`,
  });
};

export function ShuffleModal() {
  const { isMobile } = useDetectMobile();
  const { state } = useLocation();
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  const { data: postData, isLoading } = useQuery(
    ["post", { slug: state?.slug ?? slug }],
    getPost
  );

  useEffect(() => {
    state?.postPreload
      ? setPost(state?.postPreload)
      : setPost(postData?.data.posts[0]);
  }, [postData, state]);

  return (
    <Modal>
      <div className="rounded-xl z-90">
        <div className="grid grid-cols-12 gap-x-5 mt-3 items-start">
          <div className="col-span-12 md:col-span-3">
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="rounded-xl md:rounded-l-xl opacity-70"
                animation="pulse"
                height={288}
                width={"100%"}
                variant="rectangular"
              />
            ) : (
              <>
                {post?.featuredImage && (
                  <img
                    src={post.featuredImage}
                    alt={post.title}
                    className="rounded-xl md:rounded-l-xl max-h-[288px] w-full object-cover"
                  />
                )}
              </>
            )}
            <div className="font-montserrat italic font-semibold text-white text-2xl flex items-center mb-5 md:mb-0 bg-gradient-to-r from-primary from-0% via-primary via-80% to-transparent to-100% p-4 rounded-l-xl mt-5 uppercase">
              The Shuffle
            </div>
          </div>
          <div className="col-span-12 md:col-span-9 bg-black rounded-lg py-6 px-5 text-white h-full">
            <div className="grid grid-cols-12 gap-x-4 md:gap-x-16 border-b border-[#CACACA] pb-5 mb-5">
              <div className="col-span-12 md:col-span-5 mb-4 md:mb-0">
                {isLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    className="rounded-xl opacity-70"
                    animation="pulse"
                    height={32}
                    width={160}
                    variant="rectangular"
                  />
                ) : (
                  <h2 className="text-2xl font-semibold">{post?.title}</h2>
                )}
              </div>
              <div className="col-span-12 md:col-span-7 flex items-center">
                <SocialIcons data={post?.jsonData.links} theme="white" />
              </div>
            </div>
            <div className="grid grid-cols-12 md:gap-x-16">
              {isLoading ? (
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  className="rounded-xl opacity-70 col-span-12"
                  animation="pulse"
                  height={isMobile ? 288 : 208}
                  variant="rectangular"
                />
              ) : (
                <>
                  <div className="col-span-12 md:col-span-5">
                    {post?.jsonData.newOrganization && (
                      <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-1">
                          NEW COMPANY
                        </h3>
                        <p className="font-['PT+Serif'] text-base font-normal">
                          {post?.jsonData.newOrganization.name}
                        </p>
                      </div>
                    )}
                    {post?.jsonData.newOrganization && (
                      <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-1">
                          NEW POSITION
                        </h3>
                        <p className="font-['PT+Serif'] text-base font-normal">
                          {post?.jsonData.newOrganization.role}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-span-12 md:col-span-7">
                    {post?.jsonData.oldOrganization && (
                      <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-1">
                          FORMER COMPANY
                        </h3>
                        <p className="font-['PT+Serif'] text-base font-normal">
                          {post?.jsonData.oldOrganization.name}
                        </p>
                      </div>
                    )}
                    {post?.jsonData.oldOrganization && (
                      <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-1">
                          FORMER POSITION
                        </h3>
                        <p className="font-['PT+Serif'] text-base font-normal">
                          {post?.jsonData.oldOrganization.role}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {!isLoading && (
              <div>
                {post?.note && (
                  <p className="font-['PT+Serif'] text-base font-normal">
                    {post?.note}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bg-black px-5 rounded-xl mt-5 z-90 relative pb-16 md:pb-0">
        <ShuffleKeepExploring currentId={post?.id} />
      </div>
    </Modal>
  );
}
