import Skeleton from "@mui/material/Skeleton";

type InflucerLoadingProps = {
  modalSection?: Boolean;
};

export function InfluencerLoading({
  modalSection = false,
}: InflucerLoadingProps) {
  return (
    <div className={`${modalSection ? "" : "grid grid-cols-12"}`}>
      <div className="col-span-12 lg:col-span-4">
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          className="rounded-2xl bg-red-50 mb-4"
          animation="pulse"
          variant="rectangular"
          height={modalSection ? 240 : 149}
        />
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          className="rounded-2xl bg-red-50"
          animation="pulse"
          variant="rectangular"
          height={24}
        />
      </div>
    </div>
  );
}
