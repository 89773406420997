import { useState, useEffect } from "react";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { queryRequest } from "../../utils/request";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { AudioPlayer } from "../../components/AudioPlayer";
import { InfluencerKeepExploring } from "./InfluencerKeepExploring";
import { AuthorTile } from "../../components/AuthorTile";
import { SocialIcons } from "../../components/SocialIcons";
import { Modal } from "../../components/Modal";
import influencerBackgroundSm from "./influencer-background-sm.svg";
import Skeleton from "@mui/material/Skeleton";

const getPost = async ({ queryKey }) => {
  const [_, { slug }] = queryKey;
  return queryRequest({
    path: "news/posts",
    params: `?slug=${slug}`,
  });
};

export function InfluencerModal() {
  const { state } = useLocation();
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [mediaType, setMediaType] = useState(null);

  const { data: postData, isLoading } = useQuery(
    ["post", { slug: state?.slug ?? slug }],
    getPost
  );

  useEffect(() => {
    state?.postPreload
      ? setPost(state?.postPreload)
      : setPost(postData?.data.posts[0]);

    post?.jsonData.media?.filter((media) => {
      if (media.key === "audio") {
        setMediaType({ type: "audio", href: media.href });
      }
    });
  }, [postData, state, post?.jsonData?.media]);

  return (
    <Modal>
      <div className="bg-black py-7 px-5 rounded-xl z-90">
        <div className="flex flex-col md:flex-row md:items-center">
          <span className="font-montserrat italic font-semibold text-2xl leading-8 text-white uppercase mr-5">
            Influencer
          </span>
          {isLoading ? (
            <div className="flex flex-col md:flex-row md:items-center">
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="rounded-xl opacity-70 my-2 md:my-0 md:mr-5"
                animation="pulse"
                height={36}
                width={150}
                variant="rectangular"
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="rounded-xl opacity-70 my-2 md:my-0"
                animation="pulse"
                height={36}
                width={240}
                variant="rectangular"
              />
            </div>
          ) : (
            <div className="flex flex-col md:flex-row md:items-center">
              <h3 className="text-xl md:text-2xl font-semibold leading-8 text-primary my-2 md:my-0 md:mr-5 shrink-0">
                {sanitize(post?.title)}
              </h3>
              {post?.jsonData.influencerJobTitle &&
                post?.jsonData.influencerCompany && (
                  <div className="text-xl md:text-2xl font-normal leading-8 text-white my-2 md:my-0 line-clamp-1">
                    {post.jsonData.influencerJobTitle} -{" "}
                    {post.jsonData.influencerCompany}
                  </div>
                )}
            </div>
          )}
          <div className="flex md:ml-auto items-center text-sm leading-4 shrink-0">
            <span className="mr-1 text-white">Posted By</span>
            {isLoading ? (
              <div className="flex items-center">
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  className="rounded-xl opacity-70 mx-3"
                  animation="pulse"
                  height={16}
                  width={105}
                  variant="rectangular"
                />
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  className="rounded-xl opacity-70"
                  animation="pulse"
                  height={38}
                  width={38}
                  variant="circular"
                />
              </div>
            ) : (
              <AuthorTile
                name={post?.author.name}
                addClassName="flex-row-reverse gap-x-3 text-primary"
              />
            )}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-x-5 mt-3 items-start">
          <div className="col-span-12 md:col-span-3">
            {post?.featuredImage && !isLoading ? (
              <div
                className="bg-cover flex min-h-[246px] rounded-tl-xl"
                style={{
                  backgroundImage: `url(${influencerBackgroundSm})`,
                }}
              >
                <img
                  alt={sanitize(post.title)}
                  src={post.featuredImage}
                  title={sanitize(post.title)}
                  className="mt-auto pt-1"
                />
              </div>
            ) : (
              <>
                {post?.featuredImage && (
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    className="opacity-70 flex rounded-tl-xl"
                    animation="pulse"
                    height={246}
                    variant="rectangular"
                  />
                )}
              </>
            )}
            {isLoading ? (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="rounded-l-xl mt-5 mb-5 opacity-70"
                animation="pulse"
                height={64}
                variant="rectangular"
              />
            ) : (
              <div className="mb-5 md:mb-0 bg-gradient-to-r from-primary from-0% via-primary via-80% to-transparent to-100% p-6 rounded-l-xl mt-5">
                <SocialIcons data={post?.jsonData?.links} />
              </div>
            )}
          </div>
          <div className="col-span-12 md:col-span-9 w-full">
            {post?.jsonData.influencerIntro && !isLoading ? (
              <div className="bg-white py-3 px-5">
                <p className="uppercase text-primary font-semibold mb-2">
                  Introduction
                </p>
                <p
                  className="mb-2 font-['PT+Serif'] text-base leading-6"
                  dangerouslySetInnerHTML={sanitizeForReact(
                    post?.jsonData.influencerIntro
                  )}
                />
              </div>
            ) : (
              <>
                {post?.jsonData.influencerIntro && (
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    className="opacity-70 flex col-span-12 md:col-span-9"
                    animation="pulse"
                    height={588}
                    variant="rectangular"
                  />
                )}
              </>
            )}
            {mediaType && mediaType?.type === "audio" && (
              <div className="mt-8">
                <AudioPlayer src={mediaType?.href} />
              </div>
            )}
            {post?.jsonData.interviewQuestions && !isLoading ? (
              <div className="bg-white py-3 px-5">
                {post.jsonData.interviewQuestions.map((questions, index) => (
                  <div key={index}>
                    <p className="uppercase text-primary font-semibold mb-2">
                      {questions.question}
                    </p>
                    <p
                      className="mb-2 font-['PT+Serif'] text-base leading-6"
                      dangerouslySetInnerHTML={sanitizeForReact(
                        questions.answer
                      )}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="opacity-70 flex col-span-12 md:col-span-9"
                animation="pulse"
                height={450}
                variant="rectangular"
              />
            )}
          </div>
        </div>
      </div>
      <div className="bg-black px-5 rounded-xl mt-5 z-90 relative pb-16 md:pb-0">
        <InfluencerKeepExploring currentId={post?.id} />
      </div>
    </Modal>
  );
}
