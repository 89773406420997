import { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { CloseIcon } from "../CloseIcon";
import ReactModal from "react-modal";

type AllModalProps = {
  className?: string;
  appearance?: "default" | "zap" | "post";
  shouldCloseOnOverlayClick?: Boolean;
  shouldFocusAfterRender?: Boolean;
  style?: Object;
};

interface ModalProps extends AllModalProps {
  children: ReactNode;
  bgOpacity?: string;
  hideClose?: Boolean;
}

export function Modal({
  bgOpacity = ".85",
  hideClose,
  children,
  appearance = "default",
  ...props
}: ModalProps) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef(null);

  const appearanceClass = {
    default:
      "max-w-[90%] md:max-w-[1210px] md:px-[80px] md:min-h-[680px] top-16 md:top-16 pb-16 relative",
    zap: "max-w-[90%] md:max-w-[552px] md:min-h-[540px] justify-around rounded-xl fixed top-0 bg-black text-white",
    post: "container md:min-h-[680px] top-7 md:top-16 pb-16 md:mt-[150px] relative",
  };
  const classes = `${appearanceClass[appearance]} w-full h-fit min-h-[585px] flex flex-col bottom-0 left-0 right-0 m-auto z-90`;

  useEffect(() => {
    modalRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [slug]);

  return (
    <ReactModal
      appElement={document.getElementById("app")}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(false);
        navigate("/");
      }}
      shouldCloseOnOverlayClick={true}
      shouldFocusAfterRender={false}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: `rgba(0, 0, 0, ${bgOpacity})`,
          overflow: "auto",
          zIndex: "99",
        },
      }}
      {...props}
      className={`${classes} ${{ ...props }["className"]}`}
    >
      <div ref={modalRef}>
        {children}
        {!hideClose && (
          <div className="md:absolute left-0 top-0 bottom-0">
            <Link to={"/"} className="sticky flex justify-center top-0">
              <CloseIcon hideGradientDesktop={true} />
            </Link>
            <Link
              to={"/"}
              className="md:hidden absolute -top-14 -right-2 flex justify-center"
            >
              <CloseIcon />
            </Link>
          </div>
        )}
      </div>
    </ReactModal>
  );
}
