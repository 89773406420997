import { ShareIcon } from "./ShareIcon";

export function Share({ onClick }) {
  return (
    <button
      aria-label="share"
      className="flex items-center space-x-2 cursor-pointer hover:text-primary-darker"
      onClick={onClick}
    >
      <ShareIcon />
      <div className="font-normal">Share</div>
    </button>
  );
}
