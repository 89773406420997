const StatusFilters = ({
  filters,
  onClick,
  current,
  className = "",
  filterClassName = "",
  excludedFilters = [],
}) => {
  return (
    <div className={`${className}`}>
      {filters &&
        filters.map((item, index) => (
          <div
            key={`status.filter.${index}`}
            className={`inline cursor-pointer ${filterClassName} ${
              index !== filters.length - 1
                ? "mr-4 pr-4 border-r border-r-[#969696]"
                : ""
            }`}
            role="presentation"
            onClick={() => {
              onClick(item);
            }}
          >
            <span
              className={`mr-1 text-sm lg:text-base ${
                current === item.id ? "font-bold" : " text-[#005CB2]"
              }`}
            >
              {item.name}
            </span>
            <span className="text-sm lg:text-base text-[#636363]">
              ({item.count})
            </span>
          </div>
        ))}
    </div>
  );
};
export default StatusFilters;
