import {
  Children,
  cloneElement,
  MouseEventHandler,
  useCallback,
  useState,
  ReactElement,
  ReactNode,
} from "react";
import { noop } from "../../utils/noop";
import { TabProps } from "./Tab";

export type TabListProps = {
  activeTabId: string;
  children: ReactNode;
  disabled?: boolean;
  onActiveChange: (tabId: string) => void;
};

const buttonId = (target: EventTarget) => {
  const button =
    target instanceof Element ? (target as Element).closest("button") : null;

  return button?.disabled ? null : button?.id;
};

export const TabList = ({
  activeTabId,
  children,
  disabled = false,
  onActiveChange = noop,
}: TabListProps): ReactElement => {
  const [focusedTabId, setFocusedTabId] = useState<string | null>(null);

  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      const id = buttonId(e.target);
      if (!id) return;

      onActiveChange(id);
      setFocusedTabId(id);
    },
    [onActiveChange, setFocusedTabId]
  );

  const tabArray = Children.toArray(children).filter(
    Boolean
  ) as ReactElement<TabProps>[];
  const enabledTabs = tabArray.filter((tab) => !tab.props.disabled);

  return (
    <div
      role="tablist"
      onClick={onClick}
      className="flex flex-grow"
      tabIndex={0}
    >
      <div className="flex space-x-2">
        {tabArray.map((tab) => {
          return cloneElement(tab, {
            ...tab.props,
            active: activeTabId === tab.props.id,
            disabled: disabled || tab.props.disabled,
            hasFocus: focusedTabId !== null && focusedTabId === tab.props.id,
          });
        })}
      </div>
    </div>
  );
};
