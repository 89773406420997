import { Skeleton } from "@mui/material";

export function DailyEditionLoading() {
  return (
    <div className="mb-[10px]">
      <Skeleton
        variant="rectangular"
        height={38}
        animation="pulse"
        className="rounded-3xl"
      />
    </div>
  );
}
