import { useState } from "react";
import { Tab, TabList, TabPanel } from "../../components/Tabs";
import { Place } from "../Place";
import { Impact } from "../Impact";
import { Venture } from "../Venture";

export function TabModule() {
  const [activeTabId, setActiveTabId] = useState("place");

  return (
    <div className="bg-black px-4 pt-10 pb-9 md:p-10 md:pb-20 md:rounded-2xl mt-10 border-t-4 border-b-4 border-solid border-[#F0F0F0] md:border-none">
      <TabList
        activeTabId={activeTabId}
        onActiveChange={(nextTabId) => setActiveTabId(nextTabId)}
      >
        <Tab id="place">PLACE</Tab>
        <Tab id="impact">IMPACT</Tab>
        <Tab id="venture">VENTURE</Tab>
      </TabList>
      <TabPanel activeTabId={activeTabId} forTabId="place">
        <Place />
      </TabPanel>
      <TabPanel activeTabId={activeTabId} forTabId="impact">
        <Impact />
      </TabPanel>
      <TabPanel activeTabId={activeTabId} forTabId="venture">
        <Venture />
      </TabPanel>
    </div>
  );
}
