import { useEffect, useState } from "react";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { queryRequest } from "../../utils/request";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { AudioPlayer } from "../../components/AudioPlayer";
import { Badge } from "../../components/Badge";
import { MediaKeepExploring } from "./MediaKeepExploring";
import { useDetectMobile } from "../../utils/isMobile";
import { relativeTime } from "../../utils/formatDate";
import { Modal } from "../../components/Modal";
import Skeleton from "@mui/material/Skeleton";

const getPost = async ({ queryKey }) => {
  const [_, { slug }] = queryKey;
  return queryRequest({
    path: "news/posts",
    params: `?slug=${slug}`,
  });
};

export function MediaModal() {
  const { isMobile } = useDetectMobile();
  const { state } = useLocation();
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [mediaType, setMediaType] = useState(null);

  const { data: postData, isLoading } = useQuery(
    ["post", { slug: state?.slug ?? slug }],
    getPost
  );

  useEffect(() => {
    state?.postPreload
      ? setPost(state?.postPreload)
      : setPost(postData?.data.posts[0]);
  }, [postData, state]);

  useEffect(() => {
    post?.medias?.filter((media) => {
      if (media.key === "video") {
        setMediaType({ type: "video", href: media.media.cdn_source_url });
      }

      if (media.key === "audio") {
        setMediaType({ type: "audio", href: media.media.cdn_source_url });
      }
    });
  }, [post]);

  return (
    <Modal bgOpacity="0.95">
      <div className="rounded-xl z-90">
        {isLoading ? (
          <div>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              className="rounded-t-xl"
              animation="pulse"
              height={isMobile ? 208 : 580}
              variant="rectangular"
            />
          </div>
        ) : (
          <div>
            {mediaType && mediaType?.type === "video" && (
              <div className="aspect-video rounded-t-xl overflow-hidden mb-5">
                {/* <ReactPlayer
                    url={mediaType?.href}
                    controls={true}
                    width="100%"
                    height="100%"
                    playing
                  /> */}
              </div>
            )}
            {post?.featuredImage && mediaType?.type === "audio" && (
              <div>
                <img
                  src={post?.featuredImage}
                  alt={post?.title}
                  className="w-full h-[208px] md:h-[608px] object-cover rounded-t-xl"
                />
              </div>
            )}
            {mediaType && mediaType?.type === "audio" && (
              <div>
                <AudioPlayer src={mediaType?.href} />
              </div>
            )}
          </div>
        )}
        {isLoading ? (
          <div>
            <div className="flex items-center mb-4 mt-5">
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                animation="pulse"
                height={36}
                width={65}
                variant="rectangular"
              />
              <Skeleton
                sx={{ bgcolor: "grey.900" }}
                className="ml-4"
                animation="pulse"
                height={24}
                width={70}
                variant="rectangular"
              />
            </div>
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              className="mb-4 mt-5"
              animation="pulse"
              height={350}
              variant="rectangular"
            />
          </div>
        ) : (
          <div>
            <div className="mb-6 flex items-center">
              <Badge title={post?.primaryCategory.name} size="text-sm" />
              <span className="text-white ml-4">
                {relativeTime(post?.effectivePublishDate)}
              </span>
            </div>
            <h3 className="text-xl md:text-2xl font-semibold leading-8 text-white mt-2 mb-3">
              {sanitize(post?.title)}
            </h3>
            <div
              className="text-white font-['PT+Serif']"
              dangerouslySetInnerHTML={sanitizeForReact(post?.htmlContent)}
            />
          </div>
        )}
      </div>
      <div className="pt-7 rounded-xl z-90">
        <MediaKeepExploring currentId={post?.id} />
      </div>
    </Modal>
  );
}
