import { KeepExploringTile } from "./KeepExploringTile";
import { KeepExploringLoading } from "./KeepExploringLoading";
import { useSlideContent } from "../../utils/useSlideContent";
import { useDetectMobile } from "../../utils/isMobile";

type KeepExploring = {
  id: number;
  title?: string;
  slug?: string;
  featuredImage?: string;
};

type KeepExploringProps = {
  currentId: number;
};

export function KeepExploring({ currentId }: KeepExploringProps) {
  const { isMobile } = useDetectMobile();
  const postCount = isMobile ? 1 : 3;
  const { posts, isLoading, isFetching } = useSlideContent<KeepExploring>({
    requestUrl: "posts?categoryIds=14&categoryIds=16&categoryIds=27",
    count: isMobile ? 1 : 3,
    excludeId: currentId,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <div>
      <h3 className="text-primary text-2xl font-montserrat italic font-semibold py-3 border-t border-b border-[#CACACA] mt-7">
        Keep Exploring
      </h3>
      <div className="flex flex-col w-full mb-4 lg:mb-0">
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-x-5 mb-4 mt-6">
          {(loading ? Array.from(new Array(postCount)) : posts).map((post, i) =>
            post ? (
              <KeepExploringTile
                key={post.id}
                title={post.title}
                slug={post.slug}
                image={post.featuredImage}
                category={post.primaryCategory.name}
              />
            ) : (
              <KeepExploringLoading key={i} />
            )
          )}
        </div>
      </div>
    </div>
  );
}
