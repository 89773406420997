import { useEffect, useState } from "react";
import DropdownAsync from "./DropdownAsync";
import { searchQueryRequest } from "../../../utils/request";

const DropdownCategories = ({
  label,
  name,
  placeholder = "",
  showLabel = true,
  theme = "default",
  size = "default",
  required = true,
  register = null,
  errors = {},
  className = "",
  inputClassName = "",
  value = null,
  setValue = null,
}) => {
  const [inputValue, setInputState] = useState(value);

  useEffect(() => {
    setInputState(value);
  }, [value]);

  const loadServerData = (inputValue) => {
    return searchQueryRequest("categories", { text: inputValue });
  };

  return (
    <DropdownAsync
      label={label}
      name={name}
      placeholder={placeholder}
      showLabel={showLabel}
      theme={theme}
      size={size}
      required={required}
      register={register}
      errors={errors}
      className={className}
      inputClassName={inputClassName}
      value={inputValue}
      setValue={setValue}
      getOptionLabel={(e) => e.name}
      getOptionValue={(e) => e.id}
      loadOptions={loadServerData}
      isClearable={true}
    />
  );
};

export default DropdownCategories;
