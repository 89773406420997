type WebsiteIconProps = {
  addClassName?: string;
  addSvgClassName?: string;
};
export function WebsiteIcon({
  addClassName = "text-black",
  addSvgClassName,
}: WebsiteIconProps) {
  const strokeColor = addClassName === "text-white" ? "#000000" : "#2BE2D8";
  return (
    <span className={addClassName}>
      <svg
        className={addSvgClassName}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill="currentColor" />
        <path
          d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5M12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5M12 19C10.0284 16.97 8.88889 14.5707 8.88889 12C8.88889 9.42935 10.0284 7.03004 12 5M12 19C13.9716 16.97 15.1111 14.5707 15.1111 12C15.1111 9.42935 13.9716 7.03004 12 5M18.2222 9.66667H5.77778M18.2222 14.3333H5.77778"
          stroke={strokeColor}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
