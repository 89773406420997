import React, { useEffect, useState } from "react";
import CategoryService from "../../services/CategoryService";
import DropdownCategories from "../Inputs/DropdownCategories";
import Checkbox from "../Inputs/Checkbox";
import { toIds } from "../../utils/arrays";

export function CategoryEditor({
  name,
  primaryCategory = null,
  selected = [],
  setValue = null,
  getValues = null,
}) {
  const exclusiveCategory = "Partner";

  const [CategoriesListReady, setCategoriesListState] = useState(false);
  const [CategoriesList, setCategoriesList] = useState({
    retrieved: false,
    whitelisted: {
      required: [
        { id: "", name: "Know" },
        { id: "", name: "Thrive" },
        { id: "", name: "Innovate" },
        { id: "", name: "Create" },
      ],
      optional: [
        { id: "", name: "Place" },
        { id: "", name: "Impact" },
        { id: "", name: "Venture" },
      ],
    },
  });

  const [primaryCategoryId, setPrimaryCategoryId] = useState(
    primaryCategory ? primaryCategory.id : ""
  );
  const [categories, setCategories] = useState(selected);

  useEffect(() => {
    setFormCategories();
  }, [categories, selected]);

  const setFormCategories = () => {
    const newCategories = toIds(categories);
    if (setValue) {
      setValue(name, newCategories);
    }
  };

  const addCategory = (category, isPrimary = false) => {
    let newCategories = [];
    if (isPrimary) {
      setPrimaryCategoryId(category.id);
      setValue("primaryCategoryId", category.id);
    } else {
      if ("" !== category) {
        categories.push(category);
      }
      newCategories = categories.filter((x, i, a) => a.indexOf(x) == i);
      setCategories(newCategories);
    }
  };

  const removeCategory = (category, isPrimary = false) => {
    if (isPrimary) {
      setPrimaryCategoryId(null);
      setValue("primaryCategoryId", null);
    } else {
      let newCategories = [];
      newCategories = categories.filter((item) => item.id !== category.id);
      setCategories(newCategories);
    }
  };

  if (!CategoriesList.retrieved) {
    CategoryService.getAll({}).then((response) => {
      if (response) {
        let newCategories = CategoriesList;
        for (let i in CategoriesList.whitelisted.optional) {
          for (let j in response.data) {
            if (
              response.data[j].name ===
              CategoriesList.whitelisted.optional[i].name
            ) {
              newCategories.whitelisted.optional[i].id = response.data[j].id;
            }
          }
        }
        for (let i in CategoriesList.whitelisted.required) {
          for (let j in response.data) {
            if (
              response.data[j].name ===
              CategoriesList.whitelisted.required[i].name
            ) {
              newCategories.whitelisted.required[i].id = response.data[j].id;
            }
          }
        }
        newCategories.retrieved = true;
        setCategoriesList(newCategories);
        setCategoriesListState(true);
      }
    });
  }

  const [seriesSearchValue, setSeriesSearchValue] = useState("");
  const handleSeriesInputChange = (name, value) => {
    setSeriesSearchValue(value);
  };

  return (
    <>
      {CategoriesListReady && (
        <div className="m-4 flex flex-col lg:flex-row lg:space-x-4">
          <div className="w-full lg:w-1/2">
            <div className="flex space-y-1">
              <div className="w-1/2">
                {CategoriesList.whitelisted.required.map((category, index) => (
                  <div key={index}>
                    <Checkbox
                      label={category.name}
                      name={category.id}
                      checked={primaryCategoryId === category.id}
                      onChange={(checked) => {
                        if (checked) {
                          addCategory(category, true);
                        } else {
                          removeCategory(category, true);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
              <div className="w-1/2">
                {CategoriesList.whitelisted.optional.map((category) => (
                  <div key={category.id}>
                    <Checkbox
                      label={category.name}
                      name={category.id}
                      checked={categories
                        .map((x) => (typeof x === "object" ? x.id : x))
                        .includes(category.id)}
                      onChange={(checked) => {
                        if (checked) {
                          addCategory(category);
                        } else {
                          removeCategory(category);
                        }
                      }}
                    />
                  </div>
                ))}
                <Checkbox
                  name="Partner"
                  label="Partner Story"
                  checked={categories[0] == exclusiveCategory}
                  onChange={(state) => {
                    if (state) {
                      addCategory("Partner", true);
                    } else {
                      removeCategory("Partner", true);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/* MVP HIDE */}
          <div className="w-full lg:w-1/2 mt-6 lg:mt-0 hidden">
            <DropdownCategories
              label=""
              value={seriesSearchValue}
              name="more_categories"
              placeholder="Select to add series"
              setValue={handleSeriesInputChange}
            />
          </div>
        </div>
      )}
    </>
  );
}
