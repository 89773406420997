import { SectionHeader } from "../../components/SectionHeader";
import { SlideButton } from "../../components/SlideButton";
import { ZapLoading } from "./ZapLoading";
import { ZapTile } from "./ZapTile";
import { useSlideContent } from "../../utils/useSlideContent";

type Zap = {
  id: number;
  title: string;
};

export function Zaps({ isCategoryPage }) {
  const {
    posts,
    isLoading,
    isFetching,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
  } = useSlideContent<Zap>({
    requestUrl: "posts?categoryIds=32&categoryIds=33&categoryIds=34",
    count: 5,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <div>
      <SectionHeader
        title="zaps"
        gradient={!isCategoryPage}
        addClassName="hidden md:block"
      />

      <div className="col-span-4 rounded-xl md:border md:border-gray px-5">
        <div className="divide-y divide-dotted">
          {(loading ? Array.from(new Array(5)) : posts).map((post, i) =>
            post ? (
              <ZapTile key={post.id} id={post.id} title={post.title} />
            ) : (
              <ZapLoading key={i} />
            )
          )}
        </div>

        <div className="flex justify-between m-5">
          <SlideButton
            direction="back"
            onClick={slideBack}
            disabled={slideBackDisabled}
          />
          <SlideButton
            direction="forward"
            onClick={slideForward}
            disabled={slideForwardDisabled}
          />
        </div>
      </div>
    </div>
  );
}
