import Skeleton from "@mui/material/Skeleton";

export function ArticleTileLoading() {
  return (
    <div className="grid gap-x-5 grid-cols-2 py-6 items-center md:items-start">
      <div className="order-2 md:order-none">
        <div className="hidden md:block">
          <Skeleton
            className="rounded w-full"
            animation="pulse"
            height={128}
            variant="rectangular"
          />
        </div>
        <div className="md:hidden">
          <Skeleton
            className="rounded w-full"
            animation="pulse"
            height={72}
            variant="rectangular"
          />
        </div>
      </div>
      <div className="order-1 md:order-none">
        <Skeleton
          className="rounded w-full mb-3"
          animation="pulse"
          height={40}
          variant="rectangular"
        />
        <Skeleton
          className="rounded w-full"
          animation="pulse"
          height={48}
          variant="rectangular"
        />
      </div>
    </div>
  );
}
