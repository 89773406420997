export async function readAsDataURL(file) {
  return new Promise<string>((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      let value = "";
      if (typeof fr.result == "string") {
        value = fr.result;
      }
      resolve(value);
    };
    fr.readAsDataURL(file);
  });
}

export async function getBlobFromUrl(url) {
  const response = await fetch(url);
  const fileName = url.split("/").pop();
  return {
    fileName: fileName,
    contentType: response.headers.get("content-type"),
    blob: await response.blob(),
  };
}

export async function getBase64FromUrl(url) {
  const result = await getBlobFromUrl(url);
  return readAsDataURL(result.blob);
}

export async function getFileFromUrl(url) {
  const result = await getBlobFromUrl(url);
  return new File([result.blob], result.fileName, {
    type: result.contentType,
  });
}
