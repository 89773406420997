import { Skeleton } from "@mui/material";
import { ZapIcon } from "./ZapIcon";

export function ZapLoading() {
  return (
    <div className="flex items-center space-x-5 p-5">
      <div className="grow">
        <ZapIcon />
      </div>
      <Skeleton className="rounded-xl w-full" animation="pulse" height={40} />
    </div>
  );
}
