import { useEffect, useState } from "react";
import { InputFieldSizes, InputFieldStyles } from "../../constants";
import Label from "../Label";

const TextArea = ({
  label,
  name,
  required = true,
  showLabel = true,
  theme = "default",
  size = "default",
  register = null,
  setValue = null,
  value = "",
  errors = {},
  className = "",
  inputClassName = "",
  rows = 3,
  max = Infinity,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  let fieldClasses =
    (InputFieldStyles[theme]
      ? InputFieldStyles[theme]
      : InputFieldStyles.default) + " ";
  fieldClasses += InputFieldSizes[size]
    ? InputFieldSizes[size]
    : InputFieldSizes.default;

  if (errors?.[name]) {
    fieldClasses += " error";
  }
  if (inputClassName) {
    fieldClasses += ` ${inputClassName}`;
  }

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length > max) {
      setInputValue(newValue.slice(0, max));
    } else {
      setInputValue(newValue);
      if (setValue) {
        setValue(name, newValue);
      }
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div
      className={"flex w-full flex-wrap items-stretch text-left " + className}
    >
      {label ? (
        <Label className={`${showLabel ? "" : "sr-only"}`} text={label} />
      ) : (
        ""
      )}
      <textarea
        className={`${fieldClasses}`}
        {...(register ? register(name, { required: required }) : {})}
        rows={rows}
        defaultValue={inputValue}
        onChange={handleChange}
      />
      {errors?.[name] && <span>This field is required</span>}
    </div>
  );
};

export default TextArea;
