import { ButtonColors, ButtonSizes } from "../../constants";
import { v4 } from "uuid";

const Button = ({
  name = "",
  label = "",
  type = undefined,
  children = null,
  theme = "default",
  size = "default",
  onClick = null,
  className = "",
}) => {
  const ariaLabel = label
    ? label.toLocaleLowerCase().replace(" ", "_").replace("-", "_")
    : `button_${v4()}`;

  let buttonClass = className;
  buttonClass +=
    " " + (ButtonColors[theme] ? ButtonColors[theme] : ButtonColors.secondary);
  buttonClass +=
    " " + (ButtonSizes[size] ? ButtonSizes[size] : ButtonSizes.default);

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <>
      <button
        aria-label={ariaLabel}
        type={type}
        onClick={handleClick}
        className={`${buttonClass}`}
      >
        {children} {label ? label : ""}
      </button>
    </>
  );
};

export default Button;
