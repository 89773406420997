type EmailIconProps = {
  addClassName?: string;
  addSvgClassName?: string;
};
export function EmailIcon({
  addClassName = "text-black",
  addSvgClassName,
}: EmailIconProps) {
  return (
    <span className={addClassName}>
      <svg
        className={addSvgClassName}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1962_3504)">
          <path
            d="M12 24C15.182 24 18.2376 22.7368 20.4872 20.4872C22.7368 18.2376 24 15.182 24 12C24 8.81805 22.7368 5.76241 20.4872 3.51278C18.2376 1.26316 15.182 0 12 0C8.81805 0 5.76241 1.26316 3.51278 3.51278C1.26316 5.76241 0 8.81805 0 12C0 15.182 1.26316 18.2376 3.51278 20.4872C5.76241 22.7368 8.81805 24 12 24ZM5.71429 8.3609C5.86466 7.99398 6.12932 7.67519 6.46617 7.46466C6.80301 7.24812 7.2 7.13985 7.59699 7.15789H16.3789C16.7759 7.13985 17.1609 7.2421 17.4977 7.44662C17.8346 7.65113 18.0992 7.95188 18.2677 8.31278C18.3338 8.40301 18.3519 8.52331 18.3158 8.63158C18.2797 8.73985 18.1895 8.82406 18.0812 8.85414L12.2586 11.8917C12.1083 11.9759 11.9278 11.9759 11.7774 11.8917L5.87669 8.82406C5.78045 8.79398 5.70827 8.7218 5.67819 8.63158C5.64812 8.54135 5.66015 8.4391 5.71429 8.35489V8.3609ZM6.09925 10.3038C7.93383 11.2601 9.76842 12.2165 11.597 13.1729C11.7173 13.2451 11.8617 13.2872 12.006 13.2872C12.1504 13.2872 12.2887 13.2511 12.415 13.1729C14.2376 12.2165 16.0601 11.2722 17.8887 10.3338C17.9429 10.3038 17.997 10.2797 18.0571 10.2677C18.1534 10.2316 18.2677 10.2496 18.3459 10.3158C18.4301 10.382 18.4722 10.4842 18.4601 10.5865V14.2376C18.4722 14.5504 18.4601 14.8692 18.4241 15.182C18.3519 15.6511 18.1113 16.0782 17.7504 16.385C17.3895 16.6917 16.9263 16.8541 16.4511 16.8481H7.59098C7.14586 16.8601 6.70677 16.7158 6.34586 16.4451C5.98496 16.1744 5.73233 15.7955 5.61804 15.3624C5.56992 15.194 5.54586 15.0195 5.54586 14.8451V10.6286C5.54586 10.2737 5.77444 10.1414 6.09925 10.3098V10.3038Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1962_3504">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
