export const range = (min, max) => {
  return Array.from({ length: max - min }, (_, i) => i + min);
};

export const chunk = (arr, len) => {
  let chunks = [],
    i = 0,
    n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
};

export const insert = (arr, index, ...rest) => {
  return arr.slice(0, index).concat(rest, arr.slice(index));
};

export const swap = (arr, index1, index2) => {
  let maxIndex = index1 > index2 ? index1 : index2;
  if (typeof arr[maxIndex] === "undefined") {
    return arr;
  }
  let tmp = arr[index1];
  arr[index1] = arr[index2];
  arr[index2] = tmp;
  return arr;
};

export const remove = (arr, value) => {
  let index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const only = (object, keys) => {
  const data = {};
  for (const i in keys) {
    data[keys[i]] = object[keys[i]];
  }
  return data;
};

export const toIds = (data) => {
  return data.map((item) => {
    if (typeof item === "object" && item.id) {
      return item.id;
    } else if (!isNaN(item)) {
      return item;
    }
  });
};
