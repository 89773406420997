import { StateColors } from "../constants";
import {
  DangerAlertIcon,
  InfoAlertIcon,
  SuccessAlertIcon,
  WarningAlertIcon,
} from "./Icons";

const Alert = ({ text, className = "", type = "danger", onUndo = null }) => {
  let borderColor = `border-l-[${StateColors[type]}]`;
  let wrapperClasses = `flex justify-content align-middle bg-white shadow-sm border-l-4 rounded-sm pl-4 pl-4 pt-2 pb-2 ${borderColor} ${className}`;

  let icon = null;
  switch (type) {
    case "success":
      icon = <SuccessAlertIcon />;
      break;
    case "info":
      icon = <InfoAlertIcon />;
      break;
    case "danger":
      icon = <DangerAlertIcon />;
      break;
    case "warning":
      icon = <WarningAlertIcon />;
      break;
  }

  return (
    <div className={wrapperClasses}>
      {icon !== null && icon}
      <span className="inline ml-2" role="presentation">
        {text}
      </span>
      {onUndo && (
        <span
          className="inline relative top--4 mr-2"
          role="presentation"
          onClick={onUndo}
        >
          Undo
        </span>
      )}
    </div>
  );
};
export default Alert;
