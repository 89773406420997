export function NewsletterRightIcon() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2071 8.70705C14.5976 8.31653 14.5976 7.68336 14.2071 7.29284L7.84312 0.928903C7.45259 0.53838 6.81943 0.538382 6.42891 0.928908C6.03838 1.31943 6.03839 1.9526 6.42891 2.34312L12.0858 7.99995L6.42895 13.6568C6.03843 14.0474 6.03843 14.6805 6.42896 15.071C6.81948 15.4616 7.45265 15.4616 7.84317 15.071L14.2071 8.70705ZM13.5 6.99995L-3.72727e-06 7L3.72727e-06 9L13.5 8.99995L13.5 6.99995Z"
        fill="black"
      />
    </svg>
  );
}
