import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { queryRequest } from "./request";

const pageBuffer = 1;

type UseSlideContentProps = {
  requestUrl: string;
  count?: number;
  excludeId?: number;
};

type UseSlideContentReturnType<T> = {
  posts: T[];
  isLoading: boolean;
  isFetching: boolean;
  slideForward: () => void;
  slideBack: () => void;
  slideBackDisabled: boolean;
  slideForwardDisabled: boolean;
};

export function useSlideContent<T>({
  requestUrl,
  count = 4,
  excludeId = null,
}: UseSlideContentProps): UseSlideContentReturnType<T> {
  const [page, setPage] = useState(1);
  const [displayIndex, setDisplayIndex] = useState(0);
  const [allPosts, setAllPosts] = useState([]);

  const buffer = pageBuffer * count;
  const queryCount = count * 2 + buffer;
  const queryKey = [requestUrl, queryCount, page];
  const queryFn = useCallback(
    () =>
      queryRequest({
        path: `news/${requestUrl}&take=${queryCount}&page=${page}`,
      }),
    [requestUrl, queryCount, page]
  );

  const {
    data: postData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (postData?.data.posts.length) {
      setAllPosts([...allPosts, ...postData?.data.posts]);
    }
  }, [postData]);

  useEffect(() => {
    if (allPosts.length && displayIndex + count + buffer === allPosts.length) {
      setPage(page + 1);
    }
  }, [displayIndex]);

  const slideForward = useCallback(() => {
    setDisplayIndex(displayIndex + count);
  }, [displayIndex, count]);

  const slideBack = useCallback(() => {
    setDisplayIndex(displayIndex - count);
  }, [displayIndex, count]);

  const posts = useMemo(() => {
    const filteredPosts = excludeId
      ? allPosts.filter((post) => post.id !== excludeId)
      : allPosts;
    return filteredPosts.slice(displayIndex, displayIndex + count);
  }, [displayIndex, allPosts, excludeId]);

  return {
    posts,
    isLoading,
    isFetching,
    slideForward,
    slideBack,
    slideBackDisabled: displayIndex === 0,
    slideForwardDisabled: displayIndex + count >= allPosts.length,
  };
}

type UseFeaturedSlideContentReturnType<T> = UseSlideContentReturnType<T> & {
  featuredPost: T;
  slideFeaturedPostForward: () => void;
  slideFeaturedPostBack: () => void;
  slideFeaturedPostBackDisabled: boolean;
};

export function useFeaturedSlideContent<T>({
  requestUrl,
  count = 5,
  aboveTheFold,
}: UseSlideContentProps & {
  aboveTheFold?: boolean;
}): UseFeaturedSlideContentReturnType<T> {
  const [featuredPostIndex, setFeaturedPostIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [displayIndex, setDisplayIndex] = useState(1);
  const [allPosts, setAllPosts] = useState([]);
  const isFirstFetch = useRef(true);

  const buffer = pageBuffer * count;
  const [queryCount, setQueryCount] = useState(
    aboveTheFold ? 5 : count * 2 + buffer
  );
  const queryKey = [requestUrl, queryCount, page];
  const queryFn = useCallback(
    () =>
      queryRequest({
        path: `news/${requestUrl}&take=${queryCount}&page=${page}`,
      }),
    [requestUrl, queryCount, page]
  );

  const {
    data: postData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (postData?.data.posts.length) {
      setAllPosts([...allPosts, ...postData?.data.posts]);

      if (aboveTheFold && isFirstFetch) {
        isFirstFetch.current = false;
        setQueryCount(count * 2 + buffer);
      }
    }
  }, [postData]);

  useEffect(() => {
    if (allPosts.length && allPosts.length - (displayIndex + count) < buffer) {
      setPage(page + 1);
    }
  }, [displayIndex]);

  const featuredPost = useMemo(
    () => allPosts && allPosts[featuredPostIndex],
    [allPosts, featuredPostIndex]
  );

  const slideForward = useCallback(() => {
    setDisplayIndex(displayIndex + count);
  }, [displayIndex, count]);

  const slideBack = useCallback(() => {
    if (displayIndex - count <= 0) {
      setDisplayIndex(1);
    } else {
      setDisplayIndex(displayIndex - count);
    }
  }, [displayIndex, count]);

  const slideFeaturedPostBack = useCallback(() => {
    setFeaturedPostIndex(featuredPostIndex - 1);
    setDisplayIndex(displayIndex - 1);
  }, [featuredPostIndex, displayIndex]);

  const slideFeaturedPostForward = useCallback(() => {
    setFeaturedPostIndex(displayIndex);
    setDisplayIndex(displayIndex + 1);
  }, [displayIndex]);

  const posts = useMemo(() => {
    return allPosts.slice(displayIndex, displayIndex + (count - 1));
  }, [displayIndex, allPosts]);

  return {
    posts,
    isLoading,
    isFetching,
    featuredPost,
    slideForward,
    slideBack,
    slideBackDisabled: displayIndex === 1,
    slideForwardDisabled: displayIndex + count >= allPosts.length,
    slideFeaturedPostForward,
    slideFeaturedPostBack,
    slideFeaturedPostBackDisabled: featuredPostIndex < 1,
  };
}
