import {
  forwardRef,
  useEffect,
  useRef,
  ComponentPropsWithRef,
  ReactElement,
  ReactNode,
  SyntheticEvent,
} from "react";

export type TabProps = {
  id: string;
  children: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: (e: SyntheticEvent<HTMLButtonElement, Event>) => void;
  hasFocus?: boolean;
};

export function Tab(props: TabProps): ReactElement {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (props.hasFocus) buttonRef?.current?.focus();
  }, [props.hasFocus, buttonRef]);

  const buttonProps = {
    ...props,
    tabIndex: props.hasFocus ? 0 : -1,
  };

  return <TabButton {...buttonProps} ref={buttonRef} />;
}

type TabButtonProps = {
  active?: boolean;
} & Pick<
  ComponentPropsWithRef<"button">,
  "id" | "children" | "disabled" | "onClick" | "tabIndex"
>;

const TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(
  (
    {
      id,
      active = false,
      children,
      disabled = false,
      onClick,
      tabIndex,
    }: TabButtonProps,
    ref
  ): ReactElement => {
    return (
      <button
        aria-label={`tab_${id}`}
        id={id}
        className={`
          flex items-center px-4 pt-2 font-montserrat italic font-semibold mb-1  
          focus:outline-none focus-visible:ring focus-visible:ring-inset 
          box-content bg-black text-white rounded-tl-lg rounded-tr-lg 
          border border-primary border-b-black
          ${disabled ? "cursor-not-allowed text-disabled" : ""} ${
          active
            ? "text-primary !border-2 !border-white !border-b-white !-mb-1 pb-2"
            : ""
        }
        `}
        role="tab"
        aria-selected={active}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        tabIndex={tabIndex}
        type="button"
      >
        {children}
      </button>
    );
  }
);
TabButton.displayName = "TabButton";
