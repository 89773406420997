export function CatalystIcon() {
  return (
    <div className="inline-block">
      <svg
        width="239"
        height="149"
        viewBox="0 0 239 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1389_460)">
          <path
            d="M23.3748 94.6623L20.7292 96.4451C19.0901 94.0133 16.3171 92.4852 13.0594 92.4852C7.51348 92.4852 3.31913 96.5026 3.31913 102.426C3.31913 108.349 7.51348 112.367 13.0594 112.367C16.3007 112.367 19.0572 110.892 20.7127 108.423L23.3049 110.313C21.1811 113.463 17.4181 115.411 13.0594 115.411C5.49641 115.411 0.00390625 109.972 0.00390625 102.426C0.00390625 94.8801 5.49641 89.4414 13.0594 89.4414C17.4715 89.4414 21.3988 91.4213 23.3789 94.6623H23.3748Z"
            fill="white"
          />
          <path
            d="M57.673 115.026H54.2715L51.4985 108.145H39.2729L36.5164 115.026H33.2217L43.4672 89.8164H47.411L57.6771 115.026H57.673ZM40.4766 105.138H50.2908L45.3939 92.9465L40.4766 105.138Z"
            fill="white"
          />
          <path
            d="M84.6587 92.8233H76.0687V115.026H72.9014V92.8233H64.2949V89.8164H84.6628V92.8233H84.6587Z"
            fill="white"
          />
          <path
            d="M118.707 115.026H113.342L111.144 109.156H100.393L98.195 115.026H92.9531L102.767 89.8164H108.888L118.703 115.026H118.707ZM102.122 104.526H109.414L105.774 94.7868L102.118 104.526H102.122Z"
            fill="white"
          />
          <path
            d="M147.496 115.026H129.146V89.8164H134.17V110.326H147.496V115.026Z"
            fill="white"
          />
          <path
            d="M171.66 105.483V115.026H164.816V105.574L155.306 89.8164H163.156L168.254 99.3423L173.332 89.8164H180.948L171.656 105.483H171.66Z"
            fill="white"
          />
          <path
            d="M206.246 97.6114C204.591 96.6749 202.089 95.5041 198.971 95.5041C197.134 95.5041 195.729 96.0094 195.729 97.1431C195.729 98.5644 198.305 98.7082 200.881 99.2134L201.69 99.3777C206.895 100.388 209.541 102.89 209.541 106.759C209.541 113.135 204.155 115.492 198.79 115.492C194.271 115.492 190.163 114.412 187.283 112.358L190.631 106.776C192.632 107.983 195.043 109.368 198.88 109.368C200.717 109.368 202.068 108.863 202.068 107.622C202.068 106.308 200.503 106.073 197.369 105.535L196.415 105.375C190.619 104.385 188.203 101.879 188.331 97.4142C188.458 92.5877 192.509 89.3672 198.56 89.3672C201.855 89.3672 204.825 89.7081 209.204 92.0126L206.25 97.6114H206.246Z"
            fill="white"
          />
          <path
            d="M239 96.1216H231.831V115.03H224.987V96.1216H217.818V89.8203H238.996V96.1216H239Z"
            fill="white"
          />
          <path
            d="M89.2352 141.167C88.43 140.67 87.4523 140.296 86.3349 140.296C85.2175 140.296 84.3753 140.748 84.3753 141.524C84.3753 142.301 85.2586 142.502 86.4869 142.707L87.0661 142.802C88.8942 143.102 90.3238 143.882 90.3238 145.665C90.3238 147.924 88.3355 148.996 86.1377 148.996C84.589 148.996 82.9211 148.544 81.8242 147.657L82.8841 146.014C83.6236 146.605 84.8272 147.119 86.1459 147.119C87.2798 147.119 88.1548 146.671 88.1548 145.883C88.1548 145.176 87.4071 144.909 85.9857 144.683L85.3366 144.58C83.5332 144.293 82.2145 143.5 82.2145 141.647C82.2145 139.516 84.1165 138.402 86.2938 138.402C87.7275 138.402 88.9189 138.723 90.2047 139.491L89.2393 141.155L89.2352 141.167Z"
            fill="white"
          />
          <path
            d="M102.512 140.496H99.3409V148.818H97.3033V140.496H94.1318V138.59H102.516V140.496H102.512Z"
            fill="white"
          />
          <path
            d="M107.812 147.573C107.812 148.341 107.155 148.998 106.403 148.998C105.651 148.998 104.978 148.341 104.978 147.573C104.978 146.805 105.643 146.164 106.403 146.164C107.163 146.164 107.812 146.813 107.812 147.573Z"
            fill="white"
          />
          <path
            d="M114.545 145.076V148.818H112.503V138.586H116.677C119.117 138.586 120.419 139.859 120.419 141.839C120.419 143.819 119.117 145.076 116.693 145.076H114.545ZM114.545 140.463V143.211H116.644C117.753 143.211 118.332 142.759 118.332 141.839C118.332 140.919 117.753 140.463 116.644 140.463H114.545Z"
            fill="white"
          />
          <path
            d="M132.736 148.818H125.091V138.586H132.678V140.455H127.133V142.718H132.424V144.587H127.133V146.941H132.74V148.81L132.736 148.818Z"
            fill="white"
          />
          <path
            d="M145.204 140.496H142.032V148.818H139.991V140.496H136.819V138.59H145.204V140.496Z"
            fill="white"
          />
          <path
            d="M157.174 148.818H149.529V138.586H157.117V140.455H151.571V142.718H156.862V144.587H151.571V146.941H157.179V148.81L157.174 148.818Z"
            fill="white"
          />
          <path
            opacity="0.05"
            d="M119.5 64.8858C137.42 64.8858 151.946 50.3606 151.946 32.4429C151.946 14.5252 137.42 0 119.5 0C101.581 0 87.0547 14.5252 87.0547 32.4429C87.0547 50.3606 101.581 64.8858 119.5 64.8858Z"
            fill="#2BE2D8"
          />
          <path
            d="M101.753 32.4429C101.753 22.6419 109.698 14.6975 119.5 14.6975V0C101.581 0 87.0547 14.525 87.0547 32.4429C87.0547 50.3609 101.581 64.8858 119.5 64.8858V50.1883C109.698 50.1883 101.753 42.244 101.753 32.4429Z"
            fill="#2BE2D8"
          />
          <path
            d="M119.5 14.6992V50.1901C129.302 50.1901 137.247 42.2457 137.247 32.4447C137.247 22.6436 129.302 14.6992 119.5 14.6992Z"
            fill="#2BE2D8"
          />
        </g>
        <defs>
          <clipPath id="clip0_1389_460">
            <rect width="239" height="149" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
