import { SectionHeader } from "../../components/SectionHeader";
import { SlideButton } from "../../components/SlideButton";
import { useSlideContent } from "../../utils/useSlideContent";
import { Advertisement } from "../../components/Advertisement";
import { useDetectMobile } from "../../utils/isMobile";
import { CreateTile } from "./CreateTile";
import { CreateLoading } from "./CreateLoading";
import createBackground from "./create-background.svg";
import createBackgroundMobile from "./create-background-mobile.svg";
import type { PostType } from "../../types";

export function Create() {
  const { isMobile } = useDetectMobile();
  const postCount = isMobile ? 1 : 4;
  const {
    posts,
    isLoading,
    isFetching,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
  } = useSlideContent<PostType>({
    requestUrl: "posts?categoryIds=9&postType=post",
    count: postCount,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <>
      <div
        style={{
          backgroundImage: isMobile
            ? `url(${createBackgroundMobile})`
            : `url(${createBackground})`,
        }}
        className="md:rounded-2xl mt-10 p-5 bg-cover bg-no-repeat border-b-4 border-t-4 border-[#F0F0F0] md:border-none"
      >
        <div className="text-white">
          <SectionHeader title="create" gradient={false} border={false} />
        </div>

        <div className="grid grid-cols-12 gap-5">
          {(loading ? Array.from(new Array(postCount)) : posts).map((post, i) =>
            post ? (
              <CreateTile key={post.id} post={post} index={i} />
            ) : (
              <CreateLoading key={i} index={i} isMobile={isMobile} />
            )
          )}
          <div className="col-span-12 md:col-span-6 xl:col-span-3 hidden md:block">
            <Advertisement
              path="/21735245768/SPC_HP_MREC_1B"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-1"
            />
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3 hidden md:block">
            <Advertisement
              path="/21735245768/SPC_HP_MREC_2T"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-2"
            />
          </div>
        </div>

        <div className="flex justify-between my-5">
          <SlideButton
            direction="back"
            onClick={slideBack}
            disabled={slideBackDisabled}
          />
          <SlideButton
            direction="forward"
            onClick={slideForward}
            disabled={slideForwardDisabled}
          />
        </div>
      </div>
      <div className="md:hidden pt-10">
        <Advertisement
          path="/21735245768/SPC_PL_HP_MREC_4"
          size={[300, 250]}
          id="div-gpt-ad-1682306808816-1-mobile"
        />
      </div>
    </>
  );
}
