export function ShareIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H7.24925V0H2.99938C1.3433 0 0 1.3433 0 2.99938V17.0006C0 18.6567 1.3433 20 2.99938 20H17.0006C18.6567 20 20 18.6567 20 17.0006V14.2508C20 13.4223 19.3284 12.7508 18.5 12.7508C17.6716 12.7508 17 13.4223 17 14.2508V17H3V3ZM10.9792 0H20V7.52083C20 8.34926 19.3284 9.02083 18.5 9.02083C17.6716 9.02083 17 8.34926 17 7.52083V5.12132L12.1232 9.99816C11.5374 10.5839 10.5876 10.5839 10.0018 9.99816C9.41605 9.41237 9.41605 8.46263 10.0018 7.87684L14.8787 3H10.9792V0Z"
        fill="#2BE2D8"
      />
    </svg>
  );
}
