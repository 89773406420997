export function AudioPlayIcon() {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4934 10.6514C21.1689 11.0256 21.1689 11.9745 20.4934 12.3486L1.49574 22.8712C0.828324 23.2408 -3.38534e-08 22.7709 0 22.0226L9.5208e-07 0.977405C9.85933e-07 0.229094 0.828324 -0.240832 1.49574 0.128841L20.4934 10.6514Z"
        fill="#FAFAFA"
      />
    </svg>
  );
}
