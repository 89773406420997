import { toIds } from "./arrays";
import { categories } from "../../constants";

export function sanitizeData(data) {
  if (data.categories) {
    data["categories"] = toIds(data["categories"]);
    data["categories"] = data["categories"].filter((n) => n);
  }
  if (data.tags) {
    data["tags"] = toIds(data["tags"]);
    data["tags"] = data["tags"].filter((n) => n);
  }
  return data;
}
