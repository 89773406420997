import { InfluencerTile } from "./InfluencerTile";
import { useSlideContent } from "../../utils/useSlideContent";
import { SlideButton } from "../../components/SlideButton";
import { useDetectMobile } from "../../utils/isMobile";
import { InfluencerLoading } from "./InfluencerLoading";
import type { InfluencerType } from "../../types";

type InfluencerKeepExploring = {
  post: InfluencerType;
};

type InfluencerKeepExploringProps = {
  currentId: number;
};

export function InfluencerKeepExploring({
  currentId,
}: InfluencerKeepExploringProps) {
  const { isMobile } = useDetectMobile();
  const postCount = isMobile ? 1 : 3;
  const {
    posts,
    slideBack,
    slideBackDisabled,
    slideForward,
    slideForwardDisabled,
    isLoading,
    isFetching,
  } = useSlideContent<InfluencerKeepExploring>({
    requestUrl: "posts?postType=influencer",
    count: postCount,
    excludeId: currentId,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <div>
      <h3 className="text-primary text-2xl font-bold font-montserrat py-3 border-t border-b border-[#CACACA] mt-7">
        Keep Exploring
      </h3>
      <div className="flex flex-col w-full mb-4 lg:mb-0">
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-x-5 mb-4 mt-6">
          {(loading ? Array.from(new Array(postCount)) : posts).map((post, i) =>
            post ? (
              <InfluencerTile key={post.id} post={post} modalSection={true} />
            ) : (
              <InfluencerLoading key={i} modalSection={true} />
            )
          )}
        </div>

        <div className="flex w-full flex-col mt-auto mb-5">
          <div className="flex justify-between">
            <SlideButton
              appearance="light"
              direction="back"
              onClick={slideBack}
              disabled={slideBackDisabled}
            />
            <SlideButton
              appearance="light"
              direction="forward"
              onClick={slideForward}
              disabled={slideForwardDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
