import { ArticleTile, ArticleTileLoading } from "../../components/ArticleTile";
import {
  FeaturedPost,
  FeaturedPostLoading,
} from "../../components/FeaturedPost";
import { SlideButton } from "../../components/SlideButton";
import { useFeaturedSlideContent } from "../../utils/useSlideContent";
import { PostType } from "../../types";
import { Badge } from "../../components/Badge";
import { VideoTile } from "../../components/VideoTile/VideoTile";
import { PlaceListingTile } from "./PlaceListingTile";
import { Link } from "react-router-dom";
import { Divider } from "../../components/Divider";

export function Place() {
  const {
    posts,
    isLoading,
    featuredPost,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
    slideFeaturedPostForward,
    slideFeaturedPostBack,
    slideFeaturedPostBackDisabled,
  } = useFeaturedSlideContent<PostType>({
    requestUrl: "posts?categoryIds=23",
  });

  return (
    <div className="bg-white px-5 grid lg:grid-cols-8 gap-5 rounded-tr-lg rounded-br-lg rounded-bl-lg relative">
      <div className="flex flex-col lg:col-span-4 my-5 md:pr-5 lg:border-r">
        {isLoading ? (
          <FeaturedPostLoading />
        ) : (
          <FeaturedPost
            post={featuredPost}
            badge={<Badge appearance="dark" title="the market" />}
          />
        )}

        <div className="flex flex-col grow justify-end">
          <div className="flex justify-between">
            <SlideButton
              direction="back"
              disabled={slideFeaturedPostBackDisabled}
              onClick={slideFeaturedPostBack}
            />
            <SlideButton
              direction="forward"
              onClick={slideFeaturedPostForward}
            />
          </div>
        </div>
      </div>

      <div className="md:flex flex-col lg:col-span-4 hidden">
        <div className="divide-y">
          {(isLoading ? Array.from(new Array(4)) : posts).map((post, i) =>
            post ? (
              <ArticleTile
                key={post.id}
                post={post}
                badge={
                  <Badge appearance="dark" title="the market" size="text-xs" />
                }
              />
            ) : (
              <ArticleTileLoading key={i} />
            )
          )}
        </div>

        <div className="flex flex-col grow justify-end">
          <div className="flex justify-between my-5">
            <SlideButton
              direction="back"
              onClick={slideBack}
              disabled={slideBackDisabled}
            />
            <SlideButton
              direction="forward"
              onClick={slideForward}
              disabled={slideForwardDisabled}
            />
          </div>
        </div>
      </div>

      {/* <Divider addClassName="md:hidden" />

      <div className="lg:col-span-3 my-5 md:px-5 md:border-l">
        <VideoTile title="Market Catalyst real estate show" />
        <div className="divide-y">
          <PlaceListingTile
            title="RESIDENTIAL FOR SALE"
            content="Non diam phasellus vestibulum lorem sed. Vitae semper quis lectus
          nulla at. Enim praesent"
          />
          <PlaceListingTile
            title="COMMERCIAL FOR LEASE"
            content="Non diam phasellus vestibulum lorem sed. Vitae semper quis lectus
          nulla at. Enim praesent"
            addClassName="hidden md:grid"
          />
          <PlaceListingTile
            title="COMMERCIAL FOR LEASE"
            content="Non diam phasellus vestibulum lorem sed. Vitae semper quis lectus
          nulla at. Enim praesent"
            addClassName="hidden md:grid"
          />
        </div>
      </div> */}

      <Link
        to="/place"
        title="KEEP EXPLORING"
        className="absolute block right-4 -bottom-16"
      >
        <div className="bg-black text-2xl font-montserrat italic font-semibold rounded-lg py-2 px-4 text-primary border-b-2 border-b-primary text-center hidden md:block">
          KEEP EXPLORING
        </div>
      </Link>
    </div>
  );
}
