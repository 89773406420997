import { PostLink } from "../../components/PostLink";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { ShuffleUserInfo } from "./ShuffleUserInfo";
import { ShuffleType } from "../../types";

type ShuffleTileProps = {
  post: ShuffleType;
};

export function ShuffleTile({ post }: ShuffleTileProps) {
  return (
    <PostLink
      slug={`shuffle/${post?.slug}`}
      title={sanitize(post?.title)}
      preloadData={post}
      className="grid grid-cols-1 md:grid-cols-12 md:gap-x-5 group"
    >
      <div className="md:col-span-4">
        {post?.featuredImage && (
          <img
            src={post?.featuredImage}
            alt={sanitize(post?.title)}
            className="w-full h-64 md:h-[252px] object-cover rounded-t-2xl mb-4 md:mb-0 md:rounded-r-none md:rounded-l-2xl brightness-100 group-hover:brightness-75 transition duration-300"
          />
        )}
      </div>

      <div className="col-span-8 bg-black p-5 rounded-b-2xl rounded-t-none md:rounded-l-none md:rounded-r-2xl min-h-[236px] md:min-h-auto">
        <h2
          className="text-primary text-2xl leading-6 font-medium mb-4 group-hover:opacity-70 transition duration-300"
          dangerouslySetInnerHTML={sanitizeForReact(post?.title)}
          title={sanitize(post?.title)}
        />
        <div className="grid grid-cols-2 gap-x-4 gap-y-3 group-hover:opacity-70 transition duration-300">
          {post?.jsonData?.newOrganization?.name && (
            <ShuffleUserInfo
              infoTitle={"New Company"}
              infoData={post?.jsonData?.newOrganization?.name}
            />
          )}

          {post?.jsonData?.oldOrganization?.name && (
            <ShuffleUserInfo
              infoTitle={"Former Company"}
              infoData={post?.jsonData?.oldOrganization?.name}
            />
          )}

          {post?.jsonData?.newOrganization?.role && (
            <ShuffleUserInfo
              infoTitle={"New Position"}
              infoData={post?.jsonData?.newOrganization?.role}
            />
          )}

          {post?.jsonData?.oldOrganization?.role && (
            <ShuffleUserInfo
              infoTitle={"Former Position"}
              infoData={post?.jsonData?.oldOrganization?.role}
            />
          )}
        </div>
      </div>
    </PostLink>
  );
}
