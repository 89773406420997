export function CityverseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83313 16.807C5.34742 16.1835 4.95335 15.4867 4.67231 14.7336H23.7312C23.8595 14.1817 23.9496 13.6145 24 13.0367H4.24769C4.2034 12.6967 4.17896 12.3507 4.17896 12C4.17896 11.6493 4.20187 11.3017 4.24769 10.9633H23.9985C23.9496 10.3855 23.8595 9.81832 23.7297 9.26642H4.67231C4.95335 8.51328 5.34742 7.81502 5.83313 7.19299H23.0393C21.1819 2.95769 16.948 0 12.0221 0C5.38255 0 0 5.37263 0 12C0 18.6274 5.38255 24 12.0221 24C16.948 24 21.1819 21.0423 23.0393 16.8085H5.83313V16.807ZM12.0221 4.17279C13.6381 4.17279 15.138 4.66065 16.3859 5.49613H7.65684C8.90473 4.66065 10.4062 4.17279 12.0206 4.17279H12.0221ZM12.0221 19.8272C10.4062 19.8272 8.90626 19.3393 7.65837 18.5039H16.3874C15.1396 19.3393 13.6381 19.8272 12.0237 19.8272H12.0221Z"
        fill="currentColor"
      />
    </svg>
  );
}
