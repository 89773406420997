import { MediaTile } from "./MediaTile";
import { useSlideContent } from "../../utils/useSlideContent";
import { useDetectMobile } from "../../utils/isMobile";
import { MediaLoading } from "./MediaLoading";
import type { PostType } from "../../types";

type MediaKeepExploring = {
  post: PostType;
};

type MediaKeepExploringProps = {
  currentId: number;
};

export function MediaKeepExploring({ currentId }: MediaKeepExploringProps) {
  const { isMobile } = useDetectMobile();
  const postCount = isMobile ? 1 : 3;
  const { posts, isLoading, isFetching } = useSlideContent<MediaKeepExploring>({
    requestUrl: "posts?tagIds=2267",
    count: postCount,
    excludeId: currentId,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <div>
      <h3 className="text-primary text-2xl font-bold font-montserrat py-3 border-t border-b border-[#CACACA] mt-7">
        Keep Exploring
      </h3>
      <div className="flex flex-col w-full mb-4 lg:mb-0">
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-x-5 mb-4 mt-6">
          {(loading ? Array.from(new Array(postCount)) : posts).map((post, i) =>
            post ? (
              <MediaTile key={post.id} post={post} modalSection={true} />
            ) : (
              <MediaLoading key={i} />
            )
          )}
        </div>
      </div>
    </div>
  );
}
