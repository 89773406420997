import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { queryRequest } from "../../utils/request";

const pageBuffer = 1;

type useDailyEditionSlideProps = {
  count?: number;
};

type useDailyEditionSlideReturnType<T> = {
  posts: T[];
  isLoading: boolean;
  isFetching: boolean;
  slideForward: () => void;
  slideBack: () => void;
  slideBackDisabled: boolean;
  slideForwardDisabled: boolean;
};

export function useDailyEditionSlide<T>({
  count = 4,
}: useDailyEditionSlideProps): useDailyEditionSlideReturnType<T> {
  const [page, setPage] = useState(1);
  const [displayIndex, setDisplayIndex] = useState(0);
  const [allPosts, setAllPosts] = useState([]);

  const buffer = pageBuffer * count;
  const queryCount = count * 2 + buffer;
  const queryKey = [queryCount, page];
  const queryFn = useCallback(
    () =>
      queryRequest({
        path: "newsletters",
      }),
    [queryCount, page]
  );

  const {
    data: postData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (postData?.data.items.length) {
      setAllPosts([...allPosts, ...postData?.data.items]);
    }
  }, [postData]);

  useEffect(() => {
    if (allPosts.length && displayIndex + count + buffer === allPosts.length) {
      setPage(page + 1);
    }
  }, [displayIndex]);

  const slideForward = useCallback(() => {
    setDisplayIndex(displayIndex + count);
  }, [displayIndex, count]);

  const slideBack = useCallback(() => {
    setDisplayIndex(displayIndex - count);
  }, [displayIndex, count]);

  const posts = useMemo(() => {
    return allPosts.slice(displayIndex, displayIndex + count);
  }, [displayIndex, allPosts]);

  return {
    posts,
    isLoading,
    isFetching,
    slideForward,
    slideBack,
    slideBackDisabled: displayIndex === 0,
    slideForwardDisabled: displayIndex + count >= allPosts.length,
  };
}
