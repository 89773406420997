export function NewsletterLogoMobile() {
  return (
    <svg
      width="254"
      height="53"
      viewBox="0 0 254 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.667 20.7739L85.6309 22.1447C84.3702 20.2728 82.2364 19.0973 79.7276 19.0973C75.4601 19.0973 72.2295 22.1857 72.2295 26.7457C72.2295 31.3057 75.4569 34.3941 79.7276 34.3941C82.2207 34.3941 84.3418 33.2564 85.6183 31.3593L87.6134 32.8152C85.9776 35.2386 83.0811 36.7355 79.7276 36.7355C73.9062 36.7355 69.6797 32.5505 69.6797 26.7457C69.6797 20.9409 73.9062 16.7559 79.7276 16.7559C83.1221 16.7559 86.1446 18.2811 87.667 20.7739Z"
        fill="white"
      />
      <path
        d="M114.062 36.4455H111.442L109.309 31.1512H99.9005L97.7794 36.4455H95.2422L103.128 17.0488H106.163L114.062 36.4455ZM100.827 28.8381H108.379L104.609 19.4565L100.827 28.8381Z"
        fill="white"
      />
      <path
        d="M134.83 19.3619H128.221V36.4455H125.781V19.3619H119.156V17.0488H134.83V19.3619Z"
        fill="white"
      />
      <path
        d="M161.031 36.4455H156.902L155.213 31.9296H146.939L145.25 36.4455H141.219L148.77 17.0488H153.482L161.034 36.4455H161.031ZM148.269 28.3685H153.883L151.084 20.8714L148.269 28.3685Z"
        fill="white"
      />
      <path
        d="M183.186 36.4455H169.066V17.0488H172.934V32.8309H183.19V36.4486L183.186 36.4455Z"
        fill="white"
      />
      <path
        d="M201.777 29.1028V36.4455H196.51V29.1721L189.191 17.0488H195.233L199.154 24.3789L203.062 17.0488H208.925L201.773 29.1028H201.777Z"
        fill="white"
      />
      <path
        d="M228.394 23.0456C227.117 22.324 225.191 21.4258 222.796 21.4258C221.381 21.4258 220.303 21.8134 220.303 22.6864C220.303 23.7799 222.285 23.8933 224.265 24.281L224.889 24.407C228.895 25.1823 230.931 27.1077 230.931 30.0889C230.931 34.9925 226.786 36.8076 222.657 36.8076C219.178 36.8076 216.02 35.9757 213.801 34.3968L216.379 30.1015C217.917 31.0312 219.773 32.0963 222.727 32.0963C224.142 32.0963 225.179 31.7087 225.179 30.7539C225.179 29.7423 223.972 29.5627 221.564 29.1467L220.829 29.0206C216.366 28.258 214.51 26.3325 214.608 22.8975C214.705 19.1852 217.822 16.7051 222.478 16.7051C225.015 16.7051 227.3 16.9698 230.666 18.7409L228.394 23.0488V23.0456Z"
        fill="white"
      />
      <path
        d="M253.599 21.8988H248.083V36.4486H242.816V21.8988H237.301V17.0488H253.599V21.8988Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M26.1157 52.2653C40.539 52.2653 52.2314 40.5745 52.2314 26.1532C52.2314 11.7318 40.539 0.0410156 26.1157 0.0410156C11.6924 0.0410156 0 11.7318 0 26.1532C0 40.5745 11.6924 52.2653 26.1157 52.2653Z"
        fill="#2BE2D8"
      />
      <path
        d="M11.8311 26.1532C11.8311 18.2646 18.2261 11.8705 26.1157 11.8705V0.0410156C11.6922 0.0410156 0 11.7316 0 26.1532C0 40.5747 11.6922 52.2653 26.1157 52.2653V40.4358C18.2261 40.4358 11.8311 34.0417 11.8311 26.1532Z"
        fill="#2BE2D8"
      />
      <path
        d="M26.1133 11.873V40.4384C34.0029 40.4384 40.3979 34.0442 40.3979 26.1557C40.3979 18.2672 34.0029 11.873 26.1133 11.873Z"
        fill="#2BE2D8"
      />
      <path
        d="M208.358 46.3381H212.119V46.9784H208.358V46.3381ZM208.44 49.6212H212.719V50.2717H207.688V43.1567H212.566V43.8072H208.44V49.6212Z"
        fill="white"
      />
      <path
        d="M215.198 50.2717V43.1567H218.095C218.854 43.1567 219.518 43.3092 220.087 43.6141C220.663 43.9122 221.111 44.329 221.429 44.8643C221.747 45.3996 221.907 46.0162 221.907 46.7142C221.907 47.4121 221.747 48.0288 221.429 48.5641C221.111 49.0994 220.663 49.5195 220.087 49.8244C219.518 50.1226 218.854 50.2717 218.095 50.2717H215.198ZM215.95 49.6212H218.054C218.685 49.6212 219.23 49.4992 219.691 49.2552C220.158 49.0045 220.521 48.6623 220.778 48.2286C221.036 47.7882 221.165 47.2834 221.165 46.7142C221.165 46.1382 221.036 45.6334 220.778 45.1997C220.521 44.766 220.158 44.4272 219.691 44.1833C219.23 43.9326 218.685 43.8072 218.054 43.8072H215.95V49.6212Z"
        fill="white"
      />
      <path
        d="M224.307 50.2717V43.1567H225.059V50.2717H224.307Z"
        fill="white"
      />
      <path
        d="M229.468 50.2717V43.8072H226.968V43.1567H232.721V43.8072H230.22V50.2717H229.468Z"
        fill="white"
      />
      <path
        d="M234.634 50.2717V43.1567H235.386V50.2717H234.634Z"
        fill="white"
      />
      <path
        d="M241.523 50.3326C240.987 50.3326 240.489 50.2446 240.029 50.0684C239.575 49.8854 239.178 49.6313 238.839 49.3061C238.507 48.974 238.247 48.5912 238.057 48.1575C237.874 47.717 237.782 47.2359 237.782 46.7142C237.782 46.1924 237.874 45.7147 238.057 45.281C238.247 44.8406 238.507 44.4577 238.839 44.1325C239.178 43.8004 239.575 43.5463 240.029 43.3701C240.483 43.1872 240.981 43.0957 241.523 43.0957C242.058 43.0957 242.553 43.1872 243.007 43.3701C243.461 43.5463 243.854 43.797 244.186 44.1223C244.525 44.4475 244.786 44.8304 244.968 45.2709C245.158 45.7113 245.253 46.1924 245.253 46.7142C245.253 47.2359 245.158 47.717 244.968 48.1575C244.786 48.598 244.525 48.9808 244.186 49.3061C243.854 49.6313 243.461 49.8854 243.007 50.0684C242.553 50.2446 242.058 50.3326 241.523 50.3326ZM241.523 49.6618C241.95 49.6618 242.343 49.5907 242.702 49.4484C243.068 49.2993 243.383 49.0926 243.647 48.8283C243.918 48.5573 244.128 48.2456 244.277 47.8932C244.426 47.5341 244.501 47.1411 244.501 46.7142C244.501 46.2873 244.426 45.8976 244.277 45.5453C244.128 45.1862 243.918 44.8744 243.647 44.6102C243.383 44.3391 243.068 44.1325 242.702 43.9902C242.343 43.8411 241.95 43.7665 241.523 43.7665C241.096 43.7665 240.699 43.8411 240.334 43.9902C239.968 44.1325 239.649 44.3391 239.378 44.6102C239.114 44.8744 238.904 45.1862 238.748 45.5453C238.599 45.8976 238.524 46.2873 238.524 46.7142C238.524 47.1343 238.599 47.5239 238.748 47.8831C238.904 48.2422 239.114 48.5573 239.378 48.8283C239.649 49.0926 239.968 49.2993 240.334 49.4484C240.699 49.5907 241.096 49.6618 241.523 49.6618Z"
        fill="white"
      />
      <path
        d="M247.65 50.2717V43.1567H248.27L253.18 49.3366H252.855V43.1567H253.597V50.2717H252.977L248.077 44.0918H248.403V50.2717H247.65Z"
        fill="white"
      />
      <path
        d="M169.188 50.2712V43.1562H172.298C173.07 43.1562 173.748 43.3053 174.331 43.6035C174.913 43.9016 175.367 44.315 175.693 44.8435C176.018 45.3721 176.181 45.9955 176.181 46.7137C176.181 47.4252 176.018 48.0486 175.693 48.584C175.367 49.1125 174.913 49.5258 174.331 49.824C173.748 50.1221 173.07 50.2712 172.298 50.2712H169.188ZM170.509 49.1532H172.237C172.772 49.1532 173.233 49.0515 173.619 48.8482C174.012 48.6449 174.314 48.3603 174.524 47.9944C174.741 47.6285 174.849 47.2016 174.849 46.7137C174.849 46.2191 174.741 45.7922 174.524 45.433C174.314 45.0671 174.012 44.7825 173.619 44.5792C173.233 44.376 172.772 44.2743 172.237 44.2743H170.509V49.1532Z"
        fill="#2BE2D8"
      />
      <path
        d="M177.191 50.2712L180.393 43.1562H181.694L184.906 50.2712H183.523L180.769 43.8576H181.297L178.553 50.2712H177.191ZM178.665 48.6246L179.02 47.5879H182.863L183.218 48.6246H178.665Z"
        fill="#2BE2D8"
      />
      <path
        d="M186.522 50.2712V43.1562H187.843V50.2712H186.522Z"
        fill="#2BE2D8"
      />
      <path
        d="M190.479 50.2712V43.1562H191.801V49.1532H195.521V50.2712H190.479Z"
        fill="#2BE2D8"
      />
      <path
        d="M198.279 50.2712V47.4557L198.574 48.2689L195.484 43.1562H196.897L199.377 47.2728H198.584L201.085 43.1562H202.386L199.296 48.2689L199.601 47.4557V50.2712H198.279Z"
        fill="#2BE2D8"
      />
    </svg>
  );
}
