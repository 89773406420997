import MediaService from "../services/MediaService";

export function getMediaObjects(post, key) {
  let objects = [];
  for (let i in post.medias) {
    if (post.medias[i]["key"] === key) {
      objects.push(post.medias[i]);
    }
  }

  return objects.reverse();
}

export function getMediaItem(medias, key) {
  for (let i in medias) {
    if (medias[i]["key"] === key) {
      return medias[i];
    }
  }
  return null;
}

export function getPreviewItem(medias, key) {
  let item = getMediaItem(medias, key);
  if (!item) {
    return null;
  }
  return {
    id: item?.media?.id,
    caption: item?.media?.caption,
    preview: item?.media?.cdn_source_url,
  };
}

export function getPreviewItems(medias, keys) {
  let items = {};
  for (let i in keys) {
    let item = getPreviewItem(medias, keys[i]);
    if (item) {
      items[keys[i]] = item;
    }
  }
  return items;
}

export function getMediaPreviewUrl(medias, key) {
  const item = getMediaItem(medias, key);
  return item ? item["media"]["cdn_source_url"] : "";
}

export function getMediaId(medias, key) {
  const item = getMediaItem(medias, key);
  return item ? item["id"] : "";
}

export function getImageMultiValues(medias, name, number) {
  let objects = {};
  for (let i = 0; i < number; i++) {
    for (let j = 0; j < medias.length; j++) {
      let indexName = name.replace("{index}", i);
      if (indexName === medias[j]["key"]) {
        objects[i] = medias[j];
      }
    }
  }
  return objects;
}

export function parseMediaValue(name, value, order = 0) {
  let newMedia = {};
  if (value) {
    if (value?.files && value.files.length > 0) {
      newMedia[name] = {
        files: value?.files,
        caption: value?.caption,
        order: order,
      };
    } else if (value?.id) {
      newMedia[name] = {
        id: value?.id,
        files: null,
        caption: value?.caption,
        order: order,
      };
    }
  }
  return newMedia;
}

export async function uploadMedia(media, currentPost = null) {
  for (const i in media) {
    const newMediaFile =
      media[i]?.files && media[i]?.files.length > 0 ? media[i].files[0] : null;
    let order = media?.order ? media?.order : 0;
    if (newMediaFile) {
      let uploadId = null;
      const extension = newMediaFile.name.split(".").pop();
      const mediaResponse = await MediaService.create({
        name: newMediaFile.name,
        caption: media[i]?.caption,
        mediaExtension: extension,
      });
      const signedUrl = mediaResponse.data.response.signedUrl;
      const sourceId = mediaResponse.data.response.id;
      const azureResponse = await MediaService.uploadToAzure(
        signedUrl,
        newMediaFile
      );
      if (azureResponse.status >= 200 && azureResponse.status <= 301) {
        uploadId = sourceId;
      }
      if (uploadId && currentPost) {
        for (let j in currentPost.medias) {
          if (currentPost.medias[j].key === i) {
            await MediaService.removePostMedia(
              currentPost.id,
              currentPost.medias[j].id,
              i
            );
          }
        }
        await MediaService.setPostMedia(currentPost.id, uploadId, i, order);
      }
    } else {
      await MediaService.update(media[i].id, { caption: media[i]?.caption });
      await MediaService.removePostMedia(currentPost.id, media[i].id, i);
      await MediaService.setPostMedia(currentPost.id, media[i].id, i, order);
    }
  }
}

export async function uploadMediaFile(mediaFile) {
  let fileId = null;
  let fileUrl = null;
  if (mediaFile) {
    const extension = mediaFile.name.split(".").pop();
    const mediaResponse = await MediaService.create({
      mediaExtension: extension,
    });
    const signedUrl = mediaResponse.data.response.signedUrl;
    const sourceId = mediaResponse.data.response.id;
    fileUrl = mediaResponse.data.response?.cdn_source_url;
    const azureResponse = await MediaService.uploadToAzure(
      signedUrl,
      mediaFile
    );
    if (azureResponse.status >= 200 && azureResponse.status <= 301) {
      fileId = sourceId;
    }
  }
  return {
    fileId: fileId,
    fileUrl: fileUrl,
  };
}
