import Skeleton from "@mui/material/Skeleton";

type PartnerLoadingProps = {
  index: number;
  isMobile?: Boolean;
};

export function PartnerLoading({ index, isMobile }: PartnerLoadingProps) {
  const height = index > 1 ? 313 : 470;
  const colSpan = index > 1 ? "md:col-span-3" : "md:col-span-6";

  return (
    <div className={`${colSpan} col-span-12`}>
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="rounded-t-2xl bg-red-50"
        animation="pulse"
        height={isMobile ? 357 : height}
        variant="rectangular"
      />
    </div>
  );
}
