import { useForm } from "react-hook-form";
import { Post } from "../../../utils/models";
import TextInput from "../../components/Inputs/TextInput";
import Wysiwyg from "../../components/Inputs/Wysiwyg";
import { CategoryEditor } from "../../components/Sections/CategoryEditor";
import { Section } from "../../components/Layout/Section";
import { Publish } from "../../components/Sections/Publish";
import { Admin } from "../../components/Sections/Admin";
import Image from "../../components/Inputs/Image";
import Title from "../../components/Title";
import { Suspense, useState } from "react";
import Alert from "../../components/Alert";
import { sanitizeData } from "../../utils/forms";
import ZapService from "../../services/ZapService";
import { handleRedirect } from "../../utils/redirects";
import { parseMediaValue, uploadMedia } from "../../utils/media";
import { SEO } from "../../components/Sections/SEO";
import Spinner from "../../components/Spinner";

export function Create() {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Post>();

  const [isLoading, setLoading] = useState("");
  const [response, setResponse] = useState(null);
  const [redirect, setRedirect] = useState(null);

  handleRedirect(redirect);

  // Media
  const [media, setMedia] = useState({});
  const setMediaItem = (name, value, order = 0) => {
    let newMedia = parseMediaValue(name, value, order);
    if (newMedia) {
      for (let i in newMedia) {
        media[i] = newMedia[i];
      }
    }
    setMedia(media);
  };
  // SEO Section
  const setSeoValue = (name, value) => {
    if ("object" === typeof value) {
      for (let i in value) {
        if (value[i] instanceof File) {
          setMediaItem(i, { files: [value[i]] });
        }
      }
    }
  };

  const onSubmit = async (data) => {
    setLoading(data["status"]);
    try {
      const postResponse = await ZapService.create(sanitizeData(data));
      await uploadMedia(media, postResponse.data.response);
      setLoading("");
      setResponse({
        type: "success",
        message:
          data["status"] === "draft"
            ? "Zap published as draft successfully."
            : "Zap published successfully.",
      });
      setTimeout(() => {
        setRedirect({ post: postResponse.data.response });
      }, 2000);
      reset();
    } catch (e: any) {
      setResponse({ type: "danger", message: e.message });
      setLoading("");
    }
  };

  return (
    <Suspense
      fallback={
        <div className="text-center space-y-4">
          <Spinner />
        </div>
      }
    >
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <Title text="Add new Zap" />

        {response && <Alert type={response.type} text={response.message} />}

        <TextInput
          key="title"
          label=""
          name="title"
          placeholder="Add title"
          register={register}
          errors={errors}
        />

        <Wysiwyg
          name="htmlContent"
          setValue={(n, v) => {
            setValue(n, v);
          }}
        />

        <Section label="Publish">
          <Publish
            label={""}
            setValue={setValue}
            getValues={getValues}
            loading={isLoading}
          />
        </Section>
        <Section label="Featured Image">
          <div className="p-3">
            <Image
              name="featured_image_full"
              register={register}
              setValue={setMediaItem}
            />
          </div>
        </Section>

        <Section label="Categories">
          <CategoryEditor name="categories" setValue={setValue} />
        </Section>

        <Section label="Admin">
          <Admin
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            register={register}
          />
        </Section>
        <Section label="SEO">
          <SEO
            name="meta"
            register={register}
            setValue={setSeoValue}
            errors={errors.meta}
          />
        </Section>
      </form>
    </Suspense>
  );
}
