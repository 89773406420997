import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { ZapIcon } from "./ZapIcon";
import { Link } from "react-router-dom";

type ZapTileProps = {
  id: number;
  title: string;
};

export function ZapTile({ id, title }: ZapTileProps) {
  return (
    <Link
      className="flex items-center space-x-5 p-5 overflow-hidden h-20 cursor-pointer group"
      to={`/zap/${id}`}
      state={{ id, type: "modal" }}
    >
      <div>
        <ZapIcon />
      </div>
      <div
        title={sanitize(title)}
        className="font-medium text-lg md:text-base md:leading-6 leading-7 text-left line-clamp-2 group-hover:opacity-70 transition duration-300"
        dangerouslySetInnerHTML={sanitizeForReact(title)}
      />
    </Link>
  );
}
