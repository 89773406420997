import { sanitize } from "../../utils/sanitizeHtml";
import { PostLink } from "../../components/PostLink";
import influencerSillhouette from "./influencer-sillhouette.svg";
import influencerBackgroundSm from "./influencer-background-sm.svg";
import type { InfluencerType } from "../../types";

type InfluencerTileProps = {
  post: InfluencerType;
  modalSection?: Boolean;
};

export function InfluencerTile({
  post,
  modalSection = false,
}: InfluencerTileProps) {
  return (
    <PostLink
      slug={`influencer/${post?.slug}`}
      preloadData={post}
      state={{ slug: post?.slug, type: "modal" }}
      title={sanitize(post?.title)}
    >
      <div
        className={`group w-full ${modalSection ? "rounded-2xl bg-cover" : ""}`}
        style={{
          backgroundImage: modalSection ? `url(${influencerBackgroundSm})` : ``,
        }}
      >
        {post?.featuredImage ? (
          <img
            alt={sanitize(post?.title)}
            src={post?.featuredImage}
            title={sanitize(post?.title)}
            className={`left-0 right-0 m-auto bottom-0 brightness-100 group-hover:brightness-75 transition duration-300 ${
              modalSection
                ? "h-[240px] pt-6 object-cover"
                : "absolute left-28 md:left-0 h-[350px] xl:w-auto xl:max-h-[350px] object-cover md:object-contain xl:left-56"
            }`}
          />
        ) : (
          <img
            alt={sanitize(post?.title)}
            src={influencerSillhouette}
            title={sanitize(post?.title)}
            className={`left-0 right-0 m-auto top-1/3 md:top-[30%] xl:top-[15%] brightness-100 group-hover:brightness-75 transition duration-300 ${
              modalSection
                ? "h-[240px] pt-6 object-cover"
                : "absolute left-28 md:left-0 h-[600px] xl:w-[280px] xl:max-h-[350px] object-cover md:object-contain xl:left-56"
            }`}
          />
        )}
      </div>
      {!modalSection ? (
        <div className="max-w-screen-sm xl:max-w-[336px] z-20 relative">
          <h2
            className="text-7xl xl:text-6xl leading-none font-semibold uppercase relative mb-7 group-hover:opacity-60 transition duration-300"
            title={sanitize(post?.title)}
          >
            <span className="text-white w-full">{sanitize(post?.title)}</span>
            <span className="text-primary [text-shadow:_-2px_2px_2px_rgb(0_0_0_/_100%)] absolute bottom-1 left-1 w-full">
              {sanitize(post?.title)}
            </span>
          </h2>
          <p className="text-white text-md leading-6 group-hover:opacity-60 transition duration-300 max-w-[50%] md:max-w-none">
            {post?.jsonData?.influencerJobTitle && (
              <span className="text-primary">
                {post?.jsonData?.influencerJobTitle}
              </span>
            )}
            <br />
            {post?.jsonData?.influencerCompany && (
              <span>{post?.jsonData?.influencerCompany} </span>
            )}
          </p>
        </div>
      ) : (
        <div>
          <h2
            className="text-xl md:text-2xl font-semibold leading-8 text-primary mt-2 mb-8 md:mb-0"
            title={sanitize(post?.title)}
          >
            {sanitize(post?.title)}
          </h2>
        </div>
      )}
    </PostLink>
  );
}
