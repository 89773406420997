import TextInput from "../Inputs/TextInput";
import { useState } from "react";
import Button from "../Inputs/Button";

const DataSearch = ({ onInput }) => {
  const [searchInput, setSearchInput] = useState("");

  return (
    <div className="inline">
      <div className="flex">
        <div>
          <TextInput
            label=""
            key="search"
            name="search"
            size="default"
            inputClassName="mr-2"
            setValue={(name, value) => {
              setSearchInput(value);
            }}
          />
        </div>
        <div>
          <Button
            key="search_btn"
            label="Search posts"
            onClick={() => {
              onInput(searchInput);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DataSearch;
