import { ReactNode, ReactElement } from "react";

export type TabPanelProps = {
  activeTabId?: string | null;
  addClassName?: string;
  children: ReactNode;
  forTabId: string;
  hideInactiveTabs?: boolean;
};

export const TabPanel = ({
  activeTabId = null,
  addClassName = "",
  children,
  forTabId,
  hideInactiveTabs = false,
}: TabPanelProps): ReactElement | null => {
  const active = activeTabId === null || activeTabId === forTabId;

  if (!active && !hideInactiveTabs) return null;

  return (
    <div
      className={`outline-none ${!active && "hidden"} ${addClassName}`}
      aria-labelledby={forTabId}
      role="tabpanel"
      tabIndex={active ? 0 : -1}
    >
      {children}
    </div>
  );
};
