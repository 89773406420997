import { Badge } from "../../components/Badge";
import { SectionHeader } from "../../components/SectionHeader";
import { PostsResponse } from "../../types";
import { Footer } from "../Footer";
import { useParams } from "react-router-dom";
import { queryRequest } from "../../utils/request";
import { useQuery } from "react-query";
import { ArticleTile, ArticleTileLoading } from "../../components/ArticleTile";
import { titleCase } from "../../journalist/utils/strings";
import { CircularProgress } from "@mui/material";

const getPostsByTagId = ({ queryKey }) => {
  const [_, { tagId }] = queryKey;
  return queryRequest({
    path: "news/posts",
    params: `?tagIds=${tagId}`,
  });
};

const TaggedContent = () => {
  const { tagId } = useParams();
  const { data, isLoading } = useQuery<PostsResponse>(
    ["posts", { tagId }],
    getPostsByTagId
  );

  const tagName = titleCase(
    (
      (data?.data?.posts ?? []).find((post) => {
        return post.tags.some((tag) => tag.id.toString() === tagId);
      })?.tags ?? []
    ).find((tag) => tag.id.toString() === tagId)?.name ?? ""
  );

  const showNoResults =
    !isLoading && (!data?.data?.posts || data.data.posts.length === 0);

  if (isLoading) {
    return (
      <div className="h-full w-full flex flex-col items-center">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <>
      <div className="grid lg:grid-cols-8 gap-5 p-5 md:p-0">
        <div className="lg:col-span-6">
          <SectionHeader
            title={
              <span className="bg-black text-white border border-black rounded-full py-1 px-4 text-lg">
                {tagName}
              </span>
            }
          />

          <div className="border-t border-t-gray divide-y">
            {showNoResults ? (
              <div className="flex items-center justify-between mb-3 pt-0 md:pt-5 lg:pt-0">
                <a href="/news">
                  No posts were found for this tag. Click here to go back home.
                </a>
              </div>
            ) : (
              data.data.posts.map((post, i) =>
                post ? (
                  <ArticleTile
                    isTaggedTile
                    key={post.id}
                    post={post}
                    badge={
                      <Badge appearance="dark" title="impact" size="text-xs" />
                    }
                  />
                ) : (
                  <ArticleTileLoading key={i} />
                )
              )
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TaggedContent;
