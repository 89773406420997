import { useEffect, useState } from "react";
import TextInput from "../Inputs/TextInput";
import { TrashIcon } from "../Icons";
import Button from "../Inputs/Button";
import DropdownAuthors from "../Inputs/DropdownAuthors";
import { slugify } from "../../utils/strings";

export function Admin({
  post = null,
  register = null,
  setValue = null,
  getValues = null,
  watch = null,
  onDelete = null,
}) {
  const [selectedAuthor, setSelectedAuthor] = useState(
    post ? post.author : null
  );
  const [postSlug, setPostSlug] = useState(post ? post.slug : "");

  // Watch the form input only for new posts.
  // It doesn't make sense to change the slug
  // on every title change for existing posts.
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!post) {
        if ("title" === name) {
          const slug = slugify(value.title);
          setPostSlug(slug);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setValue("slug", postSlug);
  }, [postSlug]);

  const handleAuthorChange = (name, value) => {
    setSelectedAuthor(value);
    if (setValue) {
      setValue("author", value);
    }
  };

  const handleSlugInput = (name, value) => {
    const slug = slugify(value);
    setPostSlug(slug);
  };

  const deleteArticle = () => {
    if (confirm("Are you sure you want to do this?")) {
      if (onDelete) {
        onDelete(post);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-4 p-4">
        <div className="w-full lg:w-2/6">
          <DropdownAuthors
            label=""
            className="w-full"
            value={selectedAuthor}
            name="more_authors"
            placeholder="Select author"
            setValue={handleAuthorChange}
          />
        </div>

        <div className="w-full lg:w-3/6">
          <div className="flex items-center">
            <span className="w-1/6">URL / Slug:</span>
            <TextInput
              className="w-5/6 mb-0 pb-0"
              label=""
              setValue={handleSlugInput}
              name="slug"
              register={register}
              required={false}
            ></TextInput>
          </div>
        </div>

        <div
          className={`w-full lg:w-1/6 text-right ${postSlug ? "" : "hidden"}`}
        >
          <Button
            type="button"
            label="Delete Article"
            theme="secondary"
            size="medium"
            onClick={deleteArticle}
          >
            <TrashIcon />
          </Button>
        </div>
      </div>
    </>
  );
}
