import { StateColors } from "../constants";

export function Bars() {
  return (
    <svg
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.16602 4.66732H12.8327M1.16602 1.33398H12.8327"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EyeOpen() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3327 6.99952C15.8769 9.9797 12.2306 12.8327 8.99915 12.8327C5.76769 12.8327 2.1214 9.9797 0.666016 6.99916"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3327 6.99952C15.8769 4.01934 12.2312 1.16602 8.99973 1.16602C5.76827 1.16602 2.1214 4.01863 0.666016 6.99916"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4993 6.99935C11.4993 8.38006 10.3801 9.49935 8.99935 9.49935C7.61864 9.49935 6.49935 8.38006 6.49935 6.99935C6.49935 5.61864 7.61864 4.49935 8.99935 4.49935C10.3801 4.49935 11.4993 5.61864 11.4993 6.99935Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function Exit() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8.66602L9.66667 6.99935M9.66667 6.99935L11.3333 5.33268M9.66667 6.99935L8 5.33268M9.66667 6.99935L11.3333 8.66602M0.5 6.99935L5.84518 12.3445C6.15774 12.6571 6.58166 12.8327 7.02369 12.8327H13.8333C14.7538 12.8327 15.5 12.0865 15.5 11.166V2.83268C15.5 1.91221 14.7538 1.16602 13.8333 1.16602H7.02369C6.58166 1.16602 6.15774 1.34161 5.84518 1.65417L0.5 6.99935Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Bolt() {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3327 0.5H3.666L1.16602 8H5.33267L3.666 16.3333L14.4993 4.66667H9.08267L10.3327 0.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Speedometer() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8076 5.51417C15.252 6.47241 15.5 7.54025 15.5 8.66602C15.5 9.88976 15.2069 11.0451 14.6871 12.0655C14.4347 12.5611 13.9032 12.8327 13.3471 12.8327H2.65292C2.09677 12.8327 1.56532 12.5611 1.31289 12.0655C0.793087 11.0451 0.5 9.88976 0.5 8.66602C0.5 4.52388 3.85786 1.16602 8 1.16602C9.10259 1.16602 10.1496 1.40394 11.0925 1.83126M8.58925 8.07686C8.26382 7.75142 7.73618 7.75142 7.41074 8.07686C7.08531 8.4023 7.08531 8.92993 7.41074 9.25537C7.73618 9.58081 8.26382 9.58081 8.58925 9.25537C8.91469 8.92993 8.91469 8.4023 8.58925 8.07686ZM8.58925 8.07686L13.8926 2.77356"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EditLine() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5007 2.41602L12.584 4.49935M7.16732 13.666H14.6673M1.33398 13.666V11.5827L11.959 0.957683C12.5343 0.382386 13.467 0.382385 14.0423 0.957682V0.957682C14.6176 1.53298 14.6176 2.46572 14.0423 3.04102L3.41732 13.666H1.33398Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Edit() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6935 2.36019L13.6398 5.30647M11.9435 1.11019C12.7571 0.296602 14.0762 0.296602 14.8898 1.11019C15.7034 1.92379 15.7034 3.24288 14.8898 4.05647L3.41667 15.5296H0.5V12.5537L11.9435 1.11019Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Add() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1V11M11 6H1"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Times() {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.73303 4.00006L6.88664 1.84727C6.94739 1.78653 6.99557 1.71441 7.02845 1.63503C7.06133 1.55566 7.07825 1.47059 7.07825 1.38468C7.07825 1.29877 7.06133 1.2137 7.02845 1.13432C6.99557 1.05495 6.94739 0.982831 6.88664 0.922082C6.82589 0.861333 6.75377 0.813144 6.6744 0.780267C6.59502 0.74739 6.50995 0.730469 6.42404 0.730469C6.33813 0.730469 6.25306 0.74739 6.17369 0.780267C6.09431 0.813144 6.02219 0.861333 5.96144 0.922082L3.80866 3.07569L1.65587 0.922082C1.53318 0.799394 1.36678 0.730469 1.19327 0.730469C1.01976 0.730469 0.853364 0.799394 0.730676 0.922082C0.607988 1.04477 0.539062 1.21117 0.539062 1.38468C0.539062 1.55819 0.607988 1.72459 0.730676 1.84727L2.88428 4.00006L0.730676 6.15285C0.669927 6.2136 0.621738 6.28572 0.588861 6.36509C0.555984 6.44446 0.539062 6.52953 0.539062 6.61545C0.539062 6.70136 0.555984 6.78643 0.588861 6.8658C0.621738 6.94517 0.669927 7.01729 0.730676 7.07804C0.791425 7.13879 0.863544 7.18698 0.942917 7.21986C1.02229 7.25273 1.10736 7.26966 1.19327 7.26966C1.27918 7.26966 1.36426 7.25273 1.44363 7.21986C1.523 7.18698 1.59512 7.13879 1.65587 7.07804L3.80866 4.92444L5.96144 7.07804C6.02219 7.13879 6.09431 7.18698 6.17369 7.21986C6.25306 7.25273 6.33813 7.26966 6.42404 7.26966C6.50995 7.26966 6.59502 7.25273 6.6744 7.21986C6.75377 7.18698 6.82589 7.13879 6.88664 7.07804C6.94739 7.01729 6.99557 6.94517 7.02845 6.8658C7.06133 6.78643 7.07825 6.70136 7.07825 6.61545C7.07825 6.52953 7.06133 6.44446 7.02845 6.36509C6.99557 6.28572 6.94739 6.2136 6.88664 6.15285L4.73303 4.00006Z"
        fill="white"
      />
    </svg>
  );
}

export function ImageIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="48"
        height="40"
        viewBox="0 0 48 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.3077 0H3.69231C2.71305 0 1.77389 0.383116 1.08145 1.06507C0.38901 1.74702 0 2.67194 0 3.63636V36.3636C0 37.3281 0.38901 38.253 1.08145 38.9349C1.77389 39.6169 2.71305 40 3.69231 40H44.3077C45.287 40 46.2261 39.6169 46.9185 38.9349C47.611 38.253 48 37.3281 48 36.3636V3.63636C48 2.67194 47.611 1.74702 46.9185 1.06507C46.2261 0.383116 45.287 0 44.3077 0ZM44.3077 3.63636V26.9886L38.2915 21.0659C37.9487 20.7282 37.5416 20.4602 37.0936 20.2774C36.6455 20.0946 36.1653 20.0005 35.6804 20.0005C35.1954 20.0005 34.7152 20.0946 34.2672 20.2774C33.8192 20.4602 33.4121 20.7282 33.0692 21.0659L28.4538 25.6114L18.3 15.6114C17.6076 14.9299 16.6688 14.5471 15.69 14.5471C14.7112 14.5471 13.7724 14.9299 13.08 15.6114L3.69231 24.8568V3.63636H44.3077ZM3.69231 30L15.6923 18.1818L34.1538 36.3636H3.69231V30ZM44.3077 36.3636H39.3762L31.0685 28.1818L35.6838 23.6364L44.3077 32.1318V36.3636ZM27.6923 13.6364C27.6923 13.097 27.8547 12.5697 28.159 12.1212C28.4633 11.6727 28.8958 11.3231 29.4018 11.1167C29.9078 10.9103 30.4646 10.8563 31.0018 10.9615C31.539 11.0667 32.0324 11.3265 32.4197 11.7079C32.807 12.0893 33.0707 12.5753 33.1776 13.1043C33.2844 13.6333 33.2296 14.1817 33.02 14.68C32.8104 15.1784 32.4554 15.6043 32 15.904C31.5446 16.2037 31.0092 16.3636 30.4615 16.3636C29.7271 16.3636 29.0227 16.0763 28.5034 15.5648C27.9841 15.0534 27.6923 14.3597 27.6923 13.6364Z"
          fill="#005CB2"
        />
      </svg>
    </div>
  );
}

export function VideoIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="48"
        height="34"
        viewBox="0 0 48 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 2C2.89543 2 2 2.89543 2 4L2 30C2 31.1046 2.89543 32 4 32H29.3333C30.4379 32 31.3333 31.1046 31.3333 30V4C31.3333 2.89543 30.4379 2 29.3333 2H4Z"
          stroke="#005CB2"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.7005 15.6018C31.9405 16.379 31.9405 17.621 32.7005 18.3982L44.285 30.246C44.9116 30.8869 46 30.4432 46 29.5469V4.4531C46 3.55677 44.9116 3.1131 44.285 3.75398L32.7005 15.6018Z"
          stroke="#005CB2"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function AudioIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="29"
        height="40"
        viewBox="0 0 29 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 18.9412C2 25.9584 7.59644 31.6471 14.5 31.6471M14.5 31.6471C21.4036 31.6471 27 25.9584 27 18.9412M14.5 31.6471V38M14.5 23.1765V23.1765C16.8012 23.1765 18.6667 21.311 18.6667 19.0098V6.16667C18.6667 3.86548 16.8012 2 14.5 2V2C12.1988 2 10.3333 3.86548 10.3333 6.16667V19.0098C10.3333 21.311 12.1988 23.1765 14.5 23.1765Z"
          stroke="#005CB2"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function TrashIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 5V15C2 16.1046 2.89543 17 4 17H11C12.1046 17 13 16.1046 13 15V5M2 5H1M2 5H4M13 5H14M13 5H11M6 9V11V13M4 5V3C4 1.89543 4.89543 1 6 1H9C10.1046 1 11 1.89543 11 3V5M4 5H11M9 9V11V13"
          stroke="#005CB2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function RemoveSignIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 8C16 9.58225 15.5308 11.129 14.6518 12.4446C13.7727 13.7602 12.5233 14.7855 11.0615 15.391C9.59966 15.9965 7.99113 16.155 6.43928 15.8463C4.88743 15.5376 3.46197 14.7757 2.34315 13.6569C1.22433 12.538 0.462403 11.1126 0.153721 9.56072C-0.15496 8.00887 0.00346627 6.40034 0.608967 4.93853C1.21447 3.47672 2.23985 2.22729 3.55544 1.34824C4.87103 0.469192 6.41775 0 8 0C10.121 0.00223986 12.1546 0.845814 13.6544 2.34562C15.1542 3.84542 15.9978 5.87895 16 8Z"
          fill="#AB000D"
        />
        <path
          d="M8.87109 7.99914L10.898 5.97299C10.9552 5.91581 11.0005 5.84793 11.0315 5.77323C11.0624 5.69853 11.0784 5.61846 11.0784 5.5376C11.0784 5.45674 11.0624 5.37668 11.0315 5.30197C11.0005 5.22727 10.9552 5.15939 10.898 5.10222C10.8408 5.04504 10.773 4.99969 10.6983 4.96874C10.6236 4.9378 10.5435 4.92188 10.4626 4.92188C10.3818 4.92188 10.3017 4.9378 10.227 4.96874C10.1523 4.99969 10.0844 5.04504 10.0272 5.10222L8.00109 7.12914L5.97494 5.10222C5.85947 4.98675 5.70286 4.92188 5.53955 4.92188C5.37625 4.92188 5.21964 4.98675 5.10417 5.10222C4.9887 5.21769 4.92383 5.3743 4.92383 5.5376C4.92383 5.7009 4.9887 5.85752 5.10417 5.97299L7.13109 7.99914L5.10417 10.0253C5.04699 10.0825 5.00164 10.1503 4.9707 10.225C4.93975 10.2998 4.92383 10.3798 4.92383 10.4607C4.92383 10.5415 4.93975 10.6216 4.9707 10.6963C5.00164 10.771 5.04699 10.8389 5.10417 10.8961C5.16135 10.9532 5.22922 10.9986 5.30393 11.0295C5.37863 11.0605 5.4587 11.0764 5.53955 11.0764C5.62041 11.0764 5.70048 11.0605 5.77518 11.0295C5.84989 10.9986 5.91776 10.9532 5.97494 10.8961L8.00109 8.86914L10.0272 10.8961C10.0844 10.9532 10.1523 10.9986 10.227 11.0295C10.3017 11.0605 10.3818 11.0764 10.4626 11.0764C10.5435 11.0764 10.6236 11.0605 10.6983 11.0295C10.773 10.9986 10.8408 10.9532 10.898 10.8961C10.9552 10.8389 11.0005 10.771 11.0315 10.6963C11.0624 10.6216 11.0784 10.5415 11.0784 10.4607C11.0784 10.3798 11.0624 10.2998 11.0315 10.225C11.0005 10.1503 10.9552 10.0825 10.898 10.0253L8.87109 7.99914Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export function PlusSignIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0454 8C16.0454 9.58225 15.5749 11.129 14.6934 12.4446C13.8118 13.7602 12.5588 14.7855 11.0929 15.391C9.62692 15.9965 8.01382 16.155 6.45756 15.8463C4.90131 15.5376 3.4718 14.7757 2.3498 13.6569C1.22781 12.538 0.463716 11.1126 0.154158 9.56072C-0.1554 8.00887 0.00347611 6.40034 0.610696 4.93853C1.21792 3.47672 2.24621 2.22729 3.56554 1.34824C4.88486 0.469192 6.43597 0 8.02272 0C10.1498 0.00223986 12.1891 0.845814 13.6931 2.34562C15.1972 3.84542 16.0432 5.87895 16.0454 8Z"
          fill="#005CB2"
        />
        <path
          d="M8.64264 8.61564L11.5127 8.62026C11.5936 8.62037 11.6738 8.60453 11.7486 8.57365C11.8233 8.54277 11.8912 8.49745 11.9484 8.44027C12.0056 8.3831 12.0509 8.31519 12.0818 8.24042C12.1127 8.16566 12.1285 8.0855 12.1284 8.00453C12.1283 7.92356 12.1122 7.84335 12.0811 7.7685C12.05 7.69365 12.0045 7.62561 11.9472 7.56827C11.8898 7.51093 11.8218 7.46542 11.7469 7.43432C11.6721 7.40323 11.5919 7.38717 11.5109 7.38705L8.64089 7.38353L8.63737 4.5135C8.63714 4.34997 8.57195 4.19304 8.45615 4.07725C8.34035 3.96145 8.18343 3.89626 8.01989 3.89603C7.85636 3.8958 7.69962 3.96054 7.58415 4.07601C7.46868 4.19148 7.40393 4.34822 7.40417 4.51176L7.40878 7.38178L4.53875 7.37717C4.45778 7.37705 4.37762 7.39289 4.30286 7.42377C4.22809 7.45465 4.16018 7.49997 4.10301 7.55715C4.04583 7.61433 4.00051 7.68223 3.96963 7.757C3.93875 7.83177 3.92291 7.91192 3.92303 7.9929C3.92314 8.07387 3.9392 8.15407 3.9703 8.22893C4.00139 8.30378 4.0469 8.37182 4.10424 8.42916C4.16158 8.48649 4.22962 8.53201 4.30447 8.5631C4.37933 8.59419 4.45953 8.61026 4.5405 8.61037L7.41053 8.61389L7.41405 11.4839C7.41416 11.5649 7.43023 11.6451 7.46132 11.72C7.49241 11.7948 7.53793 11.8628 7.59527 11.9202C7.6526 11.9775 7.72064 12.023 7.7955 12.0541C7.87035 12.0852 7.95055 12.1013 8.03153 12.1014C8.1125 12.1015 8.19266 12.0857 8.26742 12.0548C8.34219 12.0239 8.4101 11.9786 8.46727 11.9214C8.52445 11.8642 8.56977 11.7963 8.60065 11.7216C8.63153 11.6468 8.64737 11.5666 8.64725 11.4857L8.64264 8.61564Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export function MinusSignIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0454 8C16.0454 9.58225 15.5749 11.129 14.6934 12.4446C13.8118 13.7602 12.5588 14.7855 11.0929 15.391C9.62692 15.9965 8.01382 16.155 6.45756 15.8463C4.90131 15.5376 3.4718 14.7757 2.3498 13.6569C1.22781 12.538 0.463716 11.1126 0.154158 9.56072C-0.1554 8.00887 0.00347611 6.40034 0.610696 4.93853C1.21792 3.47672 2.24621 2.22729 3.56554 1.34824C4.88486 0.469192 6.43597 0 8.02272 0C10.1498 0.00223986 12.1891 0.845814 13.6931 2.34562C15.1972 3.84542 16.0432 5.87895 16.0454 8Z"
          fill="#AB000D"
        />
        <path
          d="M8.64264 8.61564L11.5127 8.62026C11.5936 8.62037 11.6738 8.60453 11.7486 8.57365C11.8233 8.54277 11.8912 8.49745 11.9484 8.44027C12.0056 8.3831 12.0509 8.31519 12.0818 8.24042C12.1127 8.16566 12.1285 8.0855 12.1284 8.00453C12.1283 7.92356 12.1122 7.84335 12.0811 7.7685C12.05 7.69365 12.0045 7.62561 11.9472 7.56827C11.8898 7.51093 11.8218 7.46542 11.7469 7.43432C11.6721 7.40323 11.5919 7.38717 11.5109 7.38705L8.64089 7.38353L7.40878 7.38178L4.53875 7.37717C4.45778 7.37705 4.37762 7.39289 4.30286 7.42377C4.22809 7.45465 4.16018 7.49997 4.10301 7.55715C4.04583 7.61433 4.00051 7.68223 3.96963 7.757C3.93875 7.83177 3.92291 7.91192 3.92303 7.9929C3.92314 8.07387 3.9392 8.15407 3.9703 8.22893C4.00139 8.30378 4.0469 8.37182 4.10424 8.42916C4.16158 8.48649 4.22962 8.53201 4.30447 8.5631C4.37933 8.59419 4.45953 8.61026 4.5405 8.61037L7.41053 8.61389L8.64264 8.61564Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export function ArrowRightDirectionIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H15V16H8C3.58172 16 0 12.4183 0 8Z"
          fill="#005CB2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.18013 10.8199C6.93996 10.5797 6.93996 10.1903 7.18013 9.95013L9.20526 7.925L7.18013 5.89987C6.93996 5.6597 6.93996 5.2703 7.18013 5.03013C7.4203 4.78996 7.8097 4.78996 8.04987 5.03013L10.5099 7.49013C10.75 7.7303 10.75 8.1197 10.5099 8.35987L8.04987 10.8199C7.8097 11.06 7.4203 11.06 7.18013 10.8199Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export function ArrowLeftDirectionIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 8C15 3.58172 11.4183 0 7 0H0V16H7C11.4183 16 15 12.4183 15 8Z"
          fill="#005CB2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.81987 10.8199C8.06004 10.5797 8.06004 10.1903 7.81987 9.95013L5.79474 7.925L7.81987 5.89987C8.06004 5.6597 8.06004 5.2703 7.81987 5.03013C7.5797 4.78996 7.1903 4.78996 6.95013 5.03013L4.49013 7.49013C4.24996 7.7303 4.24996 8.1197 4.49013 8.35987L6.95013 10.8199C7.1903 11.06 7.5797 11.06 7.81987 10.8199Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export function PaginationBackward() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 1L1.5 7L7.5 13"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function PaginationForward() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 1L7.5 7L1.5 13"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function PaginationStart() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 1L1 7L7 13M14 1L8 7L14 13"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function PaginationEnd() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 1L14 7L8 13M1 1L7 7L1 13"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function FilterIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.334 8.228C11.0561 8.52258 10.9009 8.91201 10.9 9.317V14.3309C10.9 14.6652 10.7329 14.9774 10.4548 15.1629L9.25482 15.9631C8.59028 16.4063 7.7 15.9299 7.7 15.1312V9.317C7.70093 8.91056 7.54637 8.51915 7.268 8.223L2.07769 2.68375C1.47919 2.04502 1.93209 1 2.8074 1H15.7942C16.6692 1 17.1222 2.04454 16.5242 2.68339L11.334 8.228Z"
          stroke="#005CB2"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}

export function ScissorsTableIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.25 10.0323L9.03743 7.37659M6.8538 6L3.52778 3.90323M13.25 1.96774L3.52778 8.09677M4.22222 2.6129C4.22222 3.50369 3.44494 4.22581 2.48611 4.22581C1.52728 4.22581 0.75 3.50369 0.75 2.6129C0.75 1.72212 1.52728 1 2.48611 1C3.44494 1 4.22222 1.72212 4.22222 2.6129ZM4.22222 9.3871C4.22222 8.49631 3.44494 7.77419 2.48611 7.77419C1.52728 7.77419 0.75 8.49631 0.75 9.3871C0.75 10.2779 1.52728 11 2.48611 11C3.44494 11 4.22222 10.2779 4.22222 9.3871Z"
          stroke="#969696"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function CopyTableIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.98615 6.36133L4.017 2.09468C3.98755 1.96481 3.98923 1.83037 4.02192 1.7012C4.05461 1.57203 4.11749 1.4514 4.20593 1.34813C4.29438 1.24487 4.40617 1.16159 4.53311 1.1044C4.66005 1.0472 4.79893 1.01754 4.93958 1.01758L7.062 1.01888C7.2026 1.01893 7.34141 1.04865 7.46827 1.10588C7.59514 1.16311 7.70685 1.2464 7.79524 1.34965C7.88362 1.45291 7.94644 1.57351 7.9791 1.70264C8.01175 1.83177 8.01342 1.96616 7.98399 2.09598L7.01542 6.36264"
          stroke="#969696"
          strokeWidth="1.25"
        />
        <rect
          x="1.32422"
          y="6.79688"
          width="9.35386"
          height="2.67253"
          rx="1"
          stroke="#969696"
          strokeWidth="1.25"
        />
        <line
          x1="1.2793"
          y1="12.3516"
          x2="10.7194"
          y2="12.3516"
          stroke="#969696"
          strokeWidth="1.25"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

export function PreviewTableIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33203 3.58203C1.33203 2.47746 2.22746 1.58203 3.33203 1.58203H12.6654C13.7699 1.58203 14.6654 2.47746 14.6654 3.58203V10.4154C14.6654 11.5199 13.7699 12.4154 12.6654 12.4154H3.33203C2.22746 12.4154 1.33203 11.5199 1.33203 10.4154V3.58203Z"
        stroke="#969696"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99888 4.29036C9.2292 4.29036 10.5846 5.12384 11.4413 6.15908C11.8463 6.64841 11.8463 7.34914 11.4413 7.83845C10.5845 8.87365 9.22893 9.70703 7.9986 9.70703C6.76815 9.70703 5.4124 8.87348 4.55572 7.83805C4.15096 7.34884 4.15096 6.64838 4.55574 6.1592C5.4125 5.12381 6.76843 4.29036 7.99888 4.29036ZM7.9987 7.67578C8.36689 7.67578 8.66536 7.37264 8.66536 6.9987C8.66536 6.62476 8.36689 6.32161 7.9987 6.32161C7.63051 6.32161 7.33203 6.62476 7.33203 6.9987C7.33203 7.37264 7.63051 7.67578 7.9987 7.67578Z"
        stroke="#969696"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TrashTableIcon() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.41536 4.66536V13.1654C2.41536 13.9938 3.08694 14.6654 3.91537 14.6654H10.082C10.9105 14.6654 11.582 13.9938 11.582 13.1654V4.66536M2.41536 4.66536H1.58203M2.41536 4.66536H4.08203M11.582 4.66536H12.4154M11.582 4.66536H9.91536M5.7487 7.9987V9.66536V11.332M4.08203 4.66536V3.33203C4.08203 2.22746 4.97746 1.33203 6.08203 1.33203H7.91536C9.01993 1.33203 9.91536 2.22746 9.91536 3.33203V4.66536M4.08203 4.66536H9.91536M8.2487 7.9987V9.66536V11.332"
        stroke="#969696"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CommentsIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.265625"
          y="0.134766"
          width="15.7333"
          height="13.4857"
          rx="2"
          fill="black"
        />
        <path
          d="M6.44727 15.9051C6.44727 16.3323 6.94832 16.5627 7.27266 16.2847L10.3806 13.6208L6.44727 13.6208L6.44727 15.9051Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export function ViewsIcon() {
  return (
    <div className="inline-block image-icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7.99977" cy="7.99977" r="7.865" fill="black" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00121 4.59766C9.62609 4.59766 11.4247 5.75503 12.4862 7.14747C12.8713 7.65259 12.8713 8.34682 12.4862 8.85191C11.4246 10.2443 9.62574 11.4016 8.00086 11.4016C6.37585 11.4016 4.57684 10.2441 3.51538 8.85146C3.13049 8.34648 3.13049 7.65251 3.51541 7.14755C4.57695 5.75493 6.37619 4.59766 8.00121 4.59766Z"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99951 8.84941C8.462 8.84941 8.83691 8.46864 8.83691 7.99892C8.83691 7.52921 8.462 7.14844 7.99951 7.14844C7.53703 7.14844 7.16211 7.52921 7.16211 7.99892C7.16211 8.46864 7.53703 8.84941 7.99951 8.84941Z"
          fill="white"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function InfoAlertIcon() {
  return (
    <div className="inline-block">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 8V8.5M12 12V16M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke={StateColors.info}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function SuccessAlertIcon() {
  return (
    <div className="inline-block">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 8V8.5M12 12V16M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke={StateColors.success}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function WarningAlertIcon() {
  return (
    <div className="inline-block">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 8V8.5M12 12V16M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke={StateColors.warning}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function DangerAlertIcon() {
  return (
    <div className="inline-block">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 8V8.5M12 12V16M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke={StateColors.danger}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function LoadingIcon({ theme = "light" }) {
  let className = "w-3 h-3 mr-2 animate-spin";
  className +=
    theme === "light"
      ? "text-gray-200 dark:text-gray-600 fill-blue-600"
      : "text-gray-200 dark:text-gray-600 fill-blue-600";

  return (
    <div className="inline-block">
      <div role="status">
        <svg
          aria-hidden="true"
          className={className}
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export function ImageClear() {
  return (
    <div className="inline-block">
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.87109 3.99914L6.89802 1.97299C6.95519 1.91581 7.00054 1.84793 7.03149 1.77323C7.06243 1.69853 7.07836 1.61846 7.07836 1.5376C7.07836 1.45674 7.06243 1.37668 7.03149 1.30197C7.00054 1.22727 6.95519 1.15939 6.89802 1.10222C6.84084 1.04504 6.77296 0.999687 6.69826 0.968744C6.62356 0.937801 6.54349 0.921875 6.46263 0.921875C6.38177 0.921875 6.30171 0.937801 6.227 0.968744C6.1523 0.999687 6.08442 1.04504 6.02725 1.10222L4.00109 3.12914L1.97494 1.10222C1.85947 0.986746 1.70286 0.921875 1.53955 0.921875C1.37625 0.921875 1.21964 0.986746 1.10417 1.10222C0.988699 1.21769 0.923828 1.3743 0.923828 1.5376C0.923828 1.7009 0.988699 1.85752 1.10417 1.97299L3.13109 3.99914L1.10417 6.02529C1.04699 6.08247 1.00164 6.15035 0.970697 6.22505C0.939754 6.29975 0.923828 6.37982 0.923828 6.46068C0.923828 6.54154 0.939754 6.6216 0.970697 6.69631C1.00164 6.77101 1.04699 6.83889 1.10417 6.89606C1.16135 6.95324 1.22922 6.99859 1.30393 7.02954C1.37863 7.06048 1.4587 7.0764 1.53955 7.0764C1.62041 7.0764 1.70048 7.06048 1.77518 7.02954C1.84989 6.99859 1.91776 6.95324 1.97494 6.89606L4.00109 4.86914L6.02725 6.89606C6.08442 6.95324 6.1523 6.99859 6.227 7.02954C6.30171 7.06048 6.38177 7.0764 6.46263 7.0764C6.54349 7.0764 6.62356 7.06048 6.69826 7.02954C6.77296 6.99859 6.84084 6.95324 6.89802 6.89606C6.95519 6.83889 7.00054 6.77101 7.03149 6.69631C7.06243 6.6216 7.07836 6.54154 7.07836 6.46068C7.07836 6.37982 7.06243 6.29975 7.03149 6.22505C7.00054 6.15035 6.95519 6.08247 6.89802 6.02529L4.87109 3.99914Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export function ImageSwap() {
  return (
    <div className="inline-block">
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.73753 4.73C1.58416 5.12371 1.5 5.55203 1.5 6C1.5 7.933 3.067 9.5 5 9.5H6.5M8.26247 7.27C8.41584 6.87629 8.5 6.44797 8.5 6C8.5 4.067 6.933 2.5 5 2.5H3.5M3.5 2.5L5 1M3.5 2.5L5 4M6.5 9.5L5 8M6.5 9.5L5 11"
          stroke="white"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function ButtonArrowLeft() {
  return (
    <div className="inline-block">
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 1L1 7M1 7L7 13M1 7H15"
          stroke="#005CB2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function ButtonCopy() {
  return (
    <div className="inline-block">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13H3C1.89543 13 1 12.1046 1 11V3C1 1.89543 1.89543 1 3 1H11C12.1046 1 13 1.89543 13 3V7M9 19H17C18.1046 19 19 18.1046 19 17V9C19 7.89543 18.1046 7 17 7H9C7.89543 7 7 7.89543 7 9V17C7 18.1046 7.89543 19 9 19Z"
          stroke="#005CB2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function ButtonEdit() {
  return (
    <div className="inline-block">
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3.75L14.5 6.25M8 17.25H17M1 17.25V14.75L13.75 2C14.4404 1.30964 15.5596 1.30964 16.25 2V2C16.9404 2.69036 16.9404 3.80964 16.25 4.5L3.5 17.25H1Z"
          stroke="#005CB2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </div>
  );
}
