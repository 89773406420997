import Title from "../../components/Title";
import DefaultLayout from "../../components/PostType/DefaultLayout";

export function Index() {
  return (
    <div className="space-y-4">
      <Title text="All DailyEditions" />
      <DefaultLayout
        type="newsletter"
        excludedColumns={[
          "tags",
          "primaryCategory",
          "views_count",
          "comments_count",
          "author",
          "select",
        ]}
        titleKey="subject"
        titleRoute="view/:id"
        showSearchBar={false}
        excludedDataFilters={["date_range", "category", "author"]}
      />
    </div>
  );
}
