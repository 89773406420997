import { Link } from "react-router-dom";

type BadgeProps = {
  appearance?: "primary" | "dark";
  title: string;
  size?: string;
  link?: boolean;
};

export function Badge({
  appearance = "primary",
  title = "",
  size = "",
  link = false,
}: BadgeProps) {
  const sizeClass = size ? `${size}` : "md:text-base";
  const appearanceClass = {
    primary: "bg-primary",
    dark: "bg-black text-white",
  };
  const classes = `${appearanceClass[appearance]} py-2 px-3 uppercase inline-block text-sm ${sizeClass}`;

  return link ? (
    <Link
      to={`/${title.toLowerCase()}`}
      className={`${classes} hover:opacity-70`}
    >
      {title}
    </Link>
  ) : (
    <div className={classes}>{title}</div>
  );
}
