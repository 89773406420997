export const titleCase = (str) => {
  let splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const beautify = (string) => {
  return titleCase(string.replace("_", " ").replace("-", " "));
};

export const slugify = (string, separator = "-") => {
  return string
    .toLowerCase()
    .replace(/ /g, separator)
    .replace(/[^\w-]+/g, "");
};

export function stripHtml(htmlText) {
  return htmlText.replace(/(<([^>]+)>)/gi, "");
}
