import { useState } from "react";
import newsletterBackground from "./newsletter-background.svg";
import newsletterBackgroundMobile from "./newsletter-background-mobile.svg";
import { NewsletterLogo } from "./NewsletterLogo";
import { NewsletterLogoMobile } from "./NewsletterLogoMobile";
import { NewsletterSubmitIcon } from "./NewsletterSubmitIcon";
import { NewsletterCloseIcon } from "./NewsletterCloseIcon";
import { NewsletterRightIcon } from "./NewsletterRightIcon";
import { useForm } from "react-hook-form";
import EmailInput from "../../components/Inputs/EmailInput";
import TextInput from "../../components/Inputs/TextInput";
import { SignUp } from "../../utils/models";
import { queryRequest } from "../../utils/request";
import { useMutation } from "react-query";
import { useDetectMobile } from "../../utils/isMobile";

export function NewsletterSignup() {
  const { isMobile } = useDetectMobile("1280px");
  const [showSection, setShowSection] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUp>();

  const addMutation = useMutation(
    (data) =>
      queryRequest({
        path: "subscriptions",
        body: data,
        method: "POST",
        authenticated: false,
      }),
    {
      onSuccess: () => {
        setIsSubmitted(true);
      },
    }
  );

  const onSubmit = (data) => {
    data.specCode = "DAILY_SPARK";
    addMutation.mutate(data);
  };

  return (
    <>
      {showSection && (
        <div className="w-full min-h-[150px] bg-[#F1F1F1] bg-no-repeat bg-left flex items-center mt-1 md:mt-10 relative px-5 pt-10 md:pt-5 pb-20 xl:p-0 md:shadow-lg overflow-hidden">
          <div
            className="absolute left-0 right-0 top-0 -bottom-40 xl:bottom-0 m-auto xl:block bg-no-repeat bg-cover xl:bg-auto bg-top xl:bg-left"
            style={{
              backgroundImage: isMobile
                ? `url(${newsletterBackgroundMobile})`
                : `url(${newsletterBackground})`,
            }}
          ></div>
          <div className="mx-auto grid grid-cols-12 gap-x-4 items-center w-full relative z-10 px-5">
            <div className="col-span-12 xl:col-span-2 2xl:col-span-3 justify-center pt-4 pb-8 xl:py-0 flex xl:grid grid-cols-3">
              <div className="2xl:col-start-2">
                <div className="hidden xl:block">
                  <NewsletterLogo />
                </div>
                <div className="xl:hidden mb-4">
                  <NewsletterLogoMobile />
                </div>
              </div>
            </div>
            <div className="col-span-12 xl:col-span-10 2xl:col-span-9 flex flex-col xl:flex-row items-center">
              <div className="text-primary text-2xl leading-6 font-medium ml-1 mr-2 xl:mr-4 hidden xl:block">
                <span className="text-black">Subscribe</span> for a free
                lifetime subscription
              </div>
              <div className="xl:hidden text-white text-lg leading-6 font-light">
                Sign-up for a free Daily Edition
              </div>
              {!isSubmitted ? (
                <form
                  className="flex flex-col md:flex-row items-center mt-10 xl:mt-0 max-w-[275px] md:max-w-fit w-full"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <TextInput
                    label="Name"
                    showLabel={false}
                    placeholder="Name"
                    name="subscriberName"
                    register={register}
                    errors={errors}
                    className="mb-4 md:mb-0 md:mr-2 xl:mr-4 flex-col w-full md:w-auto"
                    inputClassName="rounded-2xl pl-5 pr-5 xl:pr-7 py-3 text-black leading-4 text-sm placeholder:text-black placeholder:leading-4 placeholder:text-sm"
                  />
                  <EmailInput
                    label="Email"
                    showLabel={false}
                    placeholder="Email"
                    name="email"
                    register={register}
                    errors={errors}
                    className="mb-4 md:mb-0 md:mr-4 xl:mr-6 flex-col w-full md:w-auto"
                    inputClassName="rounded-2xl pl-5 pr-5 xl:pr-7 py-3 text-black leading-4 text-sm placeholder:text-black placeholder:leading-4 placeholder:text-sm"
                  />
                  <button
                    aria-label="submit"
                    type="submit"
                    className="flex items-center justify-between w-full md:w-auto bg-primary md:bg-transparent py-3 px-5 md:p-0 rounded-2xl md:rounded-none hover:opacity-70 transition duration-300"
                  >
                    <NewsletterSubmitIcon addClassName="w-20 h-20 xl:h-auto xl:w-auto hidden md:block" />
                    <span className="md:hidden uppercase font-montserrat italic font-semibold leading-4">
                      Subscribe
                    </span>
                    <span className="md:hidden">
                      <NewsletterRightIcon />
                    </span>
                  </button>
                </form>
              ) : (
                <div className="flex items-center mt-10 mb-12 xl:mb-0 xl:mt-0 m-auto">
                  Thank you for subscribing!
                </div>
              )}
              <button
                aria-label="close_newsletter"
                title="Close Newsletter Signup Section"
                className="absolute top-0 bottom-0 right-0 xl:relative xl:ml-auto 2xl:mr-12 cursor-pointer hover:scale-105 transition duration-300 hidden md:block"
                onClick={() => setShowSection(false)}
              >
                <NewsletterCloseIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
