import BaseService from "./BaseService";

const getAll = (params) => {
  return BaseService.getAll("/newsletters", params, true);
};

const get = (id) => {
  return BaseService.get(`newsletters/${id}`, true);
};

const create = (data) => {
  return BaseService.create("newsletters", data, true);
};

const update = (id, data) => {
  return BaseService.update(`newsletters/${id}`, data, true);
};

const remove = (id) => {
  return BaseService.remove(`newsletters/${id}`, "", true);
};

const DailyEditionService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default DailyEditionService;
