import { useParams } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import Title from "../../components/Title";
import Alert from "../../components/Alert";
import TextInput from "../../components/Inputs/TextInput";
import Wysiwyg from "../../components/Inputs/Wysiwyg";
import { Section } from "../../components/Layout/Section";
import { Publish } from "../../components/Sections/Publish";
import Image from "../../components/Inputs/Image";
import { TagEditor } from "../../components/Sections/TagEditor";
import { CategoryEditor } from "../../components/Sections/CategoryEditor";
import { Admin } from "../../components/Sections/Admin";
import { SEO } from "../../components/Sections/SEO";
import { useForm } from "react-hook-form";
import Spinner from "../../components/Spinner";
import { only } from "../../utils/arrays";
import dayjs from "dayjs";
import {
  getMediaId,
  getImageMultiValues,
  getPreviewItem,
  getPreviewItems,
  parseMediaValue,
  uploadMedia,
} from "../../utils/media";
import { ImagePickerMulti } from "../../components/Sections/ImagePickerMulti";
import { QuestionPickerMulti } from "../../components/Sections/QuestionPickerMulti";
import { HustleQuestions } from "../../constants";
import Audio from "../../components/Inputs/Audio";
import Video from "../../components/Inputs/Video";
import HustleService from "../../services/HustleService";
import { sanitizeData } from "../../utils/forms";
import { useFormResponse } from "../../utils/hooks";
import { handleRedirect } from "../../utils/redirects";
import { formatLinks } from "../../utils/links";

const prepareFormData = (model) => {
  const data = only(model, [
    "title",
    "primaryCategoryId",
    "categories",
    "tags",
    "author",
    "effectivePublishDate",
  ]);
  const jsonData = only(model.jsonData, ["seoTitle", "seoDescription"]);
  for (const i in jsonData) {
    data[i] = jsonData[i];
  }
  data["effectivePublishDate"] = dayjs(
    data["effectivePublishDate"],
    "YYYY-MM-DDTHH:mm:ss.sssZ"
  ).toDate();

  const meta = ["firstName", "lastName", "introduction"];
  for (const key in meta) {
    data[meta[key]] = model.jsonData[meta[key]];
  }
  return data;
};

export function Edit() {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const [currentPost, setCurrentPost] = useState(null);
  const [isLoading, setLoading] = useState("");
  const [isPageLoading, setPageLoading] = useState(true);
  const [response, setResponse] = useFormResponse(null);
  const [redirect, setRedirect] = useState(null);

  handleRedirect(redirect);

  // Questions
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    setValue("questions", questions);
  }, [questions]);

  // Media
  const [media, setMedia] = useState({});
  const setMediaItem = (name, value, order = 0) => {
    let newMedia = parseMediaValue(name, value, order);
    if (newMedia) {
      for (let i in newMedia) {
        media[i] = newMedia[i];
      }
    }
    setMedia(media);
  };

  // Links
  const [links, setLinks] = useState({});
  const setLink = (key, value) => {
    let newLinks = links;
    newLinks[key] = value;
    setValue("links", []);
    setValue("links", newLinks);
    setValue("links", formatLinks(links));
  };
  useEffect(() => {
    setValue("links", formatLinks(links));
  }, [links]);

  // SEO Section
  const setSeoValue = (name, value) => {
    if ("object" === typeof value) {
      for (let i in value) {
        if (value[i] instanceof File) {
          setMediaItem(i, { files: [value[i]] }, 0);
        }
      }
    }
  };

  const retrievePost = (postId) => {
    return HustleService.get(postId)
      .then((response) => {
        if (response.data) {
          setCurrentPost(response.data);
          const prepared = prepareFormData(response.data);
          for (let i in prepared) {
            setValue(i, prepared[i]);
          }
          let newLinks = [];
          for (let i in response.data.jsonData.links) {
            newLinks[response.data.jsonData.links[i].key] =
              response.data.jsonData.links[i].href;
          }
          setLinks(newLinks);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const onSubmit = async (data) => {
    setLoading(data["status"]);
    try {
      await HustleService.update(id, sanitizeData(data));
      await uploadMedia(media, currentPost);
      setLoading("");
      setResponse({
        type: "success",
        message:
          data["status"] === "draft"
            ? "Hustle published as draft successfully."
            : "Hustle updated successfully.",
      });
      setMedia({});
      await retrievePost(id);
    } catch (e: any) {
      setResponse({ type: "danger", message: e.message });
      setLoading("");
    }
  };

  const onDelete = (post) => {
    if (post) {
      setPageLoading(true);
      HustleService.remove(post.id)
        .then((r) => {
          setResponse({
            type: "success",
            message: "Post deleted successfully.",
          });
          setTimeout(() => {
            setRedirect({ index: post });
          });
        })
        .catch((e) => {
          setResponse({
            type: "danger",
            message: "Unable to delete post, error happened.",
          });
          console.warn(e);
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
  };

  const onClone = (result) => {
    setRedirect({ post: result.data.response, params: { cloned: true } });
  };

  if (!currentPost) {
    retrievePost(id);
  }

  return (
    <Suspense
      fallback={
        <div className="text-center space-y-4">
          <Spinner />
        </div>
      }
    >
      {currentPost ? (
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Title text="Edit Hustle" />

          {response && <Alert type={response.type} text={response.message} />}

          <TextInput
            key="title"
            label=""
            name="title"
            placeholder="Add title"
            register={register}
            errors={errors}
          />

          <Section label="Publish">
            <Publish
              label={""}
              post={currentPost}
              setValue={setValue}
              getValues={getValues}
              loading={isLoading}
              onClone={onClone}
            />
          </Section>

          <Section label="Introduction">
            <Wysiwyg
              name="introduction"
              value={currentPost.jsonData.introduction}
              register={register}
              setValue={(n, v) => {
                setValue(n, v);
              }}
            />
          </Section>

          <div className="space-y-0">
            <div className="grid grid-cols-2">
              <Section label="First Name">
                <TextInput
                  className="p-3"
                  name="firstName"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                />
              </Section>
              <Section label="Last Name">
                <TextInput
                  className="p-3"
                  name="lastName"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                />
              </Section>
            </div>
            <div className="grid grid-cols-2">
              <Section label="Email">
                <TextInput
                  className="p-3"
                  name="email"
                  label=""
                  value={links["email"]}
                  setValue={setLink}
                  getValues={getValues}
                />
              </Section>
              <Section label="Website">
                <TextInput
                  className="p-3"
                  name="website"
                  label=""
                  value={links["website"]}
                  setValue={setLink}
                  getValues={getValues}
                />
              </Section>
            </div>
            <div className="grid grid-cols-2">
              <Section label="Cityverse">
                <TextInput
                  className="p-3"
                  name="cityverse"
                  label=""
                  value={links["cityverse"]}
                  setValue={setLink}
                  getValues={getValues}
                />
              </Section>
              <Section label="Twitter">
                <TextInput
                  className="p-3"
                  name="twitter"
                  label=""
                  value={links["twitter"]}
                  setValue={setLink}
                  getValues={getValues}
                />
              </Section>
            </div>
            <div className="grid grid-cols-2">
              <Section label="Facebook">
                <TextInput
                  className="p-3"
                  name="facebook"
                  label=""
                  value={links["facebook"]}
                  setValue={setLink}
                  getValues={getValues}
                />
              </Section>
              <Section label="Instagram">
                <TextInput
                  className="p-3"
                  name="instagram"
                  label=""
                  value={links["instagram"]}
                  setValue={setLink}
                  getValues={getValues}
                />
              </Section>
            </div>
            <div className="grid grid-cols-2">
              <Section label="Linkedin">
                <TextInput
                  className="p-3"
                  name="linkedin"
                  label=""
                  value={links["linkedin"]}
                  setValue={setLink}
                  getValues={getValues}
                />
              </Section>
            </div>
          </div>

          <div className="xl:flex gap-4">
            <div className="xl:w-1/3 w-full mb-5">
              <Section label="Photo of Entrepreneur and Logo" className="">
                <div className="flex gap-3 p-3">
                  <div className="w-1/2 pr-4">
                    <Image
                      name="entrepreneur_image"
                      setValue={setMediaItem}
                      getValues={getValues}
                      errors={errors}
                      showCaptionInput={false}
                      showUrlInput={false}
                      full={true}
                      value={getPreviewItem(
                        currentPost.medias,
                        "featured_image_full"
                      )}
                    />
                  </div>
                  <div className="w-1/2 pr-4">
                    <Image
                      name="entrepreneur_logo"
                      setValue={setMediaItem}
                      getValues={getValues}
                      errors={errors}
                      showCaptionInput={false}
                      showUrlInput={false}
                      full={true}
                      value={getPreviewItem(
                        currentPost.medias,
                        "entrepreneur_logo_full"
                      )}
                    />
                  </div>
                </div>
              </Section>
            </div>
            <div className="xl:w-2/3 w-full mb-5">
              <Section label="Hustle cover images">
                <ImagePickerMulti
                  className="gap-3 p-3"
                  name="header_images_{index}_full"
                  setValue={(name, value) => {
                    let index = 0;
                    for (let i in value) {
                      let item = currentPost.medias.find((entry) => {
                        return entry?.id === value[i]?.value?.id;
                      });
                      let key = item?.key
                        ? item.key
                        : "header_images_" + i + "_full";
                      setMediaItem(key, value[i].value, index);
                      index++;
                    }
                  }}
                  getValues={getValues}
                  value={getImageMultiValues(
                    currentPost.medias,
                    "header_images_{index}_full",
                    3
                  )}
                  number={3}
                ></ImagePickerMulti>
              </Section>
            </div>
          </div>

          <QuestionPickerMulti
            name="questions"
            setValue={(name, data) => {
              if (data) {
                setQuestions(data);
              }
            }}
            getValues={getValues}
            questions={HustleQuestions}
            selected={currentPost.jsonData.questions}
          />

          <div className="grid gird-cols-1 xl:grid-cols-2 gap-4">
            <Section label="Tags">
              <TagEditor
                name="tags"
                setValue={setValue}
                selected={currentPost.tags}
              />
            </Section>
            <Section label="Categories">
              <CategoryEditor
                name="categories"
                setValue={setValue}
                primaryCategory={
                  currentPost ? { id: currentPost.primaryCategoryId } : null
                }
                selected={currentPost.categories}
              />
            </Section>
          </div>

          <Section label="Admin">
            <Admin
              setValue={setValue}
              getValues={getValues}
              post={currentPost}
              watch={watch}
              register={register}
              onDelete={onDelete}
            />
          </Section>

          <div className="grid grid-cols-2 gap-3">
            <Section label="Featured Audio">
              <Audio
                className="p-3"
                name="featured_audio"
                setValue={setMediaItem}
                getValues={getValues}
                showCaptionInput={false}
                value={{
                  preview: getMediaId(currentPost.medias, "featured_audio")
                    ? "File #" +
                      getMediaId(currentPost.medias, "featured_audio")
                    : "",
                }}
              />
            </Section>
            <Section label="Featured Video">
              <Video
                className="p-3"
                name="featured_video"
                setValue={setMediaItem}
                getValues={getValues}
                showCaptionInput={false}
                value={{
                  preview: getMediaId(currentPost.medias, "featured_video")
                    ? "File #" +
                      getMediaId(currentPost.medias, "featured_video")
                    : "",
                }}
              />
            </Section>
          </div>

          <Section label="SEO">
            <SEO
              name="meta"
              register={register}
              setValue={setSeoValue}
              errors={errors.meta}
              value={{
                ...{
                  seoTitle: currentPost?.jsonData?.seoTitle,
                  seoDescription: currentPost?.jsonData?.seoDescription,
                },
                ...getPreviewItems(currentPost.medias, [
                  "facebook_image_full",
                  "twitter_image_full",
                  "linkedin_image_full",
                  "instagram_image_full",
                ]),
              }}
            />
          </Section>
        </form>
      ) : isPageLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <p>Not found</p>
      )}
    </Suspense>
  );
}
