const Spinner = ({ className = "", width = "32", height = "32" }) => {
  const wrapperClassName = `inline-block ${className}`;

  return (
    <div role="status" className={`inline-block ${wrapperClassName}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          opacity="0.05"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#2BE2D8"
        />
        <path
          d="M7.24842 16C7.24842 11.1664 11.1664 7.24842 16 7.24842V0C7.16333 0 0 7.16333 0 16C0 24.8367 7.16333 32 16 32V24.7516C11.1664 24.7516 7.24842 20.8336 7.24842 16Z"
          fill="#2BE2D8"
        />
        <path
          d="M16 7.24805V24.7512C20.8336 24.7512 24.7516 20.8333 24.7516 15.9996C24.7516 11.166 20.8336 7.24805 16 7.24805Z"
          fill="#2BE2D8"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
