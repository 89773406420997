import { relativeTime } from "../../utils/formatDate";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { Badge } from "../Badge";
import { AuthorTile } from "../AuthorTile";
import { ReactElement } from "react";
import { PostLink } from "../PostLink";
import type { PostType } from "../../types";

type FeaturedPostProps = {
  post: PostType;
  badge?: ReactElement;
};

const transition = "transition duration-300";

export function FeaturedPost({ post, badge }: FeaturedPostProps) {
  return (
    <PostLink
      className="group flex flex-col lg:max-h-[650px] grow-0"
      slug={post?.slug}
      preloadData={post}
    >
      <img
        alt={sanitize(post?.title)}
        className={`rounded-xl mb-10 md:mb-7 brightness-100 group-hover:brightness-75 max-h-[366px] object-cover ${transition}`}
        src={post?.featuredImage}
      />
      <div
        className={`flex justify-between mb-5 group-hover:opacity-60 ${transition}`}
      >
        <div className="flex items-center space-x-2">
          {badge ?? <Badge title={post?.primaryCategory.name} />}
          <div className="font-normal text-sm md:text-base">
            {relativeTime(post?.effectivePublishDate)}
          </div>
        </div>

        <AuthorTile name={post?.author?.name} />
      </div>

      <div
        className={`font-semibold text-2xl md:text-4xl mb-3 group-hover:opacity-60 ${transition}`}
        dangerouslySetInnerHTML={sanitizeForReact(post?.title)}
      />

      <div className="relative group-hover:opacity-60 ${transition} shrink grow-0 overflow-hidden">
        <div
          className={`font-['PT+Serif']`}
          dangerouslySetInnerHTML={sanitizeForReact(post?.excerpt)}
        ></div>
        <div className="absolute bottom-0 h-6 bg-gradient-to-b from-transparent to-white w-full"></div>
      </div>
    </PostLink>
  );
}
