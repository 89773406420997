export function AudioSoundIcon() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1361 2.22209C13.4644 1.32639 15.2542 2.2782 15.2542 3.88034V19.1194C15.2542 20.7216 13.4643 21.6734 12.1359 20.7775L6.97411 17.2963C6.97107 17.2951 6.96797 17.2939 6.96483 17.2926C6.93724 17.2816 6.90449 17.2674 6.86855 17.2493L6.85197 17.2409L6.83571 17.2319C6.77622 17.199 6.71122 17.1791 6.64476 17.1731L6.6403 17.1727C6.57904 17.1669 6.5263 17.157 6.48564 17.1477H3C1.89543 17.1477 1 16.2523 1 15.1477V7.85223C1 6.74766 1.89543 5.85223 3 5.85223H6.48855C6.5286 5.84313 6.58156 5.83312 6.64337 5.82726L6.63396 5.8282C6.70649 5.82063 6.7773 5.79881 6.84246 5.76356L6.84003 5.76488C6.89182 5.73667 6.93885 5.71621 6.97526 5.70188L12.1361 2.22209ZM13.2542 3.88034L7.99519 7.42634L7.98731 7.43144C7.91096 7.48086 7.84126 7.51299 7.79098 7.53347C7.76744 7.54306 7.74544 7.55114 7.72758 7.55738C7.47194 7.68617 7.19653 7.77128 6.91264 7.80897C6.8908 7.81484 6.86187 7.82207 6.82951 7.82859C6.77021 7.84054 6.69011 7.85223 6.59531 7.85223L3 7.85223V15.1477H6.59531C6.6924 15.1477 6.77419 15.1601 6.83428 15.1726C6.86211 15.1784 6.88743 15.1847 6.90778 15.1901C7.19085 15.2254 7.46556 15.3091 7.72007 15.4375C7.78081 15.4582 7.89343 15.5021 8.01134 15.5835L13.2542 19.1194V3.88034Z"
        fill="#FAFAFA"
      />
      <path
        d="M19 8.00047C19 7.44819 19.4477 7.00047 20 7.00047C20.5523 7.00047 21 7.44819 21 8.00047V15.0005C21 15.5528 20.5523 16.0005 20 16.0005C19.4477 16.0005 19 15.5528 19 15.0005V8.00047Z"
        fill="#FAFAFA"
      />
    </svg>
  );
}
