export function AudioPauseIcon() {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1C0 0.447715 0.447715 0 1 0H6C6.55228 0 7 0.447715 7 1V22C7 22.5523 6.55228 23 6 23H1C0.447715 23 0 22.5523 0 22V1Z"
        fill="#FAFAFA"
      />
      <path
        d="M14 1C14 0.447715 14.4477 0 15 0H20C20.5523 0 21 0.447715 21 1V22C21 22.5523 20.5523 23 20 23H15C14.4477 23 14 22.5523 14 22V1Z"
        fill="#FAFAFA"
      />
    </svg>
  );
}
