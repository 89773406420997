import Skeleton from "@mui/material/Skeleton";

export function MediaLoading() {
  return (
    <div className="lg:col-span-1 col-span-12">
      <Skeleton
        sx={{ bgcolor: "grey.900" }}
        className="rounded-2xl"
        animation="pulse"
        height={313}
        variant="rectangular"
      />
    </div>
  );
}
