import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import Label from "../Label";

const DropdownAsync = ({
  label,
  id = "",
  name,
  placeholder = "",
  showLabel = true,
  theme = "default",
  size = "default",
  required = true,
  register = null,
  errors = {},
  className = "",
  inputClassName = "",
  value = null,
  setValue = null,
  getOptionLabel,
  getOptionValue,
  loadOptions,
  isClearable = false,
}) => {
  const [selectedItem, setSelectedItem] = useState(value);

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);

  let fieldClasses = "w-full";

  if (errors?.[name]) {
    fieldClasses += " error";
  }
  if (inputClassName) {
    fieldClasses += ` ${inputClassName}`;
  }

  let wrapperClasses = "flex w-full flex-wrap items-stretch text-left";
  wrapperClasses += " " + className;

  const getOptionLabelWrapper = (item) => {
    if (getOptionLabel) {
      return getOptionLabel(item);
    }
    return "";
  };

  const getOptionValueWrapper = (item) => {
    if (getOptionValue) {
      return getOptionValue(item);
    }
    return "";
  };

  const loadOptionsWrapper = (inputValue) => {
    if (loadOptions) {
      return loadOptions(inputValue);
    }
    return null;
  };

  const handleChange = (value) => {
    setSelectedItem(value);
    if (setValue) {
      setValue(name, value);
    }
  };
  const handleInputChange = (value) => {};

  return (
    <div className={`${wrapperClasses}`}>
      {label ? (
        <Label className={`${showLabel ? "" : "sr-only"}`} text={label} />
      ) : (
        ""
      )}
      <AsyncSelect
        key={id}
        name={name}
        placeholder={placeholder}
        cacheOptions={false}
        defaultOptions
        className={`${fieldClasses}`}
        getOptionLabel={getOptionLabelWrapper}
        getOptionValue={getOptionValueWrapper}
        loadOptions={loadOptionsWrapper}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isClearable={isClearable}
        value={selectedItem}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "grey" : "#969696",
          }),
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: "black",
            };
          },
        }}
        defaultValue={value ? value : "Select item..."}
        {...(register ? register(name, { required: required }) : {})}
      />
      {errors?.[name] && <span>This field is required</span>}
    </div>
  );
};

export default DropdownAsync;
