import { Link } from "react-router-dom";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { Badge } from "../Badge";

type KeepExploringTileProps = {
  title: string;
  slug: string;
  image: string;
  category: string;
};

export function KeepExploringTile({
  title,
  slug,
  image,
  category,
}: KeepExploringTileProps) {
  return (
    <Link
      to={`/${slug}`}
      state={{ slug: slug, type: "modal" }}
      title={sanitize(title)}
      className="group"
    >
      <img
        alt={sanitize(title)}
        src={image}
        title={sanitize(title)}
        className="w-full h-[142px] mb-5 shrink-0 rounded-2xl object-cover brightness-100 group-hover:brightness-75 transition duration-300"
      />
      <Badge title={category} appearance="dark" size="text-xs" />
      <h2
        className="font-semibold line-clamp-2 mt-4 text-xl leading-6 text-black mb-0 group-hover:opacity-70 transition duration-300"
        dangerouslySetInnerHTML={sanitizeForReact(title)}
        title={sanitize(title)}
      />
    </Link>
  );
}
