type CloseIconProps = {
  onClick?: () => void;
  addClassName?: string;
  addSvgClassName?: string;
  hideGradientDesktop?: Boolean;
};

export function CloseIcon({
  onClick,
  addClassName = "text-white",
  addSvgClassName,
  hideGradientDesktop = false,
}: CloseIconProps) {
  return (
    <button
      aria-label="close"
      className="bg-no-repeat bg-contain select-none py-2 px-3 cursor-pointer"
      onClick={onClick}
    >
      <span className={addClassName}>
        <svg
          className={addSvgClassName}
          width="56"
          height="36"
          viewBox="0 0 56 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="14.3693"
            height="1.39058"
            rx="0.695291"
            transform="matrix(-0.728412 0.68514 -0.728412 -0.68514 33.4805 16.123)"
            fill="currentColor"
            stroke="currentColor"
          />
          <rect
            width="14.3693"
            height="1.39058"
            rx="0.69529"
            transform="matrix(-0.728412 -0.68514 0.728412 -0.68514 32.4668 25.9668)"
            fill="currentColor"
            stroke="currentColor"
          />
          <path
            className={`${hideGradientDesktop ? "md:hidden" : ""}`}
            d="M2 35.8613L2 5.78417C2 3.69299 3.7983 1.99923 6.01854 1.99923L48.9768 1.99923C51.7496 1.99923 54 4.11879 54 6.7304L54 12.105"
            stroke="url(#paint0_linear_1962_3373)"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1962_3373"
              x1="21.5"
              y1="1.99813"
              x2="21.5"
              y2="35.8613"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2BE2D8" />
              <stop offset="1" stopColor="#2BE2D8" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </span>
    </button>
  );
}
