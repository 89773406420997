export function AudioMutedIcon() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8132 2.82923C15.2711 2.52047 15.3921 1.89896 15.0833 1.44104C14.7745 0.983128 14.153 0.862214 13.6951 1.17097L6.97526 5.70199C6.93928 5.71616 6.89294 5.7363 6.84189 5.76399C6.77742 5.79877 6.70743 5.82042 6.63573 5.82813C6.57719 5.83407 6.52694 5.84363 6.48855 5.85235H3C1.89543 5.85235 1 6.74778 1 7.85235V15.1478C1 16.2524 1.89543 17.1479 3 17.1479H6.48564C6.52631 17.1571 6.57904 17.167 6.64031 17.1728L6.64476 17.1732C6.71122 17.1792 6.77622 17.1991 6.83571 17.232L6.85198 17.241L6.86856 17.2494C6.90449 17.2676 6.93724 17.2818 6.96483 17.2928L6.97411 17.2964L13.695 21.8292C14.1529 22.138 14.7744 22.0171 15.0832 21.5592C15.392 21.1014 15.2712 20.4798 14.8133 20.171L8.01138 15.5836C7.89342 15.5022 7.78076 15.4583 7.72002 15.4376C7.46553 15.3092 7.19084 15.2255 6.90778 15.1903C6.88743 15.1848 6.86211 15.1785 6.83429 15.1727C6.77419 15.1602 6.6924 15.1478 6.59531 15.1478H3V7.85235L6.59531 7.85235C6.69011 7.85235 6.77021 7.84066 6.82951 7.82871C6.86187 7.82219 6.8908 7.81496 6.91264 7.80909C7.19653 7.7714 7.47194 7.68629 7.72758 7.5575C7.74544 7.55126 7.76744 7.54318 7.79098 7.53359C7.84126 7.5131 7.91096 7.48098 7.98731 7.43155L7.99519 7.42645L14.8132 2.82923ZM6.63573 5.82813L6.63396 5.82832L6.64337 5.82738C6.64081 5.82762 6.63826 5.82787 6.63573 5.82813Z"
        fill="#FAFAFA"
      />
      <path
        d="M14.2957 8.295C13.9024 8.68834 13.9024 9.32607 14.2957 9.71941L16.0758 11.4997L14.295 13.2806C13.9017 13.6739 13.9017 14.3117 14.295 14.705C14.6883 15.0983 15.326 15.0983 15.7193 14.705L17.5001 12.9241L19.2807 14.7048C19.674 15.0982 20.3117 15.0982 20.705 14.7048C21.0983 14.3115 21.0983 13.6738 20.705 13.2804L18.9244 11.4997L20.7043 9.71957C21.0976 9.32623 21.0976 8.6885 20.7043 8.29516C20.311 7.90182 19.6733 7.90182 19.28 8.29516L17.5001 10.0752L15.72 8.295C15.3267 7.90167 14.689 7.90167 14.2957 8.295Z"
        fill="#FAFAFA"
      />
    </svg>
  );
}
