import { useEffect, useState } from "react";
import { Times } from "../Icons";
import Button from "../Inputs/Button";
import TagService from "../../services/TagService";
import AsyncCreatableSelect from "react-select/async-creatable";
import { searchQueryRequest } from "../../../utils/request";
import { toIds } from "../../utils/arrays";

export function TagEditor({
  name,
  selected = [],
  setValue = null,
  getValues = null,
}) {
  const [tags, setTags] = useState(selected);
  const [inputValue, setInputValue] = useState(null);

  const setFormTags = () => {
    const newTags = toIds(tags);
    setValue(name, newTags);
  };

  useEffect(() => {
    setFormTags();
  }, [tags, selected]);

  const handleAddTag = async (e) => {
    e.preventDefault();
    if (inputValue) {
      let record = null;
      if (inputValue.__isNew__) {
        try {
          const response = await TagService.create({ name: inputValue.value });
          record = response.data;
        } catch (e) {
          console.warn("Error: " + e.message);
        }
      } else {
        record = inputValue;
      }
      if (record) {
        setTags([...tags, record]);
        setInputValue(null);
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag.id !== tagToRemove.id));
  };

  const loadOptions = (inputValue) => {
    return searchQueryRequest("tags", { text: inputValue });
  };

  return (
    <>
      <div className="flex m-3">
        <AsyncCreatableSelect
          name={name + "_input"}
          defaultOptions
          cacheOptions
          loadOptions={loadOptions}
          className="w-full"
          value={inputValue}
          onChange={(newValue) => {
            setInputValue(newValue);
          }}
          getOptionLabel={(e: any) => (e.name ? e.name : e.label)}
          getOptionValue={(e: any) => (e.id ? e.id : e.value)}
        />

        <button
          aria-label="add"
          type="button"
          onClick={handleAddTag}
          className="!w-fit !h-auto h-full flex justify-center items-center !bg-gray-100 rounded !px-4 ml-1 border border-[#969696]"
        >
          Add
        </button>
      </div>
      <div className="flex flex-wrap gap-2 m-3 mt-0">
        {tags.map((tag, i) => (
          <Button
            type="button"
            key={i}
            onClick={() => handleRemoveTag(tag)}
            size="mediumRounded"
            theme="primary"
            className="!w-fit !h-auto mt-2 flex items-center"
          >
            <span>{Times()}</span>
            <span className="mx-2 text-white">{tag.name}</span>
          </Button>
        ))}
      </div>
    </>
  );
}
