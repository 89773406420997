type CategoryHeaderProps = {
  category: string;
  intro: string;
};

export function CategoryHeader({ category, intro }: CategoryHeaderProps) {
  return (
    <div className="relative">
      <div className="relative overflow-hidden mt-3 md:mt-0">
        <div className="text-[6em] md:text-[14em] uppercase text-transparent mt-[-0.1em] leading-[0.85em]  primary-text-outline">
          {category}
        </div>
        <div className="absolute text-[6em] md:text-[14em] uppercase text-transparent mt-[-0.1em] leading-[0.85em]  primary-text-outline opacity-30 top-0 left-1">
          {category}
        </div>
      </div>
      {intro && (
        <div className="md:absolute p-2 md:p-2 inset-0 grid lg:grid-cols-2">
          <div className="lg:col-span-1"></div>
          <div className="font-montserrat italic font-semibold text-xs lg:col-span-1 flex items-center">
            <div
              className="leading-6"
              dangerouslySetInnerHTML={{ __html: intro }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
