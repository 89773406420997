import { ShuffleLoading } from "./ShuffleLoading";
import { ShuffleTile } from "./ShuffleTile";
import { SlideButton } from "../../components/SlideButton";
import { SectionHeader } from "../../components/SectionHeader";
import { useSlideContent } from "../../utils/useSlideContent";
import { Advertisement } from "../../components/Advertisement";
import shuffleBackground from "./shuffle-background.svg";
import { ShuffleType } from "../../types";

type Shuffle = {
  post: ShuffleType;
};

export function Shuffle() {
  const {
    posts,
    isLoading,
    isFetching,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
  } = useSlideContent<Shuffle>({
    requestUrl: "posts?postType=shuffle",
    count: 1,
  });

  const loading = isLoading || (!posts.length && isFetching);

  return (
    <>
      <div
        className="md:rounded-2xl mt-10 p-5 bg-cover border-t-4 border-b-4 md:border border-solid border-[#F0F0F0] md:border-gray"
        style={{
          backgroundImage: `url(${shuffleBackground})`,
        }}
      >
        <SectionHeader title="The Shuffle" gradient={false} border={false} />

        <div className="grid xl:grid-cols-2 gap-x-5">
          <div>
            <div>
              {(loading ? Array.from(new Array(1)) : posts).map((post, i) =>
                post ? (
                  <ShuffleTile key={post.id} post={post} />
                ) : (
                  <ShuffleLoading key={i} />
                )
              )}
            </div>

            <div className="flex justify-between my-5">
              <SlideButton
                appearance="dark"
                direction="back"
                onClick={slideBack}
                disabled={slideBackDisabled}
              />
              <SlideButton
                appearance="dark"
                direction="forward"
                onClick={slideForward}
                disabled={slideForwardDisabled}
              />
            </div>
          </div>
          <div className="md:grid sm:grid-cols-2 gap-x-2 mb-5 hidden content-start">
            <Advertisement
              path="/21735245768/SPC_IC_HP_MREC_1"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-5"
            />
            <Advertisement
              path="/21735245768/SPC_IC_HP_MREC_2"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-6"
            />
          </div>
        </div>
      </div>
      <div className="md:hidden pt-10">
        <Advertisement
          path="/21735245768/SPC_News_MREC_M"
          size={[300, 250]}
          id="div-gpt-ad-1682306808816-SM"
        />
      </div>
    </>
  );
}
