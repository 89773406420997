import { useForm } from "react-hook-form";
import { CategoryEditor } from "../../components/Sections/CategoryEditor";
import { Section } from "../../components/Layout/Section";
import { Publish } from "../../components/Sections/Publish";
import { SEO } from "../../components/Sections/SEO";
import { TagEditor } from "../../components/Sections/TagEditor";
import Title from "../../components/Title";
import Image from "../../components/Inputs/Image";
import TextInput from "../../components/Inputs/TextInput";
import { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sanitizeData } from "../../utils/forms";
import Alert from "../../components/Alert";
import { Admin } from "../../components/Sections/Admin";
import ShuffleService from "../../services/ShuffleService";
import { only } from "../../utils/arrays";
import dayjs from "dayjs";
import Spinner from "../../components/Spinner";
import {
  getPreviewItem,
  getPreviewItems,
  parseMediaValue,
  uploadMedia,
} from "../../utils/media";
import { handleRedirect } from "../../utils/redirects";
import { useFormResponse } from "../../utils/hooks";
import { formatLinks } from "../../utils/links";

const prepareFormData = (model) => {
  const data = only(model, [
    "title",
    "primaryCategoryId",
    "categories",
    "tags",
    "author",
    "effectivePublishDate",
    "htmlContent",
  ]);
  const jsonData = only(model.jsonData, ["seoTitle", "seoDescription"]);
  for (const i in jsonData) {
    data[i] = jsonData[i];
  }
  data["effectivePublishDate"] = dayjs(
    data["effectivePublishDate"],
    "YYYY-MM-DDTHH:mm:ss.sssZ"
  ).toDate();

  const meta = {
    firstName: model.jsonData?.contact?.firstName,
    lastName: model.jsonData?.contact?.lastName,
    previousCompany: model.jsonData?.oldOrganization?.name,
    previousPosition: model.jsonData?.oldOrganization?.role,
    newCompany: model.jsonData?.newOrganization?.name,
    newPosition: model.jsonData?.newOrganization?.role,
    previousCompanyEmail: model.jsonData?.oldOrganization?.email,
    newCompanyEmail: model.jsonData?.newOrganization?.email,
  };
  for (const key in meta) {
    data[key] = meta[key];
  }
  return data;
};

export function Edit() {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const [currentPost, setCurrentPost] = useState(null);
  const [isLoading, setLoading] = useState("");
  const [isPageLoading, setPageLoading] = useState(true);
  const [response, setResponse] = useFormResponse(null);
  const [redirect, setRedirect] = useState(null);

  handleRedirect(redirect);

  // Media
  const [media, setMedia] = useState({});
  const setMediaItem = (name, value, order = 0) => {
    let newMedia = parseMediaValue(name, value, order);
    if (newMedia) {
      for (let i in newMedia) {
        media[i] = newMedia[i];
      }
    }
    setMedia(media);
  };

  // Links
  const [links, setLinks] = useState({});
  const setLink = (name, value) => {
    const newLinks = links;
    newLinks[name] = value;
    setLinks(newLinks);
    const postLinks = [];
    for (const i in newLinks) {
      postLinks.push({ key: i, href: newLinks[i] });
    }
    setValue("links", postLinks);
  };
  useEffect(() => {
    setValue("links", formatLinks(links));
  }, [links]);

  const retrievePost = (postId) => {
    return ShuffleService.get(postId)
      .then((response) => {
        if (response.data) {
          setCurrentPost(response.data);
          const prepared = prepareFormData(response.data);
          for (let i in prepared) {
            setValue(i, prepared[i]);
          }
          let newLinks = [];
          for (let i in response.data.jsonData.links) {
            newLinks[response.data.jsonData.links[i].key] =
              response.data.jsonData.links[i].href;
          }
          setLinks(newLinks);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  // SEO Section
  const setSeoValue = (name, value) => {
    if ("object" === typeof value) {
      for (let i in value) {
        if (value[i] instanceof File) {
          setMediaItem(i, { files: [value[i]] });
        }
      }
    }
  };

  const onSubmit = async (data) => {
    setLoading(data["status"]);
    try {
      await ShuffleService.update(id, sanitizeData(data));
      await uploadMedia(media, currentPost);
      setLoading("");
      setResponse({
        type: "success",
        message:
          data["status"] === "draft"
            ? "Shuffle published as draft successfully."
            : "Shuffle updated successfully.",
      });
      setMedia({});
      await retrievePost(id);
    } catch (e: any) {
      setResponse({ type: "danger", message: e.message });
      setLoading("");
    }
  };

  const onDelete = (post) => {
    if (post) {
      setPageLoading(true);
      ShuffleService.remove(post.id)
        .then((r) => {
          setResponse({
            type: "success",
            message: "Shuffle deleted successfully.",
          });
          setTimeout(() => {
            setRedirect({ index: post });
          });
        })
        .catch((e) => {
          setResponse({
            type: "danger",
            message: "Unable to delete post, error happened.",
          });
          console.warn(e);
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
  };

  const onClone = (result) => {
    setRedirect({ post: result.data.response, params: { cloned: true } });
  };

  if (!currentPost) {
    retrievePost(id);
  }

  return (
    <Suspense
      fallback={
        <div className="text-center space-y-4">
          <Spinner />
        </div>
      }
    >
      {currentPost ? (
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <Title text="Edit Shuffle" />

          {response && <Alert type={response.type} text={response.message} />}

          <TextInput
            key="title"
            label=""
            name="title"
            placeholder="Add title"
            register={register}
            errors={errors}
          />

          <Section label="Publish">
            <Publish
              label={""}
              post={currentPost}
              setValue={setValue}
              getValues={getValues}
              loading={isLoading}
              onClone={onClone}
            />
          </Section>

          <div className="space-y-0">
            <div className="grid grid-cols-2">
              <Section label="First Name">
                <TextInput
                  className="p-3"
                  name="firstName"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                />
              </Section>
              <Section label="Last Name">
                <TextInput
                  className="p-3"
                  name="lastName"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                />
              </Section>
            </div>
            <div className="grid grid-cols-2">
              <Section label="Former Company">
                <TextInput
                  className="p-3"
                  name="previousCompany"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                ></TextInput>
              </Section>
              <Section label="Former Position">
                <TextInput
                  className="p-3"
                  name="previousPosition"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                ></TextInput>
              </Section>
            </div>
            <div className="grid grid-cols-2">
              <Section label="New Company">
                <TextInput
                  className="p-3"
                  name="newCompany"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                />
              </Section>
              <Section label="New Position">
                <TextInput
                  className="p-3"
                  name="newPosition"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                />
              </Section>
            </div>
            <div className="grid grid-cols-2">
              <Section label="Previous Company Email">
                <TextInput
                  className="p-3"
                  name="previousCompanyEmail"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                ></TextInput>
              </Section>
              <Section label="New Company Email">
                <TextInput
                  className="p-3"
                  name="newCompanyEmail"
                  label=""
                  setValue={setValue}
                  getValues={getValues}
                  register={register}
                  required={false}
                ></TextInput>
              </Section>
            </div>
          </div>

          <Section label="Additional content">
            <TextInput
              className="p-3"
              name="htmlContent"
              setValue={setValue}
              getValues={getValues}
              register={register}
              required={false}
              label=""
            ></TextInput>
          </Section>

          <Section label="Featured Image">
            <Image
              name="featured_image_full"
              setValue={setMediaItem}
              getValues={getValues}
              errors={errors}
              showCaptionInput={false}
              showUrlInput={false}
              full={true}
              value={getPreviewItem(currentPost.medias, "featured_image_full")}
            />
          </Section>

          <div className="grid grid-cols-2">
            <Section label="Linkedin">
              <TextInput
                className="p-3"
                name="linkedin"
                label=""
                value={links["email"]}
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
            <Section label="Email">
              <TextInput
                className="p-3"
                name="email"
                label=""
                value={links["email"]}
                setValue={setLink}
                getValues={getValues}
              />
            </Section>
          </div>

          <div className="grid gird-cols-1 xl:grid-cols-2 gap-4">
            <Section label="Tags">
              <TagEditor
                name="tags"
                setValue={setValue}
                selected={currentPost.tags}
              />
            </Section>
            <Section label="Categories">
              <CategoryEditor
                name="categories"
                setValue={setValue}
                primaryCategory={
                  currentPost ? { id: currentPost.primaryCategoryId } : null
                }
                selected={currentPost.categories}
              />
            </Section>
          </div>

          <Section label="Admin">
            <Admin
              setValue={setValue}
              getValues={getValues}
              post={currentPost}
              watch={watch}
              register={register}
              onDelete={onDelete}
            />
          </Section>

          <Section label="SEO">
            <SEO
              name="meta"
              register={register}
              setValue={setSeoValue}
              errors={errors.meta}
              value={{
                ...{
                  seoTitle: currentPost?.jsonData?.seoTitle,
                  seoDescription: currentPost?.jsonData?.seoDescription,
                },
                ...getPreviewItems(currentPost.medias, [
                  "facebook_image_full",
                  "twitter_image_full",
                  "linkedin_image_full",
                  "instagram_image_full",
                ]),
              }}
            />
          </Section>
        </form>
      ) : isPageLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <p>Not found</p>
      )}
    </Suspense>
  );
}
