import Skeleton from "@mui/material/Skeleton";

export function FeaturedPostLoading() {
  return (
    <span>
      <div className="hidden md:block">
        <Skeleton
          className="rounded mb-7"
          animation="pulse"
          height={366}
          variant="rectangular"
        />
      </div>
      <div className="md:hidden">
        <Skeleton
          className="rounded mb-7"
          animation="pulse"
          height={210}
          variant="rectangular"
        />
      </div>
      <Skeleton
        className="rounded mb-5"
        animation="pulse"
        height={40}
        variant="rectangular"
      />
      <Skeleton
        className="rounded"
        animation="pulse"
        height={180}
        variant="rectangular"
      />
    </span>
  );
}
