import { queryRequest } from "../../utils/request";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Footer } from "../Footer";
import CircularProgress from "@mui/material/CircularProgress";

const getPost = async ({ queryKey }) => {
  const [_, { id }] = queryKey;
  return queryRequest({
    path: "newsletters/",
    params: id,
  });
};

export function DailyEditionSingle() {
  const { id } = useParams();
  const { data: postData, isLoading } = useQuery(["post", { id: id }], getPost);

  const setIframe = (htmlContent) => {
    const src =
      "data:text/html;charset=utf-8," + encodeURIComponent(htmlContent);
    return src;
  };

  return (
    <>
      <div className="container mx-auto md:-mt-12">
        {isLoading ? (
          <div className="flex justify-center">
            <CircularProgress
              sx={{
                color: "#2be2d8",
              }}
            />
          </div>
        ) : (
          <>
            {postData?.data?.htmlContent?.length > 100 &&
            postData?.data?.htmlContent ? (
              <iframe
                title={postData?.data?.subject}
                className="w-full h-[calc(100vh-100px)] md:h-[calc(100vh-256px)]"
                src={setIframe(postData?.data?.htmlContent)}
              ></iframe>
            ) : (
              <h1 className="text-center text-3xl">Daily Edition Not Found</h1>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
