export function getPostEditUrl(post) {
  let postType = post?.postType ? post.postType : null;
  if (!postType && post.subject && post.contentJson) {
    return `/journalist/daily-edition/view/${post.id}`;
  }

  return `/journalist/${post.postType}/edit/${post.id}`;
}

export function getPostIndexUrl(post) {
  return `/journalist/${post.postType}`;
}
