import { ArrowRightIcon } from "../../components/ArrowIcons";
import { sanitizeForReact } from "../../utils/sanitizeHtml";
import { formatDayMonthYear } from "../../utils/formatDate";
import { Link } from "react-router-dom";
import { DailyEditionType } from "../../types";

type DailyEditionTileProps = {
  index: number;
  post: DailyEditionType;
};

export function DailyEditionTile({ index, post }: DailyEditionTileProps) {
  return (
    <div className="grid grid-cols-12 gap-x-[10px] items-center">
      <div className="px-3 py-1 text-sm hidden sm:block sm:col-span-2 xl:col-span-1 bg-primary text-white rounded-l-2xl text-center">
        {formatDayMonthYear(post?.effectivePublishDate)}
      </div>
      <div className="col-span-12 sm:col-span-10 xl:col-span-11 flex items-center h-full px-4 sm:px-0">
        <div
          className={`${
            index == 0 ? "border-t" : ""
          } border-b border-[#CACACA] py-2 w-full mr-[10px] md:text-sm xl:text-base`}
        >
          <div className="flex sm:hidden mb-2">
            <div className="px-3 py-1 text-sm bg-primary text-white rounded-l-2xl w-full flex items-center">
              {formatDayMonthYear(post?.effectivePublishDate)}
            </div>
            <div className="ml-auto bg-black text-white rounded-r-2xl px-4 py-2 flex items-center justify-center w-full">
              <ArrowRightIcon />
            </div>
          </div>
          <h2 dangerouslySetInnerHTML={sanitizeForReact(post?.subject)} />
        </div>
        <Link
          to={`${post?.id}`}
          className="hidden sm:flex ml-auto bg-black text-white rounded-r-2xl px-4 py-2 items-center justify-center"
        >
          <ArrowRightIcon />
        </Link>
      </div>
    </div>
  );
}
