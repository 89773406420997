type LinkedinIconProps = {
  addClassName?: string;
  addSvgClassName?: string;
};
export function LinkedinIcon({
  addClassName = "text-black",
  addSvgClassName,
}: LinkedinIconProps) {
  return (
    <span className={addClassName}>
      <svg
        className={addSvgClassName}
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1370_585)">
          <path
            d="M12.1455 0C5.51694 0 0.145508 5.37143 0.145508 12C0.145508 18.6286 5.51694 24 12.1455 24C18.7741 24 24.1455 18.6286 24.1455 12C24.1455 5.37143 18.7741 0 12.1455 0ZM9.12596 16.9504H6.60566V9.3594H9.12596V16.9504ZM7.8628 8.32481H7.84476C6.99664 8.32481 6.44927 7.74135 6.44927 7.01353C6.44927 6.28571 7.01468 5.70226 7.87483 5.70226C8.73498 5.70226 9.27032 6.26767 9.28235 7.01353C9.28235 7.74135 8.73498 8.32481 7.85679 8.32481H7.8628ZM18.2207 16.9504H15.7004V12.8902C15.7004 11.8677 15.3335 11.1759 14.4252 11.1759C13.7275 11.1759 13.3124 11.6451 13.132 12.0962C13.0658 12.2586 13.0478 12.4872 13.0478 12.7098V16.9504H10.5275C10.5275 16.9504 10.5636 10.0752 10.5275 9.3594H13.0478V10.4361C13.3846 9.9188 13.9801 9.18496 15.3214 9.18496C16.9816 9.18496 18.2267 10.2677 18.2267 12.6015V16.9564L18.2207 16.9504Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1370_585">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.145508)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
