import { useState, useEffect, useRef } from "react";
import { AudioPlayIcon } from "./AudioPlayIcon";
import { AudioPauseIcon } from "./AudioPauseIcon";
import { AudioSoundIcon } from "./AudioSoundIcon";
import { AudioMutedIcon } from "./AudioMutedIcon";
import { formatTime } from "../../utils/formatTime";
import audioProgressIcon from "./AudioProgressIcon.svg";
import Slider from "@mui/material/Slider";

type AudioPlayerProps = {
  src: string;
  title?: string;
};

export function AudioPlayer({ src, title }: AudioPlayerProps) {
  const audioRef = useRef<any>();
  const progressBarRef = useRef<any>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    isPlaying ? audioRef.current.play() : audioRef.current.pause();
  }, [isPlaying, audioRef]);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  const handleProgressChange = (event) => {
    audioRef.current.currentTime = event.target.value;
  };

  const timeUpdate = (event) => {
    setTimeProgress(event.target.currentTime);
  };

  return (
    <div
      className="
      bg-black rounded-tr-xl mt-5 mb-6 border-b-[3px] border-primary relative 
      before:content-[''] before:h-full before:w-[3px] before:top-0 before:left-0 before:absolute before:bg-gradient-to-t before:from-primary before:via-transparent before:to-indigo-transparent 
      after:content-[''] after:h-full after:w-[3px] after:top-0 after:right-0 after:absolute after:bg-gradient-to-t after:from-primary after:via-transparent after:to-indigo-transparent
      "
    >
      {title && (
        <div className="text-primary uppercase font-semibold pt-3 px-5">
          {title}
        </div>
      )}
      <div className="py-5 px-8 flex items-center flex-wrap md:flex-nowrap">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          className="hidden"
          src={src}
          controls
          ref={audioRef}
          onLoadedMetadata={onLoadedMetadata}
          onTimeUpdate={(event) => timeUpdate(event)}
        />
        <div className="flex items-center justify-between w-full md:w-auto">
          <button
            aria-label={isPlaying ? "pause" : "play"}
            className="mr-4 hover:opacity-60"
            onClick={() => setIsPlaying((state) => !state)}
          >
            {isPlaying ? <AudioPauseIcon /> : <AudioPlayIcon />}
          </button>
          <div className="flex md:hidden items-center">
            <button
              aria-label={muteVolume ? "muted" : "sound"}
              onClick={() => setMuteVolume((state) => !state)}
              className="mx-4 hover:opacity-70"
            >
              {muteVolume ? <AudioMutedIcon /> : <AudioSoundIcon />}
            </button>
            <div className="w-32 md:w-[60px] flex items-center">
              <Slider
                defaultValue={0}
                aria-label="Volume"
                valueLabelDisplay="off"
                min={0}
                max={100}
                value={volume}
                onChange={(event) =>
                  setVolume(parseInt((event.target as HTMLInputElement).value))
                }
                sx={{
                  color: "#2BE2D8",
                  "& .MuiSlider-thumb": {
                    display: "none",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center w-full">
          <span className="text-white font-semibold text-sm font-['Inter'] w-10">
            {formatTime(timeProgress)}
          </span>
          <div className="w-full mx-4 flex items-center">
            <Slider
              defaultValue={0}
              value={timeProgress}
              aria-label="Progress"
              valueLabelDisplay="off"
              ref={progressBarRef}
              onChange={(event) => handleProgressChange(event)}
              min={0}
              max={duration}
              sx={{
                color: "#2BE2D8",
                "& .MuiSlider-thumb": {
                  background: `url(${audioProgressIcon});`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  "&:hover, &:active, &.Mui-focusVisible": {
                    boxShadow: `none`,
                  },
                },
              }}
            />
          </div>
          <span className="text-white font-semibold text-sm font-['Inter'] w-10">
            {formatTime(duration)}
          </span>
        </div>
        <div className="hidden md:flex items-center">
          <button
            aria-label={muteVolume ? "muted" : "sound"}
            onClick={() => setMuteVolume((state) => !state)}
            className="mx-4 hover:opacity-70"
          >
            {muteVolume ? <AudioMutedIcon /> : <AudioSoundIcon />}
          </button>
          <div className="w-[60px] flex items-center">
            <Slider
              defaultValue={0}
              aria-label="Volume"
              valueLabelDisplay="off"
              min={0}
              max={100}
              value={volume}
              onChange={(event) =>
                setVolume(parseInt((event.target as HTMLInputElement).value))
              }
              sx={{
                color: "#2BE2D8",
                "& .MuiSlider-thumb": {
                  display: "none",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
