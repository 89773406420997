import TextArea from "../../components/Inputs/TextArea";
import TextInput from "../../components/Inputs/TextInput";
import { useEffect, useRef } from "react";
import { getBase64FromUrl } from "../../utils/files";
import { AudioIcon } from "../Icons";
import Button from "./Button";
import useObject from "../Hooks/useObject";

export type AudioType = {
  files: FileList;
  caption: string;
  link: string;
  preview: string;
};

const Audio = ({
  name,
  setValue = null,
  getValues = null,
  errors = null,
  className = "",
  value = null,
  allowedFiles = "audio/*",
  showCaptionInput = true,
  showUrlInput = true,
  onClear = null,
}) => {
  const initialValues: AudioType = {
    files: null,
    caption: "",
    link: "",
    preview: "",
  };

  const [values, setValues] = useObject(initialValues);

  const fileInput = useRef<HTMLInputElement | null>(null);
  const fieldName = name;

  useEffect(() => {
    if (value) {
      setValues({
        link: value.link ? value.link : "",
        preview: value.preview ? value.preview : "",
        caption: value.caption ? value.caption : "",
      });
    }
  }, [value]);

  const handlePreviewClick = (event) => {
    if (event.target.nodeName !== "BUTTON") {
      fileInput.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const result = files[0].name;
    setValues({ preview: result, files: files });
    if (setValue) {
      setValue(fieldName, {
        files: files,
        preview: result,
      });
    }
  };

  const handleSubFieldChange = async (name, value) => {
    if (value && name === "link") {
      let preview = await getBase64FromUrl(value);
      if (preview.includes("data:audio/")) {
        setValues({ preview: value.preview });
      } else {
        alert("Invalid URL.");
        return;
      }
    }

    if (setValue) {
      setValue(fieldName, values);
    }
  };

  const handleClear = (event) => {
    setValues(initialValues);
    if (setValue) {
      setValue(fieldName, values);
    }
    if (onClear) {
      onClear();
    }
  };

  let wrapperClasses =
    "flex w-full flex-wrap items-stretch text-left " + className;
  let rowClasses = "flex items-center w-full gap-4";
  let columnClasses =
    "bg-gray-100 border-[#005CB2] border-2 border-solid p-4 pr-5 " +
    (errors?.[name] ? "error" : "");
  let captionColumnClasses = "w-2/3";

  return (
    <div className={`${wrapperClasses}`}>
      <div className={`${rowClasses}`}>
        <div
          role="presentation"
          className={`${columnClasses}`}
          onClick={handlePreviewClick}
        >
          <input
            name="files"
            type="file"
            ref={fileInput}
            style={{ display: "none" }}
            accept={allowedFiles}
            onChange={handleFileChange}
          />

          <div className="w-full text-center pt-4 w-[180px] h-[130px]">
            {values.preview ? (
              <div className="w-full mb-4 break-word">{values.preview}</div>
            ) : (
              <AudioIcon />
            )}

            {!values.preview ? (
              <div>
                <p className="text-sm mb-1 mt-2 font-bold text-[#005CB2]">
                  Click to add audio
                </p>
                <p className="text-xs mb-0">or drag and drop here</p>
              </div>
            ) : (
              <div className="w-full text-center">
                <Button
                  theme="secondary"
                  size="default"
                  onClick={handleClear}
                  label="Clear"
                />
              </div>
            )}
          </div>
        </div>

        {showCaptionInput && (
          <div className={`${captionColumnClasses}`}>
            <TextArea
              label="Add caption or credit/s"
              className="text-[#969696]"
              name="caption"
              errors={{}}
              setValue={handleSubFieldChange}
            />
          </div>
        )}
      </div>

      {showUrlInput && (
        <TextInput
          label=""
          placeholder="Or insert link to the source file here"
          className="w-full placeholder-[#969696] mt-1"
          name="link"
          errors={{}}
          setValue={handleSubFieldChange}
        />
      )}

      {errors?.[name] && <p className="text-[red]">This field is required</p>}
    </div>
  );
};

export default Audio;
