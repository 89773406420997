import { MenuHeader } from "../../../components/MenuHeader";
import { MenuLink } from "../../../components/MenuLink";
const MENU_LINKS = [
  {
    id: 1,
    title: "PRIVACY POLICY",
    to: "/news/privacy-policy",
    active: true,
  },
  {
    id: 2,
    title: "COPYRIGHT",
    to: "",
    active: false,
  },
];

export function SiteSection() {
  return (
    <>
      <MenuHeader title="SITE" addClassName="pb-2 mb-4 mt-8 font-semibold" />
      <div className="space-y-2">
        {MENU_LINKS.filter((link) => link.active).map((link) => {
          return (
            <MenuLink
              key={`site_section_menu_link_${link.id}`}
              title={link.title}
              to={link.to}
              size="md:text-sm md:leading-6"
              addClassName="font-normal"
            />
          );
        })}
      </div>
    </>
  );
}
