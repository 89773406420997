export function dateRangeTodayToYear(end) {
  let options = [];
  let today = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (let i = today.getFullYear(); i >= end; i--) {
    for (let j = 11; j >= 0; j--) {
      let date = new Date(i, j, 1);
      if (today.getFullYear() === date.getFullYear() && j > date.getMonth()) {
        continue;
      }
      options.push({
        label: `${months[j]} ${i}`,
        value: `${date.getFullYear()}-${date.getMonth()}-${"01"}/${date.getFullYear()}-${date.getMonth()}-${"31"}`,
      });
    }
  }
  return options;
}
