import React, { useState } from "react";

const TextInput = ({
  label,
  showLabel = true,
  name,
  register = null,
  required = true,
  errors,
  className = "",
  inputClassName = "",
  placeholder = "",
  setValue = null,
  max = Infinity,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;

    if (value.length > max) {
      setInputValue(value.slice(0, max));
    } else {
      setInputValue(value);
      if (setValue) {
        setValue(name, value);
      }
    }
  };

  return (
    <div
      className={
        "mb-4 flex w-full flex-wrap items-stretch text-left " + className
      }
    >
      {label && (
        <label className={`${showLabel ? "" : "sr-only"}`}>{label}</label>
      )}
      <input
        value={inputValue}
        placeholder={placeholder}
        className={
          (errors?.[name] ? "error" : "") +
          " w-full form-control " +
          inputClassName
        }
        {...(register
          ? register(name, {
              required: required,
              onChange: handleChange,
            })
          : {})}
      />
      {errors?.[name] && <span>This field is required</span>}
    </div>
  );
};

export default TextInput;
