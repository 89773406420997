export function FluentCount() {
  return (
    <div className="flex items-center space-x-2">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2001_5532)">
          <path
            d="M11.1946 0H8.39819H5.60181V2.79638V5.60181H2.79638H0V8.39819V11.1946V14H2.79638V11.1946V8.39819H5.60181H8.39819V5.60181V2.79638H11.1946H14V0H11.1946Z"
            fill="#2BE2D8"
          />
          <path d="M2.79638 0H0V2.79638H2.79638V0Z" fill="#2BE2D8" />
        </g>
        <defs>
          <clipPath id="clip0_2001_5532">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div className="text-white text-sm">789</div>
    </div>
  );
}
