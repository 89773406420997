import { uploadMediaFile } from "./media";

export const generateJson = (formData) => {
  let jsonData = [];
  let index = 0;
  for (let i in formData) {
    jsonData.push({
      id: index++,
      type: i,
      component: JSON.stringify(formData[i]),
      htmlContent: "<!-- No Content -->",
    });
  }
  return jsonData;
};

export const formatFormData = (data) => {
  if (!data) {
    return data;
  }
  if (data.contentJson) {
    for (let i in data.contentJson) {
      if (data.contentJson[i].component) {
        data.contentJson[i].component = data.contentJson[i].component
          ? JSON.parse(data.contentJson[i].component)
          : null;
      }
    }
  }
  return data;
};

export const handleImageUpload = async (item) => {
  if (item && item.image && item.image.files instanceof FileList) {
    const data = await uploadMediaFile(item.image.files[0]);
    if (!data || !data.fileId) {
      console.warn("Unable to upload file for: " + JSON.stringify(item));
      return null;
    }
    return {
      id: data.fileId,
      preview: data.fileUrl,
    };
  } else {
    if (item?.post && item.post?.medias) {
      let medias = item?.post?.medias;
      for (let j in medias) {
        if ("featured_image_full" === medias[j].key) {
          return {
            id: medias[j]?.media?.id,
            preview: medias[j]?.media?.cdn_source_url,
          };
        }
      }
    }
  }
  return null;
};
