const stripHtml = (str) => {
  let tmp = document.implementation.createHTMLDocument("New").body;
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
};

const formatCustom = (o) => {
  if (!o || !o["type"]) {
    return {};
  }
  return {
    meta: {},
    image: o.image?.preview ? { preview: o.image?.preview } : null,
    excerpt: o.excerpt ? stripHtml(o.excerpt) : "",
    title: o.title,
    link: o.link,
    id: o.id,
  };
};

const formatMedia = (o) => {
  const result = {
    id: 0,
    title: "",
    excerpt: "",
    image: null,
    link: "",
    meta: null,
  };

  result.title = o?.title ? o?.title : o?.post?.title;
  result.excerpt = o?.excerpt ? o?.excerpt : stripHtml(o?.post?.excerpt);
  result.image = o?.image?.preview;
  result.link = "/home/" + o?.id;

  return result;
};

const formatPost = (o) => {
  const result = {
    id: 0,
    title: "",
    excerpt: "",
    image: null,
    link: "",
    meta: null,
  };

  result.title = o?.title ? o?.title : o?.post?.title;
  result.excerpt = o?.excerpt ? o?.excerpt : stripHtml(o?.post?.excerpt);
  result.image = o?.image?.preview
    ? o?.image
    : { preview: o?.post?.featuredImage };
  result.link = o?.url ? o?.url : o?.post?.url;

  return result;
};

const formatHustle = (o) => {
  const result = formatPost(o);
  result.excerpt = o?.excerpt ? o?.excerpt : o.post.jsonData.introduction;
  return result;
};
const formatShuffle = (o) => {
  const result = formatPost(o);
  result.excerpt = o?.excerpt ? o?.excerpt : o.post.note;

  if (!result?.meta) {
    result.meta = {};
  }

  if (!result.meta?.newOrganizationName) {
    result.meta.newOrganizationName = o?.post?.jsonData?.newOrganization?.name;
  }
  if (!result.meta?.newOrganizationRole) {
    result.meta.newOrganizationRole = o?.post?.jsonData?.newOrganization?.role;
  }
  if (!result.meta?.oldOrganizationName) {
    result.meta.oldOrganizationName = o?.post?.jsonData?.oldOrganization?.name;
  }
  if (!result.meta?.oldOrganizationRole) {
    result.meta.oldOrganizationRole = o?.post?.jsonData?.oldOrganization?.role;
  }
  return result;
};
const formatInfluencer = (o) => {
  const result = formatPost(o);

  if (!result?.meta) {
    result.meta = {};
  }

  if (!result.meta?.companyName) {
    result.meta.companyName = o.post.jsonData?.influencerCompany;
  }
  if (!result.meta?.companyRole) {
    result.meta.companyRole = o.post.jsonData?.influencerJobTitle;
  }
  return result;
};

const formatPostInput = (o) => {
  if (!o || !o["type"]) {
    return null;
  }
  if ("custom" === o.type) {
    return formatCustom(o);
  } else {
    switch (o.type) {
      case "hustle":
        return formatHustle(o);
      case "shuffle":
        return formatShuffle(o);
      case "influencer":
        return formatInfluencer(o);
      case "media":
        return formatMedia(o);
      default:
        return formatPost(o);
    }
  }
};

const generateSparkPlug = (o) => {
  if (!o || !o?.link || !o?.image?.preview) {
    return "";
  }

  return `<table style="border-spacing: 0;">
    <tr>
        <td>
            <a href="#" style="text-decoration: none; text-transform: uppercase; text-align:center;">
                <h3 style="color: #fff; font-family: Oswald,sans-serif;font-size:45px; margin-top: 20px;margin-bottom: 15px;">The <span style="color: #41E1D7;">Spark</span>Plug</h3>
            </a>
            <p>
                <a href="${o.link}"><img style="display: block;width:480px;" src="${o.image.preview}"/></a>
            </p>
        </td>
    </tr>
</table>`;
};

const generateCityVersePosts = (o) => {
  if (!o) {
    return "";
  }
  let markup = `<tr>
        <td align="center" style="background-color: #F5F5F5; padding: 20px;">
        <table width="660" style="width: 660px; margin-bottom: 15px;" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="vertical-align: top; width: 660px;">
                        <a href="https://cityverse.com" target="_blank">
                            <img src="https://stpetecatalyst.com/wp-content/uploads/2023/03/CTA-Cityverse-1.png" style="display:block; width:660px;" alt="" width="660"/>
                        </a>
                    </td>
                </tr>
            </table>`;
  let ok = 0;
  for (let i = 0; i < 5; i++) {
    let post = o["cityverse_post" + (i + 1)];
    if (!post) {
      continue;
    }
    post = formatPostInput({ type: "media", post: post });
    if (
      !post ||
      !post?.image ||
      !post?.image?.preview ||
      !post?.title ||
      !post?.link
    ) {
      continue;
    }
    markup += `<table width="660" style="width: 660px; margin-bottom: 15px;" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="vertical-align: top; width: 660px;">
                        <img src="https://stpetecatalyst.com/wp-content/uploads/2023/02/Rectangle-14-1.png" style="display:block; width:660px;" alt="" width="660"/>
                    </td>
                </tr>
                <tr>
                    <td style="vertical-align: top; width: 660px;">
                        <a href="${post.link}">
                            <img src="${post.image.preview}" style="display:block; width:660px;" alt="" width="660"/>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td style="padding:20px; vertical-align: top; background: linear-gradient(97.16deg, #1F0024 0%, #32005B 100%); border-radius: 0px 0px 20px 20px;">
                        <h1 style="font-size: 32px; padding-top:0; margin-top:0; margin-bottom: 15px; padding-bottom: 0;">
                            <a style="color: #41E1D7; font-family: 'Oswald', sans-serif; font-weight: 500; text-decoration: none;text-transform:uppercase;line-height: 1;" href="${post.link}">${post.title}</a>
                        </h1>
                        <p style="color:#FAFAFA; font-family: 'Oswald', sans-serif; font-weight: 400; font-size: 16px;">${post.excerpt}</p>
                    </td>
                </tr>
            </table>`;
    ok++;
  }
  markup += "</td></tr>";

  if (!ok) {
    markup = "";
  }

  return markup;
};

const generateHustle = (o) => {
  if (!o) {
    return "";
  }

  const post = formatPostInput(o);
  if (!post) {
    return "";
  }
  return `<tr>
        <td align="center"
            style="background-image: url(https://stpetecatalyst.com/wp-content/uploads/2023/03/The-Hustle-2.png); background-repeat: no-repeat; background-size: 100% 100%; padding: 30px 20px">
            <table style="border-spacing: 0;">
                <tr>
                    <td colSpan="2">
                        <h3 style="font-family: Oswald, serif; font-size: 20px; margin-top: 5px; padding-top: 0; margin-bottom: 20px; padding-bottom: 0; color: #2BE2D8; font-style: italic;">THE HUSTLE</h3>
                    </td>
                </tr>
                <tr>
                    <td style="width:200px; padding:0; background-color: #000; height: 100%; vertical-align:middle;  border-top-left-radius:20px;  border-bottom-left-radius:20px;">
                        <p style="margin-bottom: 0; margin-top:0; padding-bottom: 0; padding-top:0;">
                            <a href="#">
                                <img src="https://stpetecatalyst.com/wp-content/uploads/2023/03/Mask-group.png" alt=""width="220" style="display: block;width:220px;"/>
                            </a>
                        </p>
                    </td>
                    <td style="width:418px; vertical-align: top; background-color: #FFFFFF; padding:20px; border-top-right-radius:20px; border-bottom-right-radius:20px;">
                        <p style="color: #333333; margin-bottom: 10px; margin-top:0;">
                            <a style="font-family: Oswald, serif; font-size: 24px; color: #333333; font-weight: bold; text-decoration: none; line-height: 1;" href="${post?.link}">${post?.title}</a>
                        </p>
                        <p style="color: #333333; margin-bottom: 15px; margin-top: 0; font-size: 18px; font-weight: 600; font-family: Oswald, serif;">
                            ${post?.meta?.company}
                        </p>
                        <p style="margin-bottom: 0; margin-top:0; color: #333333;">
                            ${post?.excerpt}
                        </p>
                    </td>
                </tr>
            </table>
        </td>
    </tr>`;
};

const generateInfluencer = (o) => {
  if (!o) {
    return "";
  }

  const post = formatPostInput(o);
  if (!post) {
    return "";
  }

  return `<tr>
        <td align="center" style="background-color: #F5F5F5; padding: 20px; background-image: url(https://stpetecatalyst.com/wp-content/uploads/2023/06/Group-812.png); background-size: 100% 100%;">
        <table width="660" style="width: 660px; margin-bottom: 15px;" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="padding: 20px 20px 0;">
                        <h3 style="font-family: 'Montserrat', sans-serif; text-transform: uppercase; font-weight: bolder; font-style: italic; font-size:32px; color: #ffffff;">Influencers</h3>
                        <h2 style="font-family: 'Oswald', sans-serif; text-transform: uppercase; font-weight: bold; font-size:62px; word-break: break-word; line-height: 70px; color: #00FFFF; stroke: 1">${post.title}</h2>
                        <h4 style="font-family: 'Montserrat', sans-serif; color: #ffffff;"><span style="color: #00FFFF;">${post.meta?.companyRole}</span> at ${post.meta?.companyName}</h4>
                    </td>
                    <td style="padding: 20px 20px 0;">
                       <img src="${post.image?.preview}" style="width: 200px; height:auto;" alt="${post.title}"/>
                    </td>
                </tr>
            </table></td></tr>`;
};

const generateShuffle = (o) => {
  if (!o) {
    return "";
  }

  const post = formatPostInput(o);
  if (!post) {
    return "";
  }

  return `<tr>
        <td align="center" style="background-image: url(https://stpetecatalyst.com/wp-content/uploads/2023/03/The-Shuffle-1.png); background-repeat: no-repeat; background-size: 100% 100%; padding: 30px 20px">
            <table style="border-spacing: 0;">

                <tr>
                    <td colspan="2">
                        <h3 style="font-family: Oswald, serif; font-size: 20px; margin-top: 5px; padding-top: 0; margin-bottom: 20px; padding-bottom: 0; color: #000; font-style: italic;">THE SHUFFLE</h3>
                    </td>
                </tr>

                <tr>
                    <td style="width:200px; padding:0; background-color: #000; height: 100%; vertical-align:middle;  border-top-left-radius:20px;  border-bottom-left-radius:20px;">
                        <img src="${post.image?.preview}" alt="" width="220" style="display: block;width:220px;"/>
                    </td>
                    <td style="width:418px; vertical-align: top; background-color: #000000; padding:20px; border-top-right-radius:20px; border-bottom-right-radius:20px;">
                        <p style="margin-bottom: 10px; margin-top:0;">
                            <a style="font-family: Oswald, serif; font-size: 32px; color: #2BE2D8; font-weight: bold; text-decoration: none; line-height: 1;" href="${post.link}">${post.title}</a>
                        </p>
                        <table style="border-spacing: 0;">
                            <tr>
                                <td style="width:325px; vertical-align: top; padding:0; margin-bottom: 15px;">
                                    <h4 style="font-family: Oswald, serif; font-size: 16px; margin-top: 10px; padding-top: 0; margin-bottom: 0; padding-bottom: 0; color: #FFF; font-weight: 400; text-decoration: none; line-height: 1;">
                                        New Company
                                    </h4>
                                    <h3 style="font-family: Oswald, serif; font-size: 18px; margin-top: 10px; padding-top: 0; margin-bottom: 0; padding-bottom: 0; color: #2BE2D8; font-weight: bold; text-decoration: none; line-height: 1;">${post.meta?.newOrganizationName}</h3>
                                </td>
                                <td style="width:325px; vertical-align: top; padding:0;">
                                    <h4 style="font-family: Oswald, serif; font-size: 16px; margin-top: 10px; padding-top: 0; margin-bottom: 0; padding-bottom: 0; color: #FFF; font-weight: 400; text-decoration: none; line-height: 1;">
                                        Former Company
                                    </h4>
                                    <h3 style="font-family: Oswald, serif; font-size: 18px; margin-top: 10px; padding-top: 0; margin-bottom: 0; padding-bottom: 0; color: #2BE2D8; font-weight: bold; text-decoration: none; line-height: 1;">${post.meta?.oldOrganizationName}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:325px; vertical-align: top; padding: 20px 0 0;">
                                    <h4 style="font-family: Oswald, serif; font-size: 16px; margin-top: 10px; padding-top: 0; margin-bottom: 0; padding-bottom: 0; color: #FFF; font-weight: 400; text-decoration: none; line-height: 1;">
                                        New Position
                                    </h4>
                                    <h3 style="font-family: Oswald, serif; font-size: 18px; margin-top: 10px; padding-top: 0; margin-bottom: 0; padding-bottom: 0; color: #2BE2D8; font-weight: bold; text-decoration: none; line-height: 1;">${post.meta?.newOrganizationRole}</h3>
                                </td>
                                <td style="width:325px; vertical-align: top; padding: 20px 0 0;">
                                    <h4 style="font-family: Oswald, serif; font-size: 16px; margin-top: 10px; padding-top: 0; margin-bottom: 0; padding-bottom: 0; color: #FFF; font-weight: 400; text-decoration: none; line-height: 1;">
                                        Former Position
                                    </h4>
                                    <h3 style="font-family: Oswald, serif; font-size: 18px; margin-top: 10px; padding-top: 0; margin-bottom: 0; padding-bottom: 0; color: #2BE2D8; font-weight: bold; text-decoration: none; line-height: 1;">${post.meta?.oldOrganizationRole}</h3>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
    </tr>`;
};

const generateAd = (adA, adB) => {
  const foundA = adA && adA?.link && adA?.image && adA?.image?.preview;
  const foundB = adB && adB?.link && adB?.image && adB?.image?.preview;

  if (!foundA && !foundB) {
    return "";
  }

  let markup = "";
  if (foundA) {
    markup += `<td style="width:315px; vertical-align: top; padding:0;">
                <a href="${adA?.link}" style="padding:0;text-decoration: none;">
                    <img style="display:block; width: 315px;" src="${adA?.image?.preview}"/>
                </a>
            </td>`;
  }

  if (foundB) {
    markup += `<td style="width:315px; vertical-align: top; padding:0;">
                <a href="${adB?.link}" style="padding:0;text-decoration: none;">
                    <img style="display:block; width: 315px;" src="${adB?.image?.preview}"/>
                </a>
            </td>`;
  }

  return `<tr>
        <td align="center" style="background-color: #F5F5F5; padding: 0;">
            <table style="border-spacing: 20px;">
                <tr>
                    ${markup}
                </tr>
            </table>
        </td>
    </tr>`;
};

const generateGraphicArticle = (o) => {
  if (!o) {
    return "";
  }

  const post = formatPostInput(o);
  if (!post) {
    return "";
  }

  return `<tr>
        <td align="center"
            style="background-color: #F5F5F5; padding-top:20px; padding-bottom:0; padding-left:20px;padding-right: 20px;">
            <table width="660" style="width: 660px;" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="padding:0;">
                        <a href="${post.image.link}" style="color: #41E1D7;">
                            <img src="${post.image.preview}" alt="" width="660" style="width: 660px;display: block;"/>
                        </a>
                    </td>
                </tr>
            </table>
        </td>
    </tr>`;
};

const generateMediaArticles = (o) => {
  if (!o) {
    return "";
  }

  let markup = `<tr>
        <td align="center" style="background-color: #41E1D7; padding: 20px;">
            <table width="660" style="width: 660px; background-color: #111D2A; margin-bottom: 15px;" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="padding:0; vertical-align: top;" colspan="2">
                        <img src="https://stpetecatalyst.com/wp-content/uploads/2023/02/Frame-99.png" width="100%">
                    </td>
                </tr>
            </table>`;

  let ok = 0;

  for (let i = 0; i < 5; i++) {
    let post = formatPostInput(o["media" + (i + 1)]);
    if (
      !post ||
      !post?.image ||
      !post?.image?.preview ||
      !post?.link ||
      !post?.title ||
      !post?.excerpt
    ) {
      continue;
    }

    ok++;

    markup += `<table width="660" style="width: 660px; background-color: #111D2A; border-radius: 20px; margin-bottom: 15px;" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="padding:20px; vertical-align: top;">
                        <a href="${post.link}">
                            <img src="${post?.image?.preview}" style="display:block; width:200px;" alt="" width="200"/>
                        </a>
                    </td>
                    <td style="padding:20px; vertical-align: top;">
                        <h1 style="font-size: 32px; padding-top:0; margin-top:0; margin-bottom: 15px; padding-bottom: 0;">
                            <a style="color: #41E1D7; font-family: 'Oswald', sans-serif; font-weight: 500; text-decoration: none;text-transform:uppercase;line-height: 1;" href="${post.link}">${post.title}</a>
                        </h1>
                        <p style="color:#FAFAFA; font-family: 'Oswald', sans-serif; font-weight: 400; font-size: 16px;">${post.excerpt}</p>
                    </td>
                </tr>
            </table>`;
  }
  markup += "</td></tr>";

  if (!ok) {
    markup = "";
  }

  return markup;
};

function generateZaps(o) {
  if (!o) {
    return "";
  }
  let markup = `<tr>
        <td align="center" style="background-color: #18222E; padding: 20px;">
            <table width="660" style="width: 660px; background-color: #111D2A; margin-bottom: 15px;" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="padding:0; vertical-align: top;">
                        <img src="https://stpetecatalyst.com/wp-content/uploads/2020/05/zaps-header.png" alt="" width="660" style="width: 660px;display: block;"/>
                    </td>
                </tr>
            </table>`;

  markup += `<table width="660" style="width: 660px; border-collapse:separate; border-spacing: 0 1em;" cellpadding="0" border="0">`;

  let ok = 0;

  for (let i = 0; i < 5; i++) {
    let post = formatPostInput({ type: "zap", post: o["zap" + (i + 1)] });
    if (!post || !post?.link || !post?.title) {
      continue;
    }

    markup += `
                <tr style="background-color: #41E1D7;">
                    <td style="padding:20px; vertical-align: top;">
                        <img src="https://stpetecatalyst.com/wp-content/uploads/2020/05/spark.png" alt="" width="20" style="width: 20px;display: block;"/>
                    </td>
                    <td style="padding:20px; vertical-align: middle;">
                        <a href="${post.link}" style="color: #000000; font-size: 25px; font-family: 'Oswald', sans-serif; font-weight: 500; text-decoration: none;text-transform:uppercase;line-height: 1;">${post.title}</a>
                    </td>
                    <td style="width: 60px; text-align:center;">
                        <a href="${post.link}" style="color: #41E1D7;">
                            <img src="https://stpetecatalyst.com/wp-content/uploads/2020/05/more-icon.png" alt="" width="30" style="width: 30px;display: block;"/>
                        </a>
                    </td>
                </tr>`;

    ok++;
  }

  markup += "</table>";

  markup += "</td></tr>";

  if (!ok) {
    markup = "";
  }

  return markup;
}

const generateMainArticle = (o) => {
  if (!o) {
    return "";
  }

  const post = formatPostInput(o);
  if (!post) {
    return "";
  }

  return `<tr>
        <td align="center" style="background-color: #F5F5F5; padding:20px 20px 0;">
            <table width="660" style="width: 660px;margin-bottom: 20px;" cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="background-color: #111D2A;">
                        <a href="${post.link}" style=";margin:0;display: block;">
                            <img width="660" style="width:660px;margin:0;padding:0; display: block;" src="${post.image.preview}" alt="main image">
                        </a>
                    </td>
                </tr>
                <tr>
                    <td style="background-color: #111D2A; padding:20px 45px;">
                        <h1 style="font-size: 32px; padding-top:0; margin-top:0; margin-bottom: 15px; padding-bottom: 0;">
                            <a style="color: #41E1D7; font-family: 'Oswald', sans-serif; font-weight:500; text-decoration: none;line-height: 1;" href="${post.link}">${post.title}</a>
                        </h1>
                        <p style="color:#FAFAFA; font-family: 'Oswald', sans-serif; font-weight: 400; font-size: 16px;">${post.excerpt}</p>
                    </td>
                </tr>
            </table>
        </td>
    </tr>`;
};

const generateGridArticles = (data) => {
  let grid = [];
  let post;
  for (let i = 0; i < 4; i++) {
    post = formatPostInput(data["grid_post" + i]);
    if (
      !(
        !post ||
        !post?.image ||
        !post?.image?.preview ||
        !post?.link ||
        !post?.title
      )
    ) {
      grid.push(post);
    }
  }
  let markup = `<tr>
        <td align="center" style="background-color: #F5F5F5; padding: 0;">
            <table style="border-spacing: 20px;">`;

  let c,
    ok = 0;
  for (let i = 0; i < 2; i++) {
    markup += "<tr>";
    for (let j = 0; j < 2; j++) {
      if (!grid[c]) {
        continue;
      }
      post = grid[c];
      markup += `<td style="width:315px; background-color: #111D2A; vertical-align: top; padding:0;">
                        <a href="${post?.link}" style="padding:0;text-decoration: none;">
                            <img style="display:block; width: 315px;" width="315" src="${post?.image?.preview}" alt="${post?.title}">
                        </a>
                        <h3 style="font-size: 24px; margin:10px 30px;">
                            <a href="${post?.link}" style="color: #41E1D7; font-family: 'Oswald', sans-serif; font-weight:500; text-decoration: none;line-height: 1;">${post?.title}</a>
                        </h3>
                    </td>`;
      c++;
    }
    markup += "</tr>";
  }

  markup += "</table></td></tr>";

  if (!grid.length) {
    markup = "";
  }

  return markup;
};

export function generateHtml(data) {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="utf-8"> <!-- utf-8 works for most cases -->
    <meta name="viewport" content="width=device-width"> <!-- Forcing initial-scale shouldn't be necessary -->
    <meta http-equiv="X-UA-Compatible" content="IE=edge"> <!-- Use the latest (edge) version of IE rendering engine -->
    <meta name="x-apple-disable-message-reformatting">  <!-- Disable auto-scale in iOS 10 Mail entirely -->


    <!--[if mso]>
    <style>
        * {
            font-family: sans-serif !important;
        }
    </style>
    <![endif]-->

    <!--[if !mso]><!-->
    <style type="text/css">

        /* latin-ext */
        @font-face {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/oswald/v49/TK3iWkUHHAIjg752Fz8Ghe4.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        /* latin */
        @font-face {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 300;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/oswald/v49/TK3iWkUHHAIjg752GT8G.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* latin-ext */
        @font-face {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/oswald/v49/TK3iWkUHHAIjg752Fz8Ghe4.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        /* latin */
        @font-face {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/oswald/v49/TK3iWkUHHAIjg752GT8G.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* latin-ext */
        @font-face {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/oswald/v49/TK3iWkUHHAIjg752Fz8Ghe4.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        /* latin */
        @font-face {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/oswald/v49/TK3iWkUHHAIjg752GT8G.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* latin-ext */
        @font-face {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/oswald/v49/TK3iWkUHHAIjg752Fz8Ghe4.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        /* latin */
        @font-face {
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url(https://fonts.gstatic.com/s/oswald/v49/TK3iWkUHHAIjg752GT8G.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* cyrillic-ext */
        @font-face {
            font-family: 'PT Serif';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFbzr-tdg.woff2) format('woff2');
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
        }

        /* cyrillic */
        @font-face {
            font-family: 'PT Serif';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFSzr-tdg.woff2) format('woff2');
            unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        /* latin-ext */
        @font-face {
            font-family: 'PT Serif';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFYzr-tdg.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        /* latin */
        @font-face {
            font-family: 'PT Serif';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: local('PT Serif'), local('PTSerif-Regular'), url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFWzr8.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        /* cyrillic-ext */
        @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2) format('woff2');
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
        }

        /* cyrillic */
        @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2) format('woff2');
            unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
        }

        /* vietnamese */
        @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2) format('woff2');
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
        }

        /* latin-ext */
        @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2) format('woff2');
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }

        /* latin */
        @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }


    </style>
    <!--<![endif]-->


    <!-- CSS Reset : BEGIN -->
    <style type="text/css">
        table table table {
            table-layout: auto;
        }

        /* Uses a better rendering method when resizing images in IE. */
        img {
            -ms-interpolation-mode: bicubic;
        }

        /* What it does: A work-around for email clients meddling in triggered links. */
        *[x-apple-data-detectors], /* iOS */
        .x-gmail-data-detectors, /* Gmail */
        .x-gmail-data-detectors *,
        .aBn {
            border-bottom: 0 !important;
            cursor: default !important;
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        /* What it does: Prevents Gmail from displaying an download button on large, non-linked images. */
        .a6S {
            display: none !important;
            opacity: 0.01 !important;
        }

        /* If the above doesn't work, add a .g-img class to any image in question. */
        img.g-img + div {
            display: none !important;
        }

        /* What it does: Prevents underlining the button text in Windows 10 */
        .button-link {
            text-decoration: none !important;
        }
    </style>
    <title>SPC Newsletter</title>
    <!-- CSS Reset : END -->

</head>
<body>

<table width="700" style="width:700px; border: 1px solid #f0f0f0;" align="center" cellpadding="0" cellspacing="0" border="0">
    <!-- HEADER START -->
    <tr>
        <td align="center">
            <table width="700" style="width: 700px; background-color: #000;" cellpadding="20px" cellspacing="0" border="0">
                <tr>
                    <td style="text-align:center;">
                        <a href="https://stpetecatalyst.com/" target="_blank">
                            <!-- LOGO -->
                            <img width="300" src="https://stpetecatalyst.com/wp-content/uploads/2023/03/Group-811.png" style="width: 300px; display:inline; border-style: none;">
                        </a>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <!-- HEADER END -->

    <!-- Top Main Article -->
    ${generateMainArticle(data?.top_main_article)}
    
    <!-- Grid posts -->
    ${generateGridArticles(data)}
    
    <!-- Ad #1 & Ad #2 -->
    ${generateAd(data?.advertisement1, data?.advertisement2)}

    <!-- The 2nd Main Article -->
    ${generateMainArticle(data?.second_main_article)}
    
    <!-- Zap Articles -->
    ${generateZaps(data)}

    <!-- Media Articles -->
    ${generateMediaArticles(data)}

    <!-- Graphic Article -->
    ${generateGraphicArticle(data?.graphic_article)}

    <!-- Ad #3 & Ad #4 -->
    ${generateAd(data?.advertisement3, data?.advertisement4)}

    <!-- Community Calendar -->
    <tr>
        <td align="center" style="background-color: #18222E; padding: 20px;">
            <table width="660"
                   style="width: 660px; background-color: #111D2A; margin-bottom: 15px;"
                   cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td style="padding:0; vertical-align: top;">
                        <img src="https://stpetecatalyst.com/wp-content/uploads/2020/05/community-calendar.png" alt="" style="width: 660px; display: block;" width="660"/>
                    </td>
                </tr>
            </table>
            <table width="660" style="width: 660px; border-collapse:separate; border-spacing: 10px 0;" cellpadding="0" border="0">
                <tr>
                    <td style="width:202px; vertical-align: top;">
                        <a href="https://stpetecatalyst.com/events/ymca-spin-a-rama-2/" style="text-decoration: none; display: block;">
                            <img src="https://stpetecatalyst.com/wp-content/uploads/2022/02/Spin2021-D-400x240.png" alt="" width="202" style="margin-bottom: 15px;"/>
                            <h4 style="font-family: Oswald, sans-serif; color: #FAFAFA; line-height: 1; text-transform: uppercase; font-size: 12px; margin-bottom: 15px; padding-top:0; padding-bottom:0; margin-top:0;">Feb 25 @ 08:00 AM</h4>
                            <h3 style="font-family: Oswald, sans-serif; color: #FAFAFA; line-height: 1.2; font-size: 24px; margin-bottom: 0; padding-top:0; padding-bottom:0; margin-top:0;">Spin-a-rama</h3>
                        </a>
                    </td>
                    <td style="width:202px; vertical-align: top;">
                        <a href="https://stpetecatalyst.com/events/radio-theatre-project-live-2/" style="text-decoration: none; display: block;">
                            <img src="https://stpetecatalyst.com/wp-content/uploads/2023/02/rtp-400x240.png" alt="" width="202" style="margin-bottom: 15px;"/>
                            <h4 style="font-family: Oswald, sans-serif; color: #FAFAFA; line-height: 1; text-transform: uppercase; font-size: 12px; margin-bottom: 15px; padding-top:0; padding-bottom:0; margin-top:0;">Feb 27 @ 07:00 PM</h4>
                            <h3 style="font-family: Oswald, sans-serif; color: #FAFAFA; line-height: 1.2; font-size: 24px; margin-bottom: 0; padding-top:0; padding-bottom:0; margin-top:0;">Radio Theatre Project LIVE</h3>
                        </a>
                    </td>
                    <td style="width:202px; vertical-align: top;">
                        <a href="https://stpetecatalyst.com/events/friday-night-bites/" style="text-decoration: none; display: block;">
                            <img src="https://stpetecatalyst.com/wp-content/uploads/2023/02/renee-fri-e1675733423713-400x240.png" alt="" width="202" style="margin-bottom: 15px;"/>
                            <h4 style="font-family: Oswald, sans-serif; color: #FAFAFA; line-height: 1; text-transform: uppercase; font-size: 12px; margin-bottom: 15px; padding-top:0; padding-bottom:0; margin-top:0;">Mar 03 @ 05:00 PM</h4>
                            <h3 style="font-family: Oswald, sans-serif; color: #FAFAFA; line-height: 1.2; font-size: 24px; margin-bottom: 0; padding-top:0; padding-bottom:0; margin-top:0;">Friday Night Bites</h3>
                        </a>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    
    <!-- Shuffles -->
    ${generateShuffle(data?.shuffle)}

    <!-- Hustles -->
    ${generateHustle(data?.hustle)}
   
    <!-- Influencers -->
    ${generateInfluencer(data?.influencer)}

    <!-- CityVerse posts -->
    ${generateCityVersePosts(data)}
    
    <!-- Ad #5 & Ad #6 -->
    ${generateAd(data?.advertisement5, data?.advertisement6)}

    <!-- 
        Comments
        TODO: Needs Implementation
     -->
    <tr>
        <td style="padding:20px;">
            <table style="background-color: #000000; border-bottom: 10px solid #2BE2D8; border-top-left-radius: 20px; border-top-right-radius: 20px;">
                <thead>
                <tr>
                    <td style="padding:20px;">
                        <img src="https://stpetecatalyst.com/wp-content/uploads/2023/03/Group-678.png" style="width:30px; height:30px"> <span style="text-transform: uppercase; color: #fff; font-style: italic; font-size: 32px; font-family: Oswald,serif; font-weight: bold;">IDEA STACKING</span>
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style="padding:20px;">
                        <img src="https://stpetecatalyst.com/wp-content/uploads/2023/03/quote.png" width="16px;">
                        <span style="padding-left:20px; font-family: Montserrat,serif; font-size: 16px; color: #2BE2D8;">Venenatis urna cursus eget nunc. Tincidunt praesent semper feugiat nibh. Dui nunc mattis enim ut tellus. Integer vitae justo eget magna fermentum iaculis eu non. Leo integer malesuada nunc vel risus commodo viverra maecenas. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Ornare suspendisse sed nisi lacus sed.Quis varius quam quisque id diam vel quam elementum.Nisl nisi scelerisque eu ultrices vitae auctor eu augue . . .</span>
                    </td>
                </tr>
                <tr>
                    <td style="padding:5px 20px;">
                        <img alt="Name" src="https://fakeimg.pl/28x28?font=lobster" height="28" style="border-radius: 50%;"> <span style="padding-left:25px; font-size: 28px; color: #fff; font-family: Oswald,serif;">Name</span>
                    </td>
                </tr>
                <tr>
                    <td style="padding:20px;">
                        <p style="text-align:center; font-family: Oswald; margin-bottom: 0; margin-top:0;padding-bottom: 0; padding-top: 0; font-size: 16px; color: #fff;">
                            In response to <a style="color: #fff; font-weight: bold; text-decoration: underline;" href="#">Consultant: Sugar Hill, Rays/Hines proposals are top contenders</a></p>
                    </td>
                </tr>
                <tr>
                    <td style="padding-top:20px; padding-bottom: 40px;">
                        <p style="margin-bottom: 0; margin-top: 0; padding-bottom: 0; padding-top: 0; text-align: center;">
                            <a href="#" style="padding:10px 20px; color: #2BE2D8; border: 2px solid #2BE2D8; border-radius: 20px; text-decoration: none; font-family: Montserrat,serif; font-style: italic;">What do you think?</a>
                        </p>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td style="padding:0;margin:0;border:0;">
                        <table style="background-color: #000000;">
                            <tbody>
                            <tr>
                                <td style="padding:20px;">
                                    <img src="https://stpetecatalyst.com/wp-content/uploads/2023/03/quote.png" width="16px;">
                                    <span style="padding-left:20px; font-family: Montserrat,serif; font-size: 16px; color: #2BE2D8;">Venenatis urna cursus eget nunc. Tincidunt praesent semper feugiat nibh. Dui nunc mattis enim ut tellus. Integer vitae justo eget magna fermentum iaculis eu non. Leo integer malesuada nunc vel risus commodo viverra maecenas. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Ornare suspendisse sed nisi lacus sed.Quis varius quam quisque id diam vel quam elementum.Nisl nisi scelerisque eu ultrices vitae auctor eu augue . . .</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:5px 20px;">
                                    <img alt="Name" src="https://fakeimg.pl/28x28?font=lobster" height="28" style="border-radius: 50%;">
                                    <span style="padding-left:25px; font-size: 28px; color: #fff; font-family: Oswald,serif;">Name</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:20px;">
                                    <p style="text-align:center; font-family: Oswald; margin-bottom: 0; margin-top:0;padding-bottom: 0; padding-top: 0; font-size: 16px; color: #fff;">In response to <a style="color: #fff; font-weight: bold; text-decoration: underline;" href="#">Consultant: Sugar Hill, Rays/Hines proposals are top contenders</a></p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding-top:20px; padding-bottom: 40px;">
                                    <p style="margin-bottom: 0; margin-top: 0; padding-bottom: 0; padding-top: 0; text-align: center;">
                                        <a href="#" style="padding:10px 20px; color: #2BE2D8; border: 2px solid #2BE2D8; border-radius: 20px; text-decoration: none; font-family: Montserrat,serif; font-style: italic;">What do you think?</a>
                                    </p>
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    </td>
                </tr>
                </tfoot>
            </table>
        </td>
    </tr

    <!-- Footer/Spark Plug -->
    <tr>
        <td align="center" style="background-image: url(https://stpetecatalyst.com/wp-content/uploads/2023/03/Footer-1.png); background-size: 100% 100%; padding: 20px 20px 0;">
            ${generateSparkPlug(data.spark_plug)}
            <table style="border-spacing: 0;width:658px;">
                <tr>
                    <td style="text-align: center;">
                        <h3 style="color: #ffffff; font-size: 28px; margin-top: 25px;margin-bottom: 15px; font-family: Montserrat, Arial, sans-serif;">We want to hear from you!</h3>
                        <a href="https://stpetecatalyst.com/contribute/">
                            <img src="https://stpetecatalyst.com/wp-content/uploads/2023/03/Group-809.png"/>
                        </a>
                    </td>
                </tr>
            </table>
            <table style="border-spacing: 0; padding-top: 40px; padding-bottom: 40px;">
                <tr>
                    <td style="text-align:center;">
                        <img width="300"
                             src="https://stpetecatalyst.com/wp-content/uploads/2018/04/STP-logo-01-1.png"
                             style="width: 300px; display:block; margin:0 auto; border-style: none;">
                    </td>
                </tr>
                <tr>
                    <td style="margin-bottom: 20px;">
                        <p style="text-align:center;">
                            <a href="https://stpetecatalyst.com/" style="margin-right: 15px; text-decoration: none;text-transform: uppercase;color: #D1D1D1;font-family: Oswald, sans-serif; font-size: 16px;">Subscribe</a>
                            &nbsp;&nbsp;
                            <unsubscribe style="margin-right: 15px; text-decoration: none;text-transform: uppercase;color: #D1D1D1;font-family: Oswald, sans-serif; font-size: 16px;">
                                Unsubscribe
                            </unsubscribe>
                            &nbsp;&nbsp;
                            <a href="https://stpetecatalyst.com/contact/" style="margin-right: 15px; text-decoration: none;text-transform: uppercase;color: #D1D1D1;font-family: Oswald, sans-serif; font-size: 16px;">Contact</a>
                            &nbsp;&nbsp;
                            <a href="https://stpetecatalyst.com/partner/" style="text-decoration: none;text-transform: uppercase;color: #D1D1D1;font-family: Oswald, sans-serif; font-size: 16px;">Partner</a>
    
                        </p>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    
</table>

</body>
</html>`;
}
