import { ArticleTile, ArticleTileLoading } from "../../components/ArticleTile";
import {
  FeaturedPost,
  FeaturedPostLoading,
} from "../../components/FeaturedPost";
import { SlideButton } from "../../components/SlideButton";
import { useFeaturedSlideContent } from "../../utils/useSlideContent";
import { PostType } from "../../types";
import { Badge } from "../../components/Badge";
import { VideoTile } from "../../components/VideoTile/VideoTile";
import { ImpactTile } from "./ImpactTile";
import { Link } from "react-router-dom";

export function Impact() {
  const {
    posts,
    isLoading,
    featuredPost,
    slideForward,
    slideBack,
    slideBackDisabled,
    slideForwardDisabled,
    slideFeaturedPostForward,
    slideFeaturedPostBack,
    slideFeaturedPostBackDisabled,
  } = useFeaturedSlideContent<PostType>({
    requestUrl: "posts?categoryIds=12",
  });

  return (
    <div className="bg-white px-5 relative grid lg:grid-cols-8 gap-5 rounded-tr-lg rounded-br-lg rounded-bl-lg">
      <div className="flex flex-col lg:col-span-4 my-5 md:pr-5 lg:border-r">
        {isLoading ? (
          <FeaturedPostLoading />
        ) : (
          <FeaturedPost
            post={featuredPost}
            badge={<Badge appearance="dark" title="impact" />}
          />
        )}

        <div className="flex flex-col grow justify-end">
          <div className="flex justify-between">
            <SlideButton
              direction="back"
              disabled={slideBackDisabled}
              onClick={slideFeaturedPostBack}
            />
            <SlideButton
              direction="forward"
              onClick={slideFeaturedPostForward}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:col-span-4">
        <div className="divide-y">
          {(isLoading ? Array.from(new Array(4)) : posts).map((post, i) =>
            post ? (
              <ArticleTile
                key={post.id}
                post={post}
                badge={
                  <Badge appearance="dark" title="impact" size="text-xs" />
                }
              />
            ) : (
              <ArticleTileLoading key={i} />
            )
          )}
        </div>

        <div className="flex flex-col grow justify-end">
          <div className="flex justify-between my-5">
            <SlideButton
              direction="back"
              onClick={slideBack}
              disabled={slideBackDisabled}
            />
            <SlideButton
              direction="forward"
              onClick={slideForward}
              disabled={slideForwardDisabled}
            />
          </div>
        </div>
      </div>

      {/* <div className="lg:col-span-3 my-5 px-5 border-l">
        <VideoTile title="Impact Catalyst" />
        <div className="border-l-[5px] border-black bg-primary text-black uppercase mt-4 p-2 text-sm leading-4 inline-block">
          Impact Connector
        </div>
        <ImpactTile />
        <ImpactTile />
        <ImpactTile />
      </div> */}

      <Link
        to="/impact"
        title="KEEP EXPLORING"
        className="absolute block right-4 -bottom-16"
      >
        <div className="bg-black text-2xl font-montserrat italic font-semibold rounded-lg py-2 px-4 text-primary border-b-2 border-b-primary text-center hidden md:block">
          KEEP EXPLORING
        </div>
      </Link>
    </div>
  );
}
