import { PostLink } from "../../components/PostLink";
import { Badge } from "../../components/Badge";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import type { PostType } from "../../types";

type MediaTileProps = {
  post: PostType;
  modalSection: Boolean;
};

export function MediaTile({ post, modalSection }: MediaTileProps) {
  return (
    <PostLink
      slug={`media/${post?.slug}`}
      preloadData={post}
      title={sanitize(post?.title)}
      className={`col-span-12 lg:col-span-1 bg-black rounded-2xl grow block ${
        modalSection ? "p-0" : "p-4 space-y-4"
      }`}
    >
      <div className="w-full bg-gray h-40 rounded-tl-2xl rounded-tr-2xl overflow-hidden">
        {post?.featuredImage && (
          <img
            alt={sanitize(post?.title)}
            src={post?.featuredImage}
            title={sanitize(post?.title)}
            className={`aspect-video bg-black rounded-t-2xl brightness-100 group-hover:brightness-75 transition duration-300 items-center ${
              modalSection ? "object-cover" : "object-contain"
            }`}
          />
        )}
      </div>
      <div className={`${modalSection ? "p-5" : ""}`}>
        <Badge title={post?.primaryCategory.name} size="text-sm" />
        <div
          className="font-semibold text-xl text-white mt-4"
          dangerouslySetInnerHTML={sanitizeForReact(post?.title)}
        ></div>
      </div>
    </PostLink>
  );
}
