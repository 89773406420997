import { FacebookIcon } from "../FacebookIcon";
import { TwitterIcon } from "../TwitterIcon";
import { LinkedinIcon } from "../LinkedinIcon";

type ShareBarProps = {
  url: string;
  addClassName?: string;
};

export function ShareBar({ url, addClassName }: ShareBarProps) {
  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterShare = `https://twitter.com/intent/tweet?text=${url}`;
  const linkedinShare = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

  return (
    <div className={`flex space-x-4 ${addClassName}`}>
      <a href={facebookShare} target="_blank" title="Share to FaceBook">
        <FacebookIcon addClassName="text-primary hover:text-black" />
      </a>
      <a href={twitterShare} target="_blank" title="Share to Twitter">
        <TwitterIcon addClassName="text-primary hover:text-black" />
      </a>
      <a href={linkedinShare} target="_blank" title="Share to Linkedin">
        <LinkedinIcon addClassName="text-primary hover:text-black" />
      </a>
    </div>
  );
}
