import { SectionHeader } from "../../components/SectionHeader";
import { SlideButton } from "../../components/SlideButton";
import { useSlideContent } from "../../utils/useSlideContent";
import { MediaLoading } from "./MediaLoading";
import { MediaTile } from "./MediaTile";
import { useDetectMobile } from "../../utils/isMobile";
import { Advertisement } from "../../components/Advertisement";
import blockBackground from "./block-background.svg";
import blockBackgroundMobile from "./block-background-mobile.svg";
import type { PostType } from "../../types";

type MediaPost = {
  post: PostType;
};

export function Media() {
  const { isMobile } = useDetectMobile();
  const postCount = isMobile ? 1 : 4;
  const { posts, isLoading, slideForward, slideBack, slideBackDisabled } =
    useSlideContent<MediaPost[]>({
      requestUrl: "posts?tagIds=2267",
      count: postCount,
    });

  return (
    <>
      <div
        style={{
          backgroundImage: isMobile
            ? `url(${blockBackgroundMobile})`
            : `url(${blockBackground})`,
        }}
        className="bg-contain md:rounded-2xl mt-10 border-b-4 border-t-4 border-[#F0F0F0] md:border-none"
      >
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(43, 226, 216, 0) 0%, rgba(43, 226, 216, 0.31) 42.03%, #2BE2D8 100%)",
          }}
          className="md:rounded-2xl p-5"
        >
          <SectionHeader title="media" gradient={false} border={false} />

          <div className="grid grid-cols-4 lg:gap-x-4 gap-y-4 lg:gap-y-0">
            {(isLoading ? Array.from(new Array(postCount)) : posts).map(
              (post, i) =>
                post ? (
                  <MediaTile key={post.id} post={post} modalSection={false} />
                ) : (
                  <MediaLoading key={i} />
                )
            )}
          </div>

          <div className="mt-4 flex flex-col grow justify-end">
            <div className="flex justify-between">
              <SlideButton
                direction="back"
                onClick={slideBack}
                disabled={slideBackDisabled}
              />
              <SlideButton direction="forward" onClick={slideForward} />
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden pt-10">
        <Advertisement
          path="/21735245768/SPC_PL_HP_MREC_3"
          size={[300, 250]}
          id="div-gpt-ad-1682306808816-media"
        />
      </div>
    </>
  );
}
