import { createBrowserRouter } from "react-router-dom";
import Root from "./root.component";
import { Home } from "./Home";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import { ZapModal } from "./sections/Zaps/ZapModal";
import { InfluencerModal } from "./sections/Influencer/InfluencerModal";
import { MediaModal } from "./sections/Media/MediaModal";
import { HustleModal } from "./sections/Hustle/HustleModal";
import { PostModal } from "./sections/Post/PostModal";
import { ShuffleModal } from "./sections/Shuffle/ShuffleModal";
import { Journalist } from "./journalist";

import { Index as ArticleIndex } from "./journalist/pages/Articles/Index";
import { Create as CreateArticle } from "./journalist/pages/Articles/Create";
import { Edit as EditArticle } from "./journalist/pages/Articles/Edit";

import { Index as HustleIndex } from "./journalist/pages/Hustle/Index";
import { Create as HustleCreate } from "./journalist/pages/Hustle/Create";
import { Edit as HustleEdit } from "./journalist/pages/Hustle/Edit";

import { Index as ShuffleIndex } from "./journalist/pages/Shuffle/Index";
import { Create as ShuffleCreate } from "./journalist/pages/Shuffle/Create";
import { Edit as ShuffleEdit } from "./journalist/pages/Shuffle/Edit";

import { Index as InfluencerIndex } from "./journalist/pages/Influencer/Index";
import { Create as InfluencerCreate } from "./journalist/pages/Influencer/Create";
import { Edit as InfluencerEdit } from "./journalist/pages/Influencer/Edit";

import { Index as ZapsIndex } from "./journalist/pages/Zap/Index";
import { Create as CreateZap } from "./journalist/pages/Zap/Create";
import { Edit as EditZap } from "./journalist/pages/Zap/Edit";

import { Index as DailyEditionIndex } from "./journalist/pages/DailyEdition/Index";
import { Create as DailyEditionCreate } from "./journalist/pages/DailyEdition/Create";
import { View as DailyEditionView } from "./journalist/pages/DailyEdition/View";
import { Edit as DailyEditionEdit } from "./journalist/pages/DailyEdition/Edit";

import { Index as EventsIndex } from "./journalist/pages/Events/Index";

import PrivacyPolicy from "./sections/PrivacyPolicy/PrivacyPolicy";
import TaggedContent from "./sections/TaggedContent/TaggedContent";
import Support from "./sections/Support/Support";
import MeetTheTeam from "./sections/Team/MeetTheTeam";

import { DailyEditionSingle } from "./sections/DailyEdition/DailyEditionSingle";

const categoryPages = [
  "know",
  "innovate",
  "thrive",
  "create",
  "place",
  "impact",
  "venture",
];

import { DailyEdition } from "./sections/DailyEdition";

export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      children: [
        ...categoryPages.map((category) => ({
          path: `/${category}`,
          element: <Home category={category} />,
        })),
        {
          path: "/tags/:tagId",
          element: <TaggedContent />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/start-supporting",
          element: <Support />,
        },
        {
          path: "/meet",
          element: <MeetTheTeam />,
        },
        {
          path: "/",
          element: <Home />,
          children: [
            {
              path: "/zap/:id",
              element: <ZapModal />,
            },
            {
              path: "/influencer/:slug",
              element: <InfluencerModal />,
            },
            {
              path: "/hustle/:slug",
              element: <HustleModal />,
            },
            {
              path: "/media/:slug",
              element: <MediaModal />,
            },
            {
              path: "/:slug",
              element: <PostModal />,
            },
            {
              path: "/shuffle/:slug",
              element: <ShuffleModal />,
            },
          ],
        },
        {
          path: "daily-edition",
          element: <DailyEdition />,
        },
        {
          path: "daily-edition/:id",
          element: <DailyEditionSingle />,
        },
      ],
    },
    {
      path: "journalist",
      element: (
        <AuthenticatedRoute>
          <Journalist />
        </AuthenticatedRoute>
      ),
      children: [
        {
          path: "post",
          children: [
            { path: "", element: <ArticleIndex /> },
            { path: "create", element: <CreateArticle /> },
            { path: "edit/:id", element: <EditArticle /> },
          ],
        },
        {
          children: [
            { path: "", element: <DailyEditionIndex /> },
            { path: "create", element: <DailyEditionCreate /> },
          ],
        },
        {
          path: "daily-edition",
          children: [
            { path: "", element: <DailyEditionIndex /> },
            { path: "create", element: <DailyEditionCreate /> },
            { path: "view/:id", element: <DailyEditionView /> },
            { path: "edit/:id", element: <DailyEditionEdit /> },
          ],
        },
        {
          path: "influencer",
          children: [
            { path: "", element: <InfluencerIndex /> },
            { path: "create", element: <InfluencerCreate /> },
            { path: "edit/:id", element: <InfluencerEdit /> },
          ],
        },
        {
          path: "shuffle",
          children: [
            { path: "", element: <ShuffleIndex /> },
            { path: "create", element: <ShuffleCreate /> },
            { path: "edit/:id", element: <ShuffleEdit /> },
          ],
        },
        {
          path: "hustle",
          children: [
            { path: "", element: <HustleIndex /> },
            { path: "create", element: <HustleCreate /> },
            { path: "edit/:id", element: <HustleEdit /> },
          ],
        },
        {
          path: "events",
          children: [{ path: "", element: <EventsIndex /> }],
        },
        {
          path: "zap",
          children: [
            { path: "", element: <ZapsIndex /> },
            { path: "create", element: <CreateZap /> },
            { path: "edit/:id", element: <EditZap /> },
          ],
        },
      ],
    },
  ],
  {
    basename: "/news",
  }
);
