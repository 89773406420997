import { useEffect, useState } from "react";
import DropdownSimple from "../Inputs/DropdownSimple";
import TextInput from "../Inputs/TextInput";
import TextArea from "../Inputs/TextArea";
import Image from "../Inputs/Image";
import DropdownAsync from "../Inputs/DropdownAsync";
import { SubSection } from "../Layout/SubSection";
import { searchQueryRequest } from "../../../utils/request";
import { PostTypes } from "../../constants";
import { ArticlePickerComplexEntry } from "../../utils/models";
import { stripHtml } from "../../utils/strings";

export function ArticlePickerComplex({
  label,
  name,
  setValue,
  allowedPostTypes = [],
  value = null,
}) {
  const initialValues: ArticlePickerComplexEntry = {
    type: "custom",
    post: null,
    title: "",
    excerpt: "",
    link: "",
    image: null,
    tenant: null,
  };

  const [values, setPickerValues] = useState(value ? value : initialValues);

  const validPostTypes =
    Array.isArray(allowedPostTypes) && allowedPostTypes.length > 0
      ? PostTypes.filter((item) => allowedPostTypes.includes(item.value))
      : PostTypes;

  const setInputValue = (fieldName, newValue) => {
    let newData = null;
    if ("post" === fieldName) {
      newData = {
        [fieldName]: newValue,
        title: newValue.title,
        excerpt: newValue.excerpt ? stripHtml(newValue.excerpt) : "",
        image: newValue.featuredImage
          ? { preview: newValue.featuredImage }
          : null,
        link: newValue.url,
      };
    } else {
      newData = { [fieldName]: newValue };
    }

    const newValues = { ...values, ...newData };
    setPickerValues(newValues);

    if (setValue) {
      setValue(name, newValues);
    }
  };

  const loadOptions = (inputValue) => {
    return searchQueryRequest(values.type, { text: inputValue });
  };

  const overrideWarning = () => {
    let overrideWarningMessage =
      "Specifying the values for title, excerpt, etc below will override the ones form the selected post.";
    let overrideWarningMarkup = (
      <p className={`${"italic text-xs mb-0 text-gray-300 mt-1"}`}>
        {overrideWarningMessage}
      </p>
    );
    return values.type === "post" ? overrideWarningMarkup : "";
  };

  useEffect(() => {
    if (value) {
      setPickerValues(value);
    }
  }, [value]);

  return (
    <>
      <div>
        <SubSection>
          <DropdownSimple
            name="type"
            showLabel={false}
            label="Type"
            options={validPostTypes}
            value={values.type}
            setValue={setInputValue}
          />
          {overrideWarning()}

          {values.type != "custom" ? (
            <DropdownAsync
              id={values.type}
              className="mt-2"
              name="post"
              showLabel={true}
              label="Post"
              value={values.post}
              setValue={setInputValue}
              placeholder="Search for existing post..."
              getOptionLabel={(e) => e.title}
              getOptionValue={(e) => e.id}
              loadOptions={loadOptions}
            />
          ) : (
            ""
          )}
        </SubSection>

        <SubSection>
          <TextInput
            name="title"
            showLabel={true}
            label="Title"
            value={values.title}
            setValue={setInputValue}
          />
        </SubSection>

        <SubSection>
          <TextArea
            name="excerpt"
            showLabel={true}
            label="Excerpt"
            value={values.excerpt}
            setValue={setInputValue}
          />
        </SubSection>

        <SubSection>
          <TextInput
            name="link"
            showLabel={true}
            label="URL"
            value={values.link}
            setValue={setInputValue}
          />
        </SubSection>

        <SubSection label="Photo">
          <Image name="image" value={values.image} setValue={setInputValue} />
        </SubSection>
      </div>
    </>
  );
}
