import { Tags } from "../../utils/models";
import { Link } from "react-router-dom";

type TagListProps = {
  tags: Array<Tags>;
};

export function TagList({ tags }: TagListProps) {
  return (
    <div>
      {tags && (
        <div className="flex items-center flex-wrap pt-7 mt-7 border-t border-[#CACACA]">
          {tags.map((tag, i) => (
            <Link
              to={`/tags/${tag.id}`}
              key={i}
              className="text-xs md:text-sm text-[#4D4D4D] hover:text-primary mr-2 capitalize border hover:border-primary border-[#CACACA] rounded-full p-2 md:p-3 leading-none mb-2"
            >
              #{tag.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
