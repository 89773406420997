import { useState } from "react";
import { InputFieldSizes, InputFieldStyles } from "../../constants";
import Label from "../Label";

const DropdownSimple = ({
  label,
  name,
  showLabel = true,
  theme = "default",
  size = "default",
  required = true,
  register = null,
  errors = {},
  className = "",
  inputClassName = "",
  value = "",
  options = [],
  setValue = null,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (setValue) {
      setValue(name, newValue);
    }
  };

  let fieldClasses =
    (InputFieldStyles[theme]
      ? InputFieldStyles[theme]
      : InputFieldStyles.default) + " ";

  fieldClasses += InputFieldSizes[size]
    ? InputFieldSizes[size]
    : InputFieldSizes.default;

  if (errors?.[name]) {
    fieldClasses += " error";
  }
  if (inputClassName) {
    fieldClasses += ` ${inputClassName}`;
  }

  let wrapperClasses = "flex w-full flex-wrap items-stretch text-left";
  wrapperClasses += " " + className;

  return (
    <div className={`${wrapperClasses}`}>
      {label ? (
        <Label className={`${showLabel ? "" : "sr-only"}`} text={label} />
      ) : (
        ""
      )}
      <select
        name={name}
        onChange={handleChange}
        value={inputValue}
        className={`${fieldClasses}`}
        {...(register ? register(name, { required: required }) : {})}
      >
        {options.map((item) => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {errors?.[name] && <span>This field is required</span>}
    </div>
  );
};

export default DropdownSimple;
