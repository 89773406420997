import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { router } from "./router";

type NewsRootProps = {
  name: string;
  queryClient: QueryClient;
};

function RootWithProviders({ queryClient }: NewsRootProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: RootWithProviders,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    console.error(err, info);
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
