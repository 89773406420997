// TODO: Update footer anchor links with proper destinations
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LinksSection } from "./sections/LinksSection";
import { SiteSection } from "./sections/SiteSection";
import { CatalystIcon } from "./CatalystIcon";
import { EmailIcon } from "../../components/EmailIcon";
import { FacebookIcon } from "../../components/FacebookIcon";
import { TwitterIcon } from "../../components/TwitterIcon";
import { InstagramIcon } from "../../components/InstagramIcon";
import { LinkedinIcon } from "../../components/LinkedinIcon";

export function Footer() {
  return (
    <div className="container px-4 md:px-24 bg-black md:rounded-tr-2xl md:rounded-tl-2xl md:mt-10 pt-20 text-white">
      <div className="grid grid-cols-12 lg:gap-x-14">
        <div className="md:text-sm font-['PT+Serif'] col-span-12 lg:col-span-4 xl:col-span-3 mb-16 md:mb-0 order-2 md:order-none">
          <p className="mb-8 leading-6">The St. Pete Catalyst</p>
          <p className="leading-6">
            The Catalyst honors its name by aggregating & curating the sparks
            that propel the St Pete engine. It is a modern news platform,
            powered by community sourced content and augmented with directed
            coverage. Bring your news, your perspective and your spark to the St
            Pete Catalyst and take your seat at the table.
          </p>
        </div>
        <div className="flex justify-center items-center col-span-12 lg:col-span-4 xl:col-span-6 mb-20 md:mb-12 mt-8 lg:my-0 order-1	md:order-none">
          <a href="/home" title="Catalyst Home">
            <CatalystIcon />
          </a>
        </div>
        <div className="flex flex-col col-span-12 lg:col-span-4 xl:col-span-3 order-2 md:order-none">
          <div className="flex justify-between mb-14 md:mb-0">
            <a
              href="mailto:spark@stpetecatalyst.com"
              target="_blank"
              title="Email"
            >
              <EmailIcon
                addClassName="text-white"
                addSvgClassName="h-[42px] md:h-auto md:w-auto w-[42px]"
              />
              <span className="sr-only">Email</span>
            </a>
            <a
              href="https://www.facebook.com/stpetecatalyst/"
              target="_blank"
              title="Facebook"
            >
              <FacebookIcon
                addClassName="text-white"
                addSvgClassName="h-[42px] md:h-auto md:w-auto w-[42px]"
              />
              <span className="sr-only">Facebook</span>
            </a>
            <a
              href="https://twitter.com/stpetecatalyst"
              target="_blank"
              title="Twitter"
            >
              <TwitterIcon
                addClassName="text-white"
                addSvgClassName="h-[42px] md:h-auto md:w-auto w-[42px]"
              />
              <span className="sr-only">Twitter</span>
            </a>
            <a
              href="https://www.instagram.com/stpetecatalyst/"
              target="_blank"
              title="Instagram"
            >
              <InstagramIcon
                addClassName="text-white"
                addSvgClassName="h-[42px] md:h-auto md:w-auto w-[42px]"
              />
              <span className="sr-only">Instagram</span>
            </a>
            <a
              href="https://www.linkedin.com/company/stpetecatalyst/"
              target="_blank"
              title="Linkedin"
            >
              <LinkedinIcon
                addClassName="text-white"
                addSvgClassName="h-[42px] md:h-auto md:w-auto w-[42px]"
              />
              <span className="sr-only">Linkedin</span>
            </a>
          </div>
          <div className="ml-24 md:ml-0">
            <LinksSection />
            <SiteSection />
          </div>
        </div>
      </div>
      <div className="mt-32 md:mt-12 border-t-[1px] border-white pt-6 md:pt-5 pb-14 md:pb-4 text-right text-sm md:text-[10px] font-extralight">
        © {new Date().getFullYear()} CATALYST |{" "}
        <a href="#" className="hover:text-primary">
          DISCLAIMER
        </a>
      </div>
    </div>
  );
}
