import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { PostLink } from "../../components/PostLink";
import { ShuffleType } from "../../types";

type ShuffleExploringTileProps = {
  post: ShuffleType;
};
export function ShuffleExploringTile({ post }: ShuffleExploringTileProps) {
  return (
    <PostLink
      slug={`shuffle/${post?.slug}`}
      preloadData={post}
      title={sanitize(post?.title)}
      className="col-span-12 md:col-span-1 bg-black rounded-2xl grow block p-0"
    >
      <div className="w-full bg-gray rounded-tl-2xl rounded-tr-2xl overflow-hidden">
        {post?.featuredImage && (
          <img
            alt={sanitize(post?.title)}
            src={post?.featuredImage}
            title={sanitize(post?.title)}
            className="bg-black rounded-t-2xl brightness-100 group-hover:brightness-75 transition duration-300 items-center object-cover w-full h-[226px]"
          />
        )}
      </div>
      <div className="p-5 bg-white rounded-b-xl">
        <div
          className="font-semibold text-xl text-black"
          dangerouslySetInnerHTML={sanitizeForReact(post?.title)}
        ></div>
      </div>
    </PostLink>
  );
}
