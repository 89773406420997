import { queryRequest } from "../../utils/request";

const getAll = (params) => {
  let type = params?.postType;
  let data;
  switch (type) {
    case "newsletter":
      data = {
        path: "newsletters",
        params: params ? "?" + new URLSearchParams(params).toString() : "",
        method: "GET",
        authenticated: true,
      };
      break;
    default:
      data = {
        path: "news/posts",
        params: params ? "?" + new URLSearchParams(params).toString() : "",
        method: "GET",
        authenticated: true,
      };
      break;
  }
  return queryRequest(data);
};

const getCounters = (params) => {
  let type = params?.postType;
  let data;

  switch (type) {
    case "newsletter":
      data = {
        path: "newsletters/counters",
        params: params ? "?" + new URLSearchParams(params).toString() : "",
        method: "GET",
        authenticated: true,
      };
      break;
    default:
      data = {
        path: "news/posts/counters",
        params: params ? "?" + new URLSearchParams(params).toString() : "",
        method: "GET",
        authenticated: true,
      };
      break;
  }
  return queryRequest(data);
};

const get = (id) => {
  return queryRequest({
    path: "news/posts/" + id,
    method: "GET",
  });
};

const create = (data) => {
  return queryRequest({
    path: "news/wp/posts",
    method: "POST",
    body: data,
    authenticated: true,
  });
};

const update = (id, data) => {
  // TODO: Implement.
  return null;
};

const remove = (id) => {
  // TODO: Implement.
  return null;
};

const clone = (id) => {
  return queryRequest({
    path: `journalist/posts/${id}/clone`,
    method: "POST",
    authenticated: true,
  });
};

const PostTypeService = {
  getAll,
  getCounters,
  get,
  create,
  update,
  remove,
  clone,
};

export default PostTypeService;
