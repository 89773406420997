import Title from "../../components/Title";
import React from "react";
import DefaultLayout from "../../components/PostType/DefaultLayout";

export function Index() {
  return (
    <div className="space-y-4">
      <Title text="All Shuffles" />
      <DefaultLayout type="shuffle" />
    </div>
  );
}
