import { EmailIcon } from "../EmailIcon";
import { WebsiteIcon } from "../WebsiteIcon";
import { LinkedinIcon } from "../LinkedinIcon";
import { FacebookIcon } from "../FacebookIcon";
import { TwitterIcon } from "../TwitterIcon";
import { InstagramIcon } from "../InstagramIcon";
import { CityverseIcon } from "../CityverseIcon";

type SocialIconsProps = {
  data: [
    link: {
      key: string;
      href: string;
    }
  ];
  theme?: string;
};

const components = {
  email: EmailIcon,
  website: WebsiteIcon,
  linkedin: LinkedinIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  cityverse: CityverseIcon,
};

export function SocialIcons({ data, theme = "black" }: SocialIconsProps) {
  return (
    <div className="flex items-center space-x-3">
      {data &&
        data.map((icons, index) => {
          const SpecificIcon = components[icons.key];
          return (
            <div key={index}>
              <a
                href={`${
                  icons.key === "email"
                    ? `mailto:${icons.href}`
                    : `${icons.href}`
                }`}
                title={icons.href}
                target="_blank"
              >
                <SpecificIcon
                  SocialIcons={icons.key}
                  addClassName={`text-${theme}`}
                />
              </a>
            </div>
          );
        })}
    </div>
  );
}
