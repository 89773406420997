export function MenuLink({ title, to, size = "text-md", addClassName = "" }) {
  return (
    <a
      href={to}
      className={`uppercase block ${size} hover:text-primary transition-colors leading-6 ${addClassName}`}
      title={title}
    >
      {title}
      <span className="sr-only">{title}</span>
    </a>
  );
}
