const Label = ({ text = "", className = "" }) => {
  let wrapperClasses = "font-medium " + className;
  return (
    <label className={`${wrapperClasses}`}>
      <strong>{text}</strong>
    </label>
  );
};

export default Label;
