import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { relativeTime } from "../../utils/formatDate";
import { PostLink } from "../../components/PostLink";
import type { PostType } from "../../types";

type CreateTitleProps = {
  index: number;
  post: PostType;
};

export function CreateTile({ post, index }: CreateTitleProps) {
  const {
    featuredImage: image,
    effectivePublishDate: date,
    title,
    slug,
  } = post;
  const colSpan =
    index > 1 ? "col-span-3 md:col-span-6 xl:col-span-3" : "md:col-span-6";
  const titleSize = index > 1 ? "md:text-xl md:leading-6" : "";

  return (
    <PostLink
      className={`${colSpan} bg-white rounded-t-2xl p-5 col-span-12 group`}
      title={sanitize(title)}
      slug={slug}
      preloadData={post}
    >
      {image && (
        <img
          alt={sanitize(title)}
          src={image}
          title={sanitize(title)}
          className="aspect-video w-full object-cover rounded-t-2xl mb-4 brightness-100 group-hover:brightness-75 transition duration-300"
        />
      )}
      <div className="flex space-x-3 group-hover:opacity-60 transition duration-300">
        <p className="text-primary text-sm font-semibold uppercase leading-4">
          ARTS & CULTURE
        </p>
        <p className="text-sm font-normal leading-4">{relativeTime(date)}</p>
      </div>
      <h2
        className={`${titleSize} text-2xl leading-8 font-medium mb-2 group-hover:opacity-60 transition duration-300 line-clamp-2`}
        dangerouslySetInnerHTML={sanitizeForReact(title)}
        title={sanitize(title)}
      />
    </PostLink>
  );
}
