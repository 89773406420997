import { useEffect, useState } from "react";
import { Add, Bars, Bolt, EditLine, Exit, EyeOpen } from "../Icons";
import { SidebarButton } from "./SidebarButton";
import { useLocation, useNavigate } from "react-router-dom";

export function SidebarMenu() {
  const [compact, setCompact] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentSubmenu, setCurrentSubmenu] = useState("");
  const [currentRoute, setCurrentRoute] = useState("");
  const [currentHighlightedItem, setCurrentHighlightedItem] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const exitEditor = () => {
    return navigate("/news");
  };

  const SubMenus = {
    Index: [
      {
        id: "post",
        name: "Article",
        path: "post",
      },
      {
        id: "daily-edition",
        name: "Daily Edition (Generator)",
        path: "daily-edition",
      },
      {
        id: "hustle",
        name: "Hustles",
        path: "hustle",
      },
      {
        id: "influencer",
        name: "Influencers",
        path: "influencer",
      },
      {
        id: "shuffle",
        name: "Shuffles",
        path: "shuffle",
      },
      {
        id: "zap",
        name: "Zaps",
        path: "zap",
      },
      // {
      //   id: "events",
      //   name: "Events",
      //   path: "events",
      // },
      {
        id: "partner-stories",
        name: "Partner Stories",
        path: "partner-stories",
      },
    ],
    Create: [
      {
        id: "post",
        name: "Article",
        path: "post/create",
      },
      {
        id: "daily-edition",
        name: "Daily Edition (Generator)",
        path: "daily-edition/create",
      },
      {
        id: "hustle",
        name: "Hustles",
        path: "hustle/create",
      },
      {
        id: "influencer",
        name: "Influencers",
        path: "influencer/create",
      },
      {
        id: "shuffles",
        name: "Shuffles",
        path: "shuffle/create",
      },
      {
        id: "zap",
        name: "Zaps",
        path: "zap/create",
      },
      // {
      //   id: "events",
      //   name: "Events",
      //   path: "events/create",
      // },
      {
        id: "partner-stories",
        name: "Partner Stories",
        path: "partner-stories/create",
      },
    ],
  };

  const separator = (
    <hr className="w-full m-1 mt-3 mb-3 px-4 border-[#636363]" />
  );

  const navigateTo = (item) => {
    setCurrentRoute(item.path);
    setCurrentSubmenu("");
    navigate(item.path);
  };

  const handleSubmenuState = (key) => {
    setCurrentSubmenu(currentSubmenu === "" ? key : "");
  };

  useEffect(() => {
    for (let i in SubMenus) {
      for (let j in SubMenus[i]) {
        if (location.pathname.includes(SubMenus[i][j].path)) {
          setCurrentRoute(SubMenus[i][j].path);
          setCurrentHighlightedItem(i);
        }
      }
    }

    setIsEdit(location.pathname.includes("edit/"));
  }, [location]);

  return (
    <div className="h-full flex flex-col bg-black md:rounded-2xl lg:ml-2 mb-0 md:mb-4 lg:mb-0 p-4">
      <SidebarButton
        icon={Bars()}
        label="Compact"
        collapsed={compact}
        onClick={() => {
          setCompact(!compact);
        }}
        isOpen={false}
        isHighlighted={false}
        addClassName="hidden lg:flex"
      />
      <SidebarButton
        icon={Exit()}
        label="Exit Editor"
        collapsed={compact}
        onClick={exitEditor}
        isOpen={false}
        isHighlighted={false}
      />
      {separator}
      <SidebarButton
        icon={EyeOpen()}
        label="View all"
        collapsed={compact}
        currentRoute={currentRoute}
        onClick={() => {
          handleSubmenuState("Index");
        }}
        onSubmenuItemClick={navigateTo}
        submenuItems={SubMenus.Index}
        isOpen={currentSubmenu === "Index"}
        isHighlighted={
          (currentSubmenu === "Index" || currentHighlightedItem === "Index") &&
          !isEdit
        }
      />
      <SidebarButton
        icon={Bolt()}
        label="Recent&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
        collapsed={compact}
        onClick={() => {}}
        isOpen={false}
        isHighlighted={false}
        addClassName="hidden"
      />
      {separator}
      <SidebarButton
        icon={EditLine()}
        label="Article"
        collapsed={compact}
        onClick={() => {}}
        isOpen={false}
        isHighlighted={isEdit}
      />
      <SidebarButton
        icon={Add()}
        label="Add New"
        collapsed={compact}
        currentRoute={currentRoute}
        onClick={() => {
          handleSubmenuState("Create");
        }}
        onSubmenuItemClick={navigateTo}
        submenuLabel="Add New"
        submenuItems={SubMenus.Create}
        isOpen={currentSubmenu === "Create"}
        isHighlighted={
          (currentSubmenu === "Create" ||
            currentHighlightedItem === "Create") &&
          !isEdit
        }
      />
    </div>
  );
}
