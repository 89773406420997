import { useQueryClient } from "react-query";
import { Link, LinkProps, useLocation } from "react-router-dom";
import type { PostType } from "../../types";

type PostLinkProps = Omit<LinkProps, "to"> & {
  slug: string;
  intent?: boolean;
  preloadData?: PostType;
  to?: string;
};

export function PostLink({
  children,
  slug,
  intent = true,
  preloadData,
  onMouseOver,
  ...props
}: PostLinkProps) {
  const queryClient = useQueryClient();

  const onHover = (e) => {
    intent &&
      queryClient.setQueryData(
        ["post", { slug }],
        { data: { posts: [preloadData] } },
        {
          updatedAt: Date.now(),
        }
      );
    onMouseOver?.(e);
  };

  return (
    <Link to={`/${slug}`} onMouseOver={onHover} {...props}>
      {children}
    </Link>
  );
}
