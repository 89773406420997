import BaseService from "./BaseService";

const getAll = (params) => {
  return BaseService.getAll("news/wp/posts", params, true);
};

const get = (id) => {
  return BaseService.get(`news/posts/${id}`, true);
};

const create = (data) => {
  return BaseService.create("journalist/posts", data, true);
};

const update = (id, data) => {
  return BaseService.update(`journalist/posts/${id}`, data, true);
};

const remove = (id) => {
  return BaseService.remove(`journalist/posts/${id}`, "", true);
};

const ArticleService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default ArticleService;
