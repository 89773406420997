import { useEffect, useState } from "react";

const Checkbox = ({
  name,
  label = null,
  checked = false,
  className = "",
  inputClassName = "",
  onChange = null,
}) => {
  const [inputValue, setInputValue] = useState(checked);

  useEffect(() => {
    setInputValue(checked);
  }, [checked]);

  const checkBox = (
    <input
      name={name}
      type="checkbox"
      className={"mr-1 " + inputClassName}
      checked={inputValue}
      onChange={(e) => {
        const newInputValue = !inputValue;
        setInputValue(newInputValue);
        if (onChange) {
          onChange(newInputValue);
        }
      }}
    />
  );
  return (
    <div key={name} className={className}>
      {label ? (
        <label>
          {checkBox}
          <span>{label}</span>
        </label>
      ) : (
        checkBox
      )}
    </div>
  );
};
export default Checkbox;
