export function ZapModalCloseIcon() {
  return (
    <svg
      width="56"
      height="36"
      viewBox="0 0 56 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="14.3693"
        height="1.39058"
        rx="0.695291"
        transform="matrix(-0.728412 0.68514 -0.728412 -0.68514 33.4805 16.2598)"
        fill="white"
        stroke="white"
      />
      <rect
        width="14.3693"
        height="1.39058"
        rx="0.69529"
        transform="matrix(-0.728412 -0.68514 0.728412 -0.68514 32.4668 26.1045)"
        fill="white"
        stroke="white"
      />
      <path
        d="M2 36L2 5.92284C2 3.83166 3.7983 2.1379 6.01854 2.1379L48.9768 2.13789C51.7496 2.13789 54 4.25746 54 6.86907L54 36"
        stroke="url(#paint0_linear_1726_735)"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1726_735"
          x1="21.5"
          y1="2.1368"
          x2="21.5"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2BE2D8" />
          <stop offset="1" stopColor="#2BE2D8" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
