import { useRef, useMemo } from "react";
import { sanitize, sanitizeForReact } from "../../utils/sanitizeHtml";
import { queryRequest } from "../../utils/request";
import { Link, useParams } from "react-router-dom";
import { relativeTime } from "../../utils/formatDate";
import { useQuery } from "react-query";
import { AuthorTile } from "../../components/AuthorTile";
import { CloseIcon } from "../../components/CloseIcon";
import { RewardFluents } from "../../components/RewardFluents";
import { Share } from "../../components/Share";
import { ShareBar } from "../../components/ShareBar";
import { Badge } from "../../components/Badge";
import { Seo } from "../../components/Seo";
import { PostPrint } from "../../components/PostPrint";
import { ToTopIcon } from "../../components/ToTopIcon";
import { Advertisement } from "../../components/Advertisement";
import { KeepExploring } from "../../components/KeepExploring";
import { FluentCount } from "../../components/FluentCount";
import { useDetectMobile } from "../../utils/isMobile";
import { TagList } from "../../components/TagList";
import { Modal } from "../../components/Modal";
import Skeleton from "@mui/material/Skeleton";

const getPost = async ({ queryKey }) => {
  const [_, { slug }] = queryKey;
  return queryRequest({
    path: "news/posts",
    params: `?slug=${slug}`,
  });
};

export function PostModal() {
  const { slug } = useParams();
  const modalRef = useRef(null);
  const shareRef = useRef(null);
  const { isMobile } = useDetectMobile();

  const { data: postData, isLoading } = useQuery(["post", { slug }], getPost);

  const scrollTop = () => {
    modalRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const showShare = () => {
    shareRef.current.classList.add("!opacity-100");
  };

  const post = useMemo(
    () => (isLoading ? {} : postData?.data.posts[0]),
    [isLoading, postData]
  );

  return (
    <Modal appearance="post" bgOpacity="0.85" hideClose={true}>
      <div className="grid grid-cols-12" ref={modalRef}>
        <div className="col-span-12 xl:col-span-9">
          <div className="px-5 pt-3 mb-6 border-t-2 border-l-2 border-r-2 border-primary rounded-t-2xl relative">
            <div className="flex items-center justify-between relative top-3">
              <div className="font-montserrat italic font-semibold text-2xl text-white uppercase md:ml-4">
                News
              </div>
              <div>
                <FluentCount />
              </div>
            </div>
          </div>
          <div className="bg-white py-7 rounded-xl z-90 grid grid-cols-12 gap-x-8 mt-1">
            <div className="col-span-2 relative">
              <div className="md:px-5">
                {isLoading ? (
                  <Skeleton
                    sx={{ bgcolor: "grey.900" }}
                    className="opacity-70 mb-5"
                    animation="pulse"
                    height={44}
                    width={74}
                    variant="rectangular"
                  />
                ) : (
                  post?.id && (
                    <Badge
                      title={post?.primaryCategory?.name}
                      size="text-sm md:text-lg"
                      link
                    />
                  )
                )}
              </div>
              <Link
                to={"/"}
                className="sticky top-[50%] inline-block bg-black/[85%] py-1 xl:py-6 md:px-2 xl:px-4 -ml-1 md:-ml-0  rounded-r-2xl"
              >
                <CloseIcon
                  addClassName="block text-white"
                  addSvgClassName="w-[28px] md:w-[56px]"
                />
              </Link>
            </div>
            <div className="col-span-8">
              {!isLoading && !post?.id ? (
                <div>
                  <h1 className="text-3xl mb-4">Content not found</h1>
                  <p>
                    The content you tried to access has been moved/deleted or
                    does not exist.
                    <Link to={"/"}> Go to homepage.</Link>
                  </p>
                </div>
              ) : (
                <>
                  {isLoading ? (
                    <div>
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        className="rounded-xl opacity-70 mb-5"
                        animation="pulse"
                        height={isMobile ? 160 : 120}
                        variant="rectangular"
                      />
                      <div className="flex items-center justify-between mb-5">
                        <div className="flex items-center">
                          <Skeleton
                            sx={{ bgcolor: "grey.900" }}
                            className="opacity-70"
                            animation="pulse"
                            height={isMobile ? 28 : 38}
                            width={isMobile ? 28 : 38}
                            variant="circular"
                          />
                          <Skeleton
                            sx={{ bgcolor: "grey.900" }}
                            className="opacity-70 ml-2 mr-2 rounded-2xl"
                            animation="pulse"
                            height={isMobile ? 20 : 24}
                            width={77}
                            variant="rectangular"
                          />
                          <Skeleton
                            sx={{ bgcolor: "grey.900" }}
                            className="opacity-70 ml-2 mr-2 rounded-2xl"
                            animation="pulse"
                            height={isMobile ? 20 : 24}
                            width={2}
                            variant="rectangular"
                          />
                          <Skeleton
                            sx={{ bgcolor: "grey.900" }}
                            className="opacity-70 ml-2 rounded-2xl"
                            animation="pulse"
                            height={isMobile ? 20 : 24}
                            width={77}
                            variant="rectangular"
                          />
                        </div>
                        <Skeleton
                          sx={{ bgcolor: "grey.900" }}
                          className="opacity-70 rounded-2xl !hidden md:!block"
                          animation="pulse"
                          height={30}
                          width={30}
                          variant="circular"
                        />
                      </div>
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        className="opacity-70 mb-5 rounded-2xl"
                        animation="pulse"
                        height={isMobile ? 169 : 530}
                        variant="rectangular"
                      />
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        className="opacity-70 mb-5 rounded-2xl"
                        animation="pulse"
                        height={530}
                        variant="rectangular"
                      />
                    </div>
                  ) : (
                    <div>
                      <Seo
                        title={`Catalyst News | ${post?.title}`}
                        description={post?.title}
                        name={post?.author.name}
                      />

                      <h1
                        className="text-4xl md:text-5xl xl:text-6xl mb-5"
                        dangerouslySetInnerHTML={sanitizeForReact(post?.title)}
                      />

                      <div className="flex flex-col md:flex-row justify-between">
                        <div className="flex items-center space-x-2 mb-5">
                          <AuthorTile name={post?.author?.name} />
                          <div>|</div>
                          <div>{relativeTime(post?.effectivePublishDate)}</div>
                        </div>
                        <div className="hidden md:block">
                          <PostPrint
                            featuredImage={post?.featuredImage}
                            title={post?.title}
                            content={post?.htmlContent}
                            author={post?.author?.name}
                            time={post?.effectivePublishDate}
                          />
                        </div>
                      </div>

                      <img
                        className="rounded-xl mb-5"
                        src={post?.featuredImage}
                        alt={sanitize(post?.title)}
                      />

                      <div
                        className="font-['PT+Serif']"
                        dangerouslySetInnerHTML={sanitizeForReact(
                          post?.htmlContent
                        )}
                      />
                    </div>
                  )}
                </>
              )}

              {post?.id && (
                <div>
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="flex items-center md:space-x-5 flex-wrap justify-between">
                      <RewardFluents onClick={() => {}} />
                      <Share onClick={showShare} />
                      <div
                        ref={shareRef}
                        className="opacity-0 transition-opacity duration-300 w-full md:w-auto"
                      >
                        <ShareBar
                          url={`${window.location.origin}/news/${
                            post?.postType === "post"
                              ? ""
                              : post?.postType + "/"
                          }${post?.slug}`}
                          addClassName="w-full justify-center my-3 md:my-0"
                        />
                      </div>
                    </div>
                    <div className="flex items-center md:space-x-5">
                      <div className="hidden md:block">
                        <PostPrint
                          featuredImage={post?.featuredImage}
                          title={post?.title}
                          content={post?.htmlContent}
                          author={post?.author?.name}
                          time={post?.effectivePublishDate}
                        />
                      </div>
                      <button
                        aria-label="scroll_to_top"
                        className="w-full md:w-auto justify-center flex md:block"
                        onClick={scrollTop}
                      >
                        <ToTopIcon />
                      </button>
                    </div>
                  </div>
                  <TagList tags={post?.tags} />
                </div>
              )}
              <KeepExploring currentId={post?.id} />
            </div>
          </div>
        </div>
        <div className="col-span-12 xl:col-span-3 ml-0 lg:ml-1 2xl:ml-6">
          <div className="sticky top-6 flex justify-center flex-col lg:flex-row xl:flex-col space-y-6 lg:space-y-0 xl:space-y-14  mt-10 xl:mt-0">
            <Advertisement
              path="/21735245768/SPC_NEWS_MREC_T"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-p1"
            />
            <Advertisement
              path="/21735245768/SPC_PL_HP_MREC_1"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-p2"
            />
            <Advertisement
              path="/21735245768/SPC_PL_HP_MREC_2"
              size={[300, 250]}
              id="div-gpt-ad-1682306808816-p3"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
