import Image from "./Image";
import TextInput from "./TextInput";
import { useState } from "react";

type AdvertisementType = {
  image: {
    files: null;
    preview: string;
  };
  link: string;
};

const Advertisement = ({
  name,
  value = null,
  setValue = null,
  getValues = null,
  className = "",
}) => {
  const initialValues: AdvertisementType = {
    image: {
      files: null,
      preview: "",
    },
    link: "",
  };

  const imageKeys = ["preview"];

  if (value) {
    for (const i in initialValues) {
      if (value[i]) {
        if (imageKeys.includes(i)) {
          initialValues.image[i] = value[i];
        } else {
          initialValues[i] = value[i];
        }
      }
    }
  }

  const [values, setValues] = useState(initialValues);

  const handleImageInput = (fieldName, newValue) => {
    values[fieldName].preview = newValue.preview;
    values[fieldName].files = newValue.files;

    setValues(values);

    if (setValue) {
      setValue(name, values);
    }
  };

  const handleTextInput = (fieldName, newValue) => {
    setValues({
      ...values,
      [fieldName]: newValue,
    });
    if (setValue) {
      setValue(name, values);
    }
  };

  return (
    <div className={`${className}`}>
      <Image
        name="image"
        value={values.image}
        setValue={handleImageInput}
        showCaptionInput={false}
        showUrlInput={false}
      />

      <TextInput
        className="mt-1"
        name="link"
        label="Link"
        setValue={handleTextInput}
        value={values.link}
      />
    </div>
  );
};

export default Advertisement;
