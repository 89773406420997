import { sanitize } from "../../utils/sanitizeHtml";

type AuthorTileProps = {
  name: string;
  image?: string;
  addClassName?: string;
};

export function AuthorTile({
  name,
  image,
  addClassName = "",
}: AuthorTileProps) {
  return (
    <div className={`flex items-center space-x-2 ${addClassName}`}>
      {image && <img alt={sanitize(name)} src={image} />}
      <div className="text-sm md:text-base font-normal">{name}</div>
    </div>
  );
}
